import React from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/studio/toolbox/templates/Templates.view";

function Templates({ flowId }) {
  const reqFlow = useDetail(`{
    flow {      
      token
    }
  }`, flowId);

  if (reqFlow.loading) return <Loading />;
  if (reqFlow.error) return "Error";

  const { flow = {} } = reqFlow.data || {};

  return (
    <View
      flow={flow}
    />
  );
}

export default Templates;