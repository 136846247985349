import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/tmisc/use_cases/UseCases.view";

function UseCases({ match }) {

  const userProfile = match?.params?.user_profile;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const hiddenUserId = searchParams.get('u');
  if (hiddenUserId) localStorage.setItem("hiddenUserId", hiddenUserId);

  const flowTemplateFilters = {
    "template_type": "USE_CASE"
  }

  if (selectedCategory)
    flowTemplateFilters["template_categories"] = [selectedCategory.id];

  const reqFlowTemplates = useGet("/flows/get_template_flows", flowTemplateFilters);
  const reqTemplateProfiles = useGet("/template_categories/get_template_category", {}, {
    onCompleted: (data) => {
      const defaultCategory = data.find((category) => category.urlSlug == "all");
      if (userProfile != null && userProfile != "" && selectedCategory == null) {
        const userProfileCategory = data.find((category) => category.urlSlug == userProfile);
        if (userProfileCategory) {
          setSelectedCategory(userProfileCategory);
          reqFlowTemplates.refetch();
        } else setSelectedCategory(defaultCategory);
      } else setSelectedCategory(defaultCategory);
    }
  });

  const onChangeCategory = (newProfile) => {
    setSelectedCategory(newProfile);
    reqFlowTemplates.refetch();
  }

  if (reqTemplateProfiles.loading || reqFlowTemplates.loading) return <Loading />;
  if (reqTemplateProfiles.error || reqFlowTemplates.error) return "Error";

  const templateCategories = reqTemplateProfiles.data ?? [];
  const flows = reqFlowTemplates.data ?? [];

  return (
    <View
      flows={flows}
      selectedCategory={selectedCategory}
      flowTemplateFilters={flowTemplateFilters}
      templateCategories={templateCategories}
      onChangeCategory={onChangeCategory}
    />
  );
}

export default UseCases;