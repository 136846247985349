import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/studio/toolbox/apps/setup/SetupApp.view";

function SetupApp({ match, flowId }) {

  const appId = match.params?.appId;
  const history = useHistory();
  if (!appId) window.location.replace("/app");

  const reqApp = useDetail(`{
    app {
      name
      uiSettings
      setupSettings
    }
  }`, appId)

  const reqAppSettings = useQuery(`{
    appSettingses {
      settings
    }
  }`, `flow.id=${flowId} AND app.id=${appId}`)

  const [callSetup, reqSetup] = usePost("/apps/setup_app", {
    onCompleted: () => {
      reqAppSettings.refetch();
      history.replace(`/flow`);
    }
  })

  const onSubmit = (values) => {
    callSetup({
      app_id: appId,
      flow_id: flowId,
      values: values
    })
  }

  if (reqApp.loading || reqAppSettings.loading) return <Loading />;
  if (reqApp.error || reqAppSettings.error) return "Error";

  const { app = {} } = reqApp.data;
  const { appSettingses = [] } = reqAppSettings.data;
  let initialValues = {};

  if (appSettingses.length > 0) {
    const appSettings = appSettingses[0];
    const settings = appSettings?.settings ?? {};
    initialValues = settings?.setup_values ?? {};
  }

  return (
    <View
      app={app}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}

export default SetupApp;