import React from "react";
import { Loading } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";
import NavCategory from "components/studio/toolbox/templates/Templates.sub.navCategory.view";


const TemplatesView = ({
  filters,
  templates,
  templateCategories,
  isTemplatesLoading,
  isTemplateCategoriesLoading,
  onClickFilterCategory,
  onClickRemoveCategory,
  onClickUseCase,
}) => (
  <div>
    
    <div className="w-100" id="template-categories-container">
      <ScrollArrow
        scrollAmount={250}
        backgroundFadeColor={{ red: 235, green: 241, blue: 246 }}
        leftButtonStyle={{
          paddingLeft: "2px",
          paddingRight: "2px"
        }}
        rightButtonStyle={{
          paddingLeft: "2px",
          paddingRight: "2px"
        }}
        arrowsWidth="auto"
      >
        <NavCategory
          key={0}
          category={{ id: "all", uiSettings: { display: { icon: "fas fa-home", title: "Todas las soluciones" } } }}
          activeCategories={filters?.template_categories ?? []}
          onClickFilterCategory={onClickFilterCategory}
          onClickRemoveCategory={onClickRemoveCategory}
        />
        {templateCategories.map((category, idx) => (
          <NavCategory
            key={idx}
            category={category}
            activeCategories={filters?.template_categories ?? []}
            onClickFilterCategory={onClickFilterCategory}
            onClickRemoveCategory={onClickRemoveCategory}
          />
        ))}
      </ScrollArrow>
    </div>

    <div className="row mt-5" id="template-items">
      {(isTemplatesLoading) ? <Loading /> : (
        <>
          {templates.map((template, idx) => (
            <div key={idx} className="btn col-md-12 btn-outline-secondary flow-template mb-2 py-2 px-3" style={{ cursor: "default" }}>

              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2 text-left py-1">
                  <div className="pb-0 mb-0 template-title">
                    {template?.rootName}
                  </div>
                  { template?.rootDescription &&
                  <div className="template-subtitle">
                    {template?.rootDescription}
                  </div> }
                </div>
                <div>
                  <button className="btn btn-sm btn-accent template-btn" onClick={() => onClickUseCase(template)}>
                    <i class="fas fa-plus mr-1"></i> Usar
                  </button>
                </div>
              </div>

            </div>
          ))}
        </>
      )}
    </div>

  </div>
);


export default TemplatesView;