
/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "seed/helpers"
import Board from "components/studio/board/Board";
import Toolbox from "components/studio/toolbox/Toolbox";
import Executions from "components/studio/board/executions/Executions";
import Contact from "components/studio/forms/contact_us/Contact";
import Execution from "components/studio/forms/executions/Execution";
import HelpMessage from 'components/util/helpers/HelpMessage';
import WSManager from "components/util/ws";

const ControllerView = ({
  flow,
  nodes,
  edges,
  user,
  selectedNodes,
  activePage,
  reactFlowWrapper,
  actualExecutionId,
  execModeRef,
  wsExecutionRef,
  wsNodeRef,
  toolboxRef,
  toolboxNodeContext,
  toolboxActiveSection,
  showAnimations,
  isFlowBeingSaved,
  isGeneratingFlow,
  isOwner,
  isEditor,
  isValidator,
  isDeveloper,
  isAnonymous,
  isContactUsShown,
  isRPADesigner,
  isExecutionDetailsShown,
  isAnimationOptionShown,
  isPaginationShown,
  isToolboxCollapsed,
  isExecutionsCollapsed,
  setIsContactUsShown,
  setReactFlowInstance,
  setActivePage,
  setIsExecutionDetailsShown,
  setToolboxActiveSection,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onClickAnnotation,
  onClickUndo,
  onClickRedo,
  onDeleteSelectedNodes,
  onExecutionMessage,
  onNodeMessage,
  onChangeAnimations,
  onGenerateFlow,
  onOpenToolbox,
  onCloseToolbox,
  onClickExecutions,
  onCloseExecutions,
  reloadFlow,
}) => (
  <div id="flow" className={`${isValidator ? "validator" : ""}`}>
    <div class="container-fluid p-0 h-100">

      <div class="d-flex h-100" style={{ overflowY: "auto" }}>

        <div id="workspace" class={"w-100"}>
          {!isRPADesigner && !isValidator && (
            <div id="workspace-header" className="d-flex justify-content-between align-items-center">
              <div id="workspace-title" class="d-flex align-items-center">

                <a
                  className="back-button d-flex align-items-center justify-content-center"
                  title="Regresar"
                  href={`/p/${flow.token}`}
                  onClick={() => { }}>
                  <i class="fas fa-arrow-left d-block"></i>
                </a>

                <div class="w-auto ml-1">
                  <a className="d-flex" href={`/p/${flow.token}`} >
                      <div className="logo-workix"></div>
                      <div className="logo-sub"><label>Studio</label></div>
                  </a>
                </div>

              </div>

              <div id="workspace-actions" className="d-flex">

                <span className="d-flex align-items-center">
                  <HelpMessage message="Comparte tu solución con otras personas, permitiendo a otros colaborar"
                    style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                    <button className="btn btn-sm btn-outline-primary mr-2 share-button" onClick={onClickExecutions}>
                      <i class="fas fa-clock-rotate-left mr-1"></i> Ejecuciones
                    </button>
                  </HelpMessage>
                </span>

                {isAnonymous && (
                  <button id="contact-us-btn" className="btn btn-sm btn-accent px-3" onClick={() => setIsContactUsShown(true)}>
                    <i class="fa-solid fa-comment mr-1"></i> Contáctanos
                  </button>
                )}

              </div>
            </div>
          )}

          <div className="d-flex flex-row" style={{ height: isValidator ? "100%" : "calc(100% - 60px)" }}>

            {user.license?.name != "Test-ext" ?
              <Toolbox
                toolboxRef={toolboxRef}
                flowId={flow.id}
                pageId={activePage}
                nodes={nodes.filter((item) => item.type == "node")}
                currentNodeContext={toolboxNodeContext}
                activeSection={toolboxActiveSection}
                isAnonymous={isAnonymous}
                isCollapsed={isToolboxCollapsed}
                isValidator={isValidator}
                setActiveSection={setToolboxActiveSection}
                onClickNode={onClickNode}
                onOpenToolbox={onOpenToolbox}
                onCloseToolbox={onCloseToolbox}
                reloadFlow={reloadFlow}
              /> : null}

            <Board
              nodes={nodes}
              edges={edges}
              user={user}
              flowId={flow.id}
              toolboxRef={toolboxRef}
              activePage={activePage}
              selectedNodes={selectedNodes}
              showAnimations={showAnimations}
              isFlowBeingSaved={isFlowBeingSaved}
              isGeneratingFlow={isGeneratingFlow}
              isRPADesigner={isRPADesigner}
              isDeveloper={isDeveloper}
              isAnonymous={isAnonymous}
              isAnimationOptionShown={isAnimationOptionShown}
              isPaginationShown={isPaginationShown}
              reactFlowWrapper={reactFlowWrapper}
              setActivePage={setActivePage}
              setReactFlowInstance={setReactFlowInstance}
              onDropNode={onDropNode}
              onDragNode={onDragNode}
              onConnectEdge={onConnectEdge}
              onUpdateEdge={onUpdateEdge}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onClickNode={onClickNode}
              onClickAnnotation={onClickAnnotation}
              onDeleteSelectedNodes={onDeleteSelectedNodes}
              onClickUndo={onClickUndo}
              onClickRedo={onClickRedo}
              onChangeAnimations={onChangeAnimations}
              onGenerateFlow={onGenerateFlow}
            />

            <Executions
              flow={flow}
              isExecutionsCollapsed={isExecutionsCollapsed}
              onCloseExecutions={onCloseExecutions}
            />
          </div>

        </div>

      </div>
    </div>

    {isContactUsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsContactUsShown(false)}
        component={Contact}
      />
    )}

    {isExecutionDetailsShown && (
      <Modal
        width={800}
        height={800}
        animation={""}
        executionId={actualExecutionId}
        isShown={true}
        isButtonHidden={true}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={Execution}
      />
    )}

    <WSManager
      ref={wsExecutionRef}
      onMessageReceived={onExecutionMessage}
    />

    <WSManager
      ref={wsNodeRef}
      onMessageReceived={onNodeMessage}
    />

  </div>
);

export default ControllerView;