import React from "react";
import { Modal } from "seed/helpers";
import Filters from "components/collections/helpers/Filters";
import Sorting from "components/collections/helpers/Sorting";
import TableControls from "components/collections/Table.sub.controls.view";
import PaginationFooter from "components/util/helpers/PaginationFooter";
import FileViewer from "components/collections/helpers/FileViewer";
import { getDate } from "components/collections/util/format";

const TableView = ({
  height = "auto",
  columns = [],
  filteredData = [],
  filterDefault,
  isDragging = false,
  isReadOnly = false,
  isLoading = false,
  isFilePreviewShown = false,
  isFilterModalShown = false,
  isSearchShown = false,
  isSortingModalShown = false,
  showFilters = true,
  showSearch = true,
  showSorting = true,
  showForm = false,
  showDownloadZip = true,
  tableRef,
  pageSize,
  pageNum,
  totalPages,
  query,
  filters,
  sorting,
  search,
  formValue,
  selectedFile,
  setSelectedFile,
  setIsFilePreviewShown,
  setIsFilterModalShown,
  setIsOptionModalShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSearchShown,
  setIsSortingModalShown,
  onAddRow = () => { },
  onDeleteRow = () => { },
  onDownloadZip = () => { },
  onPageChange = () => { },
  onChangeSearch = () => { },
  onChangeQuery = () => { },
  onChangeFilters = () => { },
  onChangeSorting = () => { },
  onDragEnter = () => { },
  onDragLeave = () => { },
  onDragOver = () => { },
	onDrop = () => { },
  folioComponent
}) => {
  return (
    <div 
      className="h-100" 
      style={{position: "relative"}}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
    >

      {
        isDragging
          ? <div
              className="drag-area d-flex justify-content-center align-items-center flex-column"
              style={{ 
                cursor: "pointer", 
                height: "40vh",
                backgroundColor: "#F8FAFE",
                border: "2px dashed #d5dae0",
                borderRadius: "0.5rem",
                borderStyle: "dashed !important",
              }}
            >
          <i className="fas fa-cloud-upload-alt fa-3x text-muted mb-2 drag-area animate__animated animate__pulse animate__infinite"></i>
          <p className="font-weight-medium drag-area">Arrastra tus archivos aquí</p>
          <p className="small text-muted drag-area">o haz clic para seleccionarlos</p>
        </div>
          : <>
          
            <TableControls
              search={search}
              filters={filters}
              sorting={sorting}
              showSearch={showSearch}
              showForm={showForm}
              showSorting={showSorting}
              showFilters={showFilters}
              showDownloadZip={showDownloadZip}
              isReadOnly={isReadOnly}
              isSearchShown={isSearchShown}
              setIsImportModalShown={setIsImportModalShown}
              setIsExportModalShown={setIsExportModalShown}
              setIsFormModalShown={setIsFormModalShown}
              setIsSortingModalShown={setIsSortingModalShown}
              setIsSearchShown={setIsSearchShown}
              setIsFilterModalShown={setIsFilterModalShown}
              onChangeSearch={onChangeSearch}
              onDownloadZip={onDownloadZip}
            />

            <div 
              className="pt-1 mt-2 mb-1 scrollbar-narrow" 
              style={{ 
                overflowX: "auto" 
              }}
            >
              <table className="table-hover table-sm m-0 p-0 w-100">

                <thead 
                  style={{
                    borderBottom: "1px solid #d5dae0"
                  }}
                >
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" className="m-0 p-0 h5 text-dark font-weight-bold">Nombre</th>
                    <th scope="col" className="m-0 p-0 h5 text-dark font-weight-bold">Creación</th>
                    <th scope="col" className="m-0 p-0 h5 text-dark font-weight-bold">Tamaño</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody 
                  style={{
                    borderBottom: "1px solid #d5dae0"
                  }}
                >
                  {
                    filteredData.map((row, i) => (
                      <tr key={i}>

                        <td className="folio-column">
                          <div className="d-flex justify-content-center align-items-center mt-2 px-2">
                            <span>
                              {
                                folioComponent
                                  ? folioComponent(row)
                                  : <> {pageSize * (pageNum - 1) + i + 1}</>
                              }
                            </span>
                          </div>
                        </td>

                        <td 
                          className="m-0 py-0 px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedFile(row.data.file)}
                        >
                          <div className="d-flex justify-content-start align-items-center my-1">
                            <span>
                              {function(){

                                const value = row.data.file;
                                const extension = value?.name?.split('.').pop();

                                let icon = <i className="far fa-file" style={{color: "#000000"}}></i>;
                                if(extension == "pdf") icon = <i className="far fa-file-pdf" style={{color: "#D32F2F"}}></i>;
                                if(extension == "doc" || extension == "docx") icon = <i className="far fa-file-word" style={{color: "#1565C0"}}></i>;
                                return <> {icon} {(value?.name ?? "Archivo").substring(0, 25)}... </>;

                              }()}
                            </span>
                          </div>
                        </td>

                        <td className="m-0 py-0">
                          <div className="d-flex justify-content-start align-items-center my-1">
                            <span>
                              {getDate(row.data._internal_created_at, "YYYY-MM-DD")}
                            </span>
                          </div>
                        </td>

                        <td className="m-0 py-0">
                          <div className="d-flex justify-content-center align-items-center my-1">
                            <span>
                              {Math.round((row.data?.file?.size??0) / 1024)} KB
                            </span>
                          </div>
                        </td>
                        
                        {
                          !isReadOnly && 
                            <td
                              className="delete-row-button"
                              style={{ width: "3rem", cursor: "pointer" }}
                              onClick={() => onDeleteRow(i)}
                            >
                              <div className="h5 d-flex justify-content-center align-items-center my-1 py-0 px-3">
                                <span>-</span>
                              </div>
                            </td>
                        }

                      </tr>
                    ))
                  }
                  {
                    filteredData.length == 0 &&
                    <tr>
                      <td
                        colSpan={columns.length + 2}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          No hay archivos
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>

                {
                  !isReadOnly && !filters.length &&
                  <tfoot className=""
                    style={{ borderTop: filteredData.length == 0 ? "1.5rem solid #F8FAFE" : "none" }}>
                    <tr>
                      <td
                        colSpan={columns.length + 2}
                        style={{ cursor: "pointer" }}
                        onClick={onAddRow}
                      >
                        <div
                          className="h5 d-flex justify-content-center align-items-center my-0 py-2"
                          style={{
                            fontSize: "0.97em",
                            borderTop: filteredData.length == 0 ? "1px solid #d5dae0" : "none"
                          }}
                        >
                          <span className="mr-1">+</span> Cargar archivos
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                }

                </table>
            </div>

            <div className="w-100 px-3 m-0" id="collection-pagination">
              <nav class="mt-3 scrollbar-narrow">
                <PaginationFooter
                  pageNum={pageNum}
                  totalPages={totalPages}
                  onClickPage={onPageChange}
                />
              </nav>
            </div>

          </>
      }

      {
        isFilterModalShown && <Modal
          width="800"
          height={window.innerHeight - 150}
          columns={columns}
          query={query}
          onClose={() => setIsFilterModalShown(false)}
          component={() =>
            <div className="card card-body w-100">
              <Filters
                columns={columns}
                query={query}
                filters={filters}
                filterDefault={filterDefault}
                onChangeQuery={onChangeQuery}
                onChangeFilters={onChangeFilters}
                onClose={() => setIsFilterModalShown(false)}
              />
            </div>
          }
        />
      }

      {
        isSortingModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          columns={columns}
          sorting={sorting}
          onChangeSorting={onChangeSorting}
          onClose={() => setIsSortingModalShown(false)}
          component={Sorting}
        />
      }

      {
        isFilePreviewShown && <Modal
          width="300"
          height="250"
          onClose={() => {
            setSelectedFile(null);
            setIsFilePreviewShown(false);
          }}
          component={() => <FileViewer file={selectedFile} />}
        />
      }

    </div>
  )
}

export default TableView;