import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import StyleColor from "components/util/helpers/StyleColor"


const UseCaseView = ({ onChangeCaptcha }) =>
  <div style={{ height: "100vh", width: "100%", overflowY: "auto" }}>
    <StyleColor />

    <div class="container p-7" style={{ position: "relative", top: "25%", marginTop: "-10rem" }}>

      <a class="d-flex justify-content-center mb-8 w-100" href={"/use_cases"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.5rem" }} />
      </a>

      <div class="d-flex justify-content-center">

        <div class={`card d-flex align-items-center justify-content-center`}
          style={{ width: "30rem", height: "15rem" }}>

          <ReCAPTCHA
            sitekey="6Lej_aEpAAAAAHfVtQtDPTZFoVv8h-lODFtrmsx8"
            onChange={onChangeCaptcha} />

        </div>
      </div>
    </div>

  </div>;

export default UseCaseView;