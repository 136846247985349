import React, { useState } from "react";
import { useDetail, useQuery } from "seed/gql";
import View from "components/start/Start.view";
import { Loading } from "seed/helpers";

function Start({ uuid }) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  const userId = sessionStorage.getItem("id");
  sessionStorage.setItem("uuid", uuid);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isExecutionsShown, setIsExecutionsShown] = useState(false);

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      accountSettings
      organizationParticipants {
        type
      }
      license {
        name
      }
    }
  }`, userId);

  const reqFolders = useQuery(`{
    folders {
      name
    }
  }`, `user.id=${userId}`, { orderBy: "name" });


  const onClickLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/logout");
  }

  const onCreateFolder = (folderId) => {
    reqFolders.refetch();
    setSelectedFolder(folderId);
  }

  const onEditFolder = (folderId) =>
    reqFolders.refetch();

  const onDeleteFolder = (folderId) => {
    reqFolders.refetch();
    setSelectedFolder(null);
  }


  if (reqUser.loading || reqFolders.loading) return <Loading />;
  if (reqUser.error || reqUser.error) return "Error";

  const user = reqUser?.data?.user ?? {};
  const folders = reqFolders?.data?.folders ? [...reqFolders.data.folders] : [];
  const isOnboardingFinished = user?.accountSettings?.is_onboarding_finished??false;

  return (
    <View
      user={user}
      folders={folders}
      selectedFolder={selectedFolder}
      isExecutionsShown={isExecutionsShown}
      isDeveloperMode={isDeveloperMode}
      isOnboardingFinished={isOnboardingFinished}
      setSelectedFolder={setSelectedFolder}
      setIsExecutionsShown={setIsExecutionsShown}
      onClickLogout={onClickLogout}
      onCreateFolder={onCreateFolder}
      onEditFolder={onEditFolder}
      onDeleteFolder={onDeleteFolder}
    />
  );
}

export default Start;