import React from "react";
import { ModalRoute } from "seed/helpers";
import { Link } from "react-router-dom";
import FlowList from "components/tmisc/settings/flows/FlowList";
import FormSetFlow from "components/start/flows/FormSetFlow";
import FormSaveFlow from "components/start/flows/FormSaveFlow";

const FlowsView = ({ user }) => (
  <div id="flows" className="card">

    <div className="card-header">
      <h3 className="card-title">Soluciones</h3>
      {user.license?.name != "Test-ext" ?
        <Link to="/settings/flows/0/create_flow" className="btn btn-sm btn-primary">
          <i class="fas fa-plus mr-1"></i> Crear solución
        </Link> : null}
    </div>

    <div className="card-body animate__animated animate__fadeIn animate__faster">
      <FlowList user={user} />
    </div>

    <ModalRoute
      width="500"
      height="500"
      path="/settings/flows/:flowId/edit_flow"
      component={(props) => <FormSetFlow {...props} onDelete={() => window.location.replace("/app/settings/flows/")} />}
    />

    <ModalRoute
      width="500"
      height="500"
      path="/settings/flows/:folderId/create_flow"
      onSave={() => window.location.href = "/app/settings/flows"}
      component={FormSaveFlow}
    />

  </div>
);

export default FlowsView;