import React from "react";
import { Formik, Field, Form } from "formik";
import { ScriptTag } from "seed/helpers";
import StyleColor from "components/util/helpers/StyleColor"



const RestorePasswordView = ({ status, message, onSubmit = () => { } }) => (

  <main id="content" role="main" class="main main pl-0 h-100 w-100" style={{ overflowY: "auto" }}>
    <StyleColor />

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster"
            style={{ border: "1px solid #d2dae0" }}>
            <div class="card-body">
              <Formik
                initialValues={{}}
                onSubmit={onSubmit}>
                {() => (
                  <Form>
                    <div class="text-center">
                      <div class="mb-5">
                        <h1 class="display-4">Restablece tu contraseña</h1>
                      </div>
                    </div>

                    {/* Password */}
                    <div class="form-group">
                      <label class="input-label" for="formPassword">
                        Contraseña
                      </label>

                      <div class="input-group input-group-merge">
                        <Field type="password" name="password" id="formPassword"
                          class="js-toggle-password form-control form-control-lg" tabindex="2"
                          placeholder="password" maxlength="512" required
                          data-hs-toggle-password-options={`{
                                "target": "#changePassTarget",
                                "defaultClass": "tio-hidden-outlined",
                                "showClass": "tio-visible-outlined",
                                "classChangeTarget": "#changePassIcon"
                              }`} />
                        <div id="changePassTarget" class="input-group-append">
                          <a class="input-group-text">
                            <i id="changePassIcon" class="tio-visible-outlined"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Confirm Password */}
                    <div class="form-group">
                      <label class="input-label" for="formConfirmPassword">
                        Confirmar contraseña
                      </label>

                      <div class="input-group input-group-merge">
                        <Field type="password" name="confirmPassword" id="formConfirmPassword"
                          class="js-toggle-password form-control form-control-lg" tabindex="2"
                          placeholder="password" maxlength="512" required
                          data-hs-toggle-password-options={`{
                                "target": "#changePassTarget",
                                "defaultClass": "tio-hidden-outlined",
                                "showClass": "tio-visible-outlined",
                                "classChangeTarget": "#changePassIcon"
                              }`} />
                        <div id="changePassTarget" class="input-group-append">
                          <a class="input-group-text">
                            <i id="changePassIcon" class="tio-visible-outlined"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <button type="submit" class="btn btn-lg btn-block btn-accent">
                      Cambiar contraseña
                    </button>

                    {status ? (
                      <div
                        class={`alert alert-soft-${status == "ERROR" ? "danger" : "success"} text-center mt-2`}
                        role="alert"
                      >
                        {message}
                      </div>
                    ) : null}

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

    </div>

    <ScriptTag content={`
        $('.js-toggle-password').each(function () {
          new HSTogglePassword(this).init()
        });
      `} />
  </main>
);

RestorePasswordView.propTypes = {};

export default RestorePasswordView;