import React from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import FormSaveOrder from "components/tmisc/organizations/users/orders/FormSave";
import FormOrder from "components/tmisc/organizations/users/orders/List";
import FormSave from "components/tmisc/organizations/users/FormSave";
import FormSet from "components/tmisc/organizations/users/FormSet";
import FormUploadMassive from "components/tmisc/organizations/users/FormUploadMassive";

const UsersView = ({
    organization,
    deleteUser = () => { }
}) => (
    <div className="card col-md-12" style={{ minHeight: "75vh" }}>

        <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="card-title mb-0">Usuarios</h4>
            <div>
                <Link 
                    to={`/organizations/users/upload_massive/${organization.id}`}
                    className="btn btn-sm btn-outline-primary mr-2"
                >
                    <i className="fa fa-upload"></i> Carga masiva
                </Link>
                <Link 
                    to={`/organizations/users/new_user/${organization.id}`} 
                    className="btn btn-sm btn-primary"
                >
                    <i className="fa fa-plus"></i> Nuevo usuario
                </Link>
            </div>
        </div>

        <div className="card-body p-0">
            <div className="row border-bottom m-0 font-weight-bold py-2 px-2">
                <div className="col-md-2">Nombre</div>
                <div className="col-md-2">Apellido</div>
                <div className="col-md-4">Usuario</div>
                <div className="col-md-2">Plan</div>
                <div className="col-md-2 text-center">Acciones</div>
            </div>

            {organization?.organizationParticipants?.length === 0 ? <div className="p-3 text-center">No hay resultados</div>
            : (
                <div style={{ maxHeight: "65vh", overflowY: "auto" }}>
                    {organization?.organizationParticipants?.map((participant, idx) => (
                        <div
                            key={participant.user.id}
                            className="row m-0 px-2 border-bottom py-2"
                            style={{
                                backgroundColor: idx % 2 ? "#f9f9f9" : "transparent",
                            }}
                        >
                            <div className="col-md-2">{participant.user.firstName}</div>
                            <div className="col-md-2">{participant.user.lastName}</div>
                            <div className="col-md-4">{participant.user.username}</div>
                            <div className="col-md-2">{participant.user?.license?.name ?? "Sin plan"}</div>
                            <div className="col-md-2 text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                    <Link
                                        to={`/organizations/users/orders/${participant.user.id}`}
                                        className="btn btn-dark btn-sm"
                                    >
                                        Ordenes
                                    </Link>

                                    <div className="btn-group dropleft ml-2">
                                        <button
                                            type="button"
                                            className="btn btn-lighter dropdown-toggle p-0 border-0"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fas fa-ellipsis-v"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <Link
                                                to={`/organizations/users/edit_user/${participant.user.id}`}
                                                className="dropdown-item"
                                            >
                                                <i className="fas fa-edit mr-2"></i> Editar
                                            </Link>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => deleteUser(participant.user.id)}
                                            >
                                                <i className="fas fa-trash mr-2"></i> Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>

        <ModalRoute
            path="/organizations/users/new_user/:orgId"
            height="650"
            width="700"
            component={FormSave}
        />

        <ModalRoute
            path="/organizations/users/orders/:userId"
            height="500"
            width="850"
            component={FormOrder}
        />

        <ModalRoute
            path="/organizations/users/orders/:userId/create"
            width="600"
            component={FormSaveOrder}
        />

        <ModalRoute 
            path="/organizations/users/upload_massive/:orgId"
            height="325"
            width="500"
            component={FormUploadMassive}
        />

        <ModalRoute
            path="/organizations/users/edit_user/:userId"
            height="650"
            width="700"
            component={FormSet}
        />
    </div>
);

export default UsersView;
