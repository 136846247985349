import React, { useState } from "react";
import View from "components/presentation/history/History.view";

function History({ 
  flow, 
  chatId, 
  changeChat 
}) {

  const [historyTab, setHistoryTab] = useState("executions");

  const onClickTab = tabName =>
    setHistoryTab(tabName)

  return (
    <View
      flow={flow}
      chatId={chatId}
      historyTab={historyTab}
      changeChat={changeChat}
      onClickTab={onClickTab}
    />
  );
}

export default History;