import React from 'react';
import { Formik, Form, Field } from 'formik';
import { ScriptTag } from "seed/helpers";



const AccountView = ({ user, onSubmit }) => (

	<div className="card">
		<div className="card-header">
			<h3 className="card-title">Cuenta</h3>
		</div>
		<div className="card-body animate__animated animate__fadeIn animate__faster">
			<Formik
				initialValues={{
					first_name: user.firstName,
					last_name: user.lastName,
					email: user.email,
					phone: user.phone
				}}
				onSubmit={onSubmit}
			>
				{({ values, setFieldValue }) => (
					<Form>

						<div className="form-group">
							<div className="d-flex align-items-center">
								<span className="col-md-3">Nombre</span>
								<Field
									type="text"
									id="first_name"
									name="first_name"
									maxlength="512"
									minlength="3"
									className="form-control flex-grow-1"
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="d-flex align-items-center">
								<span className="col-md-3">Apellido</span>
								<Field
									type="text"
									id="last_name"
									name="last_name"
									maxlength="512"
									minlength="3"
									className="form-control flex-grow-1"
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="d-flex align-items-center">
								<span className="col-md-3">Correo</span>
								<Field
									type="email"
									id="email"
									name="email"
									maxlength="512"
									className="form-control flex-grow-1"
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="d-flex align-items-center">
								<span className="col-md-3">Teléfono</span>
								<Field
									type="tel"
									name="phone"
									maxlength="512"
									className="form-control flex-grow-1"
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="d-flex align-items-center">
								<span className="col-md-3">Actualizar contraseña</span>
								<div class="input-group input-group-merge">
									<Field
										type="password"
										name="password"
										maxlength="512"
										className="js-toggle-password form-control flex-grow-1"
										data-hs-toggle-password-options={`{
										"target": "#changePassTarget",
										"defaultClass": "tio-hidden-outlined",
										"showClass": "tio-visible-outlined",
										"classChangeTarget": "#changePassIcon"
									}`}
									/>
									<div id="changePassTarget" class="input-group-append">
										<a class="input-group-text">
											<i id="changePassIcon" class="tio-visible-outlined"></i>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="text-right">
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
						</div>

					</Form>
				)}
			</Formik>
		</div>

		<ScriptTag content={`
					$('.js-toggle-password').each(function () {
						new HSTogglePassword(this).init()
					});
				`} />
	</div>
);


export default AccountView;