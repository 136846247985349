import React from "react";
import View from "components/tmisc/organizations/company/Form.view";
import { usePost } from "seed/api";
import swal from "sweetalert";

const FormSave = () => {

    const userId = sessionStorage.getItem("id");

    const [callSave, setCallSave] = usePost("/organizations/create_organization", {
        onCompleted: () => {
            swal({text: "Organización creada exitosamente", icon: "success"}).then(() => {
                window.location.href = "/app/organizations/details";
            });
        },
        onError: () => {
            swal({text: "No se ha podido crear la organización", icon: "error"});
        }
    });

    const onSubmit = (values) => {
        callSave({...values, user_id: userId});
    };

    return <View onSubmit={onSubmit} />;
}

export default FormSave;