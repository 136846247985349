import React from "react";
import { StyleTag } from "seed/helpers";

const TableControls = ({
  search,
  filters,
  sorting,
  showSearch,
  showImport,
  showExport,
  showForm,
  showSorting,
  showFilters,
  showDownloadZip,
  isReadOnly,
  isSearchShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSortingModalShown,
  setIsSearchShown,
  setIsFilterModalShown,
  setSelectedCell,
  onChangeSearch,
  onDownloadZip
}) => {

  return (
    <div id="table-controls" className="d-flex align-items-center justify-content-end w-100 my-1">

        {showForm && (
          <button
            className="btn btn-sm btn-lighter-white mr-4"
            onClick={() => setIsFormModalShown(true)}
          >
            <i className="fas fa-plus mr-2"></i>
            Agregar dato
          </button>
        )}

      {
        showSearch &&
        <>
          {isSearchShown ? (
            <div id="table-search-input" className="animate__animated animate__fadeIn animate__faster">
              <div className="bg-white px-2 py-1 rounded mr-2">
                <i className="fas fa-search mr-3" />
                <input
                  type="text"
                  className="border-0"
                  placeholder="Buscar"
                  onFocus={() => setSelectedCell && setSelectedCell(null)}
                  onBlur={() => search.length === 0 && setIsSearchShown(false)}
                  defaultValue={search}
                  onChange={onChangeSearch}
                />
                <i
                  style={{ cursor: "pointer" }}
                  className="fas fa-times ml-3"
                  onClick={() => {
                    onChangeSearch({ target: { value: "" } });
                    setIsSearchShown(false);
                  }}
                />
              </div>
            </div>
          ) : (
            <button
              id="table-search-button"
              className="btn btn-sm btn-lighter-white mr-2 text-center"
              onClick={() => setIsSearchShown(true)}
            >
              <i className="fas fa-search"></i>
            </button>
          )}
        </>
      }
      

      <div id="table-control-buttons" className="d-flex align-items-center">

        {showFilters && (
          <button
            id="table-filter-button"
            type="button"
            className={`btn btn-sm mr-2 text-center ${filters.length > 0 ? "btn-primary text-white" : "btn-lighter-white"}`}
            onClick={() => setIsFilterModalShown(true)}
          >
            <i className={`fas fa-filter`}></i>
          </button>
        )}

        {
          (showImport || showExport) && <>
            {showExport && (
                <button
                  className="btn btn-sm btn-lighter-white mr-2 text-center"
                  onClick={() => setIsExportModalShown(true)}
                >
                  <i className="fas fa-file-excel"></i>
                </button>
              )}

              {showImport && (
                <button
                  className="btn btn-sm btn-lighter-white mr-2 text-center"
                  onClick={() => setIsImportModalShown(true)}
                >
                  <i className="fas fa-upload"></i>
                </button>
              )}
          </>
        }

        {
          showDownloadZip && (
            <button
              className="btn btn-sm btn-lighter-white text-center"
              onClick={() => onDownloadZip("file")}
            >
              <i className="fas fa-download"></i>
            </button>
          )
        }

      </div>

    </div>
  );

}

export default TableControls;