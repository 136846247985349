import React from "react";
import EventInit from "components/studio/board/nodes/helpers/EventInit";
import { NodeResizeControl } from 'reactflow';
import "styles/css/studio/board/nodes/Note.css";



const NoteView = ({ id, content, readOnly, backgroundColor, isSelected, isEditable, onEditNote, onDeleteNode, onSelectAnnotation }) => (
  <div className={"note w-100 " + (readOnly ? "h-auto pb-1" : "h-100")}>

    {!isEditable && (
      <div className="no-editable-overlay drag-handle-container">
      </div>
    )}

    <div className="note-content drag-handle-container w-100 h-100"
      onClick={!readOnly ? onEditNote : null} style={{ backgroundColor: backgroundColor }}
    >
      {readOnly ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <span>{content}</span>}

      <div className={"badge-container " + (isSelected ? "selected " : "")} onClick={e => e.stopPropagation()}>

        <div class="badge badge-pill badge-checkbox" onClick={onSelectAnnotation}>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id={`checkSelectAnnotation${id}`} class="custom-control-input"
                onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
              />
              <label class="custom-control-label" for={`checkSelectAnnotation${id}`}></label>
            </div>
          </div>
        </div>

      </div>
      <EventInit onDeleteNode={onDeleteNode} />
    </div>

    {!readOnly ?
      <NodeResizeControl minWidth={10} minHeight={10} className="note-resizer">
        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
      </NodeResizeControl>
      : null}

  </div>
);

export default NoteView;