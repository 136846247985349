import React, { useState, useEffect } from "react";

const Sorting = ({ 
  columns, 
  sorting = [],
  depth = 0,
  isSubQuery = false,
  onChangeSorting = () => {},
  onChange = () => {},
  onDelete = () => {},
  onClose = () => {}
}) => {

  const [sortingValues, setSortingValues] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const _onChange = (conditions) =>
    onChangeSorting(conditions);

  useEffect(() => {
    if(!loaded) {
      setSortingValues(sorting);
      setLoaded(true);
    }
  }, [sorting]);

  return (
    <div className="card card-body">

      <div className="d-flex justify-content-between align-items-center w-100 mb-2">
        <h3>Ordenar por</h3>
        <div className="d-flex mt-2">
          <button 
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
              setSortingValues([...sortingValues, { column: null, direction: "ASC" }]);
            }}
          >
            <i className="fas fa-plus"></i>
            Agregar campo
          </button>
        </div>
      </div>

      {
        sortingValues.map((sortingValue, index) => (
          <div 
            key={`sorting-${index}`}
            className="d-flex justify-content-between align-items-center w-100 mb-2"
          >
            <div className="d-flex w-100">
              <select
                className="form-control form-control-sm mr-2 w-100"
                value={sortingValue.name}
                onChange={(e) => {
                  const newSortingValues = [...sortingValues];
                  newSortingValues[index].name = e.target.value;
                  setSortingValues(newSortingValues);
                }}
              >
                {
                  columns.map((column, index) => (
                    <option key={index} value={column.name}>{column.label}</option>
                  ))
                }
              </select>
              <select
                className="form-control form-control-sm mr-2"
                value={sortingValue.order}
                onChange={(e) => {
                  const newSortingValues = [...sortingValues];
                  newSortingValues[index].order = e.target.value;
                  setSortingValues(newSortingValues);
                }}
              >
                <option value="ASC">Ascendente</option>
                <option value="DESC">Descendente</option>
              </select>
            </div>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => {
                const newSortingValues = [...sortingValues];
                newSortingValues.splice(index, 1);
                setSortingValues(newSortingValues);
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        ))
      }

    <div className="d-flex justify-content-end align-items-center w-100 mt-3">
      <button
        className="btn btn-primary mr-3"
        onClick={() => {
          _onChange(sortingValues);
          onClose();
        }}
      >
        Aplicar
      </button>
      <button 
        className="btn btn-outline-danger"
        onClick={() => {
          _onChange([]);
          onClose();
        }}
      >
        Limpiar
      </button>
    </div>

    </div>
  );

}

export default Sorting;