import React from "react";
import PropTypes from "prop-types";
import BarChart from "components/tmisc/executions/charts/helpers/BarChart";
import LineChart from "components/tmisc/executions/charts/helpers/LineChart";
import PieChart from "components/tmisc/executions/charts/helpers/PieChart";
import DonutChart from "components/tmisc/executions/charts/helpers/DonutChart";


const getChartComponent = (type, series, labels) => {

  if (type == "bar") {
    return <BarChart labels={labels} data={series} />;
  }

  if (type == "line") {
    return <LineChart labels={labels} data={series} />;
  }

  if (type == "pie") {
    return <PieChart labels={labels} data={series} />;
  }

  if (type == "scatter") {
    return <PieChart labels={labels} data={series} />;
  }

  if (type == "doughnut") {
    return <DonutChart labels={labels} data={series} />;
  }

  return null;
}


const ChartView = ({ type, series, labels }) => (
  <>
    {getChartComponent(type, series, labels)}
  </>
);

ChartView.propTypes = {};

export default ChartView;