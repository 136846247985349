/* eslint-disable max-len */
import React from "react";
import { ModalRoute } from "seed/helpers";
import { BrowserRouter } from "react-router-dom";
import Guides from "components/studio/toolbox/guides/Guides"
import Actions from "components/studio/toolbox/actions/Actions"
import Templates from "components/studio/toolbox/templates/Templates"
import Ideas from "components/studio/toolbox/ideas/Ideas"
import Collections from "components/collections/Collections";
import Apps from "components/studio/toolbox/apps/Apps";
import SidenavItem from "components/studio/toolbox/Toolbox.sub.SidenavItem.view";
import GuidesVisualizer from "components/studio/toolbox/guides/GuidesVisualizer";
import "styles/css/studio/Toolbox.css";


const ToolboxView = ({
  toolboxRef,
  flowId,
  pageId,
  nodes,
  reqApps,
  reqAllApps,
  currentNodeContext,
  activeSection,
  isCollapsed,
  isAnonymous,
  isValidator,
  onClickNode,
  onClickCollapseButton,
  onClickSidenavItem,
  reloadFlow
}) => (
  <BrowserRouter basename={`/app/studio/${flowId}/flow`}>

    {isValidator ? (
      <div id="toolbox-container" className="d-flex h-100 actions-section absolute">
        <div id="toolbox-main" className="h-100">
          <Actions
            toolboxRef={toolboxRef}
            flowId={flowId}
            nodes={nodes}
            reqApps={reqApps}
            reqAllApps={reqAllApps}
            currentNodeContext={currentNodeContext}
            isAnonymous={isAnonymous}
            isValidator={isValidator}
            onClickNode={onClickNode}
          />
        </div>
      </div>
    ) : (
      <div id="toolbox-container" className={`d-flex h-100 ${isCollapsed ? "collapsed" : ""} ${activeSection}-section`}>

        <div id="toolbox-sidenav" className="d-flex flex-column scrollbar-narrow h-100"
          style={{ boxSizing: "border-box", overflowY: "auto" }}>

          <SidenavItem
            name="actions"
            label="Acciones"
            icon="fas fa-shapes"
            activeSection={!isCollapsed ? activeSection : null}
            onClickItem={onClickSidenavItem} />

          <SidenavItem
            name="data"
            label="Datos"
            icon="fas fa-table"
            activeSection={!isCollapsed ? activeSection : null}
            onClickItem={onClickSidenavItem} />

          <SidenavItem
            name="templates"
            label="Plantillas"
            icon="fas fa-table-list"
            activeSection={!isCollapsed ? activeSection : null}
            onClickItem={onClickSidenavItem} />

          <div style={{ height: "1px", background: "#00000050", margin: "0.8rem 0.8rem 0.9rem 0.8rem" }} />


          <SidenavItem
            name="guides"
            label="Guías"
            icon="fas fa-graduation-cap"
            iconStyle={{ fontSize: "1.54rem" }}
            activeSection={!isCollapsed ? activeSection : null}
            onClickItem={onClickSidenavItem} />

          <SidenavItem
            name="ideas"
            label="Ideas"
            icon="fas fa-lightbulb"
            activeSection={!isCollapsed ? activeSection : null}
            onClickItem={onClickSidenavItem} />


        </div>

        {!isCollapsed ?
          <div id="toolbox-main" className="h-100">

            {activeSection == "guides" ?
              <Guides /> : null
            }

            {activeSection == "actions" ?
              <Actions
                toolboxRef={toolboxRef}
                flowId={flowId}
                nodes={nodes}
                reqApps={reqApps}
                reqAllApps={reqAllApps}
                currentNodeContext={currentNodeContext}
                isAnonymous={isAnonymous}
                onClickNode={onClickNode}
              /> : null}

            {activeSection == "templates" ?
              <Templates
                flowId={flowId}
              /> : null}

            {activeSection == "data" ?
              <Collections
                flowId={flowId}
              /> : null}

            {activeSection == "ideas" ?
              <Ideas
                flowId={flowId}
                pageId={pageId}
                reloadFlow={reloadFlow}
              /> : null}

          </div>
          : null}

        <div id="collapse-toolbox" onClick={onClickCollapseButton}>
          {isCollapsed ?
            <i class="fas fa-angle-right"></i>
            : <i className="fas fa-angle-left"></i>
          }
        </div>

        <ModalRoute
          height="600"
          path={"/apps"}
          component={() =>
            <div className="card">
              <div class="card-header d-flex justify-content-between"><h3 class="mb-0">Acciones</h3></div>
              <Apps reqApps={reqApps} flowId={flowId} />
            </div>}
        />

        <ModalRoute
          width={`${window.innerWidth - 100}`}
          height={`${window.innerHeight - 100}`}
          path={"/guides/:guideId"}
          component={GuidesVisualizer}
        />

      </div>
    )}
  </BrowserRouter>
);

export default ToolboxView;
