import React from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers"
import ProcessIcon from "components/util/helpers/ProcessIcon";
import TreeSettings from "components/studio/forms/tree_settings/TreeSettings";

const FlowListView = ({
  flowId,
  userType,
  isEditor,
  nodes,
  onClickExecute,
  onClickTab,
  onClickMoveUp,
  onClickMoveDown,
  onSubmitTreeModal
}) => (

  <div className="py-3 px-4">

    {isEditor && nodes.length > 0 ?
      <a href={`/app/studio/${flowId}`} className="btn btn-outline-primary w-100 py-3 mb-3">
        <i class="fas fa-layer-group mr-2"></i> Ir a Workix Studio</a> : null}

    <ul class="list-group" id="flow-list">

      {nodes.map((node, index) => (
        <li class="flow list-group-item mb-1 pr-2" key={node.id}>

          <div class="row align-items-center gx-2">
            <div class="col-auto">
              <ProcessIcon style={{ width: "1.8rem" }} />
            </div>

            <div class="col">
              <h5 class="mb-0">
                {node.rootName ?? node.action.uiSettings.general?.title}
              </h5>
              <ul class="list-inline list-separator small">
                <li class="list-inline-item">
                  { 
                    isEditor &&
                    <span>
                      {
                        function(){

                          const permissions = node.rootSettings?.execute ?? { ADMIN: true, EDITOR: true, VIEWER: true };
                          const labels = {
                            "ADMIN": "Administradores",
                            "EDITOR": "Editores",
                            "VIEWER": "Lectores"
                          };

                          if(permissions.ADMIN && permissions.EDITOR && permissions.VIEWER) return "General";
                          const activePermissions = Object.keys(permissions).filter(key => permissions[key])
                            .sort().map(key => labels[key]).join(" y ");

                          return activePermissions || "Sin permisos";
                          
                        }()
                      }
                    </span>
                  }
                </li>
              </ul>
            </div>

            <div class="col-auto mt-2 mt-md-0 mb-1 mb-md-0" style={{ minWidth: "7rem" }}>
              {
                ((node.rootSettings?.execute ?? {})[userType] ?? true) &&
                <a
                  href="#"
                  className="btn btn-success btn-sm mr-2"
                  onClick={() => onClickExecute(node.id)}>
                  <i className="fas fa-play mr-2"></i>Ejecutar
                </a>
              }

              <button
                className="btn btn-sm btn-lighter-white text-center px-3"
                type="button"
                id="dropdown-options"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i className="fas fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right mt-n1" aria-labelledby="dropdown-options">

                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => onClickMoveUp(node.id)}>
                  <i className="fas fa-arrow-up dropdown-item-icon mr-2"></i>
                  Mover arriba
                </a>

                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => onClickMoveDown(node.id)}>
                  <i className="fas fa-arrow-down dropdown-item-icon mr-2"></i>
                  Mover abajo
                </a>

                <hr className="my-2" />

                <Link to={`/root_tree/${node.id}`}
                  className="dropdown-item"
                  onClick={() => { }}>
                  <i className="fas fa-edit dropdown-item-icon mr-2"></i>
                  Editar
                </Link>

                <button
                  className="dropdown-item"
                  onClick={() => { }}>
                  <i className="fas fa-trash dropdown-item-icon mr-2"></i>
                  Eliminar
                </button>

              </div>

            </div>
          </div>

        </li>
      ))}

      {nodes.length == 0 ?
        <div id="no-flows" className="text-center my-10 h-100">
          <i className="fas fa-project-diagram mb-4" style={{ fontSize: "3em" }}></i>
          <br />No tienes procesos registrados<br />
          {isEditor ?
            <div>
              <a className="btn btn-lighter mt-3"
                style={{ width: "11.25rem" }}
                onClick={() => onClickTab("templates")}>
                <i class="fas fa-table-list mr-2"></i> Ver plantillas
              </a><br />
              <a href={`/app/studio/${flowId}`} className="btn btn-primary mt-2"
                style={{ width: "11.25rem" }}>
                <i class="fas fa-layer-group mr-2"></i> Ir a Workix Studio
              </a>
            </div> : null}
        </div> : null}
    </ul>

    <ModalRoute
      width={600}
      height={window.innerHeight - 150}
      animation={""}
      path="/root_tree/:nodeId"
      component={(props) =>
        <TreeSettings
          match={props.match}
          flowId={flowId}
          showStudioButton={true}
          onSubmit={onSubmitTreeModal}
        />
      }
    />


  </div>

)

export default FlowListView;