import React, { useEffect } from "react";
import { usePost } from "seed/api";
import Table from "components/collections/Table";

const ChatResult = ({ formData }) => {

  const [callCloseResult] = usePost("/apps/forms/close_result");

  const title = formData.title;
  const message = formData.message;
  const result = formData.result;
  const schema = formData.schema ?? [];
  const extraData = formData.extra_data ?? {}
  const nodeId = formData.node_id;
  const executionId = formData.execution_id

  useEffect(() => {
    if (formData && nodeId && executionId) {
      callCloseResult({
        node_id: nodeId,
        execution_id: executionId,
        ...extraData
      });
    }
  }, [formData]);

  return <div className={`d-flex flex-column animate__animated animate__fadeIn animate__faster mx-3 mb-1 align-items-start`}>
    <div className={`message scrollbar-narrow`} style={{ maxWidth: "80%" }}>
      <div>
      {title ? <h4 class="mb-2">{title}</h4> : null}      
        {message ?
          <div className="mb-1"><i>{message}</i></div> : null
        }
        {schema && result ?
          schema.length > 0 ?
            <Table
              rawValues={result ?? []}
              structure={schema ?? []}
              isReadOnly={true}
              showImport={false}
              showSearch={false} /> :
            <div className="mb-2" style={{ whiteSpace: "pre-wrap", overflow: "hidden" }}>{result}</div> : null
        }
      </div>
    </div>
  </div>;

}

export default ChatResult;