import React from "react";
import View from "components/studio/toolbox/guides/GuidesVisualizer.view";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

const GuidesVisualizer = ({ guideId }) => {

    const qTutorial = useDetail(`
        {
            tutorial {
                name
                description
                media
                settings
                categoryId{
                    name
                    description
                }
                tagsIds{
                    name
                }
            }
        }
    `, guideId);

    if (qTutorial.loading) return <Loading />;
    if (qTutorial.error) return "Error";

    const { tutorial } = qTutorial.data;

    return <View tutorial={tutorial} />;
}

export default GuidesVisualizer;