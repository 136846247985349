import React from "react";
import moment from "moment";
import { executionStatus } from "components/tmisc/executions/util/executions";
import ExecutionSteps from "components/tmisc/executions/ExecutionSteps";

const ExecutionDetailsView = ({
  idx,
  randomKey,
  execution,
  details,
  style,
  isShown,
  isDeveloperMode,
  isColumns,
  isButtonHidden,
  isActive,
  isUnique,
  onResumeExecution,
  onCancelExecution,
  onShowDetails
}) => (
  <div className="w-100 h-100">
    <div
      class="d-flex justify-content-between align-items-center pr-2 py-2 w-100 mb-2 rounded"
      data-toggle={isButtonHidden ? "collapse" : ""}
      data-target={isButtonHidden ? ("#exec_" + idx + randomKey) : ""}
      aria-expanded="false"
      aria-controls={isButtonHidden ? ("exec_" + idx + randomKey) : ""}
      style={{
        paddingLeft: "1.4rem",
        borderTop: "1px solid #E7EAF3",
        borderLeft: "1px solid #E7EAF3",
        borderRight: "1px solid #E7EAF3",
        borderBottom: isActive && isButtonHidden ? "2px solid #2080E5": "1px solid #E7EAF3",
        background: "white",
        cursor: isButtonHidden ? "pointer" : "default",
        ...style
      }}
      onClick={() => {
        if(!isButtonHidden) return;
        onShowDetails(execution);
      }}
    >
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-start my-2" style={{ maxWidth: "calc(100% - 120px)" }}>
          <i className={`${executionStatus[execution.status]?.icon} ${executionStatus[execution.status]?.color} mt-1`} />
          <div className="mx-3 w-100">
            <div className="w-100 pr-1">
              <div className="h5 text-truncate">

                {
                  function () {

                    const steps = execution.executionSteps ?? [];
                    const lastStep = steps[steps.length - 1];
                    const uiSettints = (lastStep?.flowNode?.action?.uiSettings ?? {});
                    const title = uiSettints?.node?.title ?? "N/A";

                    return execution.flow.name;

                  }()
                }

                {
                  !execution.isRead && (
                    <span className="badge badge-pill badge-primary ml-2">Nuevo</span>
                  )
                }

              </div>
            </div>
            <div className="h6 font-weight-light mb-0 text-truncate">
              {moment(execution.startDate).format(
                (new Date(execution.startDate)).toDateString() == (new Date()).toDateString()
                  ? "HH:mm" : "DD.MMM")}
            </div>
          </div>
        </div>
        <div className="d-flex pl-3">
          {
            execution.status == "AWAITING" && (
              <>
                <button
                  className="btn btn-sm btn-outline-primary mb-1 mr-2"
                  onClick={(e) => { e.stopPropagation(); onResumeExecution(execution); }}
                >
                  Reanudar
                </button>
                <button
                  className="btn btn-sm btn-lighter mb-1"
                  onClick={(e) => { e.stopPropagation(); onCancelExecution(execution); }}
                >
                  Cancelar
                </button>
              </>
            )
          }
          {
            execution.status != "AWAITING" && !isButtonHidden && (
              <>
                <button
                  className="btn btn-sm btn-outline-primary mb-1 mr-2"
                  data-toggle="collapse"
                  data-target={"#exec_" + idx + randomKey}
                  aria-expanded="false"
                  aria-controls={"exec_" + idx + randomKey}
                  onClick={() => {
                    onShowDetails(execution);
                  }}
                >
                  Ver resultados
                </button>
              </>
            )
          }
        </div>
      </div>
    </div>

    {
      !isColumns &&
        <div 
          class={`${isShown ? "show" : ""} collapse ml-2`} 
          id={"exec_" + idx + randomKey}
          data-parent={isUnique ? "" : "#executionsAccordion"}
        >
          <ul class="border-left mr-1 mt-3 ml-2">
            <ExecutionSteps 
              idx={idx} 
              randomKey={randomKey} 
              details={details} 
              isDeveloperMode={isDeveloperMode}
            />
          </ul>
        </div>
    }

  </div>
)

export default ExecutionDetailsView;