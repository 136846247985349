import React, { useEffect, useState } from "react";
import { usePost } from "seed/api";
import SchemaForm from 'components/util/schema_form/SchemaForm';
import { parseCollectionOptions } from 'components/tmisc/apps/forms/util';

const ChatForm = ({ chatId, messageId, response, formData }) => {

  const userId = sessionStorage.getItem("id");
  const [callSendForm] = usePost("/apps/forms/send_form");

  const [lastExecutionId, setLastExecutionId] = useState(null);
  const [formSending, setFormSending] = useState(false);

  const [callCollections, reqCollections] = usePost("/collections/get_data_by_queries");
  const collections = reqCollections?.data;

  let schema = formData?.schema;
  const formTitle = formData?.title;
  const extraData = formData?.extra_data ?? {}
  const nodeId = formData?.node_id;
  const executionId = formData?.execution_id;
  const appId = formData?.app_id;
  const actionId = formData?.action_id;
  const flowId = formData?.flow_id;
  const token = formData?.token;

  const collectionReferences = (schema ?? [])?.map((field) => field.collection_options).flat();
  const queries = collectionReferences.map((ref) => ref?.query).filter((query) => query != null);
  const formSent = response != null;

  if (collections != null && schema != null)
    schema = schema.map((field) =>
      field.collection_options != null
        ? parseCollectionOptions(collections, field)
        : field
    );

  useEffect(() => {
    if (executionId != lastExecutionId) {
      setLastExecutionId(executionId);
      callCollections({ queries, execution_id: executionId });
    }
  }, [schema, collections, queries, executionId, lastExecutionId]);

  const onFormSubmit = (data) => {
    setFormSending(true)
    callSendForm({
      response: data,
      node_id: nodeId,
      user_id: userId,
      execution_id: executionId,
      chat_id: chatId,
      message_id: messageId,
      session_id: sessionStorage.getItem("execution_session_id"),
      token,
      ...extraData
    });
  }

  return <div className={`d-flex flex-column animate__animated animate__fadeIn animate__faster mx-3 mb-1 align-items-start`}>
    <div className={`message scrollbar-narrow`} style={{ width: "80%" }}>
      {formTitle ? <h4 class="mt-2 mb-0 ml-2">{formTitle}</h4> : null}
      <div className={"pt-4 px-0 " + (!formSent ? "pb-2" : "")}>
        <SchemaForm
          initialValues={response ?? {}}
          onSubmit={onFormSubmit}
          schema={schema}
          appId={appId}
          actionId={actionId}
          flowId={flowId}
          nodeId={nodeId}
          executionId={executionId}
          isReadOnly={formSent}
          submitComponent={() =>
            !formSent ?
              <div className="ml-6 mr-2 mt-n1">
                <button
                  type="submit"
                  class="btn btn-block btn-outline-primary"
                  disabled={formSending}>
                  {!formSending ? "Enviar" : "Enviando…"}
                </button>
              </div>
              : <div className="mt-n1"></div>
          }
        />
      </div>
    </div>
  </div>;

}

export default ChatForm;