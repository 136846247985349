import React from "react";
import { StyleTag } from "seed/helpers"

const ModelIcon = ({ className = "", style, letter = "" }) =>
	<svg
		viewBox="0 0 120 120"
		className={className}
		style={style}>
		<StyleTag content={`
        .model-icon-0{fill:none;stroke:#BDC5D1;}
				.model-icon-1{fill:#FFFFFF;stroke:#2075E5;}
				.model-icon-2{fill:#BDC5D1;}
				.model-icon-3{fill:#2075E5;}
				.model-icon-4{fill:#FFFFFF;}
      `} />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M49.6,33.7L49.6,33.7c-1.2,0-2.2-1-2.2-2.2v-4.7c0-1.2-1-2.2-2.2-2.2h-4.1c-1.2,0-2.2-1-2.2-2.2V11.7
	c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v6.5c0,1.2,1,2.2,2.2,2.2h4.1c1.2,0,2.2,1,2.2,2.2v9.1C51.8,32.8,50.8,33.7,49.6,33.7z"
		/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="41.2" cy="6.7" rx="4.5" ry="4.4" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M70.4,33.7L70.4,33.7c-1.2,0-2.2-1-2.2-2.2v-9.1c0-1.2,1-2.2,2.2-2.2h4.1c1.2,0,2.2-1,2.2-2.2v-6.5
	c0-1.2,1-2.2,2.2-2.2l0,0c1.2,0,2.2,1,2.2,2.2v10.8c0,1.2-1,2.2-2.2,2.2h-4.1c-1.2,0-2.2,1-2.2,2.2v4.7
	C72.6,32.8,71.6,33.7,70.4,33.7z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="78.8" cy="6.7" rx="4.5" ry="4.4" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M78.8,111.5L78.8,111.5c-1.2,0-2.2-1-2.2-2.2v-6.5c0-1.2-1-2.2-2.2-2.2h-4.1c-1.2,0-2.2-1-2.2-2.2v-9.1
	c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v4.7c0,1.2,1,2.2,2.2,2.2h4.1c1.2,0,2.2,1,2.2,2.2v10.8C81,110.5,80,111.5,78.8,111.5z"
		/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="78.5" cy="113.4" rx="4.4" ry="4.3" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M41.2,111.5L41.2,111.5c-1.2,0-2.2-1-2.2-2.2V98.5c0-1.2,1-2.2,2.2-2.2h4.1c1.2,0,2.2-1,2.2-2.2v-4.7
	c0-1.2,1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2v9.1c0,1.2-1,2.2-2.2,2.2h-4.1c-1.2,0-2.2,1-2.2,2.2v6.5
	C43.4,110.5,42.4,111.5,41.2,111.5z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="41.5" cy="113.4" rx="4.4" ry="4.3" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22,81.5H11.2c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h6.5c1.2,0,2.2-1,2.2-2.2v-4.1c0-1.2,1-2.2,2.2-2.2
	h9.1c1.2,0,2.2,1,2.2,2.2l0,0c0,1.2-1,2.2-2.2,2.2h-4.7c-1.2,0-2.2,1-2.2,2.2v4.1C24.2,80.5,23.2,81.5,22,81.5z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="6.1" cy="79.3" rx="4.4" ry="4.5" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M31.1,52.3H22c-1.2,0-2.2-1-2.2-2.2V46c0-1.2-1-2.2-2.2-2.2h-6.5c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2
	H22c1.2,0,2.2,1,2.2,2.2v4.1c0,1.2,1,2.2,2.2,2.2h4.7c1.2,0,2.2,1,2.2,2.2v0C33.2,51.3,32.3,52.3,31.1,52.3z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="6.1" cy="41.7" rx="4.4" ry="4.5" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M98,52.3h-9.1c-1.2,0-2.2-1-2.2-2.2v0c0-1.2,1-2.2,2.2-2.2h4.7c1.2,0,2.2-1,2.2-2.2v-4.1c0-1.2,1-2.2,2.2-2.2
	h10.8c1.2,0,2.2,1,2.2,2.2v0c0,1.2-1,2.2-2.2,2.2h-6.5c-1.2,0-2.2,1-2.2,2.2v4.1C100.2,51.3,99.2,52.3,98,52.3z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="113.9" cy="41.7" rx="4.4" ry="4.5" />
		<path class="model-icon-0 fill-none stroke-gray-400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M108.8,81.5H98c-1.2,0-2.2-1-2.2-2.2v-4.1c0-1.2-1-2.2-2.2-2.2h-4.7c-1.2,0-2.2-1-2.2-2.2l0,0
	c0-1.2,1-2.2,2.2-2.2H98c1.2,0,2.2,1,2.2,2.2V75c0,1.2,1,2.2,2.2,2.2h6.5c1.2,0,2.2,1,2.2,2.2v0C111,80.5,110,81.5,108.8,81.5z"/>
		<ellipse class="model-icon-1 fill-white stroke-primary" stroke-width="3" stroke-miterlimit="10" cx="113.9" cy="79.3" rx="4.4" ry="4.5" />
		<path class="model-icon-2 fill-gray-400" opacity=".5" d="M86.9,100.2l-33.7,0c-7.1,0-12.9-5.8-12.9-12.9l0-33.7c0-7.1,5.8-12.9,12.9-12.9l33.7,0
	c7.1,0,12.9,5.8,12.9,12.9v33.7C99.8,94.4,94,100.2,86.9,100.2z"/>
		<path class="model-icon-3 fill-primary" d="M76.9,90.2l-33.7,0c-7.1,0-12.9-5.8-12.9-12.9l0-33.7c0-7.1,5.8-12.9,12.9-12.9l33.7,0
	c7.1,0,12.9,5.8,12.9,12.9v33.7C89.8,84.4,84.1,90.2,76.9,90.2z"/>
		<path class="model-icon-4 fill-white" d="M77.4,85.3H42.8c-4.1,0-7.5-3.3-7.5-7.5V43.2c0-4.1,3.3-7.5,7.5-7.5h34.6c4.1,0,7.5,3.3,7.5,7.5v34.6
	C84.9,81.9,81.5,85.3,77.4,85.3z M42.8,40c-1.7,0-3.1,1.4-3.1,3.1v34.6c0,1.7,1.4,3.1,3.1,3.1h34.6c1.7,0,3.1-1.4,3.1-3.1V43.2
	c0-1.7-1.4-3.1-3.1-3.1H42.8z"/>

		<text x="51.4" y="70" textLength="1" style={{ fontSize: "2.2em", fontFamily: "monospace", fill: "white" }}>
			{letter ? letter.toUpperCase() : ""}
		</text>

	</svg>

export default ModelIcon;