import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useDetail, useQuery, useSave, useSet } from "seed/gql";
import { usePost } from "seed/api";
import { SAVE_CHAT_MESSAGE, SET_CHAT_MESSAGE, SET_EXECUTION } from "seed/gql/queries";
import { showExecutionToast } from "components/util/flow";
import View from "components/presentation/chat/Chat.view";

function Chat({ 
  flow, 
  chatId, 
  currentChatId, 
  onClickTab 
}) {

  const chatRef = useRef();
  const history = useHistory();
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [isWriting, setIsWriting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatToast, setChatToast] = useState(null);
  const [actualExecutionId, setActualExecutionId] = useState(null);
  const [isExecutionDetailsShown, setIsExecutionDetailsShown] = useState(false);
  const [isMoreSuggestionsShown, setIsMoreSuggestionsShown] = useState(false);

  const userId = sessionStorage.getItem("id");
  const executionRoomName = `EXECUTION_STATUS_${flow?.id}`;
  const formRoomName = `SHOW_FORM_${userId}`;
  const resultRoomName = `SHOW_RESULT_${userId}`;
  const chatRoomName = `CHAT_STATUS_${chatId}`;

  const [callSetRead] = useSet(SET_EXECUTION);

  const reqChat = useDetail(`{
    chat {
      settings
    }
  }`, chatId);

  const reqMessages = useQuery(`{
    chatMessages {
      createdAt
      content
      action
      settings
      type
      messageChatSuggestions {
        content
        action
        settings
      }
    }
  }`, "chat_id=" + chatId, {
    onCompleted: (data) => {
      setScrollToBottom(true);
      setMessages(data.chatMessages);
    },
    notifyOnNetworkStatusChange: true,
    orderBy: "created_at"
  });

  const [callSaveMessage] = useSave(SAVE_CHAT_MESSAGE, {
    onCompleted: () => reqMessages.refetch()
  });

  const [callSetMessage] = useSet(SET_CHAT_MESSAGE, {
    onCompleted: () => reqMessages.refetch()
  });

  const [callExecute] = usePost("/flows/execute_node");

  const onExecuteNode = (nodeId, messageId) =>
    callExecute({
      root_node_id: nodeId,
      message_id: messageId,
      session_id: sessionStorage.getItem("execution_session_id"),
      user_id: sessionStorage.getItem("id"),
    });

  const onCompleteChatRequest = (data) => {

    setIsWriting(false);
    reqMessages.refetch();

    const action = data?.action;

    if (action) {

      if (action.type == "GENERATE_FLOW")
        history.push(`/flow_validation/${action.flow.id}`, { action: action });

      if (action.type == "GENERATE_ACTION")
        history.push(`/flow/${action.flow.id}`, { action: action });

    }

  }

  const onErrorChatRequest = (error) => {
    if (error.status == 401) {
      window.chrome.webview.postMessage(JSON.stringify({ type: "chat_status", method: "logout" }));
    }
    else {
      setIsWriting(false);
      reqMessages.refetch();
    }
  }

  const onFormMessage = (data) => {
    if (data.data?.session_id && data.data?.session_id != sessionStorage.getItem("execution_session_id")) return;
    callSaveMessage({
      chat: chatId,
      type: "BOT_MESSAGE",
      content: {},
      action: {},
      isTemplate: false,
      templateName: "",
      responseRequired: true,
      settings: {
        visible: true,
        is_form: true,
        data: data.data
      }
    });
  }

  const onResultMessage = ({ data }) => {
    if (data?.session_id && data?.session_id != sessionStorage.getItem("execution_session_id")) return;
    callSaveMessage({
      chat: chatId,
      type: "BOT_MESSAGE",
      content: {},
      action: {},
      isTemplate: false,
      templateName: "",
      responseRequired: true,
      settings: {
        visible: true,
        is_result: true,
        data: data
      }
    });
  }

  const onChatMessage = ({ data }) => {
    if (data?.session_id && data?.session_id != sessionStorage.getItem("execution_session_id")) return;
    if (data?.execution_id) callSetRead({ id: data.execution_id, isRead: true });
    setChatToast(data);
    reqMessages.refetch();
  }

  const onExecutionMessage = (data) => {

    if (data.data?.session_id && data.data?.session_id != sessionStorage.getItem("execution_session_id")) return;

    const exception = data.data.exception;
    const status = data.data.status;
    const executionId = data.data.execution_id;
    const executionNodeId = data.data.node_id;
    const lastNode = data.data.last_node;
    const message = data.data.message;
    const awaitingAlert = data.data.awaiting_alert;
    const executionSessionId = data.data.session_id;

    callSetRead({ id: executionId, isRead: true });
    showExecutionToast({
      exception, status, executionId, executionNodeId, message, lastNode,
      awaitingAlert, executionSessionId, setIsExecutionDetailsShown, setActualExecutionId
    });

  }

  const [callSendMessage] = usePost("/chats/send_message", {
    onCompleted: onCompleteChatRequest,
    onError: onErrorChatRequest
  });

  const [callSendSuggestion] = usePost("/chats/send_suggestion", {
    onCompleted: onCompleteChatRequest,
    onError: onErrorChatRequest
  });

  const getMessageObj = (plainText, suggestion) => (
    {
      createdAt: Date.now(),
      content: suggestion?.content ? suggestion.content :
        {
          text: `<p>${plainText.replace("\"", "\\\"")}</p>`,
          plain: `${plainText.replace("\"", "\\\"")}`
        },
      action: {},
      messageSuggestions: [],
      type: "USER_MESSAGE",
    }
  )

  const onClickSuggestion = (message, suggestion) => {
    setIsWriting(true);
    let saveSuggestion = message?.settings ? message.settings.save != false : true
    if (saveSuggestion)
      setMessages([...messages, getMessageObj(null, suggestion)]);
    callSendSuggestion({
      chat_id: chatId,
      suggestion_id: suggestion.id,
      session_id: sessionStorage.getItem("execution_session_id"),
    });
  }

  const onClickMoreSuggestions = () =>
    setIsMoreSuggestionsShown(!isMoreSuggestionsShown)

  const onClickCreateProcess = () =>
    onClickTab("flows")

  const onSendMessage = (data, form) => {
    setIsWriting(true);
    setScrollToBottom(true)
    form.setFieldValue('message', '');
    setMessages([...messages, getMessageObj(data.message)]);
    if (!isWriting)
      callSendMessage({ chat_id: chatId, message: data.message });
  }

  const onConfirmAction = (message) => {
    callSetMessage({ id: message.id, settings: { ...message.settings, active: false, result: "OK" } });
    if (message.action.type == "EXECUTE_NODE" && message.action.is_form) return onFormMessage({ data: message.action.form });
    if (message.action.type == "EXECUTE_NODE") return onExecuteNode(message.action.node.id, message.id);
    if (message.action.type == "CONTEXT") return callSendMessage({ chat_id: chatId, message: 1, visible: false });
  }

  const onCancelAction = (message) => {
    callSetMessage({ id: message.id, settings: { ...message.settings, active: false, result: "CANCEL" } });
    return callSendMessage({ chat_id: chatId, message: 2, visible: false });
  }

  useEffect(() => {
    reqMessages.refetch();
  }, [chatId]);

  const chat = reqChat.data?.chat;
  const currentMessage = messages[messages.length - 1];
  const currentSuggestions = currentMessage?.messageChatSuggestions ?? [];
  const visibleMessages = messages.filter(message => message.settings?.visible ?? true)

  if (reqMessages.error) return "";

  if (scrollToBottom) {
    setTimeout(() => {
      chatRef.current?.scrollTo({
        top: chatRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }, 150);
    setScrollToBottom(false);
  }

  return <View
    chat={chat}
    chatId={chatId}
    currentChatId={currentChatId}
    chatRef={chatRef}
    executionRoomName={executionRoomName}
    formRoomName={formRoomName}
    resultRoomName={resultRoomName}
    chatRoomName={chatRoomName}
    chatToast={chatToast}
    messages={visibleMessages}
    currentMessage={currentMessage}
    currentSuggestions={currentSuggestions}
    isWriting={isWriting}
    isExecutionDetailsShown={isExecutionDetailsShown}
    isMoreSuggestionsShown={isMoreSuggestionsShown}
    isDeveloper={isDeveloper}
    actualExecutionId={actualExecutionId}
    onClickSuggestion={onClickSuggestion}
    onClickMoreSuggestions={onClickMoreSuggestions}
    onClickCreateProcess={onClickCreateProcess}
    onSendMessage={onSendMessage}
    onConfirmAction={onConfirmAction}
    onCancelAction={onCancelAction}
    onExecutionMessage={onExecutionMessage}
    onFormMessage={onFormMessage}
    onResultMessage={onResultMessage}
    onChatMessage={onChatMessage}
    setIsExecutionDetailsShown={setIsExecutionDetailsShown}
  />;

}

export default Chat;