import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import ExcelJS from "exceljs";
import { toast, Flip } from 'react-toastify';
import { useQuery, useDelete, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { DELETE_COLLECTION } from "seed/gql/queries";
import { downloadFile } from "components/util/file_util";
import { parseExportData } from "components/collections/util/datatypes";
import { formatNumber } from "components/collections/util/format";
import { Loading } from "seed/helpers";
import * as util from "components/collections/util/tutorial";
import View from "components/collections/Collections.view";

function Collections({
	flowId = null,
	initialCollectionId = null,
	showTabs = true,
	isReadOnly = false,
	styles = {
		background: "#f8fafe",
		border: "1px solid #E7EAF3",
	},
	availableCollections = [],
	refetchParentCollections = () => { }
}) {

	const [activeCollectionId, setActiveCollectionId] = useState(initialCollectionId);
	const [collectionType, setCollectionType] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isImportModalOpen, setIsImportModalOpen] = useState(false);

	const [sorting, setSorting] = useState(JSON.parse(sessionStorage.getItem("collection_sorting")) || []);
	const [filters, setFilters] = useState(JSON.parse(sessionStorage.getItem("collection_filters")) || []);
	const [query, setQuery] = useState(sessionStorage.getItem("collection_query") || "");
	const [search, setSearch] = useState(sessionStorage.getItem("collection_search") || "");
	const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem("collection_page")) || 1);
	const pageSize = 10;

	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	const [embeddingStatus, setEmbeddingStatus] = useState(null);

	const collectionRoomName = `COLLECTION_STATUS_${activeCollectionId}`;

	const qCollection = useDetail(`{
		collection {
			id
			schema
			name
			initials
			type
		}
	}`, activeCollectionId);

	const [callGetData, reqGetData] = usePost("/collections/get_data");

	const refreshData = () => callGetData({
		collection_id: activeCollectionId,
		page_size: pageSize,
		page_number: pageNum,
		search,
		query,
		sorting,
		ignore_compress: true
	});

	const [callImportRows, reqImportRows] = usePost("/collections/import_data", {
		onCompleted: () => refreshData()
	});

	const [callGetAllData] = usePost("/collections/get_data", {
		onCompleted: (rowsData) => {

			const sheetName = "Sheet1";
			const workbook = new ExcelJS.Workbook();
			const sheet = workbook.addWorksheet(sheetName);
			const exportColumns = columns.filter((c) => !c.internal);

			const excelColumns = [
				...exportColumns.map((c) => c.label),
				"ID Sistema",
				"Folio Sistema"
			];

			const excelRows = rowsData.data.map((row) => [
				...exportColumns.map((c) => parseExportData(c, row.data[c.name])),
				row.id,
				row.full_folio
			]);

			sheet.addRow(excelColumns);

			const lastRow = sheet.lastRow;
			for (let i = 0; i < exportColumns.length + 2; i++) {
				if (i < exportColumns.length)
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
				else
					lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
			}

			excelRows.forEach((row) => sheet.addRow(row));

			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], { type: "application/octet-stream" });
				downloadFile(URL.createObjectURL(blob), null, "export.xlsx");
			});

		}
	});

	const [callDownloadZip] = usePost("/collections/download_zip", {
		onCompleted: (data) => {
			const a = document.createElement("a");
			a.href = data.url;
			a.download = data.filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	});

	const [callAction] = usePost("/collections/update_table", {
		onCompleted: () => {
			qCollection.refetch();
			refreshData();
		},
		onError: (error) => {
			if(error.status == 402 && !toast.isActive("error-toast")) {
				toast("El tamaño del archivo excede el límite de tu licencia", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					toastId: "error-toast",
					transition: Flip,
					type: "error"
				});	
			}
		}
	});

	const qCollections = useQuery(`{
		collections {
			id
			name
			type
			initials
		}
	}`, "flowId=" + flowId, {
		onCompleted: (data) => {

			const collections = data.collections.filter(c => availableCollections.length === 0 ||
				availableCollections.map(c => c.id).includes(c.id));

			if (collections.length > 0 && !activeCollectionId) {

				let defCollectionId = collections[0].id

				for (let collection of collections)
					if (collection.id == sessionStorage.getItem("def_collection_" + flowId))
						defCollectionId = collection.id

				setActiveCollectionId(defCollectionId)

			}

		}
	});

	const [callDelete] = useDelete(DELETE_COLLECTION, {
		onCompleted: (data) => {
			qCollections.refetch();
			refetchParentCollections();
			setActiveCollectionId(null);
		}
	});

	const [callGetRow] = usePost("/collections/get_collection_data", {
		onCompleted: (data) => {
			const newRows = rows.map(row => {
				if (row.id === data.id)
					return { ...row, data: data.data }
				return row;
			});
			setRows(newRows);
		}
	});

	const [callValidateDeleteRow] = usePost("/collections/validate_delete_collection_data", {
		onCompleted: (data) => {

			const args = { 
				actions: [{ initial: data.collection_data, action: "delete", cascade: true }], 
				type: "data", 
				collection_id: activeCollectionId 
			};

			if(data.valid) 
				return callAction(args);

			swal({
				title: "Eliminar registro",
				icon: "warning",
				text: "¿Estás seguro que desea eliminar esta registro? las siguientes referencias se verán afectadas:\n" 
					+ data.dependancies.map(d => `\n* ${d.collection_name}  -  ${d.full_folio}`),
				buttons: {
					delete_cascade: {
						text: "Eliminar todo",
						className: "swal-button swal-button--cancel btn-danger px-2 w-100",
					},
					delete_set_null: {
						text: "Eliminar y desvincular",
						className: "swal-button swal-button--cancel btn-danger px-2 w-100",
					},
					exit: {
						text: "Cancelar",
						className: "swal-button swal-button--cancel btn-secondary px-2 w-100",
					}
				},
			}).then((response) => {

				if(response == "delete_cascade") {
					args.actions[0].cascade = true;
					callAction(args);
				}

				if(response == "delete_set_null") {
					args.actions[0].cascade = false;
					callAction(args);
				}

			});

		}
	});
	
	const onClickTab = (collectionId) => {
		sessionStorage.setItem("def_collection_" + flowId, collectionId)
		sessionStorage.removeItem("collection_search")
		sessionStorage.removeItem("collection_query")
		sessionStorage.removeItem("collection_filters")
		sessionStorage.removeItem("collection_page")
		sessionStorage.removeItem("collection_total_pages")
		sessionStorage.removeItem("collection_sorting")
		setSearch("");
		setQuery("");
		setFilters([]);
		setSorting([]);
		setActiveCollectionId(collectionId);
	}

	const onClickEdit = (collectionId) => {
		onClickTab(collectionId);
		setIsEditModalOpen(true)
	}

	const onClickDelete = (collectionId) =>
		swal({
			title: "Eliminar tabla",
			icon: "warning",
			text: "¿Estás seguro que desea eliminar esta tabla?",
			buttons: {
				confirm: {
					text: "Aceptar",
					className: "swal-button swal-button--cancel btn-success",
				},
				cancel: "Cancelar",
			},
		}).then((response) => {
			if (response) callDelete({ id: collectionId });
		})

	const onAddColumn = (actions) =>
		callAction({ actions, type: "schema", collection_id: activeCollectionId });

	const onChangeColumn = (actions) =>
		callAction({ actions, type: "schema", collection_id: activeCollectionId });

	const onChangeColumnOptions = (actions) =>
		callAction({ actions, type: "schema", collection_id: activeCollectionId });

	const onDeleteColumn = (actions) =>
		callAction({ actions, type: "schema", collection_id: activeCollectionId });

	const onAddRow = (actions) =>
		callAction({ actions, type: "data", collection_id: activeCollectionId });

	const onDeleteRow = (index) => 
		callValidateDeleteRow({ collecion_data_id: rows[parseInt(index)].id });

	const onInsertRow = (actions) =>
		callAction({ actions, type: "data", collection_id: activeCollectionId });

	const onChangeRow = (actions) =>
		callAction({ actions, type: "data", collection_id: activeCollectionId });

	const onChangeSearch = (value) => {
		sessionStorage.setItem("collection_search", value)
		setSearch(value);
	}

	const onChangeFilters = (value) => {
		sessionStorage.setItem("collection_filters", JSON.stringify(value))
		setFilters(value);
	}

	const onChangeQuery = (value) => {
		sessionStorage.setItem("collection_query", value)
		setQuery(value);
	}

	const onChangeSorting = (value) => {
		sessionStorage.setItem("collection_sorting", JSON.stringify(value))
		setSorting(value);
	}

	const onPageChange = (page) => {
		if (page < 1) page = 1;
		if (page > totalPages) page = totalPages;
		sessionStorage.setItem("collection_page", page)
		sessionStorage.setItem("collection_total_pages", totalPages)
		setPageNum(page);
	}

	const onImport = (rows) =>
		callImportRows({
			collection_id: activeCollectionId,
			data: rows
		});

	const onExport = () => {
		callGetAllData({
			collection_id: activeCollectionId,
			ignore_compress: true
		});
	}

	const onDownloadZip = (field_name, file_limit) =>
		callDownloadZip({ collection_id: activeCollectionId, field_name, file_limit });

	const onSaveCollection = (data) => {
		setIsModalOpen(false);
		setActiveCollectionId(data.id);
		qCollections.refetch();
		refetchParentCollections();
	}

	const onEditCollection = () => {
		setIsModalOpen(false);
		qCollections.refetch();
		qCollection.refetch();
		refreshData();
		refetchParentCollections();
	}

	const onClickCell = (data, i, j) =>
		callGetRow({ collection_data_id: data.id });

	const onCollectionMessage = ({ data }) => {
		const { current, total } = data;
		setEmbeddingStatus(current / total * 100);
		if(current == total)
			setTimeout(() => setEmbeddingStatus(null), 1000);
	}

	const { collection } = qCollection.data || {};
	const { collections: auxCollections = [] } = qCollections.data || {};
	const collectionDataes = (reqGetData.data || {}).data;
	const totalPages = (reqGetData.data || {}).total_pages || 0;
	const loading = qCollection.loading || qCollections.loading || reqGetData.loading;

	const collections = auxCollections.filter(c => availableCollections.length === 0 || 
		availableCollections.map(c => c.id).includes(c.id));

	useEffect(() => {
		
		if(!availableCollections || availableCollections.length === 0) 
			return;
		
		setActiveCollectionId(null);
		qCollections.refetch();

	}, [availableCollections]);

	useEffect(() => {
		qCollections.refetch();
	}, [flowId]);

	useEffect(() => {
		setRows([]);
		qCollection.refetch();
		refreshData();
	}, [pageNum, activeCollectionId, search, query, sorting]);

	useEffect(() => {
		qCollections.refetch();
	}, [activeCollectionId]);

	useEffect(() => {
		if (collection && collection.schema) {
			let schema = {};
			try { schema = JSON.parse(collection.schema) ?? {} }
			catch (e) { schema = (collection.schema) ?? {} }
			setColumns(schema.structure || []);
		}
	}, [collection]);

	useEffect(() => {

		if (!collection) return;

		const sortedData = Object.values(collectionDataes || []);
		if(sorting.length == 0) sortedData.sort((a, b) => a.folio - b.folio);

		const data = sortedData.map(d => ({
			data: function () {
				try { return JSON.parse(d.data) ?? {} }
				catch (e) { return (d.data) ?? {} }
			}(),
			_folio: `${collection.initials}-${formatNumber(d.folio)}`,
			folio: d.folio,
			id: d.id,
		}));

		setRows(data);

	}, [collectionDataes, collection]);

	if (reqImportRows.loading) 
		return <div className="w-100" style={{height: "80%"}}><Loading /></div>;
	
	return <View
		styles={styles}
		flowId={flowId}
		showTabs={showTabs}
		columns={columns}
		rows={rows}
		pageNum={pageNum}
		pageSize={pageSize}
		totalPages={totalPages}
		collections={collections}
		activeCollectionId={activeCollectionId}
		query={query}
		filters={filters}
		search={search}
		sorting={sorting}
		height={totalPages > 1 ? "calc(100% - 180px)" : "calc(100% - 127px)"}
		loading={loading}
		tutorialSteps={util.dataTutorialSteps}
		embeddingStatus={embeddingStatus}
		collectionRoomName={collectionRoomName}
		collectionType={collectionType}
		isModalOpen={isModalOpen}
		isEditModalOpen={isEditModalOpen}
		isImportModalOpen={isImportModalOpen}
		isReadOnly={isReadOnly}
		setColumns={setColumns}
		setIsModalOpen={setIsModalOpen}
		setIsEditModalOpen={setIsEditModalOpen}
		setIsImportModalOpen={setIsImportModalOpen}
		setCollectionType={setCollectionType}
		onClickTab={onClickTab}
		onClickDelete={onClickDelete}
		onClickEdit={onClickEdit}
		onAddColumn={onAddColumn}
		onChangeColumn={onChangeColumn}
		onChangeColumnOptions={onChangeColumnOptions}
		onDeleteColumn={onDeleteColumn}
		onAddRow={onAddRow}
		onDeleteRow={onDeleteRow}
		onInsertRow={onInsertRow}
		onChangeRow={onChangeRow}
		onImport={onImport}
		onExport={onExport}
		onDownloadZip={onDownloadZip}
		onChangeSearch={onChangeSearch}
		onPageChange={onPageChange}
		onChangeQuery={onChangeQuery}
		onChangeFilters={onChangeFilters}
		onChangeSorting={onChangeSorting}
		onClickCell={onClickCell}
		onSaveCollection={onSaveCollection}
		onEditCollection={onEditCollection}
		onCollectionMessage={onCollectionMessage}
	/>;
}

export default Collections;