import React from "react";
import { Formik, Field, Form } from "formik";
import { FileField } from "seed/helpers";
import { ColumnTypes, ColumnTypeLabels, getSelectTypes } from "components/collections/util/datatypes";
import { toCamelCase } from "components/collections/util/format";

const CollectionFormView = ({
  formRef,
  collectionType,
  error,
  isGenerated,
  isLoading,
  isLoadingGenerate,
  initialColumns = [],
  showImport = false,
  collection = {},
  collections = [],
  setImportFile = () => { },
  onSubmit = () => { },
  onGenerate = () => { }
}) => (
  <div className="card">

    <div className="card-header">
      <h3 class="mb-0">{collection.id ? "Editar colección" : "Crear colección"}</h3>
    </div>

    <div className="card-body px-4">

      <Formik
        innerRef={formRef}
        initialValues={{
          ...collection,
          type: collectionType,
          structure: initialColumns
        }}
      >
        {({ values, setFieldValue }) =>
          <Form 
            id="collection-form"
            // autoComplete="off"
          >

            <div class="form-group mb-3">
              <span class="d-flex justify-content-between dropdown-header pt-0 pb-2 px-0">
                <span style={{ color: "#777" }}>Nombre</span>
              </span>
              <div class="input-group">
                <Field
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Ej. Empleados, Solicitudes, Productos"
                  class="form-control"
                  autocomplete="off"
                  maxlength="512"
                  required={true}
                  autoFocus={true}
                />
              </div>
            </div>

            {
              !showImport && values.type == "TABLE"
                ? <div>
                  <span class="d-flex justify-content-between dropdown-header pt-0 pb-3 px-0">
                    <span style={{ color: "#777" }}>Columnas
                      {
                        !collection.id && values?.name?.length > 3 &&
                        <button
                          type="button"
                          class="btn btn-sm btn-link ml-1 px-2 mb-2 py-1 animate__animated animate__fadeIn animate__faster pt-2"
                          onClick={() => onGenerate(values?.name)}
                        >
                          {
                            isLoadingGenerate && <>
                              <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                              Generando...
                            </>
                          }
                          {
                            !isLoadingGenerate && <>
                              <i class="tio-magic-wand mr-1"></i> Sugerir columnas
                            </>
                          }
                        </button>
                      }
                    </span>
                  </span>

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-capitalize text-center">Nombre</th>
                        <th className="text-capitalize text-center">Tipo</th>
                        <th className="text-capitalize text-center"></th>
                        <th className="text-capitalize text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !isLoadingGenerate &&
                        <>
                          {
                            values?.structure?.map((field, idx) => field.action != "delete" && !field.internal && <>
                                <tr 
                                  key={`field-${field.label}-${idx}`} 
                                  className={isGenerated ? " animate__animated animate__fadeIn animate__faster" : ""}
                                  style={{
                                    background: idx % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <td>
                                    <input
                                      type="text"
                                      name={`label_${idx}`}
                                      id={`label_${idx}`}
                                      className="form-control"
                                      value={field.final.label}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "structure",
                                          values?.structure?.map((f, i) => {
                                            if (i == idx) return { 
                                              ...f, 
                                              final: { 
                                                ...f.final, 
                                                label: e.target.value, 
                                                name: toCamelCase(e.target.value)
                                              },
                                              action: f.initial ? "update" : "create"
                                            };
                                            return f;
                                          })
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      name={`structure_${idx}`}
                                      id={`structure_${idx}`}
                                      className="form-control"
                                      value={field.final.type}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "structure",
                                          values?.structure?.map((f, i) => {
                                            if (i == idx) return {
                                              ...f,
                                              final: { 
                                                ...f.final, 
                                                type: e.target.value,
                                              },
                                              action: "update"
                                            };
                                            return f;
                                          })
                                        );
                                      }}
                                    >
                                      {getSelectTypes().map((type, i) => (
                                        <option
                                          key={`field-type-${type}-${i}`}
                                          value={type}
                                          selected={type == field.type}
                                        >
                                          {ColumnTypeLabels[type]}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td>
                                    <div class="btn-group" role="group">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary"
                                        disabled={idx == 0}
                                        onClick={() => {
                                          let auxStructure = values?.structure ?? [];
                                          let newStructure = [...auxStructure];
                                          let tmp = newStructure[idx - 1];
                                          newStructure[idx - 1] = newStructure[parseInt(idx)];
                                          newStructure[parseInt(idx)] = tmp;
                                          setFieldValue("structure", newStructure);
                                        }}
                                      >
                                        <i class="fas fa-arrow-up"></i>
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary"
                                        disabled={idx == values?.structure?.length - 1}
                                        onClick={() => {
                                          let auxStructure = values?.structure ?? [];
                                          let newStructure = [...auxStructure];
                                          let tmp = newStructure[idx + 1];
                                          newStructure[idx + 1] = newStructure[parseInt(idx)];
                                          newStructure[parseInt(idx)] = tmp;
                                          setFieldValue("structure", newStructure);
                                        }}
                                      >
                                        <i class="fas fa-arrow-down"></i>
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-outline-danger"
                                      onClick={() => {
                                        setFieldValue("structure", values?.structure?.map((f, i) => {
                                          if (i == idx) return { ...f, final: null, action: "delete" };
                                          return f;
                                        }));
                                      }}
                                    >
                                      <i class="fas fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                                {
                                  (field.final?.type == ColumnTypes.ENUM || field.final?.type == ColumnTypes.COLLECTION) &&
                                    <tr 
                                      style={{
                                        background: idx % 2 ? "#f9f9f9" : "#fff",
                                      }}
                                    >
                                      <td colSpan="4">
                                        <div className="d-flex flex-column align-items-center px-5">
                                          <button 
                                            type="button"
                                            className="btn btn-sm text-primary"
                                            onClick={() => {
                                              setFieldValue("structure", values?.structure?.map((f, j) => {
                                                if (j == idx) return { ...f, final: { ...f.final }, show_advanced: !f.show_advanced };
                                                return f;
                                              }));
                                            }}
                                          >
                                            <i className="fas fa-cog mr-1"></i>
                                            { field.show_advanced ? "Ocultar" : "Mostrar" } opciones avanzadas
                                            <input
                                              type="text"
                                              required
                                              name={`hidden_${idx}`}
                                              id={`hidden_${idx}`}
                                              style={{
                                                width: "1px",
                                                height: "1px",
                                                opacity: 0,
                                              }}
                                              autoComplete="off"
                                              value={field.final?.structure}
                                            />
                                          </button>

                                          <div 
                                            className="w-100"
                                            style={{
                                              display: field.show_advanced && field.final?.type == ColumnTypes.ENUM ? "block" : "none"
                                            }}
                                          >
                                            <h6 style={{ color: "#777" }}>Valores</h6>
                                            {
                                              field.final?.structure?.values?.map((value, i) => 
                                                <div 
                                                  key={`enum-value-${value}-${i}`}
                                                  className="d-flex justify-content-between w-100 my-2"
                                                >
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    defaultValue={value}
                                                    onBlur={(e) => {
                                                      const enumValues = [...field.final?.structure?.values ?? []];
                                                      enumValues[i] = e.target.value;
                                                      setFieldValue("structure", values?.structure?.map((f, j) => {
                                                        if (j == idx) return { ...f, final: { ...f.final, structure: { values: enumValues } } };
                                                        return f;
                                                      }));
                                                    }}
                                                  />
                                                  <button
                                                    className="btn btn-sm btn-danger ml-1"
                                                    onClick={() => {
                                                      const enumValues = [...field.final?.structure?.values ?? []];
                                                      enumValues.splice(i, 1);
                                                      setFieldValue("structure", values?.structure?.map((f, j) => {
                                                        if (j == idx) return { 
                                                          ...f, final: { 
                                                            ...f.final, 
                                                            structure: enumValues.length > 0 ? { values: enumValues } : null
                                                          } 
                                                        };
                                                        return f;
                                                      }));
                                                    }}
                                                  >
                                                    <i className="fas fa-trash"></i>
                                                  </button>
                                                </div>
                                              )
                                            }
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-outline-primary mt-2 w-100"
                                              onClick={() => {
                                                const enumValues = [...(field.final?.structure?.values ?? [])];
                                                enumValues.push("");
                                                setFieldValue("structure", values?.structure?.map((f, j) => {
                                                  if (j == idx) return { ...f, final: { ...f.final, structure: { values: enumValues } } };
                                                  return f;
                                                }));
                                              }}
                                            >
                                              Agregar
                                            </button>
                                          </div>

                                          <div 
                                            className="w-100"
                                            style={{
                                              display: field.show_advanced && field.final?.type == ColumnTypes.COLLECTION ? "block" : "none"
                                            }}
                                          >
                                            <h6 style={{ color: "#777" }}>Tabla</h6>
                                            <select
                                              required
                                              className="form-control form-control-sm"
                                              value={field.final?.structure?.collection_id}
                                              onChange={(e) => {
                                                
                                                if (e.target.value == -1) return setFieldValue("structure", values?.structure?.map((f, j) => {
                                                  if (j == idx) return { ...f, final: { ...f.final, structure: null } };
                                                  return f;
                                                }));

                                                const collection = collections.find(c => c.id == e.target.value);
                                                if (!collection) return;

                                                setFieldValue("structure", values?.structure?.map((f, j) => {
                                                  if (j == idx) return { 
                                                    ...f, final: { 
                                                      ...f.final, 
                                                      structure: { collection_id: collection.id, type: "@" + collection.name } 
                                                    } 
                                                  };
                                                  return f;
                                                }));

                                              }}
                                            >
                                              <option value={-1}>Seleccione una tabla</option>
                                              {
                                                collections.map((c, i) => (
                                                  <option
                                                    key={`field-collection-${c.id}-${i}`}
                                                    value={c.id}
                                                    selected={field.structure?.collection_id == c.id}
                                                  > 
                                                    {c.name} 
                                                  </option>
                                                ))
                                              }
                                            </select>
                                          </div>

                                        </div>
                                      </td>
                                    </tr>
                                }
                              </>
                            )
                          }
                        </>
                      }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="py-0" colSpan="4">
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-primary text-left btn-block mt-2 mb-2 w-auto"
                            onClick={() => {
                              setFieldValue("structure", [
                                ...values?.structure ?? [],
                                {
                                  initial: null,
                                  final: { 
                                    name: "",
                                    label: "",
                                    type: ColumnTypes.STRING
                                  },
                                  action: "create",
                                  show_advanced: true
                                },
                              ]);
                            }}
                          >
                            + Nueva columna
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                : null
            }

            {!collection.id && showImport ?
              <div className="form-group px-3 pt-2 pb-4 mt-3" style={{ background: "#fafafa", border: "1px solid #f0f0f0" }}>
                <span class="d-flex justify-content-between dropdown-header pt-2 pb-2 px-0">
                  <span style={{ color: "#777" }}>Crear desde excel</span>
                </span>
                <FileField
                  name="file"
                  id="file"
                  required={true}
                  accept=".xlsx,.xls,.csv"
                  class="form-control form-control-lg"
                  setFieldValue={() => { }}
                  onCompleted={(data) => setImportFile(data)}
                />
              </div> : null}

            {
              error &&
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            }

            <div className="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-block btn-primary mt-3"
                disabled={isLoading}
                onClick={() => {
                  let form = document.getElementById("collection-form")
                  if (form.checkValidity())
                    onSubmit(values)
                  else
                    form.reportValidity()
                }}
              >
                {
                  isLoading &&
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                }
                {
                  !isLoading && <>
                    {collection.id ? "Editar colección" : "Crear colección"}
                  </>
                }
              </button>

            </div>

          </Form>}
      </Formik>
    </div>

  </div>
);

export default CollectionFormView;