import React from "react";
import View from "components/tmisc/organizations/company/Company.view";
import { useDelete, useDetail, useSet } from "seed/gql";
import { Loading } from "seed/helpers";
import { DELETE_ORGANIZATION, SET_ORGANIZATION } from "seed/gql/queries";
import swal from "sweetalert";

const Company = ({ selectedOrganization }) => {

    const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

    const [callSet] = useSet(SET_ORGANIZATION, {
        onCompleted: () => {
            swal({ text: "Cambios guardados exitosamente", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/details";
            });
        },
        onError: () => {
            swal({ text: "Ha ocurrido un error", icon: "error" });
        }
    });

    const [callDelete] = useDelete(DELETE_ORGANIZATION, {
        onCompleted: () => {
            swal({ text: "Organización eliminada exitosamente.", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/details";
            });
        },
        onError: () => {
            swal({ text: "Ha ocurrido un error", icon: "error" });
        }
    });

    const qOrg = useDetail(
        `{
      organization {
        id
        name
        description
        orgSettings
      }
    }`,
        selectedOrganization
    );

    if (selectedOrganization === 0) {
        return <View organization={{}} isDeveloper={isDeveloper} />;
    }

    if (qOrg.loading) return <Loading />;
    if (qOrg.error) return "Error";

    const { organization = {} } = qOrg.data;

    const onSubmit = (values) => {
        callSet({
            id: organization.id,
            orgSettings: {
                ...organization.orgSettings,
                theme: {
                    primaryColor: values.primaryColor,
                    accentColor: values.accentColor
                },
                logo: values.logo
            },
            name: values.name,
            description: values.description
        });
    };

    const onDelete = (companyId) => {
        swal({
            title: "Eliminar organización",
            icon: "warning",
            text: "¿Estás seguro que desea eliminar la organización?",
            buttons: {
                confirm: {
                    text: "Aceptar",
                    className: "swal-button swal-button--cancel btn-success",
                },
                cancel: "Cancelar",
            },
        }).then((response) => {
            if (response) {
                callDelete({ id: companyId });
            }
        })
    }

    return <View
        onDelete={onDelete}
        onSubmit={onSubmit}
        organization={organization} 
        isDeveloper={isDeveloper}
    />;
};

export default Company;
