import React from "react";
import StyleColor from "components/util/helpers/StyleColor"

const LicenseInvalid = () =>
  <main id="content" role="main" class="main pl-0 h-100 w-100" style={{overflowY: "auto"}}>
    <StyleColor />
    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div className="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster">
            <div class="card-body">
              <div class="text-center">
                <div class="mb-5">
                <i class="tio-error text-danger display-2"></i>
                  <h1 class="display-4">Licencia inválida</h1>
                </div>
              </div>
              <div class="text-center">
                <p class="mb-5">Tu licencia ha expirado o no es válida. Por favor, contacta al administrador para renovarla.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>;

export default LicenseInvalid;