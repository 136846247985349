import React from "react";
import { Handle, Position, NodeResizeControl } from 'reactflow';
import "styles/css/studio/board/nodes/NodeAnnotation.css";



const NodeView = ({ id, content, backgroundColor, isSelected, isEditable, onEdit, onSelect }) => (

  <div className="node-annotation w-100 h-100" style={{ backgroundColor: backgroundColor }}>

    {!isEditable && (
      <div className="no-editable-overlay drag-handle-container">
      </div>
    )}

    <div className="node-annotation-content drag-handle-container w-100 h-100" onClick={onEdit}>
      <h6 className="mb-0 text-center">{content}</h6>

      <div className={"badge-container " + (isSelected ? "selected " : "")} onClick={e => e.stopPropagation()}>
        <div class="badge badge-pill badge-checkbox" onClick={onSelect}>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id={`checkSelectNodeAnnotation${id}`} class="custom-control-input"
                onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
              />
              <label class="custom-control-label" for={`checkSelectNodeAnnotation${id}`}></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NodeResizeControl minWidth={10} minHeight={10} className="node-annotation-resizer">
      <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
    </NodeResizeControl>

    {/* Node Annotation Handlers */}
    <Handle id="next_top" key="next_top" position={Position.Top} />
    <Handle id="next_right" key="next_right" position={Position.Right} />
    <Handle id="next_bottom" key="next_bottom" position={Position.Bottom} />
    <Handle id="next_left" key="next_left" position={Position.Left} />

  </div>
);

export default NodeView;