import React from "react";
import Executions from "components/tmisc/executions/Executions";
import ChatHistory from "components/presentation/history/ChatHistory";
import "styles/css/presentation/Presentation.css";


const HistoryView = ({
  flow,
  chatId,
  historyTab,
  changeChat,
  onClickTab,
}) => (

  <div>
    <div id="history-tabs" className="tabs d-flex mt-2 mb-4">
      <button
        type="button"
        className={"dropdown-header " + (historyTab == "executions" ? "active" : "")}
        onClick={() => onClickTab("executions")}
      >
        Ejecuciones
      </button>
      <button
        type="button"
        className={"dropdown-header " + (historyTab == "chats" ? "active" : "")}
        onClick={() => onClickTab("chats")}
      >
        Chats
      </button>
    </div>

    {
      historyTab == "executions" && 
        <Executions 
          flow={flow} 
        />
    }
    
    {
      historyTab == "chats" && 
        <ChatHistory 
          flow={flow} 
          chatId={chatId} 
          refreshKey={Math.random()} 
          changeChat={changeChat} 
        />
    }

  </div>
)

export default HistoryView;