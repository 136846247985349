/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "actions": "action",
  "action": "action",
  "apps": "app",
  "app": "app",
  "appDatatypes": "appDatatype",
  "appDatatype": "appDatatype",
  "appSettingses": "appSettings",
  "appSettings": "appSettings",
  "chats": "chat",
  "chat": "chat",
  "chatMessages": "chatMessage",
  "chatMessage": "chatMessage",
  "chatSuggestions": "chatSuggestion",
  "chatSuggestion": "chatSuggestion",
  "chatTemplateRules": "chatTemplateRule",
  "chatTemplateRule": "chatTemplateRule",
  "collections": "collection",
  "collection": "collection",
  "collectionDataes": "collectionData",
  "collectionData": "collectionData",
  "creditUsages": "creditUsage",
  "creditUsage": "creditUsage",
  "executions": "execution",
  "execution": "execution",
  "executionSteps": "executionStep",
  "executionStep": "executionStep",
  "executionUsages": "executionUsage",
  "executionUsage": "executionUsage",
  "flows": "flow",
  "flow": "flow",
  "flowAnnotations": "flowAnnotation",
  "flowAnnotation": "flowAnnotation",
  "flowEdges": "flowEdge",
  "flowEdge": "flowEdge",
  "flowNodes": "flowNode",
  "flowNode": "flowNode",
  "flowPages": "flowPage",
  "flowPage": "flowPage",
  "folders": "folder",
  "folder": "folder",
  "integrations": "integration",
  "integration": "integration",
  "integrationAccounts": "integrationAccount",
  "integrationAccount": "integrationAccount",
  "licenses": "license",
  "license": "license",
  "localStorages": "localStorage",
  "localStorage": "localStorage",
  "orders": "order",
  "order": "order",
  "organizations": "organization",
  "organization": "organization",
  "organizationParticipants": "organizationParticipant",
  "organizationParticipant": "organizationParticipant",
  "participants": "participant",
  "participant": "participant",
  "suggestions": "suggestion",
  "suggestion": "suggestion",
  "tagTutorials": "tagTutorial",
  "tagTutorial": "tagTutorial",
  "templateCategories": "templateCategory",
  "templateCategory": "templateCategory",
  "tutorials": "tutorial",
  "tutorial": "tutorial",
  "tutorialCategories": "tutorialCategory",
  "tutorialCategory": "tutorialCategory",
  "users": "user",
  "user": "user",
};