import React from "react";

const flowTutorialSteps = [
    {
        selector: "#board",
        content: () => <label><b>Bienvenido a Workix Studio.<br />Este es tu tablero principal para diseñar tus procesos.</b></label>
    },
    {
        selector: "#toolbox-sidenav",
        content: () => <label><b>A la izquierda tendrás tu barra de herramientas para personalizar tu proceso</b></label>
    },
];

const execModeTutorialSteps = [
    {
        selector: "#exec-mode",
        content: () => <label><b>En el modo visual personaliza como se verá tu proceso para utilizarlo y compartirlo fácilmente.</b></label>
    }
];

const customizationTutorialSteps = [
    {
        selector: "#exec-mode",
        content: () => <label><b>En el modo visual personaliza como se verá tu proceso para utilizarlo y compartirlo fácilmente.</b></label>
    }
];


export { flowTutorialSteps, execModeTutorialSteps, customizationTutorialSteps };