import React from "react";
import UseCaseItem from "components/tmisc/use_cases/UseCases.sub.Item.view";
import UseCaseCategory from "components/tmisc/use_cases/UseCases.sub.Category.view";
import { LANDING_URL } from "settings"
import Categories from "components/tmisc/templates/Categories";
import Flows from "components/tmisc/templates/Flows";
import StyleColor from "components/util/helpers/StyleColor"
import "styles/css/tmisc/UseCases.css";


const UseCasesView = ({
  flows,
  selectedCategory,
  flowTemplateFilters,
  templateCategories,
  onChangeCategory
}) => (
  <div id="use-cases">
    <StyleColor />
    <div id="use-cases-container" class="container w-100 px-3 pt-3">

      <a class="d-flex justify-content-center" href={LANDING_URL}>
        <img class="z-index-2 workix-logo"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" />
      </a>

      <div className="d-flex flex-column align-items-center justify-content-center mt-3 pb-5 animate__animated animate__fadeInUp w-100">

        <div id="use-cases-title" class="mb-4 text-center">
          <h1 className="text-white">Ejemplos</h1>
        </div>

        {/* Template Categories */}
        <Categories
          Category={(props) => <UseCaseCategory {...props} onChangeCategory={onChangeCategory} />}
          manualCategories={templateCategories}
          initialActiveCategory={selectedCategory ? selectedCategory.id : null}
          style={{ width: window.innerWidth > 576 ? Math.min(templateCategories.length * 7, 85) + "%" : "90%", marginBottom: "1.4rem" }}
          arrowsStyle={{
            color: "white",
            border: "2px solid white",
            fontSize: "1em"
          }}
          arrowContainerStyle={{ width: "100%" }}
          scrollAmount={400}
        />

        {/* Template Flows */}

        <Flows
          Flow={(props) => <UseCaseItem {...props} category={selectedCategory} />}
          manualFlows={flows}
          scrollAmount={600}
          filters={flowTemplateFilters}
          style={{ width: "100%" }}
          arrowContainerStyle={{ width: "100%" }}
        />

      </div>

    </div>
  </div>
);

export default UseCasesView;