import React, { useState, useRef } from "react";
import swal from "sweetalert";
import { usePost, useGet } from "seed/api";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/start/templates/Templates.view";
import { orderBy } from "lodash";


function Templates() {

  const userId = sessionStorage.getItem("id");
  const searchFieldRef = useRef(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [filters, setFilters] = useState({
    template_categories: [],
    template_type: ["USE_CASE", "SAMPLE"]
  })

  const [callCloneTemplate, reqCloneTemplate] = usePost('/flows/clone_template', {
    onCompleted: (data) => {
      const clonedFlow = data?.cloned_flow;
      if (clonedFlow) {
        window.location.replace(`/p/${clonedFlow?.token}`);
      }
    },
    onError: (error) => {
      console.log(error);
      swal("Error al usar plantilla", "Ha ocurrido un error inesperado, por favor intente de nuevo mas tarde", "error")
    }
  })

  const reqFlows = useGet('/flows/get_template_flows', { ...filters });
  const reqCategories = useQuery(`{
    templateCategories {
      name
      description
      uiSettings
    }
  }`, "", {
    orderBy: "id"
  });

  const onSearchTemplates = () => {
    if (searchFieldRef.current == null || searchFieldRef.current == undefined)
      return;

    const searchPrompt = searchFieldRef.current?.value ?? ""
    const newFilters = {
      template_categories: "all",
      template_type: ["SAMPLE"],
      search: searchPrompt
    }
    setFilters(newFilters);
    setSelectedProfile(null);
    reqFlows.refetch();
  }

  const onClearSearch = () => {
    const newFilters = {
      template_categories: [],
      template_type: ["USE_CASE", "SAMPLE"],
      search: null
    }
    setFilters(newFilters);
    setSelectedProfile(null);
    reqFlows.refetch();
  }

  const onChangeProfile = (newProfile) => {
    const deselectProfile = 
      (filters.template_categories.length > 0 && filters.template_categories[0] == newProfile.id) ||
      (filters.template_categories.length > 1 && newProfile.id == "all")

    
    const newFilters = {
      template_categories: newProfile.id == "all" ? "all" : [newProfile.id],
      template_type: ["USE_CASE", "SAMPLE"],
      search: null
    }
    if (deselectProfile) newFilters.template_categories = []
    setFilters(newFilters)
    setSelectedProfile(deselectProfile ? null : newProfile);
    searchFieldRef.current.value = "";
    reqFlows.refetch();
  }

  const onCloneTemplate = (flow_id) => {
    callCloneTemplate({
      flow_id: flow_id,
      user_id: userId
    })
  }

  const isTemplateCategoriesLoading = reqCategories.loading;
  const isFlowsLoading = reqFlows.loading;
  if (isTemplateCategoriesLoading) return <PlaceholderView />;

  const templateCategories = reqCategories.data?.templateCategories ?? [];
  const trees = reqFlows.data;

  return (
    <View
      templateTrees={trees}
      templateCategories={templateCategories}
      selectedProfile={selectedProfile}
      filters={filters}
      isFlowsLoading={isFlowsLoading}
      isTemplateCategoriesLoading={isTemplateCategoriesLoading}
      searchFieldRef={searchFieldRef}
      onChangeProfile={onChangeProfile}
      onCloneTemplate={onCloneTemplate}
      onSearchTemplates={onSearchTemplates}
      onClearSearch={onClearSearch}
    />
  );
}


const PlaceholderView = () =>
  <div id="start-templates" className="card py-10"
    style={{ background: "#d7e7f4", minHeight: "15.7rem" }}>
    <Loading />
  </div>


Templates.propTypes = {};

export default Templates;