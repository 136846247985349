import React from "react";
import { Formik, Form, Field } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

const FormView = ({ onSubmit, organization }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Crear Orden</h3>
    </div>

    <div className="card-body">
      <Formik
        initialValues={{ 
          type: "", 
          orderType: "", 
          startDate: null, 
          endDate: null, 
          quantity: "", 
          selectedUser: "" 
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="orderType">Tipo de Orden <span className="text-danger">*</span></label>
              <Field as="select" name="orderType" className="form-control" required>
                <option value="">-- Selecciona tipo --</option>
                <option value="LICENSE">Licencia</option>
                <option value="CREDITS">Créditos</option>
              </Field>
            </div>

            {values.orderType === "LICENSE" && (
              <>
                <div className="form-group">
                  <label htmlFor="type">Tipo de licencia <span className="text-danger">*</span></label>
                  <Field as="select" name="type" className="form-control" required>
                    <option value="">-- Selecciona un plan --</option>
                    <option value="Prueba">Prueba</option>
                    <option value="Enterprise">Enterprise</option>
                    <option value="Premium">Premium</option>
                    <option value="Pro">Pro</option>
                    <option value="Básico">Básico</option>
                  </Field>
                </div>

                <div className="form-group">
                  <label htmlFor="startDate">Fecha inicio <span className="text-danger">*</span></label>
                  <Field type="date" name="startDate" className="form-control" required />
                </div>

                <div className="form-group">
                  <label htmlFor="endDate">Fecha fin <span className="text-danger">*</span></label>
                  <Field type="date" name="endDate" className="form-control" required />
                </div>
              </>
            )}

            {values.orderType === "CREDITS" && (
              <div className="form-group">
                <label htmlFor="quantity">Cantidad <span className="text-danger">*</span></label>
                <Field type="number" name="quantity" className="form-control" required />
              </div>
            )}

            {/* Campo de Typeahead para seleccionar usuario */}
            <div className="form-group">
              <label htmlFor="selectedUser">Selecciona un Usuario <span className="text-danger">*</span></label>
              <Typeahead
                id="selectedUser"
                labelKey={option => `${option.firstName} ${option.lastName} (${option.email})`}
                options={
                  organization.organizationParticipants
                    ? organization.organizationParticipants.map(participant => participant.user)
                    : []
                }
                placeholder="Escribe para buscar un usuario..."
                onChange={selected => {
                  if (selected && selected.length > 0) {
                    setFieldValue('selectedUser', selected[0].id);
                  } else {
                    setFieldValue('selectedUser', "");
                  }
                }}
                selected={
                  values.selectedUser
                    ? organization.organizationParticipants
                        .map(participant => participant.user)
                        .filter(user => user.id === values.selectedUser)
                    : []
                }
              />
            </div>

            <div className="text-right" style={{ marginBottom: "-1vh" }}>
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default FormView;