/* eslint-disable max-len */
import swal from "sweetalert";
import * as indexes from "components/studio/Controller.lib.index";
import { toast } from 'react-toastify';


/*----------------------------------------------------------------*/
/* Edges                                                          */
/*----------------------------------------------------------------*/

// Function to validate edge connections
const isValidConnection = (source_id, target_id, setNodes) => {
    
    let source = null;
    let target = null;

    setNodes((nodes) => {
        source = nodes.find((node) => node.id == source_id);
        target = nodes.find((node) => node.id == target_id);
        return nodes;
    })

    if (source.id == target.id) {
        toast("Error de conexión: No puedes conectar un nodo consigo mismo", { type: "error" });
        return false;
    }

    if (target && target.data?.action?.type == "TRIGGER") {
        toast("Error de conexión: Una acción de tipo trigger no puede tener un nodo padre", { type: "error" });
        return false; 
    }

    return true;
}

// Function when node is connected to another node, see https://reactflow.dev/docs/api/types/#connection
const onConnectEdges = (source, target, sourceHandle, targetHandle, setNodes, setEdges, onSave) => {

    if (!isValidConnection(source, target, setNodes)) return;

    let mode = "NEXT";
    if (sourceHandle.includes("next")) mode = "NEXT";
    if (sourceHandle.includes("else")) mode = "ELSE";
    if (sourceHandle.includes("end")) mode = "END";

    const newEdge = {
        mode: mode,
        source: source,
        target: target,
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        onSave: onSave
    }

    setEdges((eds) => {
        const newEdges = eds.concat(newEdge)
        setNodes((nodes) => {
            onSave(nodes, newEdges);
            return nodes;
        })
        return eds;
    });

};

const onUpdateEdge = (oldEdge, connection, edges, setNodes, setEdges, onSave) => {

    if (!isValidConnection(connection.source, connection.target, setNodes)) return;

    oldEdge = edges.find((edge) => edge.id == oldEdge.id);
    if (!oldEdge) return

    const changedEdge = {
        remoteId: oldEdge.remoteId,
        mode: oldEdge?.mode ?? "NEXT",
        source: connection.source,
        target: connection.target,
        sourceHandle: connection.sourceHandle,
        targetHandle: connection.targetHandle
    }

    setEdges((eds) => {
        let newEdges = [...eds];
        const index = newEdges.findIndex((edge) => edge.id == oldEdge.id);
        if (index == -1) return eds;
        newEdges.splice(index, 1);
        newEdges.push(changedEdge);

        setNodes((nodes) => {
            onSave(nodes, newEdges);
            return nodes;
        })

        return eds;
    });

}

const setEdgeFunctions = (edge, onSave) => {
    const newEdge = { ...edge };
    newEdge.data = {};
    newEdge.data.onSave = onSave;
    return newEdge;
}

/*----------------------------------------------------------------*/
/* Nodes                                                          */
/*----------------------------------------------------------------*/

const setNodeFunctions = (node, onExecuteNode, onChangeTriggerStatus, onSelectNode, onDeleteNode, onClickInsertChild) => {
    const newNode = { ...node };
    newNode.data.onExecute = () => onExecuteNode(node.remoteId);
    newNode.data.onChangeStatusTrigger = () => onChangeTriggerStatus(node.id);
    newNode.data.onSelectNode = () => onSelectNode(node.id);
    newNode.data.onDeleteNode = onDeleteNode;
    newNode.data.onClickInsertChild = onClickInsertChild;
    return newNode;
}

// Function when node is move to board, see https://reactflow.dev/docs/api/react-flow-props/
const onDragNode = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
}

// Function when node is dropped on board, see https://reactflow.dev/docs/api/react-flow-props/
const onDropNode = (event, reactFlowWrapper, reactFlowInstance, onClickNode, onClickAnnotation) => {

    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    const type = data.type;
    const actionId = data?.actionId;

    if (typeof type === 'undefined' || !type) return;
    const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
    });

    position.x = parseInt(position.x);
    position.y = parseInt(position.y);

    if (type == "annotation") {
        onClickAnnotation(actionId, position);
    } else if (type == "node") {
        const args = {
            actionId: actionId,
            position: position
        }
        onClickNode(args);
    }

}

const onEditNode = (remoteId, values, edges, setNodes, onSaveBoard) => {
    setNodes((nds) => {
        const actualNode = nds.find((nd) => nd.remoteId == remoteId && nd.isAnnotation == false);
        const resultNodes = nds.filter((nd) => nd.remoteId != remoteId);

        const keys = Object.keys(values);
        const input = keys.map((key) => {
            return { name: key, value: values[key] };
        });

        const editedNode = {
            type: actualNode.type,
            isAnnotation: false,
            remoteId: actualNode.remoteId,
            position: actualNode.position,
            data: {
                action: actualNode.data?.action,
                input_values: input
            }
        }

        const newNodes = resultNodes.concat(editedNode);
        onSaveBoard(newNodes, edges)
        return nds;
    });
}

const onAttachNode = (edgeId, actionName, setEdges, setActualNode, setIsNodeFormShown) => {

    let position = { x: 0, y: 0 };
    let edge = null;
    setEdges((eds) => {
        edge = eds.find((edge) => edge.id == edgeId);
        return eds;
    })
    if (!edge) return;

    const source = edge.source
    const target = edge.target;

    let node = {
        type: "node",
        position: position,
        data: { category: actionName },
        isAssistant: false,
        insertBetween: { source: source, target: target }
    };
    setActualNode(node);
    setIsNodeFormShown(true);

}


/*----------------------------------------------------------------*/
/* Annotations                                                    */
/*----------------------------------------------------------------*/

const setAnnotationFunctions = (annotation, onSelectNode, onDeleteNode) => {
    const newAnnotation = { ...annotation };
    newAnnotation.data.onSelectNode = () => onSelectNode(newAnnotation.id);
    newAnnotation.data.onDeleteNode = onDeleteNode;
    return newAnnotation;
}

const onEditAnnotation = (remoteId, values, edges, setNodes, onSaveBoard) => {

    if (setNodes == null || setNodes == undefined) return;

    setNodes((nds) => {
        const actualNote = nds.find((nd) => nd.remoteId == remoteId && nd.isAnnotation == true);
        const resultNodes = nds.filter((nd) => nd.remoteId != remoteId);

        const keys = Object.keys(values);
        const input = keys.map((key) => {
            return { name: key, value: values[key] };
        });

        actualNote.data.input_values = input;

        const newNodes = resultNodes.concat(actualNote)
        onSaveBoard(newNodes, edges);
        return newNodes;
    });

}


/*----------------------------------------------------------------*/
/* General                                                        */
/*----------------------------------------------------------------*/

const getVariables = () => ({
	SCREEN_WIDTH: window.innerWidth,
	SCREEN_HEIGHT: window.innerHeight,
})

const onInitBoard = (nodes, edges, onExecuteNode, onChangeTriggerStatus, onSaveBoard, onAttachNode, onSelectNode, onDeleteNode, onClickInsertChild) => {

    const newNodes = nodes.filter((item) => item.isAnnotation == false).map((node) => {
        const newNode = setNodeFunctions(node, onExecuteNode, onChangeTriggerStatus, onSelectNode, onDeleteNode, onClickInsertChild);
        return newNode;
    });

    const newAnnotations = nodes.filter((item) => item.isAnnotation == true).map((annotation) => {
        const newAnnotation = setAnnotationFunctions(annotation, onSelectNode, onDeleteNode);
        return newAnnotation;
    })

    const newEdges = edges.map((edge) => {
        edge.data = {
            onSave: onSaveBoard,
            onAttachNode: onAttachNode
        };
        return edge;
    });

    const indexedNodes = indexes.assignIdxNodes(newNodes, newEdges);
    return [indexedNodes, newEdges, newAnnotations];

}

const onExecuteNode = (nodes, edges, remoteId, callExecute) => {

    const getNodeWithWarningErrors = (root, adjacency, nodes) => {
        let childrens = adjacency[root.id];
        for (const child of childrens) {
            const errors = child.data?.errors ?? [];
            const warnings = child.data?.warnings ?? [];
            if (errors.length > 0 || warnings.length > 0) {
                return child;
            }
            return getNodeWithWarningErrors(child, adjacency, nodes);
        }
        return null;
    }

    const adjacency = {};
    const rootNode = nodes.find((node) => node.remoteId == remoteId);
    if (!rootNode) return false;

    for (const node of nodes) {
        adjacency[node.id] = [];
    }

    for (const edge of edges) {
        const node = nodes.find((node) => node.id == edge.target);
        adjacency[edge.source].push(node)
    }

    const nodeWithErrors = getNodeWithWarningErrors(rootNode, adjacency, nodes);

    if (nodeWithErrors) {

        const action = nodeWithErrors.data?.action;
        let details = ""
        if (action) {
            const uiSettings = action.uiSettings;
            const actionTitle = uiSettings?.node?.title ?? uiSettings?.general?.title;
            details = `El nodo ${actionTitle}[${nodeWithErrors.id}] tiene errores que deben ser corregidos. `;
        }

        swal({
            title: "Su proceso cuenta con errores",
            icon: "warning",
            text: details + "<b>¿Estás seguro que ejecutar el proceso de todos modos?</b>",
            html: true,
            buttons: {
                confirm: {
                    text: "Aceptar",
                    className: "swal-button swal-button--cancel btn-success",
                },
                cancel: "Cancelar",
            },
        }).then((response) => {
            if (response) {
                callExecute({
                    root_node_id: remoteId,
                    session_id: sessionStorage.getItem("execution_session_id"),
                    user_id: sessionStorage.getItem("id"),
                    variables: getVariables()
                })
            }
        })
    } else {
        callExecute({
            root_node_id: remoteId,
            session_id: sessionStorage.getItem("execution_session_id"),
            user_id: sessionStorage.getItem("id"),
            variables: getVariables()
        })
    }

}


export { onInitBoard, onExecuteNode, setNodeFunctions, setEdgeFunctions, setAnnotationFunctions, onConnectEdges, onUpdateEdge, onDragNode, onDropNode, onEditNode, onAttachNode, onEditAnnotation };