import React from "react";
import swal from "sweetalert";
import { useDetail, useSet, useDelete } from "seed/gql";
import { useHistory } from 'react-router-dom';
import { SET_FLOW_PAGE, DELETE_FLOW_PAGE } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/studio/forms/pages/FormPage.view";

function FormSetPage({ match }) {

  const flowId = match?.params?.flowId;
  const pageId = match?.params?.pageId;

  const [callDelete] = useDelete(DELETE_FLOW_PAGE, {
    onCompleted: () => {
      window.location.replace(`/app/studio/${flowId}`)
    },
    onError: () => {
      swal("Error al eliminar la capa", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  })

  const [callSet] = useSet(SET_FLOW_PAGE, {
    onCompleted: () => {
      window.location.replace(`/app/studio/${flowId}`)
    },
    onError: () => {
      swal("Error al editar la capa", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  })

  const reqPage = useDetail(`{
    flowPage {
      name
      isDefault
    }
  }`, pageId);

  const onDelete = () => {
    callDelete({ id: parseInt(pageId) })
  }

  const onSubmit = (values) => {
    callSet({
      id: parseInt(pageId),
      name: values.name
    })
  }

  if (reqPage.loading) return <Loading />;
  if (reqPage.error) return "Error";

  const { flowPage = {} } = reqPage.data;

  return (
    <View
      page={flowPage}
      isEditing={true}
      onSubmit={onSubmit}
      onDelete={onDelete}
    />
  );
}

export default FormSetPage;