/* eslint-disable max-len */
import React from "react";
import { Formik, Form, Field } from 'formik';
import { TwitterPicker } from 'react-color';
import { StyleTag } from "seed/helpers";
import "styles/css/start/Onboarding.css";


function getDarkerColor(color, v) {

  const normalize = (x) =>
    x > 255 ? x - (x - 255) : x;

  const getHex = (x) => {
    if (x < 0 || isNaN(x)) x = 0;
    if (x > 255) x = 255;
    x = x.toString(16);
    return x.length === 1 ? "0" + x : x;
  }

  if (color.length > 6)
    color = color.substring(1, color.length)

  var rgb = parseInt(color, 16);

  var r = Math.abs(((rgb >> 16) & 0xFF) + v);
  r = normalize(r);
  r = getHex(r);

  var g = Math.abs(((rgb >> 8) & 0xFF) + v);
  g = normalize(g);
  g = getHex(g);

  var b = Math.abs((rgb & 0xFF) + v);
  b = normalize(b);
  b = getHex(b);

  return "#" + r + g + b;

}


const OnboardingView = ({ step, profiles, onClickNextStep, onClickBackStep, onSubmit }) => (
  <div className="card">
    <div id="onboarding" className="card-body">

      <h1 className="text-center my-3" style={{ fontSize: "2.5rem" }}>
        !Bienvenido a Workix!
      </h1>

      <h3 className="text-center">
        Renueva tu operación integrando inteligencia artificial en tus procesos de trabajo
      </h3>

      <div className="my-5">
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>

              {step == 1 && (
                <div>
                  <p className="text-center" style={{ fontSize: "20px" }}>Comienza personalizado los colores</p>
                  <div className="card p-0" style={{ borderRadius: "0" }}>
                    <div className="card-body p-0">

                      <div className="row">
                        <div className="col-md-8 p-0">
                          <div className="bg-img-hero"
                            style={{
                              pointerEvents: "none",
                              height: "15rem"
                            }}>

                            <figure style={{ height: "100%" }}>
                              <svg preserveAspectRatio="none" viewBox="0 0 1920 698" fill="none" xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                }}>
                                <rect width="1920" height="698" fill="#fff" />
                                <g clipPath="url(#clip0)">
                                  <path className="path-a" d="M1920 -714.403H0V1412.4H1920V-714.403Z" fill={values?.primaryColor ? getDarkerColor(values.primaryColor, -30) : getDarkerColor("#2080e5", -30)} />
                                  <path className="path-b" d="M1298.9 -5488.72L2618.5 1300.02L1702.8 1842.02C1651.6 1690.56 1569.6 1565.26 1479.9 1480.46C1338.1 1346.44 1176.9 1292.52 1020.9 1221.67C933 1182.06 832 1111.56 811.9 957.132C799 858.54 824.8 759.075 837.2 660.308C869.8 400.128 798.7 110.284 668.3 -28.0949C635.9 -62.4714 599.1 -90.391 577.4 -144.661C510.4 -312.18 639.3 -526.467 627 -729.759C622.6 -803.224 599.6 -869.01 574.6 -928.689C300.4 -1585.86 -209.4 -1749.54 -628.8 -2103.6C-751.2 -2207.08 -871.1 -2334.11 -955 -2520.83C-1038.9 -2707.72 -1081.4 -2964.58 -1036.1 -3188.29C-974.1 -3494.19 -776.5 -3644.26 -593.7 -3703.76C-410.9 -3763.27 -215.6 -3771.47 -56 -3937.77C117.6 -4118.72 216 -4451.32 351 -4713.77C418.6 -4845.17 495.6 -4958.94 577.8 -5061.9L1298.9 -5488.72Z" fill={values?.primaryColor ?? "#2080e5"} />
                                  <path className="path-c" d="M1733.2 -6299.97L3053 487.553L2137.2 1029.55C2086 878.084 2004 752.968 1914.3 668.161C1772.5 534.145 1611.3 480.224 1455.2 409.552C1367.3 369.766 1266.3 299.443 1246.2 145.01C1233.4 46.4176 1259.2 -53.0476 1271.5 -151.815C1304.1 -411.994 1233 -701.664 1102.6 -840.217C1070.2 -874.594 1033.4 -902.514 1011.7 -956.783C944.7 -1124.3 1073.6 -1338.59 1061.3 -1541.71C1056.9 -1615.17 1033.8 -1680.96 1008.9 -1740.64C734.8 -2397.63 224.9 -2561.31 -194.5 -2915.37C-316.9 -3018.85 -436.8 -3145.71 -520.7 -3332.6C-604.6 -3519.32 -647.1 -3776.18 -601.8 -3999.89C-539.8 -4305.61 -342.2 -4455.86 -159.4 -4515.36C23.3999 -4574.87 218.7 -4583.07 378.3 -4749.37C551.8 -4930.15 650.2 -5262.75 785.2 -5525.02C852.8 -5656.42 929.8 -5770.19 1012 -5873.15L1733.2 -6299.97Z" fill={values?.accentColor ?? "#f0b000"} />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="1920" height="698" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </figure>
                          </div>
                        </div>

                        <div className="col-md-4 p-0">
                          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                            <div>

                              {console.log("values", values)}
                              <ColorPicker
                                label="Color principal"
                                name="primaryColor"
                                values={values}
                                defaultColor={"#2065a5"}
                                setFieldValue={setFieldValue}
                              />

                              <ColorPicker
                                label="Color de acento"
                                name="accentColor"
                                values={values}
                                defaultColor={"#f0b000"}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex w-100 mt-3 justify-content-end">
                    <button className="btn btn-primary" onClick={onClickNextStep}>
                      <i class="fas fa-chevron-right mr-2"></i>Siguiente
                    </button>
                  </div>

                </div>
              )}

              {step == 2 && (
                <div>

                  <p className="text-center" style={{ fontSize: "20px" }}>Ahora cuentanos sobre lo que haces</p>
                  <div className="row justify-content-center">
                    {profiles.map((profile, idx) => (
                      <div className="col-md-3 text-center mb-3 px-2" key={idx}>
                        <div class={`text-center profile ${values?.profile == profile.id ? "active" : ""}`}
                          style={{
                            backgroundColor: values?.profile == profile.id ? "white" : profile?.color,
                            color: values?.profile == profile.id ? profile?.color : "white",
                            fontWeight: values?.profile == profile.id ? "bold" : "normal",
                            border: values?.profile == profile.id ? `2px solid ${profile?.color}` : "2px solid white"
                          }}
                        >
                          <div class="custom-checkbox-card mb-3">
                            <input
                              type="radio"
                              class="custom-checkbox-card-input"
                              name="profile"
                              id={`profile-${profile?.id}`}
                              value={profile?.id}
                              onChange={(event) => setFieldValue("profile", event.target.value)}
                            />

                            <label class="custom-checkbox-card-label py-2" for={`profile-${profile?.id}`}>
                              <h1 className="card-title mb-2">
                                <i
                                  className={`card-icon ${profile?.icon}`}
                                  style={{
                                    color: values?.profile == profile.id ? profile?.color : "white"
                                  }}
                                />
                              </h1>
                              <span class="custom-checkbox-card-text">{profile?.name}</span>
                            </label>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex w-100 mt-3 justify-content-end">
                    <button className="btn btn-primary mr-3" onClick={onClickBackStep}>
                      <i class="fas fa-chevron-left mr-2"></i>Atrás
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <i class="fas fa-chevron-right mr-2"></i>Finalizar
                    </button>
                  </div>

                </div>
              )}


            </Form>
          )}
        </Formik>
      </div>

    </div>
  </div>
);


const ColorPicker = ({
  label,
  name,
  values,
  defaultColor,
  setFieldValue,
  onChange = () => { }
}) => {
  const colors = [
    "#FF5252", "#FF4081", "#7B1FA2", "#512DA8", "#536DFE", "#2065a5", "#3075BA", "#1976D2", "#03A9F4", "#0097A7", "#00796B", "#4CAF50", "#AFB42B",
    "#F57C00", "#FFA000", "#FF5722", "#5D4037", "#9E9E9E", "#455A64", "#212121", "default"
  ];
  return <div className="d-flex align-items-center w-100 mb-3">
    <StyleTag content={`
      .twitter-picker input[id^=rc-editable-input] {
        display: none;
      }
      .twitter-picker div[style*="rgb(240, 240, 240)"] {
        display: none !important;
      }
    `} />
    <div className="mb-1 mr-2" style={{ width: "100px" }}>
      {label}
    </div>
    <div style={{ position: "relative" }}>
      <div className="color-picker-preview" onClick={() => setFieldValue(`show_${name}`, !values[`show_${name}`])}>
        <div className="color" style={{ backgroundColor: values[name] ?? defaultColor }}></div>
      </div>
      <div style={{ position: "absolute", right: "110%", top: "-13px", zIndex: 1000 }}>
        {
          values[`show_${name}`] &&
          <TwitterPicker
            color={values[name] ?? defaultColor}
            width="240px"
            triangle="hide"
            onChange={(color) => {
              setFieldValue(name, color.hex);
              setFieldValue(`show_${name}`, false);
              onChange(color.hex);
            }}
            colors={colors}
          />
        }
      </div>
    </div>
  </div>
}



export default OnboardingView;