import React from "react";
import { useDetail, useDelete } from "seed/gql";
import { DELETE_ORDER } from "seed/gql/queries";
import swal from "sweetalert";
import View from "components/tmisc/organizations/orders/Orders.view";

const Orders = ({ selectedOrganization }) => {

    const formatDate = (date) => {
        if (!date) return "-";
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${d.getFullYear()}`;
    };

    const [callDelete] = useDelete(DELETE_ORDER, {
        onCompleted: () => {
            swal({ text: "Orden eliminada con éxito.", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/orders";
            });
        },
        onError: () => {
            swal({
                text: "No se ha podido eliminar el usuario",
                icon: "error"
            });
        }
    });

    const qOrganization = useDetail(`
    {
        organization {
            organizationParticipants{
                user{
                    firstName
                    lastName
                    orders{
                        startDate
                        endDate
                        paymentDate
                        startDate
                        quantity
                        type
                        license{
                            name
                        }
                    }
                }
            }
        }
    }`, selectedOrganization);

    if (selectedOrganization === 0) {
        return (
            <div className="card col-md-12" style={{ minHeight: "75vh" }}>
                <div className="card-body text-center mt-5">
                    <h4>No tienes organizaciones asociadas</h4>
                </div>
            </div>
        );
    }

    const { organization = [] } = qOrganization.data;

    const deleteOrder = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado, no podrás recuperar esta orden",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                callDelete({ id: id });
            }
        });
    };


    return <View organization={organization} deleteOrder={deleteOrder} formatDate={formatDate}/>;
}

export default Orders;