import React, { useImperativeHandle } from "react";
import { useQuery } from "seed/gql";
import View from "components/studio/toolbox/Toolbox.view";


function Toolbox({
  toolboxRef,
  activeSection,
  flowId,
  pageId,
  nodes,
  currentNodeContext,
  isCollapsed,
  isAnonymous,
  isValidator,
  setActiveSection,
  onClickNode,
  onOpenToolbox = () => { },
  onCloseToolbox = () => { },
  reloadFlow = () => { },
}) {

  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

  const queryApps = `{
    apps {
      name
      uiSettings
      setupSettings
      desktopOnly
      actions {
        name
        type
        inputStructure
        outputStructure
        uiSettings
        relevance
        isHidden
        underDevelopment
        developerOnly
      }
    }
  }`

  const reqApps = useQuery(queryApps,
    `appSettingses.enabled = true AND appSettingses.flow.id = ${flowId} ${!isDeveloper ? " AND developer_only=false AND under_development=false" : ""}`,
    { orderBy: "relevance" });

  const reqAllApps = useQuery(queryApps,
    `${!isDeveloper ? "developer_only=false AND under_development=false" : ""}`, { orderBy: "relevance" });

  const onClickSidenavItem = (name) => {
    if (name != activeSection || isCollapsed) {
      onOpenToolbox()
      setActiveSection(name)
    } else onCloseToolbox()
  }

  const onClickCollapseButton = () => {
    if (isCollapsed) onOpenToolbox()
    else onCloseToolbox()
  }

  useImperativeHandle(toolboxRef, () => ({
    onClickSidenavItem
  }));

  return (
    <View
      toolboxRef={toolboxRef}
      flowId={flowId}
      pageId={pageId}
      nodes={nodes}
      reqApps={reqApps}
      reqAllApps={reqAllApps}
      currentNodeContext={currentNodeContext}
      activeSection={activeSection}
      isCollapsed={isCollapsed}
      isAnonymous={isAnonymous}
      isValidator={isValidator}
      onClickNode={onClickNode}
      onClickCollapseButton={onClickCollapseButton}
      onClickSidenavItem={onClickSidenavItem}
      reloadFlow={reloadFlow}
    />
  );

}

export default Toolbox;