import React, { useState } from "react";
import swal from "sweetalert";
import { useSet, useDetail } from "seed/gql";
import { USER, SET_USER } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/start/onboarding/Onboarding.view";


function Onboarding() {

  const userId = sessionStorage.getItem('id');
  const [step, setStep] = useState(1);
  const profiles = [
    {
      id: "rrhh", name: "RRHH", icon: "fas fa-users", color: "#5b9bd5"
    },
    {
      id: "purchasing", name: "Compras", icon: "fas fa-file-invoice", color: "#92d050"
    },
    {
      id: "sales", name: "Ventas", icon: "fas fa-handshake", color: "#ffc000"
    },
    {
      id: "legal", name: "Legal", icon: "fas fa-balance-scale", color: "#bd92de"
    },
    {
      id: "ti", name: "TI", icon: "fas fa-laptop-code", color: "#b30000"
    },
    {
      id: "other", name: "Otro", icon: "fas fa-plus-circle", color: "#708090"
    }
  ];

  const reqUser = useDetail(USER, userId);

  const [callSet, setCallSet] = useSet(SET_USER, {
    onCompleted: () => {
      window.location.reload();
    },
    onError: () => {
      alert("Ha ocurrido un error");
    }
  });

  const onClickNextStep = () => {
    setStep(step + 1);
  }

  const onClickBackStep = () => {
    setStep(step - 1);
  }

  const onSubmit = (values) => {

    if (values?.profile == null || values?.profile == undefined) {
      setStep(2);
      swal("¡Error!", "Por favor selecciona un perfil", "error");
      return;
    }

    callSet({
      id: userId,
      accountSettings: {
        ...user.accountSettings,
        theme: {
          primaryColor: values?.primaryColor,
          accentColor: values?.accentColor
        },
        is_onboarding_finished: true,
        industry: values?.profile,
      }
    });
  }

  if (reqUser.loading) return <Loading />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;

  return (
    <View
      step={step}
      profiles={profiles}
      onClickNextStep={onClickNextStep}
      onClickBackStep={onClickBackStep}
      onSubmit={onSubmit}
    />
  );
}

Onboarding.propTypes = {};

export default Onboarding;