/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const ACTION = `
{
  action {
    id
    name
    description
    type
    isHidden
    isResumable
    isAnnotation
    isEditable
    isPremium
    developerOnly
    desktopOnly
    underDevelopment
    asyncExecution
    awaitingAlert
    filterCall
    relevance
    uiSettings
    inputStructure
    outputStructure
    baseUsageLimits
    src
    suggestions {
      id
    }
    app {
      id
    }
  }
}
`;

export const SET_ACTION = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $type: String,
  $isHidden: Boolean,
  $isResumable: Boolean,
  $isAnnotation: Boolean,
  $isEditable: Boolean,
  $isPremium: Boolean,
  $developerOnly: Boolean,
  $desktopOnly: Boolean,
  $underDevelopment: Boolean,
  $asyncExecution: Boolean,
  $awaitingAlert: Boolean,
  $filterCall: String,
  $relevance: Float,
  $uiSettings: GenericScalar,
  $inputStructure: GenericScalar,
  $outputStructure: GenericScalar,
  $baseUsageLimits: GenericScalar,
  $src: GenericScalar,
  $suggestions: [Int],
  $app: Int,
)
{
  setAction(
    id: $id,
    name: $name,
    description: $description,
    type: $type,
    isHidden: $isHidden,
    isResumable: $isResumable,
    isAnnotation: $isAnnotation,
    isEditable: $isEditable,
    isPremium: $isPremium,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    underDevelopment: $underDevelopment,
    asyncExecution: $asyncExecution,
    awaitingAlert: $awaitingAlert,
    filterCall: $filterCall,
    relevance: $relevance,
    uiSettings: $uiSettings,
    inputStructure: $inputStructure,
    outputStructure: $outputStructure,
    baseUsageLimits: $baseUsageLimits,
    src: $src,
    suggestions: $suggestions,
    app: $app,
  ) {
    action {
      id
      name
      description
      type
      isHidden
      isResumable
      isAnnotation
      isEditable
      isPremium
      developerOnly
      desktopOnly
      underDevelopment
      asyncExecution
      awaitingAlert
      filterCall
      relevance
      uiSettings
      inputStructure
      outputStructure
      baseUsageLimits
      src
      suggestions {
        id
      }
      app {
        id
      }
    }
  }
}
`;

export const SAVE_ACTION = `
mutation Save(
  $name: String!,
  $description: String!,
  $type: String!,
  $isHidden: Boolean!,
  $isResumable: Boolean!,
  $isAnnotation: Boolean!,
  $isEditable: Boolean!,
  $isPremium: Boolean!,
  $developerOnly: Boolean!,
  $desktopOnly: Boolean!,
  $underDevelopment: Boolean!,
  $asyncExecution: Boolean!,
  $awaitingAlert: Boolean!,
  $filterCall: String!,
  $relevance: Float!,
  $uiSettings: GenericScalar!,
  $inputStructure: GenericScalar!,
  $outputStructure: GenericScalar!,
  $baseUsageLimits: GenericScalar!,
  $src: GenericScalar!,
  $suggestions: [Int],
  $app: Int!,
)
{
  saveAction(
    name: $name,
    description: $description,
    type: $type,
    isHidden: $isHidden,
    isResumable: $isResumable,
    isAnnotation: $isAnnotation,
    isEditable: $isEditable,
    isPremium: $isPremium,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    underDevelopment: $underDevelopment,
    asyncExecution: $asyncExecution,
    awaitingAlert: $awaitingAlert,
    filterCall: $filterCall,
    relevance: $relevance,
    uiSettings: $uiSettings,
    inputStructure: $inputStructure,
    outputStructure: $outputStructure,
    baseUsageLimits: $baseUsageLimits,
    src: $src,
    suggestions: $suggestions,
    app: $app,
  ) {
    action {
      id
    }
  }
}
`;

export const DELETE_ACTION = `
mutation Delete($id: Int!)
{
  deleteAction(id: $id)
  {
    id
  }
}
`;

export const APP = `
{
  app {
    id
    name
    description
    version
    developerOnly
    desktopOnly
    requestOnly
    underDevelopment
    relevance
    uiSettings
    setupSettings
    datatypesSettings
    src
    basePath
    codebase {
      id
      url
    }
  }
}
`;

export const SET_APP = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $version: String,
  $developerOnly: Boolean,
  $desktopOnly: Boolean,
  $requestOnly: Boolean,
  $underDevelopment: Boolean,
  $relevance: Float,
  $uiSettings: GenericScalar,
  $setupSettings: GenericScalar,
  $datatypesSettings: GenericScalar,
  $src: GenericScalar,
  $basePath: String,
  $codebase: Int,
)
{
  setApp(
    id: $id,
    name: $name,
    description: $description,
    version: $version,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    requestOnly: $requestOnly,
    underDevelopment: $underDevelopment,
    relevance: $relevance,
    uiSettings: $uiSettings,
    setupSettings: $setupSettings,
    datatypesSettings: $datatypesSettings,
    src: $src,
    basePath: $basePath,
    codebase: $codebase,
  ) {
    app {
      id
      name
      description
      version
      developerOnly
      desktopOnly
      requestOnly
      underDevelopment
      relevance
      uiSettings
      setupSettings
      datatypesSettings
      src
      basePath
      codebase {
        id
      }
    }
  }
}
`;

export const SAVE_APP = `
mutation Save(
  $name: String!,
  $description: String!,
  $version: String!,
  $developerOnly: Boolean!,
  $desktopOnly: Boolean!,
  $requestOnly: Boolean!,
  $underDevelopment: Boolean!,
  $relevance: Float!,
  $uiSettings: GenericScalar!,
  $setupSettings: GenericScalar!,
  $datatypesSettings: GenericScalar!,
  $src: GenericScalar!,
  $basePath: String!,
  $codebase: Int,
)
{
  saveApp(
    name: $name,
    description: $description,
    version: $version,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    requestOnly: $requestOnly,
    underDevelopment: $underDevelopment,
    relevance: $relevance,
    uiSettings: $uiSettings,
    setupSettings: $setupSettings,
    datatypesSettings: $datatypesSettings,
    src: $src,
    basePath: $basePath,
    codebase: $codebase,
  ) {
    app {
      id
    }
  }
}
`;

export const DELETE_APP = `
mutation Delete($id: Int!)
{
  deleteApp(id: $id)
  {
    id
  }
}
`;

export const APP_DATATYPE = `
{
  appDatatype {
    id
    name
    structure
    app {
      id
    }
  }
}
`;

export const SET_APP_DATATYPE = `
mutation Set(
  $id: Int!,
  $name: String,
  $structure: GenericScalar,
  $app: Int,
)
{
  setAppDatatype(
    id: $id,
    name: $name,
    structure: $structure,
    app: $app,
  ) {
    appDatatype {
      id
      name
      structure
      app {
        id
      }
    }
  }
}
`;

export const SAVE_APP_DATATYPE = `
mutation Save(
  $name: String!,
  $structure: GenericScalar!,
  $app: Int!,
)
{
  saveAppDatatype(
    name: $name,
    structure: $structure,
    app: $app,
  ) {
    appDatatype {
      id
    }
  }
}
`;

export const DELETE_APP_DATATYPE = `
mutation Delete($id: Int!)
{
  deleteAppDatatype(id: $id)
  {
    id
  }
}
`;

export const APP_SETTINGS = `
{
  appSettings {
    id
    settings
    enabled
    requested
    app {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_APP_SETTINGS = `
mutation Set(
  $id: Int!,
  $settings: GenericScalar,
  $enabled: Boolean,
  $requested: Boolean,
  $app: Int,
  $flow: Int,
)
{
  setAppSettings(
    id: $id,
    settings: $settings,
    enabled: $enabled,
    requested: $requested,
    app: $app,
    flow: $flow,
  ) {
    appSettings {
      id
      settings
      enabled
      requested
      app {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_APP_SETTINGS = `
mutation Save(
  $settings: GenericScalar!,
  $enabled: Boolean!,
  $requested: Boolean!,
  $app: Int!,
  $flow: Int,
)
{
  saveAppSettings(
    settings: $settings,
    enabled: $enabled,
    requested: $requested,
    app: $app,
    flow: $flow,
  ) {
    appSettings {
      id
    }
  }
}
`;

export const DELETE_APP_SETTINGS = `
mutation Delete($id: Int!)
{
  deleteAppSettings(id: $id)
  {
    id
  }
}
`;

export const CHAT = `
{
  chat {
    id
    settings
    channel
    channelSettings
    user {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_CHAT = `
mutation Set(
  $id: Int!,
  $settings: GenericScalar,
  $channel: String,
  $channelSettings: GenericScalar,
  $user: Int,
  $flow: Int,
)
{
  setChat(
    id: $id,
    settings: $settings,
    user: $user,
    flow: $flow,
    channel: $channel,
    channelSettings: $channelSettings,
  ) {
    chat {
      id
      settings
      channel
      channelSettings
      user {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_CHAT = `
mutation Save(
  $settings: GenericScalar,
  $channel: String!,
  $channelSettings: GenericScalar!,
  $user: Int,
  $flow: Int,
)
{
  saveChat(
    settings: $settings,
    user: $user,
    flow: $flow,
    channel: $channel,
    channelSettings: $channelSettings,
  ) {
    chat {
      id
    }
  }
}
`;

export const DELETE_CHAT = `
mutation Delete($id: Int!)
{
  deleteChat(id: $id)
  {
    id
  }
}
`;

export const CHAT_MESSAGE = `
{
  chatMessage {
    id
    type
    content
    action
    settings
    isTemplate
    templateName
    responseRequired
    chat {
      id
    }
    templateApplied {
      id
    }
  }
}
`;

export const SET_CHAT_MESSAGE = `
mutation Set(
  $id: Int!,
  $type: String,
  $content: GenericScalar,
  $action: GenericScalar,
  $settings: GenericScalar,
  $isTemplate: Boolean,
  $templateName: String,
  $responseRequired: Boolean,
  $chat: Int,
  $templateApplied: [Int],
)
{
  setChatMessage(
    id: $id,
    type: $type,
    content: $content,
    action: $action,
    settings: $settings,
    chat: $chat,
    isTemplate: $isTemplate,
    templateName: $templateName,
    templateApplied: $templateApplied,
    responseRequired: $responseRequired,
  ) {
    chatMessage {
      id
      type
      content
      action
      settings
      isTemplate
      templateName
      responseRequired
      chat {
        id
      }
      templateApplied {
        id
      }
    }
  }
}
`;

export const SAVE_CHAT_MESSAGE = `
mutation Save(
  $type: String!,
  $content: GenericScalar,
  $action: GenericScalar,
  $settings: GenericScalar,
  $isTemplate: Boolean!,
  $templateName: String!,
  $responseRequired: Boolean!,
  $chat: Int,
  $templateApplied: [Int],
)
{
  saveChatMessage(
    type: $type,
    content: $content,
    action: $action,
    settings: $settings,
    chat: $chat,
    isTemplate: $isTemplate,
    templateName: $templateName,
    templateApplied: $templateApplied,
    responseRequired: $responseRequired,
  ) {
    chatMessage {
      id
    }
  }
}
`;

export const DELETE_CHAT_MESSAGE = `
mutation Delete($id: Int!)
{
  deleteChatMessage(id: $id)
  {
    id
  }
}
`;

export const CHAT_SUGGESTION = `
{
  chatSuggestion {
    id
    action
    content
    settings
    message {
      id
    }
  }
}
`;

export const SET_CHAT_SUGGESTION = `
mutation Set(
  $id: Int!,
  $action: GenericScalar,
  $content: GenericScalar,
  $settings: GenericScalar,
  $message: Int,
)
{
  setChatSuggestion(
    id: $id,
    action: $action,
    content: $content,
    settings: $settings,
    message: $message,
  ) {
    chatSuggestion {
      id
      action
      content
      settings
      message {
        id
      }
    }
  }
}
`;

export const SAVE_CHAT_SUGGESTION = `
mutation Save(
  $action: GenericScalar,
  $content: GenericScalar,
  $settings: GenericScalar,
  $message: Int!,
)
{
  saveChatSuggestion(
    action: $action,
    content: $content,
    settings: $settings,
    message: $message,
  ) {
    chatSuggestion {
      id
    }
  }
}
`;

export const DELETE_CHAT_SUGGESTION = `
mutation Delete($id: Int!)
{
  deleteChatSuggestion(id: $id)
  {
    id
  }
}
`;

export const CHAT_TEMPLATE_RULE = `
{
  chatTemplateRule {
    id
    condition
    nextMessage {
      id
    }
    parent {
      id
    }
  }
}
`;

export const SET_CHAT_TEMPLATE_RULE = `
mutation Set(
  $id: Int!,
  $condition: GenericScalar,
  $nextMessage: Int,
  $parent: Int,
)
{
  setChatTemplateRule(
    id: $id,
    condition: $condition,
    nextMessage: $nextMessage,
    parent: $parent,
  ) {
    chatTemplateRule {
      id
      condition
      nextMessage {
        id
      }
      parent {
        id
      }
    }
  }
}
`;

export const SAVE_CHAT_TEMPLATE_RULE = `
mutation Save(
  $condition: GenericScalar!,
  $nextMessage: Int!,
  $parent: Int,
)
{
  saveChatTemplateRule(
    condition: $condition,
    nextMessage: $nextMessage,
    parent: $parent,
  ) {
    chatTemplateRule {
      id
    }
  }
}
`;

export const DELETE_CHAT_TEMPLATE_RULE = `
mutation Delete($id: Int!)
{
  deleteChatTemplateRule(id: $id)
  {
    id
  }
}
`;

export const COLLECTION = `
{
  collection {
    id
    name
    type
    initials
    schema
    folios
    user {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_COLLECTION = `
mutation Set(
  $id: Int!,
  $name: String,
  $type: String,
  $initials: String,
  $schema: GenericScalar,
  $folios: GenericScalar,
  $user: Int,
  $flow: Int,
)
{
  setCollection(
    id: $id,
    name: $name,
    type: $type,
    initials: $initials,
    schema: $schema,
    folios: $folios,
    user: $user,
    flow: $flow,
  ) {
    collection {
      id
      name
      type
      initials
      schema
      folios
      user {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_COLLECTION = `
mutation Save(
  $name: String!,
  $type: String!,
  $initials: String!,
  $schema: GenericScalar!,
  $folios: GenericScalar!,
  $user: Int,
  $flow: Int!,
)
{
  saveCollection(
    name: $name,
    type: $type,
    initials: $initials,
    schema: $schema,
    folios: $folios,
    user: $user,
    flow: $flow,
  ) {
    collection {
      id
    }
  }
}
`;

export const DELETE_COLLECTION = `
mutation Delete($id: Int!)
{
  deleteCollection(id: $id)
  {
    id
  }
}
`;

export const COLLECTION_DATA = `
{
  collectionData {
    id
    data
    settings
    collection {
      id
    }
  }
}
`;

export const SET_COLLECTION_DATA = `
mutation Set(
  $id: Int!,
  $data: GenericScalar,
  $settings: GenericScalar,
  $collection: Int,
)
{
  setCollectionData(
    id: $id,
    data: $data,
    settings: $settings,
    collection: $collection,
  ) {
    collectionData {
      id
      data
      settings
      collection {
        id
      }
    }
  }
}
`;

export const SAVE_COLLECTION_DATA = `
mutation Save(
  $data: GenericScalar!,
  $settings: GenericScalar!,
  $collection: Int!,
)
{
  saveCollectionData(
    data: $data,
    settings: $settings,
    collection: $collection,
  ) {
    collectionData {
      id
    }
  }
}
`;

export const DELETE_COLLECTION_DATA = `
mutation Delete($id: Int!)
{
  deleteCollectionData(id: $id)
  {
    id
  }
}
`;

export const CREDIT_USAGE = `
{
  creditUsage {
    id
    quantity
    user {
      id
    }
    executionStep {
      id
    }
  }
}
`;

export const SET_CREDIT_USAGE = `
mutation Set(
  $id: Int!,
  $quantity: Int,
  $user: Int,
  $executionStep: Int,
)
{
  setCreditUsage(
    id: $id,
    quantity: $quantity,
    user: $user,
    executionStep: $executionStep,
  ) {
    creditUsage {
      id
      quantity
      user {
        id
      }
      executionStep {
        id
      }
    }
  }
}
`;

export const SAVE_CREDIT_USAGE = `
mutation Save(
  $quantity: Int!,
  $user: Int!,
  $executionStep: Int!,
)
{
  saveCreditUsage(
    quantity: $quantity,
    user: $user,
    executionStep: $executionStep,
  ) {
    creditUsage {
      id
    }
  }
}
`;

export const DELETE_CREDIT_USAGE = `
mutation Delete($id: Int!)
{
  deleteCreditUsage(id: $id)
  {
    id
  }
}
`;

export const EXECUTION = `
{
  execution {
    id
    status
    startDate
    endDate
    isRead
    exception
    settings
    flow {
      id
    }
    user {
      id
    }
    subExecutions {
      id
    }
    chatMessage {
      id
    }
  }
}
`;

export const SET_EXECUTION = `
mutation Set(
  $id: Int!,
  $status: String,
  $startDate: DateTime,
  $endDate: DateTime,
  $isRead: Boolean,
  $exception: GenericScalar,
  $settings: GenericScalar,
  $flow: Int,
  $user: Int,
  $subExecutions: [Int],
  $chatMessage: Int,
)
{
  setExecution(
    id: $id,
    status: $status,
    startDate: $startDate,
    endDate: $endDate,
    isRead: $isRead,
    exception: $exception,
    settings: $settings,
    flow: $flow,
    user: $user,
    subExecutions: $subExecutions,
    chatMessage: $chatMessage,
  ) {
    execution {
      id
      status
      startDate
      endDate
      isRead
      exception
      settings
      flow {
        id
      }
      user {
        id
      }
      subExecutions {
        id
      }
      chatMessage {
        id
      }
    }
  }
}
`;

export const SAVE_EXECUTION = `
mutation Save(
  $status: String!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $isRead: Boolean!,
  $exception: GenericScalar,
  $settings: GenericScalar!,
  $flow: Int,
  $user: Int,
  $subExecutions: [Int],
  $chatMessage: Int,
)
{
  saveExecution(
    status: $status,
    startDate: $startDate,
    endDate: $endDate,
    isRead: $isRead,
    exception: $exception,
    settings: $settings,
    flow: $flow,
    user: $user,
    subExecutions: $subExecutions,
    chatMessage: $chatMessage,
  ) {
    execution {
      id
    }
  }
}
`;

export const DELETE_EXECUTION = `
mutation Delete($id: Int!)
{
  deleteExecution(id: $id)
  {
    id
  }
}
`;

export const EXECUTION_STEP = `
{
  executionStep {
    id
    status
    step
    time
    startDate
    endDate
    snapshot
    result
    flowNode {
      id
    }
    execution {
      id
    }
  }
}
`;

export const SET_EXECUTION_STEP = `
mutation Set(
  $id: Int!,
  $status: String,
  $step: Int,
  $time: Float,
  $startDate: DateTime,
  $endDate: DateTime,
  $snapshot: GenericScalar,
  $result: GenericScalar,
  $flowNode: Int,
  $execution: Int,
)
{
  setExecutionStep(
    id: $id,
    status: $status,
    step: $step,
    time: $time,
    startDate: $startDate,
    endDate: $endDate,
    snapshot: $snapshot,
    result: $result,
    flowNode: $flowNode,
    execution: $execution,
  ) {
    executionStep {
      id
      status
      step
      time
      startDate
      endDate
      snapshot
      result
      flowNode {
        id
      }
      execution {
        id
      }
    }
  }
}
`;

export const SAVE_EXECUTION_STEP = `
mutation Save(
  $status: String!,
  $step: Int!,
  $time: Float!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $snapshot: GenericScalar!,
  $result: GenericScalar!,
  $flowNode: Int!,
  $execution: Int!,
)
{
  saveExecutionStep(
    status: $status,
    step: $step,
    time: $time,
    startDate: $startDate,
    endDate: $endDate,
    snapshot: $snapshot,
    result: $result,
    flowNode: $flowNode,
    execution: $execution,
  ) {
    executionStep {
      id
    }
  }
}
`;

export const DELETE_EXECUTION_STEP = `
mutation Delete($id: Int!)
{
  deleteExecutionStep(id: $id)
  {
    id
  }
}
`;

export const EXECUTION_USAGE = `
{
  executionUsage {
    id
    order {
      id
    }
    execution {
      id
    }
  }
}
`;

export const SET_EXECUTION_USAGE = `
mutation Set(
  $id: Int!,
  $order: Int,
  $execution: Int,
)
{
  setExecutionUsage(
    id: $id,
    order: $order,
    execution: $execution,
  ) {
    executionUsage {
      id
      order {
        id
      }
      execution {
        id
      }
    }
  }
}
`;

export const SAVE_EXECUTION_USAGE = `
mutation Save(
  $order: Int!,
  $execution: Int!,
)
{
  saveExecutionUsage(
    order: $order,
    execution: $execution,
  ) {
    executionUsage {
      id
    }
  }
}
`;

export const DELETE_EXECUTION_USAGE = `
mutation Delete($id: Int!)
{
  deleteExecutionUsage(id: $id)
  {
    id
  }
}
`;

export const FLOW = `
{
  flow {
    id
    name
    description
    token
    isTemplate
    isTemp
    templateName
    storageUsage
    settings
    presentationSettings
    templateSettings
    chatSettings
    templateType
    user {
      id
    }
    folder {
      id
    }
    templateCategories {
      id
    }
  }
}
`;

export const SET_FLOW = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $token: String,
  $isTemplate: Boolean,
  $isTemp: Boolean,
  $templateName: String,
  $storageUsage: Int,
  $settings: GenericScalar,
  $presentationSettings: GenericScalar,
  $templateSettings: GenericScalar,
  $chatSettings: GenericScalar,
  $templateType: String,
  $user: Int,
  $folder: Int,
  $templateCategories: [Int],
)
{
  setFlow(
    id: $id,
    name: $name,
    description: $description,
    token: $token,
    isTemplate: $isTemplate,
    isTemp: $isTemp,
    templateName: $templateName,
    storageUsage: $storageUsage,
    settings: $settings,
    presentationSettings: $presentationSettings,
    templateSettings: $templateSettings,
    chatSettings: $chatSettings,
    user: $user,
    folder: $folder,
    templateCategories: $templateCategories,
    templateType: $templateType,
  ) {
    flow {
      id
      name
      description
      token
      isTemplate
      isTemp
      templateName
      storageUsage
      settings
      presentationSettings
      templateSettings
      chatSettings
      templateType
      user {
        id
      }
      folder {
        id
      }
      templateCategories {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW = `
mutation Save(
  $name: String!,
  $description: String,
  $token: String,
  $isTemplate: Boolean!,
  $isTemp: Boolean!,
  $templateName: String,
  $storageUsage: Int!,
  $settings: GenericScalar!,
  $presentationSettings: GenericScalar,
  $templateSettings: GenericScalar,
  $chatSettings: GenericScalar,
  $templateType: String,
  $user: Int,
  $folder: Int,
  $templateCategories: [Int],
)
{
  saveFlow(
    name: $name,
    description: $description,
    token: $token,
    isTemplate: $isTemplate,
    isTemp: $isTemp,
    templateName: $templateName,
    storageUsage: $storageUsage,
    settings: $settings,
    presentationSettings: $presentationSettings,
    templateSettings: $templateSettings,
    chatSettings: $chatSettings,
    user: $user,
    folder: $folder,
    templateCategories: $templateCategories,
    templateType: $templateType,
  ) {
    flow {
      id
    }
  }
}
`;

export const DELETE_FLOW = `
mutation Delete($id: Int!)
{
  deleteFlow(id: $id)
  {
    id
  }
}
`;

export const FLOW_ANNOTATION = `
{
  flowAnnotation {
    id
    uiSettings
    inputValues
    flow {
      id
    }
    action {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_ANNOTATION = `
mutation Set(
  $id: Int!,
  $uiSettings: GenericScalar,
  $inputValues: GenericScalar,
  $flow: Int,
  $action: Int,
  $page: Int,
)
{
  setFlowAnnotation(
    id: $id,
    uiSettings: $uiSettings,
    inputValues: $inputValues,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowAnnotation {
      id
      uiSettings
      inputValues
      flow {
        id
      }
      action {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_ANNOTATION = `
mutation Save(
  $uiSettings: GenericScalar!,
  $inputValues: GenericScalar!,
  $flow: Int!,
  $action: Int!,
  $page: Int,
)
{
  saveFlowAnnotation(
    uiSettings: $uiSettings,
    inputValues: $inputValues,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowAnnotation {
      id
    }
  }
}
`;

export const DELETE_FLOW_ANNOTATION = `
mutation Delete($id: Int!)
{
  deleteFlowAnnotation(id: $id)
  {
    id
  }
}
`;

export const FLOW_EDGE = `
{
  flowEdge {
    id
    type
    sourceName
    targetName
    source {
      id
    }
    target {
      id
    }
    flow {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_EDGE = `
mutation Set(
  $id: Int!,
  $type: String,
  $sourceName: String,
  $targetName: String,
  $source: Int,
  $target: Int,
  $flow: Int,
  $page: Int,
)
{
  setFlowEdge(
    id: $id,
    type: $type,
    source: $source,
    target: $target,
    flow: $flow,
    page: $page,
    sourceName: $sourceName,
    targetName: $targetName,
  ) {
    flowEdge {
      id
      type
      sourceName
      targetName
      source {
        id
      }
      target {
        id
      }
      flow {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_EDGE = `
mutation Save(
  $type: String!,
  $sourceName: String!,
  $targetName: String!,
  $source: Int!,
  $target: Int!,
  $flow: Int!,
  $page: Int,
)
{
  saveFlowEdge(
    type: $type,
    source: $source,
    target: $target,
    flow: $flow,
    page: $page,
    sourceName: $sourceName,
    targetName: $targetName,
  ) {
    flowEdge {
      id
    }
  }
}
`;

export const DELETE_FLOW_EDGE = `
mutation Delete($id: Int!)
{
  deleteFlowEdge(id: $id)
  {
    id
  }
}
`;

export const FLOW_NODE = `
{
  flowNode {
    id
    rootName
    rootDescription
    rootSettings
    iterableField
    isRoot
    isEnabled
    isVerified
    isIterable
    uiSettings
    inputValues
    outputValues
    errors
    warnings
    flow {
      id
    }
    action {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_NODE = `
mutation Set(
  $id: Int!,
  $rootName: String,
  $rootDescription: String,
  $rootSettings: GenericScalar,
  $iterableField: String,
  $isRoot: Boolean,
  $isEnabled: Boolean,
  $isVerified: Boolean,
  $isIterable: Boolean,
  $uiSettings: GenericScalar,
  $inputValues: GenericScalar,
  $outputValues: GenericScalar,
  $errors: GenericScalar,
  $warnings: GenericScalar,
  $flow: Int,
  $action: Int,
  $page: Int,
)
{
  setFlowNode(
    id: $id,
    rootName: $rootName,
    rootDescription: $rootDescription,
    rootSettings: $rootSettings,
    iterableField: $iterableField,
    isRoot: $isRoot,
    isEnabled: $isEnabled,
    isVerified: $isVerified,
    isIterable: $isIterable,
    uiSettings: $uiSettings,
    inputValues: $inputValues,
    outputValues: $outputValues,
    errors: $errors,
    warnings: $warnings,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowNode {
      id
      rootName
      rootDescription
      rootSettings
      iterableField
      isRoot
      isEnabled
      isVerified
      isIterable
      uiSettings
      inputValues
      outputValues
      errors
      warnings
      flow {
        id
      }
      action {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_NODE = `
mutation Save(
  $rootName: String,
  $rootDescription: String,
  $rootSettings: GenericScalar,
  $iterableField: String,
  $isRoot: Boolean!,
  $isEnabled: Boolean!,
  $isVerified: Boolean!,
  $isIterable: Boolean!,
  $uiSettings: GenericScalar!,
  $inputValues: GenericScalar!,
  $outputValues: GenericScalar!,
  $errors: GenericScalar,
  $warnings: GenericScalar,
  $flow: Int!,
  $action: Int!,
  $page: Int,
)
{
  saveFlowNode(
    rootName: $rootName,
    rootDescription: $rootDescription,
    rootSettings: $rootSettings,
    iterableField: $iterableField,
    isRoot: $isRoot,
    isEnabled: $isEnabled,
    isVerified: $isVerified,
    isIterable: $isIterable,
    uiSettings: $uiSettings,
    inputValues: $inputValues,
    outputValues: $outputValues,
    errors: $errors,
    warnings: $warnings,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowNode {
      id
    }
  }
}
`;

export const DELETE_FLOW_NODE = `
mutation Delete($id: Int!)
{
  deleteFlowNode(id: $id)
  {
    id
  }
}
`;

export const FLOW_PAGE = `
{
  flowPage {
    id
    name
    isDefault
    flow {
      id
    }
  }
}
`;

export const SET_FLOW_PAGE = `
mutation Set(
  $id: Int!,
  $name: String,
  $isDefault: Boolean,
  $flow: Int,
)
{
  setFlowPage(
    id: $id,
    name: $name,
    isDefault: $isDefault,
    flow: $flow,
  ) {
    flowPage {
      id
      name
      isDefault
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_PAGE = `
mutation Save(
  $name: String!,
  $isDefault: Boolean!,
  $flow: Int!,
)
{
  saveFlowPage(
    name: $name,
    isDefault: $isDefault,
    flow: $flow,
  ) {
    flowPage {
      id
    }
  }
}
`;

export const DELETE_FLOW_PAGE = `
mutation Delete($id: Int!)
{
  deleteFlowPage(id: $id)
  {
    id
  }
}
`;

export const FOLDER = `
{
  folder {
    id
    name
    user {
      id
    }
  }
}
`;

export const SET_FOLDER = `
mutation Set(
  $id: Int!,
  $name: String,
  $user: Int,
)
{
  setFolder(
    id: $id,
    name: $name,
    user: $user,
  ) {
    folder {
      id
      name
      user {
        id
      }
    }
  }
}
`;

export const SAVE_FOLDER = `
mutation Save(
  $name: String!,
  $user: Int!,
)
{
  saveFolder(
    name: $name,
    user: $user,
  ) {
    folder {
      id
    }
  }
}
`;

export const DELETE_FOLDER = `
mutation Delete($id: Int!)
{
  deleteFolder(id: $id)
  {
    id
  }
}
`;

export const INTEGRATION = `
{
  integration {
    id
    name
    uiSettings
    authSettings
    secretKeys
    isDummy
    app {
      id
    }
  }
}
`;

export const SET_INTEGRATION = `
mutation Set(
  $id: Int!,
  $name: String,
  $uiSettings: GenericScalar,
  $authSettings: GenericScalar,
  $secretKeys: String,
  $isDummy: Boolean,
  $app: Int,
)
{
  setIntegration(
    id: $id,
    name: $name,
    uiSettings: $uiSettings,
    authSettings: $authSettings,
    secretKeys: $secretKeys,
    app: $app,
    isDummy: $isDummy,
  ) {
    integration {
      id
      name
      uiSettings
      authSettings
      secretKeys
      isDummy
      app {
        id
      }
    }
  }
}
`;

export const SAVE_INTEGRATION = `
mutation Save(
  $name: String!,
  $uiSettings: GenericScalar!,
  $authSettings: GenericScalar!,
  $secretKeys: String!,
  $isDummy: Boolean!,
  $app: Int!,
)
{
  saveIntegration(
    name: $name,
    uiSettings: $uiSettings,
    authSettings: $authSettings,
    secretKeys: $secretKeys,
    app: $app,
    isDummy: $isDummy,
  ) {
    integration {
      id
    }
  }
}
`;

export const DELETE_INTEGRATION = `
mutation Delete($id: Int!)
{
  deleteIntegration(id: $id)
  {
    id
  }
}
`;

export const INTEGRATION_ACCOUNT = `
{
  integrationAccount {
    id
    username
    remoteId
    status
    secretKeys
    data
    isDummy
    user {
      id
    }
    integration {
      id
    }
  }
}
`;

export const SET_INTEGRATION_ACCOUNT = `
mutation Set(
  $id: Int!,
  $username: String,
  $remoteId: String,
  $status: String,
  $secretKeys: String,
  $data: GenericScalar,
  $isDummy: Boolean,
  $user: Int,
  $integration: Int,
)
{
  setIntegrationAccount(
    id: $id,
    username: $username,
    remoteId: $remoteId,
    status: $status,
    secretKeys: $secretKeys,
    user: $user,
    integration: $integration,
    data: $data,
    isDummy: $isDummy,
  ) {
    integrationAccount {
      id
      username
      remoteId
      status
      secretKeys
      data
      isDummy
      user {
        id
      }
      integration {
        id
      }
    }
  }
}
`;

export const SAVE_INTEGRATION_ACCOUNT = `
mutation Save(
  $username: String!,
  $remoteId: String!,
  $status: String!,
  $secretKeys: String!,
  $data: GenericScalar,
  $isDummy: Boolean!,
  $user: Int!,
  $integration: Int,
)
{
  saveIntegrationAccount(
    username: $username,
    remoteId: $remoteId,
    status: $status,
    secretKeys: $secretKeys,
    user: $user,
    integration: $integration,
    data: $data,
    isDummy: $isDummy,
  ) {
    integrationAccount {
      id
    }
  }
}
`;

export const DELETE_INTEGRATION_ACCOUNT = `
mutation Delete($id: Int!)
{
  deleteIntegrationAccount(id: $id)
  {
    id
  }
}
`;

export const LICENSE = `
{
  license {
    id
    name
    description
    fileStorage
    executions
    executionSteps
    executionTime
  }
}
`;

export const SET_LICENSE = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $fileStorage: Int,
  $executions: Int,
  $executionSteps: Int,
  $executionTime: Int,
)
{
  setLicense(
    id: $id,
    name: $name,
    description: $description,
    fileStorage: $fileStorage,
    executions: $executions,
    executionSteps: $executionSteps,
    executionTime: $executionTime,
  ) {
    license {
      id
      name
      description
      fileStorage
      executions
      executionSteps
      executionTime
    }
  }
}
`;

export const SAVE_LICENSE = `
mutation Save(
  $name: String!,
  $description: String!,
  $fileStorage: Int!,
  $executions: Int!,
  $executionSteps: Int!,
  $executionTime: Int!,
)
{
  saveLicense(
    name: $name,
    description: $description,
    fileStorage: $fileStorage,
    executions: $executions,
    executionSteps: $executionSteps,
    executionTime: $executionTime,
  ) {
    license {
      id
    }
  }
}
`;

export const DELETE_LICENSE = `
mutation Delete($id: Int!)
{
  deleteLicense(id: $id)
  {
    id
  }
}
`;

export const LOCAL_STORAGE = `
{
  localStorage {
    id
    key
    value
    flow {
      id
    }
    action {
      id
    }
  }
}
`;

export const SET_LOCAL_STORAGE = `
mutation Set(
  $id: Int!,
  $key: String,
  $value: GenericScalar,
  $flow: Int,
  $action: Int,
)
{
  setLocalStorage(
    id: $id,
    key: $key,
    value: $value,
    flow: $flow,
    action: $action,
  ) {
    localStorage {
      id
      key
      value
      flow {
        id
      }
      action {
        id
      }
    }
  }
}
`;

export const SAVE_LOCAL_STORAGE = `
mutation Save(
  $key: String!,
  $value: GenericScalar!,
  $flow: Int!,
  $action: Int,
)
{
  saveLocalStorage(
    key: $key,
    value: $value,
    flow: $flow,
    action: $action,
  ) {
    localStorage {
      id
    }
  }
}
`;

export const DELETE_LOCAL_STORAGE = `
mutation Delete($id: Int!)
{
  deleteLocalStorage(id: $id)
  {
    id
  }
}
`;

export const ORDER = `
{
  order {
    id
    paymentDate
    startDate
    endDate
    isActive
    isRenewal
    type
    invoiceData
    quantity
    user {
      id
    }
    license {
      id
    }
    relatedOrder {
      id
    }
  }
}
`;

export const SET_ORDER = `
mutation Set(
  $id: Int!,
  $paymentDate: DateTime,
  $startDate: DateTime,
  $endDate: DateTime,
  $isActive: Boolean,
  $isRenewal: Boolean,
  $type: String,
  $invoiceData: GenericScalar,
  $quantity: Int,
  $user: Int,
  $license: Int,
  $relatedOrder: Int,
)
{
  setOrder(
    id: $id,
    paymentDate: $paymentDate,
    startDate: $startDate,
    endDate: $endDate,
    isActive: $isActive,
    isRenewal: $isRenewal,
    type: $type,
    invoiceData: $invoiceData,
    quantity: $quantity,
    user: $user,
    license: $license,
    relatedOrder: $relatedOrder,
  ) {
    order {
      id
      paymentDate
      startDate
      endDate
      isActive
      isRenewal
      type
      invoiceData
      quantity
      user {
        id
      }
      license {
        id
      }
      relatedOrder {
        id
      }
    }
  }
}
`;

export const SAVE_ORDER = `
mutation Save(
  $paymentDate: DateTime!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $isActive: Boolean!,
  $isRenewal: Boolean!,
  $type: String!,
  $invoiceData: GenericScalar!,
  $quantity: Int!,
  $user: Int!,
  $license: Int,
  $relatedOrder: Int,
)
{
  saveOrder(
    paymentDate: $paymentDate,
    startDate: $startDate,
    endDate: $endDate,
    isActive: $isActive,
    isRenewal: $isRenewal,
    type: $type,
    invoiceData: $invoiceData,
    quantity: $quantity,
    user: $user,
    license: $license,
    relatedOrder: $relatedOrder,
  ) {
    order {
      id
    }
  }
}
`;

export const DELETE_ORDER = `
mutation Delete($id: Int!)
{
  deleteOrder(id: $id)
  {
    id
  }
}
`;

export const ORGANIZATION = `
{
  organization {
    id
    name
    description
    orgSettings
    owner {
      id
    }
  }
}
`;

export const SET_ORGANIZATION = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $orgSettings: GenericScalar,
  $owner: Int,
)
{
  setOrganization(
    id: $id,
    name: $name,
    description: $description,
    owner: $owner,
    orgSettings: $orgSettings,
  ) {
    organization {
      id
      name
      description
      orgSettings
      owner {
        id
      }
    }
  }
}
`;

export const SAVE_ORGANIZATION = `
mutation Save(
  $name: String!,
  $description: String!,
  $orgSettings: GenericScalar!,
  $owner: Int,
)
{
  saveOrganization(
    name: $name,
    description: $description,
    owner: $owner,
    orgSettings: $orgSettings,
  ) {
    organization {
      id
    }
  }
}
`;

export const DELETE_ORGANIZATION = `
mutation Delete($id: Int!)
{
  deleteOrganization(id: $id)
  {
    id
  }
}
`;

export const ORGANIZATION_PARTICIPANT = `
{
  organizationParticipant {
    id
    type
    user {
      id
    }
    organization {
      id
    }
  }
}
`;

export const SET_ORGANIZATION_PARTICIPANT = `
mutation Set(
  $id: Int!,
  $type: String,
  $user: Int,
  $organization: Int,
)
{
  setOrganizationParticipant(
    id: $id,
    user: $user,
    organization: $organization,
    type: $type,
  ) {
    organizationParticipant {
      id
      type
      user {
        id
      }
      organization {
        id
      }
    }
  }
}
`;

export const SAVE_ORGANIZATION_PARTICIPANT = `
mutation Save(
  $type: String!,
  $user: Int!,
  $organization: Int!,
)
{
  saveOrganizationParticipant(
    user: $user,
    organization: $organization,
    type: $type,
  ) {
    organizationParticipant {
      id
    }
  }
}
`;

export const DELETE_ORGANIZATION_PARTICIPANT = `
mutation Delete($id: Int!)
{
  deleteOrganizationParticipant(id: $id)
  {
    id
  }
}
`;

export const PARTICIPANT = `
{
  participant {
    id
    type
    channel
    isAccepted
    token
    email
    phone
    user {
      id
    }
    flow {
      id
    }
    organization {
      id
    }
  }
}
`;

export const SET_PARTICIPANT = `
mutation Set(
  $id: Int!,
  $type: String,
  $channel: String,
  $isAccepted: Boolean,
  $token: String,
  $email: String,
  $phone: String,
  $user: Int,
  $flow: Int,
  $organization: Int,
)
{
  setParticipant(
    id: $id,
    type: $type,
    channel: $channel,
    isAccepted: $isAccepted,
    token: $token,
    email: $email,
    phone: $phone,
    user: $user,
    flow: $flow,
    organization: $organization,
  ) {
    participant {
      id
      type
      channel
      isAccepted
      token
      email
      phone
      user {
        id
      }
      flow {
        id
      }
      organization {
        id
      }
    }
  }
}
`;

export const SAVE_PARTICIPANT = `
mutation Save(
  $type: String!,
  $channel: String!,
  $isAccepted: Boolean!,
  $token: String!,
  $email: String,
  $phone: String,
  $user: Int,
  $flow: Int!,
  $organization: Int,
)
{
  saveParticipant(
    type: $type,
    channel: $channel,
    isAccepted: $isAccepted,
    token: $token,
    email: $email,
    phone: $phone,
    user: $user,
    flow: $flow,
    organization: $organization,
  ) {
    participant {
      id
    }
  }
}
`;

export const DELETE_PARTICIPANT = `
mutation Delete($id: Int!)
{
  deleteParticipant(id: $id)
  {
    id
  }
}
`;

export const SUGGESTION = `
{
  suggestion {
    id
    type
    description
    prompt
    isFavorite
    isShown
    response
    extraData
    flow {
      id
    }
  }
}
`;

export const SET_SUGGESTION = `
mutation Set(
  $id: Int!,
  $type: String,
  $description: String,
  $prompt: String,
  $isFavorite: Boolean,
  $isShown: Boolean,
  $response: GenericScalar,
  $extraData: GenericScalar,
  $flow: Int,
)
{
  setSuggestion(
    id: $id,
    type: $type,
    description: $description,
    prompt: $prompt,
    isFavorite: $isFavorite,
    isShown: $isShown,
    response: $response,
    extraData: $extraData,
    flow: $flow,
  ) {
    suggestion {
      id
      type
      description
      prompt
      isFavorite
      isShown
      response
      extraData
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_SUGGESTION = `
mutation Save(
  $type: String!,
  $description: String!,
  $prompt: String!,
  $isFavorite: Boolean!,
  $isShown: Boolean!,
  $response: GenericScalar!,
  $extraData: GenericScalar!,
  $flow: Int,
)
{
  saveSuggestion(
    type: $type,
    description: $description,
    prompt: $prompt,
    isFavorite: $isFavorite,
    isShown: $isShown,
    response: $response,
    extraData: $extraData,
    flow: $flow,
  ) {
    suggestion {
      id
    }
  }
}
`;

export const DELETE_SUGGESTION = `
mutation Delete($id: Int!)
{
  deleteSuggestion(id: $id)
  {
    id
  }
}
`;

export const TAG_TUTORIAL = `
{
  tagTutorial {
    id
    name
  }
}
`;

export const SET_TAG_TUTORIAL = `
mutation Set(
  $id: Int!,
  $name: String,
)
{
  setTagTutorial(
    id: $id,
    name: $name,
  ) {
    tagTutorial {
      id
      name
    }
  }
}
`;

export const SAVE_TAG_TUTORIAL = `
mutation Save(
  $name: String!,
)
{
  saveTagTutorial(
    name: $name,
  ) {
    tagTutorial {
      id
    }
  }
}
`;

export const DELETE_TAG_TUTORIAL = `
mutation Delete($id: Int!)
{
  deleteTagTutorial(id: $id)
  {
    id
  }
}
`;

export const TEMPLATE_CATEGORY = `
{
  templateCategory {
    id
    name
    description
    uiSettings
    urlSlug
  }
}
`;

export const SET_TEMPLATE_CATEGORY = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $uiSettings: GenericScalar,
  $urlSlug: String,
)
{
  setTemplateCategory(
    id: $id,
    name: $name,
    description: $description,
    uiSettings: $uiSettings,
    urlSlug: $urlSlug,
  ) {
    templateCategory {
      id
      name
      description
      uiSettings
      urlSlug
    }
  }
}
`;

export const SAVE_TEMPLATE_CATEGORY = `
mutation Save(
  $name: String!,
  $description: String!,
  $uiSettings: GenericScalar!,
  $urlSlug: String,
)
{
  saveTemplateCategory(
    name: $name,
    description: $description,
    uiSettings: $uiSettings,
    urlSlug: $urlSlug,
  ) {
    templateCategory {
      id
    }
  }
}
`;

export const DELETE_TEMPLATE_CATEGORY = `
mutation Delete($id: Int!)
{
  deleteTemplateCategory(id: $id)
  {
    id
  }
}
`;

export const TUTORIAL = `
{
  tutorial {
    id
    name
    description
    media
    settings
    thumbnailPath
    categoryId {
      id
    }
    tagsIds {
      id
    }
  }
}
`;

export const SET_TUTORIAL = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $media: String,
  $settings: GenericScalar,
  $thumbnailPath: String,
  $categoryId: Int,
  $tagsIds: Int,
)
{
  setTutorial(
    id: $id,
    name: $name,
    description: $description,
    media: $media,
    settings: $settings,
    categoryId: $categoryId,
    tagsIds: $tagsIds,
    thumbnailPath: $thumbnailPath,
  ) {
    tutorial {
      id
      name
      description
      media
      settings
      thumbnailPath
      categoryId {
        id
      }
      tagsIds {
        id
      }
    }
  }
}
`;

export const SAVE_TUTORIAL = `
mutation Save(
  $name: String!,
  $description: String!,
  $media: String!,
  $settings: GenericScalar!,
  $thumbnailPath: String!,
  $categoryId: Int!,
  $tagsIds: Int!,
)
{
  saveTutorial(
    name: $name,
    description: $description,
    media: $media,
    settings: $settings,
    categoryId: $categoryId,
    tagsIds: $tagsIds,
    thumbnailPath: $thumbnailPath,
  ) {
    tutorial {
      id
    }
  }
}
`;

export const DELETE_TUTORIAL = `
mutation Delete($id: Int!)
{
  deleteTutorial(id: $id)
  {
    id
  }
}
`;

export const TUTORIAL_CATEGORY = `
{
  tutorialCategory {
    id
    name
    description
  }
}
`;

export const SET_TUTORIAL_CATEGORY = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
)
{
  setTutorialCategory(
    id: $id,
    name: $name,
    description: $description,
  ) {
    tutorialCategory {
      id
      name
      description
    }
  }
}
`;

export const SAVE_TUTORIAL_CATEGORY = `
mutation Save(
  $name: String!,
  $description: String!,
)
{
  saveTutorialCategory(
    name: $name,
    description: $description,
  ) {
    tutorialCategory {
      id
    }
  }
}
`;

export const DELETE_TUTORIAL_CATEGORY = `
mutation Delete($id: Int!)
{
  deleteTutorialCategory(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    accountSettings
    phone
    leadToken
    developerMode
    isAnonymous
    tokenVerified
    verifyToken
    verifyTokenExpiration
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $accountSettings: GenericScalar,
  $phone: String,
  $leadToken: String,
  $developerMode: Boolean,
  $isAnonymous: Boolean,
  $tokenVerified: Boolean,
  $verifyToken: String,
  $verifyTokenExpiration: DateTime,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    accountSettings: $accountSettings,
    phone: $phone,
    leadToken: $leadToken,
    developerMode: $developerMode,
    isAnonymous: $isAnonymous,
    tokenVerified: $tokenVerified,
    verifyToken: $verifyToken,
    verifyTokenExpiration: $verifyTokenExpiration,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      accountSettings
      phone
      leadToken
      developerMode
      isAnonymous
      tokenVerified
      verifyToken
      verifyTokenExpiration
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $accountSettings: GenericScalar!,
  $phone: String,
  $leadToken: String!,
  $developerMode: Boolean!,
  $isAnonymous: Boolean!,
  $tokenVerified: Boolean!,
  $verifyToken: String,
  $verifyTokenExpiration: DateTime,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    accountSettings: $accountSettings,
    phone: $phone,
    leadToken: $leadToken,
    developerMode: $developerMode,
    isAnonymous: $isAnonymous,
    tokenVerified: $tokenVerified,
    verifyToken: $verifyToken,
    verifyTokenExpiration: $verifyTokenExpiration,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;