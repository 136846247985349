import React from "react";
import { usePost } from "seed/api";
import View from "components/tmisc/organizations/users/Form.view";
import swal from "sweetalert";

const FormSave = ({ orgId }) => {

    const[callSave, setCallSave] = usePost("/users/create_user", {
        onCompleted: () => {
            swal({text: "Usuario creado exitosamente", icon: "success"}).then(() => {
                window.location.href = "/app/organizations/users";
            });
        },
        onError: () => {
            swal({text: "No se ha podido crear el usuario", icon: "error"});
        }
    });

    const onSubmit = (values) => {
        callSave({...values, organization_id: orgId});
    };

    return <View onSubmit={onSubmit} />;

}

export default FormSave;