import React from "react";
import HelpMessage from 'components/util/helpers/HelpMessage';


const NavCategory = ({
  category,
  activeCategories,
  onClickFilterCategory,
  onClickRemoveCategory
}) => {

  const isActive = activeCategories.includes(category.id)

  const categoryElement = (
    <button className={"d-flex align-items-center rounded tab-button" + (isActive ? " active" : "")}
      style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem", whiteSpace: "nowrap" }}
      onClick={() => onClickFilterCategory(category)}
    >
      {category?.uiSettings?.display?.title ?? ""}
      <i class="ml-2 fas fa-times-circle"
        onClick={(event) => onClickRemoveCategory(event, category)}
      ></i>
    </button>
  );


  return (
    <>
      {category?.uiSettings?.display?.help_message ? (
        <HelpMessage message={category?.uiSettings?.display?.help_message ?? ""} placement="top" style={{ marginBottom: "-10px" }}>
          categoryElement
        </HelpMessage>
      ) : (
        categoryElement
      )}
    </>
  );

};


export default NavCategory;