import React from "react";
import { usePost } from "seed/api";

const ChatToast = ({ toastData }) => {

  const [callCloseResult] = usePost("/apps/forms/close_result");

  const status = toastData.status;
  const executionId = toastData.execution_id;
  const executionNodeId = toastData.node_id;
  const lastNode = toastData.last_node;
  const message = toastData.message;
  const awaitingAlert = toastData.awaiting_alert;
  const executionSessionId = toastData.session_id;

  if (executionSessionId && executionSessionId != sessionStorage.getItem("execution_session_id")) return <></>;
  if (status == "AWAITING" && !awaitingAlert) return <></>;
  if (status == "OK") return <></>;
  if (status == "FAILED") return <></>;
  if (!status) return <></>;

  if (status == "RUNNING")
    return <div className="py-5">
      <div className="loading animate__animated animate__fadeIn animate__faster">
        <div className="loader"></div>
      </div>
    </div>

  return <div className={`d-flex flex-column animate__animated animate__fadeIn animate__faster mx-3 mb-1 align-items-start`}>
    <div className={`message inner-toast scrollbar-narrow`} style={{ maxWidth: "80%" }}>
      {
        function () {
          if (status == "AWAITING" && awaitingAlert) {
            return <div>
              <i className="loading-icon fas fa-spinner fa-spin mr-2"></i> Esperando respuesta...
            </div>;
          }
        }()
      }
    </div>
  </div>;

}

export default ChatToast;