import React from "react";
import { getSmoothStepPath, useReactFlow, useEdges, useNodes } from 'reactflow';
import View from "components/studio/board/nodes/Edge.view";

function Edge({
  style = {},
  data = {},
  id,
  animated,
  selected,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  sourceHandleId,
  source,
  markerEnd
}) {

  const { setEdges } = useReactFlow();
  const nodes = useNodes();
  const edges = useEdges();
  const categorySourceNode = nodes.find((nd) => nd.id == source)?.data?.category ?? "";
  const diffY = Math.abs(targetY-sourceY)
  const diffX = Math.abs(targetX-sourceX)

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX: diffX > 7 ? targetX : sourceX,
    targetY: diffY > 7 ? targetY : sourceY,
    targetPosition
  });

  const onDeteleEdge = () => {
    const tempEdges = edges.filter((edge) => edge.id != id);
    setEdges(tempEdges);
    if (data.onSave != null && data.onSave != undefined) {
      data.onSave(nodes, [...tempEdges]);
    }
  };

  const onInsertCondition = () => {
    data?.onAttachNode(id, "conditional")
  }

  const onInsertIteration = () => {
    data?.onAttachNode(id, "iterate")
  }

  let label = null;
  if (categorySourceNode == "conditional") {
    if (sourceHandleId == "next") label = "Si cumple";
    if (sourceHandleId == "else") label = "Si no cumple";
    if (sourceHandleId == "end") label = "Al finalizar";
  } else if (categorySourceNode == "iterate") {
    if (sourceHandleId == "next") label = "Repetir";
    if (sourceHandleId == "end") label = "Al finalizar";
  }

  return (
    <View
      style={style}
      label={label}
      labelX={labelX}
      labelY={labelY}
      selected={selected}
      edgePath={edgePath}
      markerEnd={markerEnd}
      animated={animated}
      onDeteleEdge={onDeteleEdge}
      onInsertCondition={onInsertCondition}
      onInsertIteration={onInsertIteration}
    />
  );
}

export default Edge;