import React, {useState} from "react";
import { getShowInput } from "components/util/schema_form/util/schema_form";
import InputHandler from "components/util/schema_form/SchemaForm.sub.InputHandler.view";
import lodash from "lodash";

const SchemaHandler = ({
  schema = [],
  parentKey = "",
  appId,
  actionId,
  flowId,
  nodeId,
  executionId,
  values,
  sectionIdx,
  ignoreSamplePicker,
  isDeveloperMode,
  isReadOnly,
  isCommentEnabled,
  setFieldValue,
  setValues,
}) => {
  const [showCollapse, setShowCollapse] = useState(null)
  const hiddenId = "schema-form-hidden-" + parentKey.replaceAll("[", "").replaceAll("]", "").replaceAll(".", "-")
  const schemaVisible = schema.filter(input => !input.hidden && getShowInput(parentKey, input?.show_if ?? [], values));
  const schemaHidden = schema.filter(input => input.hidden && getShowInput(parentKey, input?.show_if ?? [], values));
  schemaHidden.forEach(input => {
    const schemaKey = parentKey == "" ? input.name : `${parentKey}.${input.name}`
    const schemaValue = lodash.get(values, schemaKey)
    if (schemaValue!=undefined && schemaValue !="" && showCollapse == null) setShowCollapse(true);
  })

  return <div>
    <ul className={"step step-form step-eicon-xs mb-0 " + (parentKey != "" ? "sub" : "")}>
      {schemaVisible.map((input, idx) =>
        <InputHandler key={input.name} input={input} appId={appId} actionId={actionId} flowId={flowId} nodeId={nodeId} executionId={executionId}
          nextInput={idx < schemaVisible.length - 1 ? schemaVisible[idx + 1] : null}
          prevInput={idx > 0 ? schemaVisible[idx - 1] : null}
          values={values} setFieldValue={setFieldValue} setValues={setValues} parentKey={parentKey}
          ignoreSamplePicker={ignoreSamplePicker} isDeveloperMode={isDeveloperMode}
          isReadOnly={isReadOnly} isCommentEnabled={isCommentEnabled} sectionIdx={sectionIdx}/>
      )}
    </ul>
    {schemaHidden.length > 0 ?
      <div class="mb-2 mt-1">
        <a class=" btn btn-sm btn-lighter py-2 px-2 mb-3 mt-n4 text-left ml-7" data-toggle="collapse"
          style={{ border: "none", color: "#959595" }}
          href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-form-hidden">
          Más opciones
          <i style={{ color: "#aaa" }} class="ml-1 fas fa-chevron-down"></i>
        </a>

        <div class={"collapse " + (showCollapse ? "show" : "")} id={hiddenId}>
          <ul className={"step step-form step-eicon-xs mb-0 ml-5 " + (parentKey != "" ? "sub" : "")}>
            {schemaHidden.map((input, idx) =>
              <InputHandler key={input.name} input={input} appId={appId} actionId={actionId} flowId={flowId} nodeId={nodeId} executionId={executionId}
                nextInput={idx < schemaHidden.length - 1 ? schemaHidden[idx + 1] : null}
                prevInput={idx > 0 ? schemaHidden[idx - 1] : null}
                values={values} setFieldValue={setFieldValue} setValues={setValues} parentKey={parentKey}
                ignoreSamplePicker={ignoreSamplePicker} isDeveloperMode={isDeveloperMode}
                isReadOnly={isReadOnly} isCommentEnabled={isCommentEnabled} sectionIdx={sectionIdx}/>
            )}
          </ul>
        </div>
      </div> : null}

  </div>
}

export default SchemaHandler;