import React from "react";
import { StyleTag } from "seed/helpers"

const IsometricImage = ({ fill="#0080C0" }) =>
  <svg viewBox="0 0 1500 560">

    <StyleTag content={`
            .isometric-squares-0{fill:${fill};}
          `} />
<g>
	<defs>
		<rect id="isometricSquaresID_1" width="1983" height="541"/>
	</defs>
	<clipPath id="isometricSquaresID_2">
		<use xlinkHref="#isometricSquaresID_1" />
	</clipPath>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M821.8,446.8L750,488.2c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C825.4,440.3,825.9,444.4,821.8,446.8z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M619.6,452.4L619.6,452.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L543,501c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C615.2,452.9,617.3,452.4,619.6,452.4 M619.6,450.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C626,451.2,622.7,450.4,619.6,450.4
			L619.6,450.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M510.5,515.4L510.5,515.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L433.9,564c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C506,515.9,508.1,515.4,510.5,515.4 M510.5,513.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C516.9,514.2,513.6,513.4,510.5,513.4
			L510.5,513.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M401.4,578.4L401.4,578.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L324.7,627c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C396.9,578.9,399,578.4,401.4,578.4 M401.4,576.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C407.8,577.2,404.5,576.4,401.4,576.4
			L401.4,576.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M292.2,641.4L292.2,641.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L215.6,690c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C287.8,641.9,289.9,641.4,292.2,641.4 M292.2,639.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C298.7,640.2,295.4,639.4,292.2,639.4
			L292.2,639.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M946.9,263.4L946.9,263.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L870.3,312c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C942.5,263.9,944.6,263.4,946.9,263.4 M946.9,261.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C953.4,262.2,950.1,261.4,946.9,261.4
			L946.9,261.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1056.1,200.4L1056.1,200.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L979.5,249c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1051.6,200.9,1053.7,200.4,1056.1,200.4 M1056.1,198.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1062.5,199.2,1059.2,198.4,1056.1,198.4L1056.1,198.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1274.3,74.4L1274.3,74.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1269.9,74.9,1272,74.4,1274.3,74.4 M1274.3,72.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C1280.7,73.2,1277.4,72.4,1274.3,72.4
			L1274.3,72.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1383.4,11.4L1383.4,11.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L1306.8,60c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1379,11.9,1381.1,11.4,1383.4,11.4 M1383.4,9.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C1389.8,10.2,1386.6,9.4,1383.4,9.4
			L1383.4,9.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M854.7,462.2L854.7,462.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L875,559.2c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C850.3,462.6,852.4,462.2,854.7,462.2 M854.7,460.2c-2.6,0-5.1,0.5-6.9,1.6L776,503.2c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C861.1,460.9,857.8,460.2,854.7,460.2
			L854.7,460.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M745.6,525.2L745.6,525.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L669,573.7c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C741.2,525.6,743.3,525.2,745.6,525.2 M745.6,523.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C752,523.9,748.7,523.2,745.6,523.2
			L745.6,523.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M636.5,588.2L636.5,588.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C632,588.6,634.1,588.2,636.5,588.2 M636.5,586.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C642.9,586.9,639.6,586.2,636.5,586.2
			L636.5,586.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M527.4,651.2L527.4,651.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C522.9,651.6,525,651.2,527.4,651.2 M527.4,649.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C533.8,649.9,530.5,649.2,527.4,649.2
			L527.4,649.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M963.8,399.2L963.8,399.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C959.4,399.6,961.5,399.2,963.8,399.2 M963.8,397.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C970.3,397.9,967,397.2,963.8,397.2
			L963.8,397.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1072.9,336.2L1072.9,336.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1068.5,336.6,1070.6,336.2,1072.9,336.2 M1072.9,334.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1079.4,334.9,1076.1,334.2,1072.9,334.2L1072.9,334.2z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1275.2,330.5l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C1278.7,324,1279.2,328.1,1275.2,330.5z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1384.3,267.5l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C1387.8,261,1388.4,265.1,1384.3,267.5z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1400.3,147.2L1400.3,147.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1395.9,147.6,1398,147.2,1400.3,147.2 M1400.3,145.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1406.7,145.9,1403.4,145.2,1400.3,145.2L1400.3,145.2z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1602.5,141.5l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C1606.1,135,1606.6,139.1,1602.5,141.5z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M980.7,534.9L980.7,534.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3L1001,632
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C976.3,535.4,978.4,534.9,980.7,534.9 M980.7,532.9c-2.6,0-5.1,0.5-6.9,1.6L902,576c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C987.1,533.7,983.8,532.9,980.7,532.9
			L980.7,532.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M871.6,597.9L871.6,597.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L891.8,695c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L795,646.4c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C867.2,598.4,869.3,597.9,871.6,597.9 M871.6,595.9c-2.6,0-5.1,0.5-6.9,1.6L792.8,639c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C878,596.7,874.7,595.9,871.6,595.9
			L871.6,595.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1089.8,471.9L1089.8,471.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1085.4,472.4,1087.5,471.9,1089.8,471.9 M1089.8,469.9c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1096.3,470.7,1093,469.9,1089.8,469.9L1089.8,469.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1198.9,408.9L1198.9,408.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1194.5,409.4,1196.6,408.9,1198.9,408.9 M1198.9,406.9c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1205.4,407.7,1202.1,406.9,1198.9,406.9L1198.9,406.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1417.2,282.9L1417.2,282.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1412.8,283.4,1414.8,282.9,1417.2,282.9 M1417.2,280.9c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1423.6,281.7,1420.3,280.9,1417.2,280.9L1417.2,280.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1526.3,219.9L1526.3,219.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1521.9,220.4,1524,219.9,1526.3,219.9 M1526.3,217.9c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1532.7,218.7,1529.4,217.9,1526.3,217.9L1526.3,217.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1106.7,607.7L1106.7,607.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1102.3,608.1,1104.4,607.7,1106.7,607.7 M1106.7,605.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1113.1,606.4,1109.8,605.7,1106.7,605.7L1106.7,605.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1324.9,481.7L1324.9,481.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3L1319,483
			C1320.5,482.1,1322.6,481.7,1324.9,481.7 M1324.9,479.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6L1418,539c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1331.4,480.4,1328.1,479.7,1324.9,479.7L1324.9,479.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1434.1,418.7L1434.1,418.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1429.6,419.1,1431.7,418.7,1434.1,418.7 M1434.1,416.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1440.5,417.4,1437.2,416.7,1434.1,416.7L1434.1,416.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1543.2,355.7L1543.2,355.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1538.8,356.1,1540.8,355.7,1543.2,355.7 M1543.2,353.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1549.6,354.4,1546.3,353.7,1543.2,353.7L1543.2,353.7z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1854.5,287l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C1858.1,280.5,1858.6,284.6,1854.5,287z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1232.7,680.4L1232.7,680.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1228.3,680.9,1230.4,680.4,1232.7,680.4 M1232.7,678.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1239.1,679.2,1235.8,678.4,1232.7,678.4L1232.7,678.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1341.8,617.4L1341.8,617.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1337.4,617.9,1339.5,617.4,1341.8,617.4 M1341.8,615.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1348.3,616.2,1345,615.4,1341.8,615.4L1341.8,615.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1450.9,554.4L1450.9,554.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1446.5,554.9,1448.6,554.4,1450.9,554.4 M1450.9,552.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1457.4,553.2,1454.1,552.4,1450.9,552.4L1450.9,552.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1560.1,491.4L1560.1,491.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1555.6,491.9,1557.7,491.4,1560.1,491.4 M1560.1,489.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1566.5,490.2,1563.2,489.4,1560.1,489.4L1560.1,489.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1778.3,365.4L1778.3,365.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1773.9,365.9,1776,365.4,1778.3,365.4 M1778.3,363.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1784.7,364.2,1781.4,363.4,1778.3,363.4L1778.3,363.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1887.4,302.4L1887.4,302.4c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C1883,302.9,1885.1,302.4,1887.4,302.4 M1887.4,300.4c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9
			C1893.8,301.2,1890.6,300.4,1887.4,300.4L1887.4,300.4z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M602.7,316.7L602.7,316.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L623,413.8c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C598.3,317.2,600.4,316.7,602.7,316.7 M602.7,314.7c-2.6,0-5.1,0.5-6.9,1.6L524,357.7c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C609.1,315.4,605.8,314.7,602.7,314.7
			L602.7,314.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M493.6,379.7L493.6,379.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L417,428.2c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C489.2,380.2,491.3,379.7,493.6,379.7 M493.6,377.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C500,378.4,496.7,377.7,493.6,377.7
			L493.6,377.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M384.5,442.7L384.5,442.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C380,443.2,382.1,442.7,384.5,442.7 M384.5,440.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C390.9,441.4,387.6,440.7,384.5,440.7
			L384.5,440.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M275.4,505.7L275.4,505.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C270.9,506.2,273,505.7,275.4,505.7 M275.4,503.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C281.8,504.4,278.5,503.7,275.4,503.7
			L275.4,503.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M166.2,568.7L166.2,568.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C161.8,569.2,163.9,568.7,166.2,568.7 M166.2,566.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C172.7,567.4,169.4,566.7,166.2,566.7
			L166.2,566.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M711.8,253.7L711.8,253.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C707.4,254.2,709.5,253.7,711.8,253.7 M711.8,251.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C718.3,252.4,715,251.7,711.8,251.7
			L711.8,251.7z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M914,248l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C917.6,241.5,918.1,245.7,914,248z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M930.1,127.7L930.1,127.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C925.6,128.2,927.7,127.7,930.1,127.7 M930.1,125.7c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C936.5,126.4,933.2,125.7,930.1,125.7
			L930.1,125.7z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M1148.3,1.7L1148.3,1.7c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3l-71.8,41.5
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3L1142.4,3
			C1143.9,2.2,1146,1.7,1148.3,1.7 M1148.3-0.3c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C1154.7,0.4,1151.4-0.3,1148.3-0.3
			L1148.3-0.3z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M367.6,306.9L367.6,306.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L387.8,404c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2L291,355.5c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C363.2,307.4,365.3,306.9,367.6,306.9 M367.6,304.9c-2.6,0-5.1,0.5-6.9,1.6L288.8,348c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C374,305.7,370.7,304.9,367.6,304.9
			L367.6,304.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M258.5,369.9L258.5,369.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L278.7,467c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C254,370.4,256.1,369.9,258.5,369.9 M258.5,367.9c-2.6,0-5.1,0.5-6.9,1.6L179.7,411c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C264.9,368.7,261.6,367.9,258.5,367.9
			L258.5,367.9z"/>
	</g>
	<g opacity="5.000000e-02" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M242.4,490.3l-71.8,41.5c-4.1,2.4-11.2,2.1-15.9-0.7l-82.9-47.9c-4.7-2.7-5.2-6.8-1.1-9.2l71.8-41.5
			c4.1-2.4,11.2-2.1,15.9,0.7l82.9,47.9C246,483.8,246.5,487.9,242.4,490.3z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M40.2,495.9L40.2,495.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3L60.5,593
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C35.8,496.4,37.9,495.9,40.2,495.9 M40.2,493.9c-2.6,0-5.1,0.5-6.9,1.6L-38.5,537c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C46.7,494.7,43.4,493.9,40.2,493.9
			L40.2,493.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M585.8,180.9L585.8,180.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L606.1,278c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C581.4,181.4,583.5,180.9,585.8,180.9 M585.8,178.9c-2.6,0-5.1,0.5-6.9,1.6L507.1,222c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C592.3,179.7,589,178.9,585.8,178.9
			L585.8,178.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M694.9,117.9L694.9,117.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			L715.2,215c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C690.5,118.4,692.6,117.9,694.9,117.9 M694.9,115.9c-2.6,0-5.1,0.5-6.9,1.6L616.2,159c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C701.4,116.7,698.1,115.9,694.9,115.9
			L694.9,115.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M804.1,54.9L804.1,54.9c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3L824.3,152
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C799.6,55.4,801.7,54.9,804.1,54.9 M804.1,52.9c-2.6,0-5.1,0.5-6.9,1.6L725.3,96c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C810.5,53.7,807.2,52.9,804.1,52.9
			L804.1,52.9z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M132.5,297.2L132.5,297.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C128,297.7,130.1,297.2,132.5,297.2 M132.5,295.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C138.9,296,135.6,295.2,132.5,295.2
			L132.5,295.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M23.4,360.2L23.4,360.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3l-71.8,41.5
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C18.9,360.7,21,360.2,23.4,360.2 M23.4,358.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C29.8,359,26.5,358.2,23.4,358.2
			L23.4,358.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M-85.8,423.2L-85.8,423.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3
			l-71.8,41.5c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3l71.8-41.5
			C-90.2,423.7-88.1,423.2-85.8,423.2 M-85.8,421.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C-79.3,422-82.6,421.2-85.8,421.2
			L-85.8,421.2z"/>
	</g>
	<g opacity=".15" clip-path="url(#isometricSquaresID_2)">
		<path class="isometric-squares-0 fill-white" d="M568.9,45.2L568.9,45.2c2.9,0,5.8,0.7,8,2l82.9,47.9c1.8,1,2.9,2.3,2.9,3.5c0,1.1-1.1,1.9-1.8,2.3l-71.8,41.5
			c-1.5,0.9-3.6,1.3-5.9,1.3c-2.9,0-5.8-0.7-8-2l-82.9-47.9c-1.8-1-2.9-2.3-2.9-3.5c0-1.1,1.1-1.9,1.8-2.3L563,46.5
			C564.5,45.7,566.6,45.2,568.9,45.2 M568.9,43.2c-2.6,0-5.1,0.5-6.9,1.6l-71.8,41.5c-4.1,2.4-3.6,6.5,1.1,9.2l82.9,47.9
			c2.6,1.5,5.9,2.2,9,2.2c2.6,0,5.1-0.5,6.9-1.6l71.8-41.5c4.1-2.4,3.6-6.5-1.1-9.2l-82.9-47.9C575.4,44,572.1,43.2,568.9,43.2
			L568.9,43.2z"/>
	</g>
</g>
</svg>

export default IsometricImage;