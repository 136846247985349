import React from "react";
import { Formik, Form, Field } from "formik";

const FormView = ({ onSubmit, user = {} }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">{user.id ? "Editar" : "Nuevo"} Usuario</h3>
    </div>

    <div className="card-body animate__animated animate__fadeIn animate__faster">
      <Formik
        initialValues={{
          first_name: user.firstName || "",
          last_name: user.lastName || "",
          email: user.email || "",
          phone: user.phone || "",
          password: ""
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            {/* Nombre */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="first_name" className="col-md-3 mb-0">
                  Nombre <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  id="first_name"
                  name="first_name"
                  maxLength="512"
                  className="form-control flex-grow-1"
                  required
                />
              </div>
            </div>

            {/* Apellido */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="last_name" className="col-md-3 mb-0">
                  Apellido <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  id="last_name"
                  name="last_name"
                  maxLength="512"
                  className="form-control flex-grow-1"
                  required
                />
              </div>
            </div>

            {/* Correo */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="email" className="col-md-3 mb-0">
                  Correo <span className="text-danger">*</span>
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  maxLength="512"
                  className="form-control flex-grow-1"
                  required
                />
              </div>
            </div>

            {/* Teléfono */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="phone" className="col-md-3 mb-0">
                  Teléfono
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  maxLength="512"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>

            {/* Tipo de usuario */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="type" className="col-md-3 mb-0">
                  Tipo de usuario<span className="text-danger">*</span>
                </label>
                <Field
                  as="select"
                  id="type"
                  name="type"
                  className="form-control flex-grow-1"
                  required
                >
                  <option value="">-- Selecciona un tipo de usuario --</option>
                  <option value="ADMIN">Administrador</option>
                  <option value="USER">Usuario</option>
                </Field>
              </div>
            </div>

            {/* Contraseña */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="password" className="col-md-3 mb-0">
                  Contraseña {!user.id ? <span className="text-danger">*</span> : ""}
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  maxLength="512"
                  className="form-control flex-grow-1"
                  required = {user.id ? false : true}
                />
              </div>
            </div>

            {/* Botón de guardar */}
            <div className="text-right">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default FormView;
