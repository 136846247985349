import React, { useState, useEffect } from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/tmisc/organizations/Organizations.view";

function Organizations() {
    const userId = sessionStorage.getItem("id");
    const [selectedOrganization, setSelectedOrganization] = useState(0);

    const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      organizationParticipants {
        type
        organization {
          id
          name
        }
      }
      license { name }
    }
  }`, userId);

    useEffect(() => {
        if (!reqUser.loading && !reqUser.error) {
            const adminOrganizations =
                reqUser.data?.user?.organizationParticipants
                    ?.filter(participant => participant.type === "ADMIN")
                    .map(participant => participant.organization) || [];
            if (adminOrganizations.length > 0 && !selectedOrganization) {
                setSelectedOrganization(adminOrganizations[0].id);
            }
        }
    }, [reqUser.loading, reqUser.error, reqUser.data, selectedOrganization]);

    if (reqUser.loading) return <PlaceholderView />;
    if (reqUser.error) return "Error";

    const { user = {} } = reqUser.data;
    const organizations =
        user?.organizationParticipants
            ?.filter(participant => participant.type === "ADMIN")
            .map(participant => participant.organization) || [];

    return (
        <View
            user={user}
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
        />
    );
}

const PlaceholderView = () => (
    <div className="card">
        <div className="card-body" style={{ padding: "10rem 0" }}>
            <Loading />
        </div>
    </div>
);

export default Organizations;
