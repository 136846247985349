import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import { parseDatatypes } from "components/collections/util/datatypes";
import { filterData, sortData } from "components/collections/util/filters";
import View from "components/collections/Documents.view";
import { requestFiles, uploadDragFiles } from "./util/files";

function Documents({
	query,
	filters,
	search,
	sorting,
	pageSize,
	pageNum,
	totalPages,
	values,
	rawValues,
	structure,
	isReadOnly = false,
	useCustomSearch = false,
	useCustomPagination = false,
	useCustomFilters = false,
	useCustomSorting = false,
	height = "auto",
	loading = false,
	showFilters = true,
	showSorting = true,
	showSearch = true,
  checkValidation = false,
	onAddRow = () => { },
	onDeleteRow = () => { },
	onDownloadZip = () => { },
	onPageChange = () => { },
	onChangeSearch = () => { },
	onChangeQuery = () => { },
	onChangeFilters = () => { },
	onChangeSorting = () => { },
	folioComponent
}) {

	const cacheRef = useRef({});

	const [_rows, setRows] = useState([]);
	const [_columns, setColumns] = useState([]);
	const [_query, setQuery] = useState("");
	const [_search, setSearch] = useState("");
	const [_filters, setFilters] = useState(filters || []);
	const [_sorting, setSorting] = useState(sorting || []);
	const [_pageNum, setPageNum] = useState(1);
	const [_pageSize, setPageSize] = useState(10);
	const [_totalPages, setTotalPages] = useState(1);

	const [selectedFile, setSelectedFile] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	const [filterDefault, setFilterDefault] = useState(null);
	const [isFilterModalShown, setIsFilterModalShown] = useState(false);
	const [isOptionModalShown, setIsOptionModalShown] = useState(false);
	const [isFilePreviewShown, setIsFilePreviewShown] = useState(false);
	const [isImportModalShown, setIsImportModalShown] = useState(false);
	const [isExportModalShown, setIsExportModalShown] = useState(false);
	const [isFormModalShown, setIsFormModalShown] = useState(false);
	const [isSearchShown, setIsSearchShown] = useState(false);
	const [isSortingModalShown, setIsSortingModalShown] = useState(false);
	const [formValue, setFormValue] = useState(null);
	const tableRef = useRef(null);

	useEffect(() => {
		if (selectedFile)
			setIsFilePreviewShown(true);
	}, [selectedFile]);

	useEffect(() => {
		if (values) setRows(values);
	}, [values]);

	useEffect(() => {
		if(!rawValues) return setColumns(structure);
		let { newStructure, newRawValues } = parseDatatypes(structure, rawValues);
		setColumns(newStructure);
		setRows(newRawValues);
	}, [structure, rawValues]);

	useEffect(() => {
		if (totalPages) setTotalPages(totalPages);
	}, [totalPages]);

	useEffect(() => {
		if (pageNum) setPageNum(pageNum);
	}, [pageNum]);

	useEffect(() => {
		if (pageSize) setPageSize(pageSize);
	}, [pageSize]);

	useEffect(() => {
		if (query) setQuery(query);
	}, [query]);

	useEffect(() => {
		if (search) setSearch(search);
	}, [search]);

	useEffect(() => {
		if (filters) setFilters(filters);
	}, [filters]);

	useEffect(() => {
		if (sorting) setSorting(sorting);
	}, [sorting]);

	useEffect(() => {
		if (_pageNum > _totalPages && _totalPages > 0)
			_onPageChange(_totalPages);
	}, [_totalPages]);

	useEffect(() => {
		if(!useCustomPagination) {
			let filteredData = filterData({ data: _rows, search: _search, query: _query, pageNum: _pageNum, 
				pageSize: _pageSize, useSearch: !useCustomSearch, useQuery: !useCustomFilters });
			setTotalPages(filteredData.length > _pageSize ? Math.ceil(filteredData.length / _pageSize) : 1);
		}
	}, [pageNum, totalPages, _rows, _query, _search]);

	const _onPageChange = (page) => {
		if(page < 1) page = 1;
		if(page > _totalPages) page = _totalPages;
		setPageNum(page);
		onPageChange(page);
	}

  const _onAddRow = () => {

    if(isReadOnly) return;

    requestFiles({
      multiple: true,
      accept: "application/pdf",
      onUpload: (files) => {
        onAddRow(files.map(file => ({
          final: { data: { file } },
          action: "create"
        })))
      },
      onUploadError: (file) => {
        swal({
          title: "Error al subir archivo",
          icon: "warning",
          text: "No se pudo subir el archivo " + file.name,
          className: "text-center",
          buttons: {
            exit: {
              text: "Continuar",
              className: "swal-button swal-button--cancel btn-secondary px-2 w-100 text-center",
            }
          },
        });
      }
    });

	}

	const _onDeleteRow = (index) => {

		if(isReadOnly) return;

		if(!checkValidation) {
			const newRows = [..._rows];
			newRows.splice(index, 1);
			setRows(newRows);
		}

		onDeleteRow(index);

	}

  const _onChangeSearch = (e) => {
		const id = Math.random();
    cacheRef.current["search"] = id;
		setTimeout(() => {
      if(cacheRef.current["search"] == id) {
				setSearch(e.target.value);
        onChangeSearch(e.target.value);
			}
    }, 600);
	}

	const _onChangeQuery = (query) => {
		setQuery(query);
		onChangeQuery(query);
	}

	const _onChangeFilters = (filters) => {
		setFilters(filters);
		onChangeFilters(filters);
	}

	const _onChangeSorting = (sorting) => {
		setSorting(sorting);
		onChangeSorting(sorting);
	}

	const _onDragEnter = (e) => {
    e.preventDefault();
		if(e.target === e.currentTarget || !e.target.classList.contains("drag-area"))
			setIsDragging(true);
  }

  const _onDragLeave = (e) => {
    e.preventDefault();
		if(e.target === e.currentTarget || !e.target.classList.contains("drag-area"))
			setIsDragging(false);
  }

  const _onDragOver = (e) => {
    e.preventDefault();
  }

  const _onDrop = (e) => {
		
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;

		uploadDragFiles({
			files,
			onUpload: (files) => {
				onAddRow(files.map(file => ({
					final: { data: { file } },
					action: "create"
				})))
			},
			onUploadError: (file) => {
				swal({
					title: "Error al subir archivo",
					icon: "warning",
					text: "No se pudo subir el archivo " + file.name,
					className: "text-center",
					buttons: {
						exit: {
							text: "Continuar",
							className: "swal-button swal-button--cancel btn-secondary px-2 w-100 text-center",
						}
					},
				});
			}
		});

  }

	const filteredData = filterData({ data: _rows, search: _search, query: _query, pageNum: _pageNum, pageSize: _pageSize, 
      useSearch: !useCustomSearch, useQuery: !useCustomFilters, usePagination: !useCustomPagination });
    const sortedData = sortData({ data: filteredData, sorting: _sorting, useSorting: !useCustomSorting });

	return <View
		height={height}
		columns={_columns}
		rows={sortedData}
		filteredData={filteredData}
		filterDefault={filterDefault}
		isDragging={isDragging}
		isReadOnly={isReadOnly}
		isLoading={loading}
		isFilePreviewShown={isFilePreviewShown}
		isFilterModalShown={isFilterModalShown}
		isOptionModalShown={isOptionModalShown}
		isImportModalShown={isImportModalShown}
		isExportModalShown={isExportModalShown}
		isFormModalShown={isFormModalShown}
		isSearchShown={isSearchShown}
		isSortingModalShown={isSortingModalShown}
		showFilters={showFilters}
		showSearch={showSearch}
		showSorting={showSorting}
		tableRef={tableRef}
		pageSize={_pageSize}
		pageNum={_pageNum}
		totalPages={_totalPages}
		query={_query}
		filters={_filters}
		search={_search}
		sorting={_sorting}
		formValue={formValue}
		selectedFile={selectedFile}
		setSelectedFile={setSelectedFile}
		setFormValue={setFormValue}
		setIsFilePreviewShown={setIsFilePreviewShown}
		setIsFilterModalShown={setIsFilterModalShown}
		setIsOptionModalShown={setIsOptionModalShown}
		setIsImportModalShown={setIsImportModalShown}
		setIsExportModalShown={setIsExportModalShown}
		setIsFormModalShown={setIsFormModalShown}
		setIsSearchShown={setIsSearchShown}
		setFilterDefault={setFilterDefault}
		setIsSortingModalShown={setIsSortingModalShown}
		onAddRow={_onAddRow}
		onDeleteRow={_onDeleteRow}
		onDownloadZip={onDownloadZip}
		onPageChange={_onPageChange}
		onChangeSearch={_onChangeSearch}
		onChangeQuery={_onChangeQuery}
		onChangeFilters={_onChangeFilters}
		onChangeSorting={_onChangeSorting}
		onDragEnter={_onDragEnter}
		onDragLeave={_onDragLeave}
		onDragOver={_onDragOver}
		onDrop={_onDrop}
		folioComponent={folioComponent}
	/>

}

export default Documents;