import React from "react";
import View from "components/presentation/settings/CustomViews.view";

function CustomViews({ 
  chatSettings,
  onSubmit
}) {
  const customViews = chatSettings.custom_views || [];
  return <View
    customViews={customViews}
    onSubmit={onSubmit}
  />;

}

export default CustomViews;