import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useQuery } from "seed/gql";
import { useGet, usePost } from "seed/api";
import View from "components/presentation/flows/templates/Templates.view";

const defaultFilters = {
  template_categories: "all",
  template_type: ["USE_CASE", "SAMPLE"]
}


function Templates({ flow }) {

  const [filters, setFilters] = useState(defaultFilters);

  const reqTemplates = useGet('/flows/get_tree_template_flows', { ...filters });
  const reqCategories = useQuery(`{
    templateCategories {
      name
      description
      uiSettings
    }
  }`, "", {
    orderBy: "id"
  });

  const [callUseCase, reqUseCase] = usePost(`/flows/insert_tree_case`, {
    onCompleted: (data) => {
      const tempFlow = data.temp_flow;
      window.location.replace(`/p/${flow?.token}/flow_validation/${tempFlow?.id}`);

    },
    onError: (error) => {
      console.log(error);
      swal("Error", "Error al implementar la plantilla, por favor intente de nuevo mas tarde", "error");
    }
  });

  const onClickUseCase = (template) => {

    const rootNodeId = template?.id;
    callUseCase({
      root_node_id: rootNodeId,
      flow_id: flow.id
    })

  }

  const onClickFilterCategory = (category) => {
    setFilters({
      ...filters,
      template_categories: [category.id]
    })
    reqTemplates.refetch();
  }

  const onClickRemoveCategory = (event, category) => {
    event.stopPropagation();
    setFilters({
      ...filters,
      template_categories: filters.template_categories.filter((cat) => cat != category.id)
    })
    reqTemplates.refetch();
  }

  const templates = reqTemplates.data;
  const isTemplatesLoading = reqTemplates.loading;
  const isTemplateCategoriesLoading = reqCategories.loading;
  const templateCategories = reqCategories.data?.templateCategories ?? [];

  return (
    <View
      filters={filters}
      templates={templates}
      templateCategories={templateCategories}
      isTemplatesLoading={isTemplatesLoading}
      isTemplateCategoriesLoading={isTemplateCategoriesLoading}
      onClickFilterCategory={onClickFilterCategory}
      onClickRemoveCategory={onClickRemoveCategory}
      onClickUseCase={onClickUseCase}
    />
  );
}

Templates.propTypes = {};

export default Templates;