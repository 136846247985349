import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { usePost } from "seed/api";
import { useSet } from "seed/gql";
import { SET_EXECUTION } from "seed/gql/queries";
import View from "components/tmisc/executions/ExecutionHistory.view";

function ExecutionHistory({ flow = {}, status, user_id, placeholder = "No hay actividad reciente" }) {

  const history = useHistory();
  const [filterDate] = useState(moment().subtract(1, 'year').startOf('month').format("YYYY-MM-DD"));
  const [called, setCalled] = useState(false);

  const [callExecutions, reqExecutions] = usePost("/executions/get_executions", {
    onCompleted: () => setCalled(true)
  });

  const refetchExecutions = () => 
    callExecutions({
      status: status,
      flow_id: flow?.id,
      user_id: user_id,
      date: filterDate,
    });

  const [callCancel] = usePost("/executions/cancel_execution", {
    onCompleted: () => refetchExecutions()
  });

  const onResumeExecution = (execution) =>
    history.replace(`/flow/${execution.flow.id}/flow?resume=${execution.id}`);

  const onCancelExecution = (execution) =>
    callCancel({ execution_id: execution.id });

  const [callSetRead] = useSet(SET_EXECUTION);
  const { executions = [] } = reqExecutions.data || {};

  useEffect(() => {
    refetchExecutions();
  }, []);

  useEffect(() => {
    if (executions && executions.length > 0) {
      executions.filter((execution) => !execution.isRead).forEach((execution) => {
        callSetRead({ id: execution.id, isRead: true });
      });
    }
  }, [executions, callSetRead]);

  let sortedExecutions = executions.map((execution) => {

    let auxExecution = Object.assign({}, execution);
    auxExecution.executionSteps = Object.assign([], execution.executionSteps);
    auxExecution.executionSteps.sort((a, b) => {
      if (a.step < b.step) return -1;
      if (a.step > b.step) return 1;
      return 0;
    });

    return auxExecution;

  });

  const groupedData = {
    today: [],
    thisWeek: [],
    thisMonth: [],
    months: {}
  };

  const today = moment().startOf('day');
  const startOfWeek = moment().startOf('week');
  const startOfMonth = moment().startOf('month');
  const oneYearAgo = moment().subtract(1, 'year').startOf('month');

  let currentMonth = oneYearAgo.clone();
  const lastMonth = moment().subtract(1, 'month').startOf('month');
  while (currentMonth.isSameOrBefore(lastMonth, 'month')) {
    const monthKey = currentMonth.format('YYYY-MM');
    groupedData.months[monthKey] = [];
    currentMonth.add(1, 'month');
  }

  sortedExecutions.forEach(item => {
    const itemDate = moment(item.createdAt);

    if (itemDate.isSame(today, 'day')) {
      groupedData.today.push(item);
    } else if (itemDate.isSame(startOfWeek, 'week')) {
      groupedData.thisWeek.push(item);
    } else if (itemDate.isSame(startOfMonth, 'month')) {
      groupedData.thisMonth.push(item);
    } else if (itemDate.isAfter(oneYearAgo)) {
      const monthKey = itemDate.format('YYYY-MM');
      groupedData.months[monthKey].push(item);
    }
  });

  groupedData.months = Object.fromEntries(
    Object.entries(groupedData.months).sort((a, b) => b[0].localeCompare(a[0]))
  );

  if (reqExecutions.loading || !called)
    return <div className="d-md-block" style={{ padding: "0.72rem 0rem", minWidth: "285px" }}>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
        </div>
      </div>
    </div>

  return (
    <View
      data={groupedData}
      placeholder={placeholder}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
    />
  );
}

export default ExecutionHistory;