import React from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import FormSaveFlow from "components/start/flows/FormSaveFlow";
import FormSetFlow from "components/start/flows/FormSetFlow";
import ModelIcon from "components/util/helpers/ModelIcon";


const FlowsView = ({
  user,
  folder,
  flows,
  flowCount,
  setSelectedFolder,
  onCreateFlow,
  onEditFlow,
  onDeleteFlow,
  onClickDelete,
  onClickClone,
}) => (
  <div id="start-flows" className="card">
    <div id="start-flows-container" className="card-body mx-1 pb-3">
      <div id="start-flows-header" className={"d-flex flex-wrap align-items-center justify-content-between"}>

        <small class="mb-1 ml-1" style={{ fontSize: "1.07rem", color: "#505560" }}>
          <i class="fas fa-microchip" style={{ marginRight: "0.75rem" }}></i>
          Soluciones recientes <span className="text-primary">{folder ? "(" + folder.name + ")" : ""}</span>
        </small>

        {user.license?.name != "Test-ext" ?
          <div className="d-flex" style={{ marginTop: "-0.35rem" }}>
            <Link to={`${folder?.id ?? 0}/create_flow`}
              className={"btn btn-primary ml-2 mr-1 mt-2 mt-md-0 mb-2 mb-md-0"}
              style={{ visibility: flows.length > 0 ? "visible" : "hidden" }}>
              <i class="fas fa-plus mr-2"></i>Crear solución
            </Link>
          </div> : null}

      </div>

      {flows.length > 0 ? (
        <div id="flows" class="w-100">
          <div id="flows-container" class="d-flex flex-wrap">
            {flows.map((flow) => (
              <a href={`/p/${flow.token}`} key={flow.id}
                className={"flow " + (flows.length <= 2 ? "large" : "")}>
                <div className="card card-hover-shadow pb-0 px-0 animate__animated animate__fadeIn animate__faster">
                  <div className="d-flex justify-content-between align-items-center mb-0">
                    <div>
                      {function () {
                        const participants = flow?.participants ?? [];
                        const participant = participants.find((participant) => participant?.user?.id == user.id);
                        const isParticipant = participant != null && participant != undefined;

                        if (isParticipant) {
                          return (<span class="badge bg-soft-primary text-primary p-2 ml-3"
                            style={{ margin: "1.2rem 0" }}>Compartido</span>);
                        }
                      }()}
                    </div>

                    {flow.user.id == user.id && (
                      <div class="btn-group dropleft flow-settings">
                        <button type="button" class="btn btn-lighter text-center dropdown-toggle py-3 px-4 m-2 rounded-circle"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          onClick={e => e.preventDefault()}
                        >
                          <i class="fas fa-ellipsis-v" style={{ fontSize: "1.1em" }}></i>
                        </button>
                        <div class="dropdown-menu pr-1 flow-settings-content">
                        
                          <Link to={`/${flow.id}/edit_flow`} class="dropdown-item">
                            <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar solución
                          </Link>
                          <Link to={`/${flow.id}/share`} class="dropdown-item">
                            <i class="fa-solid fa-share dropdown-item-icon mr-1"></i> Compartir
                          </Link>
                          <hr className="my-2" />
                          <a class="dropdown-item" style={{ cursor: "pointer" }}
                            onClick={e => { e.preventDefault(); onClickClone(flow.id); }}>
                            <i class="tio-copy dropdown-item-icon mr-1"></i> Crear copia
                          </a>
                          <a class="dropdown-item" style={{ cursor: "pointer" }}
                            onClick={e => { e.preventDefault(); onClickDelete(flow.id); }}>
                            <i class="tio-delete dropdown-item-icon mr-1"></i> Eliminar
                          </a>

                        </div>
                      </div>
                    )}
                  </div>

                  <div class="card-body text-center flow-content"
                    style={{ marginTop: "-2.45rem", paddingBottom: "1.65rem" }}>
                    <div class="avatar avatar-border-lg"
                      style={{ width: "4.5rem", height: "4.5rem", marginBottom: "1.2rem" }}>
                      <ModelIcon style={{ width: "4.4rem" }} letter={flow.name[0]} />
                    </div>

                    <h3 className={"flow-name mt-0 mb-0"}>
                      <div class="text-dark h4 text-truncate w-100 d-inline-block mb-0 px-2"
                        style={{lineHeight: "1.35"}}>
                        {flow.name}
                      </div>
                    </h3>

                    <div class="flow-folder w-100">
                      <div className="text-truncate w-100 d-inline-block"
                        style={{ color: "#607580" }}>
                        <i class="far fa-folder"
                          style={{ display: flow.folder ? "inline-block" : "none", marginRight: "0.35rem" }}></i>
                        <span>{flow.folder?.name ?? 'General'}</span>
                      </div>
                    </div>

                  </div>

                </div>
              </a>
            ))

            }
          </div>

          {
            flowCount > 6 && flows.length >= 6 ?
              <div className="w-100">
                <div className="card card-hover-shadow pb-0 px-0 mr-1 mb-1">
                  <Link to={`/settings/flows`} class="text-center w-100">
                    <h3 className={"flow-name mt-0 mb-0"}>
                      <div class="h5 my-2"
                        style={{ color: "#707580" }}>
                        Ver todos
                      </div>
                    </h3>
                  </Link>
                </div>
              </div> : null
          }



        </div>
      ) : (
        <div id="no-flows" class="card w-100 shadow-none mt-2 mb-2">
          <div class="card-body">
            <div className="d-flex flex-column justify-content-center py-7">
              <span id="no-flows-title" className="text-center">No tienes soluciones registradas</span>
              <div className="d-flex justify-content-center">
                {user.license?.name != "Test-ext" ?
                  <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-primary w-50 w-md-25" href="#">
                    <i class="fas fa-plus mr-2"></i>Crear solución
                  </Link> : null}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>

    <ModalRoute
      width="500"
      height="500"
      path="/:folderId/create_flow"
      component={({ match }) =>
        <FormSaveFlow match={match} />}
    />

    <ModalRoute
      width="500"
      height="500"
      path="/:flowId/edit_flow"
      component={({ match }) =>
        <FormSetFlow match={match} onEdit={onEditFlow} onDelete={onDeleteFlow} />}
    />

  </div>
)

export default FlowsView;