import React from "react";
import View from "components/tmisc/organizations/users/Form.view";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import swal from "sweetalert";

const FormSet = ({ userId }) => {

    const [updateUser, setUpdateUser] = usePost("/users/update_user", {
        onCompleted: () => {
            swal("¡Éxito!", "Usuario actualizado exitosamente", "success").then(() => {
                window.location.href = "/app/organizations/users";
            });
        },
        onError: () => {
            swal("¡Error!", "Ha ocurrido un error", "error");
        }
    });

    const reqUser = useDetail(`
    {
        user {
            email
            firstName
            lastName
            phone
        }
    }`, userId);

    if (reqUser.loading) return <Loading />;
    if (reqUser.error) return "Error";

    const { user } = reqUser.data;

    const onSubmit = (values) => {
        values.user_id = userId;
        updateUser({ ...values });
    };

    return <View onSubmit={onSubmit} user={user} />;
}  

export default FormSet;