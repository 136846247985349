import React from "react";
import FormSave from "components/tmisc/organizations/orders/FormSave";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";

const OrdersView = ({ organization, deleteOrder, formatDate }) => (
  <div>
    <div className="card" style={{ minHeight: "50vh" }}>
      <div className="card-header">
        <h3 className="card-title mb-0">Órdenes</h3>
        <Link to={`/organizations/orders/${organization.id}/create`} className="btn btn-sm btn-primary">
          <i className="fa fa-plus"></i> Crear orden
        </Link>
      </div>
      <div className="card-body p-0">
        {(
          organization?.organizationParticipants?.flatMap((participant) =>
            participant.user?.orders ? participant.user.orders : []
          ) || []
        ).length === 0 ? (
          <div className="p-3 text-center">No hay órdenes registradas.</div>
        ) : (
          <>
            <div className="row border-bottom m-0 font-weight-bold py-2 px-2">
              <div className="col-md-2">Usuario</div>
              <div className="col-md-2">Fecha inicio</div>
              <div className="col-md-2">Fecha fin</div>
              <div className="col-md-2">Fecha de pago</div>
              <div className="col-md-2">Cantidad / Licencia</div>
              <div className="col-md-2">Acciones</div>
            </div>
            <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
              {organization?.organizationParticipants?.flatMap((participant) =>
                participant.user?.orders?.map((order, idx) => (
                  <div
                    key={`${participant.user.email}-${idx}`}
                    className="row m-0 px-2 border-bottom py-2"
                    style={{
                      backgroundColor: idx % 2 ? "#f9f9f9" : "transparent",
                    }}
                  >
                    <div className="col-md-2">
                      {participant.user.firstName} {participant.user.lastName}
                    </div>
                    <div className="col-md-2">
                      {order.type === "CREDIT" ? "-" : formatDate(order.startDate)}
                    </div>
                    <div className="col-md-2">
                      {order.type === "CREDIT" ? "-" : formatDate(order.endDate)}
                    </div>
                    <div className="col-md-2">
                      {formatDate(order.paymentDate)}
                    </div>
                    <div className="col-md-2">
                      {order.type === "LICENSE"
                        ? (order.license && order.license.name) || "-"
                        : order.type === "CREDIT"
                          ? order.quantity || "-"
                          : "-"}
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => deleteOrder(order.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
    <ModalRoute
      path="/organizations/orders/:organizationId/create"
      height="650"
      width="700"
      component={FormSave}
    />
  </div>
);

export default OrdersView;
