import React from "react";
import View from "components/tmisc/executions/charts/helpers/HeatmapChart.view";

const defaultOptions = {
  chart: {
    type: 'heatmap'
  },
  dataLabels: {
    enabled: false
  },
  colors: ["#008FFB"],
  title: {
    text: 'Actividad'
  },
}

function HeatmapChart({ data, options = defaultOptions, height = 350 }) {

  return (
    <View
      options={options}
      series={data}
      height={height}
    />
  );
}

export default HeatmapChart;