import React from "react";
import { Formik, Field, Form } from "formik";
import PermissionGridField from "components/util/helpers/PermissionField";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import CustomViews from "components/presentation/settings/CustomViews";

const ChatSettingsView = ({
  flow,
  models,
  collections,
  chatSettings,
  isAdmin,
  isDeveloper,
  onSubmit,
}) => (
  <div className="p-4 pt-6">

    <h2 className="mb-3">Configuración</h2>

    <Formik
      initialValues={{
        ...chatSettings,
        token: flow.token,
        collection_id: chatSettings.collection_id ?? '',
        model: chatSettings.model ?? 'OpenAI GPT-3.5 Turbo',
        show_sidenav: chatSettings.show_sidenav ?? true,
      }}
      onSubmit={onSubmit}>
      {({ values, setFieldValue }) =>
        <Form>

          {
            isAdmin &&
            <div>
              <label className="dropdown-header px-0 pt-3 text-dark">Permisos</label>
              <PermissionGridField
                fields={[
                  { name: 'show_sidenav', label: 'Mostrar menú lateral' },
                  { name: 'show_flows', label: 'Mostrar procesos' },
                  { name: 'show_share', label: 'Mostrar Compartir' },
                ]}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          }

          <label className="dropdown-header px-0 mb-2 pt-3 text-dark">Chat</label>

          <span className="mb-3 d-block font-italic">
            Para personalizar las respuestas, ingresa los siguientes datos:
          </span>

          <div className="form-group mb-4">
            <span>Área</span>
            <Field
              type="text"
              name="area"
              maxlength="512"
              className="form-control"
            />
          </div>

          <div className="form-group mb-4">
            <span>Puesto</span>
            <Field
              type="text"
              name="job"
              maxlength="512"
              className="form-control"
            />
          </div>

          <div className="form-group mb-4">
            <span>Actividades que realizas</span>
            <Field
              as="textarea"
              name="activities"
              maxlength="512"
              className="form-control"
              rows={3}
            />
          </div>

          {
            isDeveloper &&
            <div>
              <label className="dropdown-header px-0 pt-3 text-dark">Proceso</label>
              <div className="form-group mb-4">
                <span>Token</span>
                <Field
                  type="text"
                  name="token"
                  className="form-control"
                />
              </div>
            </div>
          }

          {
            isDeveloper && <>
              <label className="dropdown-header px-0 mb-2 pt-3 text-dark">Desarrollador</label>
              <div class="form-group mb-4">
                <label class="control-label" for="folder">Modelo</label>
                <Field
                  as="select"
                  name="model"
                  class="form-control"
                >
                  <option value="">Seleccionar modelo</option>
                  {models.map(model =>
                    <option
                      key={model}
                      value={model}
                    >
                      {model}
                    </option>
                  )}
                </Field>
              </div>

              <div class="form-group mb-4">
                <label class="control-label" for="folder">Tabla</label>
                <Field
                  as="select"
                  name="collection_id"
                  class="form-control"
                >
                  <option value="">Seleccionar tabla</option>
                  {collections.map(collection =>
                    <option
                      key={`collection-${collection.id}`}
                      value={collection.id}
                    >
                      {collection.name}
                    </option>
                  )}
                </Field>
              </div>

              <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="override_prompt" id="override_prompt"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="override_prompt">
                    Sobreescribir prompt
                  </label>
                </div>
              </div>

              {values.override_prompt == true ?
                <div class="form-group mb-4">
                  <Field as="textarea" name="prompt" id="prompt" rows={30}
                    class="form-control form-control" />
                </div> : null}

            </>
          }

          <div className="d-flex align-items-center justify-content-between mt-2">

            <a href="/app/settings" className="btn btn-lighter-white mb-5">
              <i className="fas fa-cog mr-2"></i> Ajustes generales
            </a>

            {
              isDeveloper &&
                <Link
                  to="/custom"
                  className="btn btn-lighter-white mb-5"
                >
                  <i className="fas fa-pager mr-2"></i>
                  Vistas personalizadas
                </Link>
            }

          </div>

          <button type="submit" class="btn btn-primary w-100">Guardar</button>

        </Form>}
    </Formik>

    <ModalRoute
        path="/custom"
        width={1200}
        height={window.innerHeight - 100}
        flow={flow}
        onSubmit={onSubmit}
        chatSettings={chatSettings}
        component={CustomViews}
      />

  </div>
);

export default ChatSettingsView;