import React from "react";
import { Formik, Field, Form } from "formik";
import { ScriptTag } from "seed/helpers";
import { Loading } from "seed/helpers";
import StyleColor from "components/util/helpers/StyleColor"


const SignupView = ({ initialValues, error, isLoading, formikRef, onSubmit }) => (
  <main id="content" role="main" class="main pl-0">
    <StyleColor />
    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2" src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster">
            <div class="card-body">
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {() =>
                  <Form>
                    <div class="text-center">
                      <div class="mb-5">
                        <h1 class="display-4">Registrarse</h1>
                      </div>
                    </div>

                    {/* Firstname and Lastname */}
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label class="input-label d-flex" for="firstName">
                          Nombre(s) <span className='text-danger ml-1'>*</span>
                        </label>
                        <Field type="text" name="first_name" id="firstName"
                          class="form-control form-control-lg" tabindex="1"
                          placeholder="Nombre(s)" required autofocus="1" />
                      </div>
                      <div className="col-md-6">
                        <label class="input-label d-flex" for="lastName">
                          Apellidos <span className='text-danger ml-1'>*</span>
                        </label>
                        <Field type="text" name="last_name" id="lastName"
                          class="form-control form-control-lg" tabindex="1"
                          placeholder="Apellidos" required autofocus="1" />
                      </div>
                    </div>

                    {/* Email */}
                    <div class="form-group">
                      <label class="input-label d-flex" for="email">
                        Correo electrónico <span className='text-danger ml-1'>*</span>
                      </label>
                      <Field type="email" name="email" id="email"
                        class="form-control form-control-lg" tabindex="1"
                        placeholder="email@address.com" required autofocus="1" />
                    </div>

                    {/* Company and Phone */}
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label class="input-label d-flex" for="company">
                          Empresa <span className='ml-1' style={{ color: "#a0a5b0", fontSize: "0.75rem" }}>(Opcional)</span>
                        </label>
                        <Field type="text" name="company" id="company"
                          class="form-control form-control-lg" tabindex="1"
                          placeholder="Empresa" autofocus="1" />
                      </div>
                      <div className="col-md-6">
                        <label class="input-label d-flex" for="phone">
                          Teléfono <span className='ml-1' style={{ color: "#a0a5b0", fontSize: "0.75rem" }}>(Opcional)</span>
                        </label>
                        <Field type="text" name="phone" id="phone"
                          class="form-control form-control-lg" tabindex="1"
                          placeholder="XX-XXXX-XXXX" cautofocus="1" />
                      </div>
                    </div>

                    {/* Password */}
                    <div class="form-group">
                      <label class="input-label d-flex" for="password">
                        Contraseña <span className='text-danger ml-1'>*</span>
                      </label>
                      <div class="input-group input-group-merge">
                        <Field type="password" name="password" id="password"
                          class="js-toggle-password form-control form-control-lg" tabindex="2"
                          placeholder="Contraseña" required minlength="8"
                          data-hs-toggle-password-options={`{
                                "target": "#changePassTarget",
                                "defaultClass": "tio-hidden-outlined",
                                "showClass": "tio-visible-outlined",
                                "classChangeTarget": "#changePassIcon"
                              }`} />
                        <div id="changePassTarget" class="input-group-append">
                          <a class="input-group-text">
                            <i id="changePassIcon" class="tio-visible-outlined"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <button type="submit" class="btn btn-lg btn-block btn-primary">
                      Registrarse
                    </button>

                    {error ?
                      <div class="alert alert-soft-danger text-center mt-3" role="alert">
                        {error}
                      </div> : null}

                  </Form>}
              </Formik>
            </div>
          </div>

        </div>
      </div>

      {isLoading ? <div style={{ marginTop: "-30rem" }}><Loading /></div> : null}

    </div>

    <ScriptTag content={`
      $('.js-toggle-password').each(function () {
        new HSTogglePassword(this).init()
      });
    `} />

  </main>
)

export default SignupView;