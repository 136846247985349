import React from "react";
import { useDetail, useSet } from "seed/gql";
import { SET_USER, USER } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/tmisc/settings/Customization.view";


function Customization() {
  const userId = sessionStorage.getItem('id');
  const [callSet, setCallSet] = useSet(SET_USER, {
    onCompleted: () => {
      window.location.reload();
    },
    onError: () => {
      alert("Ha ocurrido un error");
    }
  });

  const reqUser = useDetail(USER, userId);

  const onSubmit = (values) => {
    callSet({
      id: userId,
      accountSettings: {
        ...user.accountSettings,
        theme: {
          primaryColor: values.primaryColor,
          accentColor: values.accentColor
        },
        showAnimations: values.showAnimations,
        showPagination: values.showPagination,
        showHelps: values.showHelps,
        logo: values.logo
      }
    });
  }

  if (reqUser.loading) return <Loading />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;


  return (
    <View
      user={user}
      onSubmit={onSubmit}
    />
  );
}


export default Customization;