import React from "react";

const PermissionGridField = ({ fields, values, setFieldValue }) => {

  const permissions = [
    {key: 'ADMIN', label: 'Admin'}, 
    {key: 'EDITOR', label: 'Editor'}, 
    {key: 'VIEWER', label: 'Lector'}
  ];

  const getDefaultValue = (name) => {
    
    const keys = name.split('.');
    let value = values;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (value == undefined)
        return permissions.reduce((acc, permission) => {
          acc[permission.key] = true;
          return acc;
        }, {});
    }

    return value;

  }

  return <div>
    <div className="form-group mb-4">
      <table className="table table-sm table-borderless table-hover mb-0">
        <thead>
          <tr>
            <th>
            </th>
            {
              permissions.map(permission =>
                <th 
                  key={permission.key} 
                  className="text-center"
                >
                  {permission.label}
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            fields.map(field =>
              <tr key={field.name}>
                <td>
                  {field.label}
                </td>
                {
                  permissions.map(permission =>
                    <td key={`${field.name}-${permission.key}`} className="text-center">
                      <div className="custom-control custom-switch">
                        <input 
                          type="checkbox" 
                          id={`${field.name}-${permission.key}`}
                          className="custom-control-input"
                          defaultChecked={getDefaultValue(field.name)[permission.key]}
                          onChange={() => {
                            const newValues = getDefaultValue(field.name);
                            newValues[permission.key] = !newValues[permission.key];
                            setFieldValue(field.name, newValues);
                          }} />
                        <label className="custom-control-label" for={`${field.name}-${permission.key}`}>
                        </label>
                      </div>
                    </td>
                  )
                }
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  </div>;

}

export default PermissionGridField;