import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { API_URL } from "settings";
import { ColumnTypes } from "components/collections/util/datatypes";
import { getDate, getObjectComponent } from "components/collections/util/format";
import { Modal } from "seed/helpers";
import SearchCollection from "components/collections/helpers/SearchCollection";

const Cell = ({
  column,
  value,
  isReadOnly,
  isActive,
  showFilters,
  setIsFilterModalShown,
  setFilterDefault,
  onClick = () => { },
  onChange = () => { },
  onDelete = () => { },
  onPreviewFile = () => { },
  onTypeEnter = () => { },
  onInsert = () => { },
}) => {

  const cellRef = useRef(null);
  const contextMenuRef = useRef(null);
  const inputRef = useRef(null);
  const [expanded, setExpanded] = useState(column.type === ColumnTypes.STRING &&  ((value?.split("\n").length??1) > 1 || value?.length > 150));
  const [showSearch, setShowSearch] = useState(false);
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isCollectionDetail, setIsCollectionDetail] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const componentId = useRef(Math.random().toString(36).substring(7));

  let borderStyles = {};
  if(!isReadOnly) borderStyles = {border: isActive ? "1px solid #9FC8F4" : "1px solid #E7EAF3"};

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (cellRef?.current &&
        !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current
      ) {
        setIsContextMenuShown(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isActive || isReadOnly || column.type == ColumnTypes.OBJECT) return;
    inputRef?.current?.focus();
  }, [isActive]);

  const onChangeFile = (e) => {

    const onUploadFile = (formWrapper) => {
      let url = `${API_URL}/files/`;
      $.ajax({
        url: url,
        type: "POST",
        headers: {
          "Authorization": `Token ${sessionStorage.getItem("token")}`
        },
        data: new FormData(formWrapper),
        cache: false,
        contentType: false,
        processData: false,
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          myXhr.upload.addEventListener("progress", function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              setUploadProgress(parseInt(percentComplete));
            }
          }, false);
          return myXhr;
        },
        success: (res) => {
          onChange(res);
          setIsUploading(false);
        },
        error: (error) => {
          setIsUploading(false);
          console.log(error);
        }
      });
    };

    setIsUploading(true);
    onUploadFile(e.target.form);

  }

  const onClickSelectFile = () => {

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("enctype", "multipart/form-data");

    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("name", "file");
    input.setAttribute("accept", ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.jpg,.jpeg,.png,.gif,.mp4,.mp3,.zip,.rar,.7z");
    input.setAttribute("style", "display: none");
    input.setAttribute("ignore-onclickoutside", componentId.current);
    input.addEventListener("change", (e) => onChangeFile(e));

    form.appendChild(input);
    document.body.appendChild(form);

    input.click();

  }

  const onCopy = () => {

    let copyValue = value;
    if(column.type == ColumnTypes.FILE) copyValue = value?.url;
    else if(column.type == ColumnTypes.OBJECT) copyValue = JSON.stringify(value);
    else if(column.type == ColumnTypes.DATE) copyValue = getDate(value, "DD/MM/YYYY");
    else if(column.type == ColumnTypes.BOOLEAN) copyValue = value ? "Si" : "No";
    else if(column.type == ColumnTypes.COLLECTION) copyValue = value?.full_folio;
    else value = value?.toString();

    navigator.clipboard.writeText(copyValue);
    setIsContextMenuShown(false);

  }

  const onFilter = () => {
    setIsFilterModalShown(true);
    setIsContextMenuShown(false);
    setFilterDefault(column.name);
  }

  if (column.visible === false) return null;

  let maxLineLength = 0;
  const cellStyles = {};

  if(column.type == ColumnTypes.STRING) {
    maxLineLength = value?.split("\n").reduce((a, b) => a.length > b.length ? a.length : b.length, 0)
    cellStyles.width = (maxLineLength * 10) + "px";
  }

  isReadOnly = isReadOnly || column.internal;
  
  return (
    <td
      ref={cellRef}
      className="m-0 py-0 px-2 border table-record"
      onClick={onClick}
      style={{ 
        position: "relative",
        ...cellStyles
      }}
      onContextMenu={(e) => {
        
        e.preventDefault();
        setIsContextMenuShown(true);

        const x = e.clientX - cellRef.current.getBoundingClientRect().x;
        const y = e.clientY - cellRef.current.getBoundingClientRect().y;
        setCoords({ x, y });

      }}
    >

      <div className="d-flex align-items-center w-100 h-100">
        <div className="text-truncate d-flex flex-wrap align-items-center h-100 w-100">

          {
            column.type === ColumnTypes.FILE && <>
              <div
                className="file-field d-flex justify-content-between align-items-center w-100 h-100 px-2"
                style={{ ...borderStyles }}
              >
                  {
                    isUploading
                      ? <div className="d-flex align-items-center">
                          <i class="fas fa-spinner fa-spin mr-2"></i>
                          {
                            uploadProgress != 100 
                              ? <span>Subiendo archivo ({uploadProgress}%)</span>
                              : <span>Procesando archivo...</span>
                          }
                        </div>
                      : <>
                          {
                            value
                              ? <span
                                  className="d-flex align-items-center btn-lighter px-2 py-1"
                                  onClick={() => onPreviewFile(value)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
                                  <span className="text-truncate">
                                    {
                                      function(){
                                        let name = value.name ?? "Archivo";
                                        name = value.name ?? "Archivo";
                                        const compress = name?.length > 30;
                                        if(compress) return name.substring(0, 30) + "...";
                                        return name;
                                      }()
                                    }
                                  </span>
                                </span>
                              : <span className="text-truncate">
                                  Sin archivo
                                </span>
                          }
                        </>
                  }
                  {
                    !isReadOnly &&
                      <i
                        className="fas fa-pencil-alt ml-2 mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={onClickSelectFile}
                      />
                  }
              </div>
            </>
          }

          {
            column.type === ColumnTypes.STRING && <>
              {
                isActive && 
                  <>
                    <div className="d-flex justify-content-between align-items-center w-100 h-100">
                      {
                        expanded
                          ? <textarea
                              ref={inputRef}
                              className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                              rows={value?.split("\n").length??1}
                              defaultValue={value}
                              disabled={isReadOnly}
                              ignore-onclickoutside={componentId.current}
                              // onChange={(e) => onChange(e.target.value)}
                              onBlur={(e) => onChange(e.target.value)}
                              onKeyUp={(e) => {
                                if (e.key === "Enter" && e.shiftKey) 
                                  onTypeEnter();
                              }}
                            />
                          : <input
                              ref={inputRef}
                              type="text"
                              className="form-control d-block w-100 h-100"
                              defaultValue={value}
                              disabled={isReadOnly}
                              ignore-onclickoutside={componentId.current}
                              // onChange={(e) => onChange(e.target.value)}
                              onBlur={(e) => onChange(e.target.value)}
                              onKeyUp={(e) => {
                                if (e.key === "Enter")
                                  onChange(e.target.value + "\n");
                              }}
                            />
                        }
                      <span
                        className="d-flex align-items-center ml-2"

                      >
                        {
                          expanded
                            ? <i className="fas fa-compress-alt text-primary" style={{ cursor: "pointer" }} onClick={() => setExpanded(false)} />
                            : <i className="fas fa-expand-alt text-primary" style={{ cursor: "pointer" }} onClick={() => setExpanded(true)} />
                        }
                      </span>
                    </div>
                  </>
              }

              {
                !isActive &&
                  <div> 
                    {
                      function(){
                        const compress = value?.length > 30;
                        if(compress) return value?.toString().substring(0, 30) + "...";
                        return value?.toString();
                      }()
                    }
                  </div>
              }

            </>
          }

          {
            column.type === ColumnTypes.FLOAT && <>
              {
                isActive && !isReadOnly &&
                  <input
                    ref={inputRef}
                    type="number"
                    className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                    value={value}
                    ignore-onclickoutside={componentId.current}
                    onChange={(e) => onChange(parseFloat(e.target.value))}
                    onKeyUp={(e) => {

                      if (e.key === "Enter")
                        onTypeEnter();

                      // Validate if the input is a number
                      if (e.key == "e")
                        e.preventDefault();

                    }}
                    onKeyDown={(e) => {

                      // Validate if the input is a number
                      if (e.key == "e")
                        e.preventDefault();

                    }}
                  />
              }
              {
                !isActive &&
                  <div>
                    {value}
                  </div>
              }
            </>
          }

          {
            column.type === ColumnTypes.DATE && <>
              {
                isActive && !isReadOnly &&
                  <input
                    ref={inputRef}
                    type="date"
                    className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                    value={getDate(value, "YYYY-MM-DD")}
                    ignore-onclickoutside={componentId.current}
                    onChange={(e) => onChange(getDate(e.target.value, "iso"))}
                  />
              }
              {
                isActive && isReadOnly &&
                  <input
                    ref={inputRef}
                    type="text"
                    className="form-control d-block w-100 h-100"
                    value={getDate(value, "DD/MM/YYYY")}
                    ignore-onclickoutside={componentId.current}
                    disabled
                  />
              }
              {
                !isActive &&
                  <>{getDate(value, "DD/MM/YYYY")}</>
              }
            </>
          }

          {
            column.type === ColumnTypes.BOOLEAN && <>
              {
                <div
                  className="boolean-field d-flex justify-content-center align-items-center w-100 h-100"
                  style={{ ...borderStyles }}
                >
                  <input
                    ref={inputRef}
                    type="checkbox"
                    className="form-check-input d-flex flex-wrap m-0 w-50 h-50"
                    ignore-onclickoutside={componentId.current}
                    defaultChecked={value}
                    disabled={isReadOnly || !isActive}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                </div>
              }
            </>
          }

          {
            column.type === ColumnTypes.COLLECTION && <>
              <div
                className="collection-field d-flex justify-content-between align-items-center w-100 h-100 px-3"
                style={{ ...borderStyles }}
              >

                {
                  value
                    ? <span className="d-flex align-items-center px-2 py-1">
                      <span 
                        className="text-truncate"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsCollectionDetail(true);
                          setShowSearch(true);
                        }}
                      >
                        {
                          function () {

                            if(!value) return "No encontrado";

                            const childKeys = Object.keys(value?.data??{}).filter(key => 
                              key[0] != "_" && typeof value?.data[key] != "object");
                              
                            if(childKeys.length == 0) return value?.full_folio;
                            return value?.full_folio + " - " + ((value?.data??{})[childKeys[0]] ?? "");
                            
                          }()
                        }
                      </span>
                    </span>
                    : <span className="text-truncate px-2">Sin selección</span>
                }

                {
                  !isReadOnly && 
                    <i
                      className="fas fa-pencil-alt ml-2 mt-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowSearch(true)}
                    />
                }

              </div>
            </>
          }

          {
            column.type === ColumnTypes.ENUM && <>
              {
                isActive && !isReadOnly &&
                  <select
                    ref={inputRef}
                    className="enum-field form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                    ignore-onclickoutside={componentId.current}
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <option value="">Seleccionar...</option>
                    {
                      column?.structure?.values?.map((item, i) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))
                    }
                  </select>
              }
              {
                !isActive &&
                  <>{value}</>
              }
            </>
          }

          {
            column.type == ColumnTypes.OBJECT && <>
              {getObjectComponent(value)}
            </>
          }

        </div>
      </div>

      {
        isContextMenuShown && (
          <div
            ref={contextMenuRef}
            class="dropdown show"
            style={{
              position: "absolute",
              top: coords.y,
              left: coords.x,
              zIndex: 1000,
            }}
          >
            <div class="dropdown-menu show" aria-labelledby="add-column-dropdown">

              <a 
                className="dropdown-item"
                href="#"
                ignore-onclickoutside={componentId.current}
                onClick={onCopy}
              >
                Copiar
              </a>

              {
                showFilters &&
                  <a 
                    className="dropdown-item"
                    href="#"
                    ignore-onclickoutside={componentId.current}
                    onClick={onFilter}
                  >
                    Filtrar
                  </a>
              }

              {
                !isReadOnly &&
                <a
                  className="dropdown-item"
                  href="#"
                  ignore-onclickoutside={componentId.current}
                  onClick={(e) => {
                    onClick(e);
                    setIsContextMenuShown(false);
                  }}
                >
                  Editar
                </a>
              }

              {
                !isReadOnly &&
                <a
                  className="dropdown-item"
                  href="#"
                  ignore-onclickoutside={componentId.current}
                  onClick={() => {
                    setIsContextMenuShown(false);
                    onInsert();
                  }}
                >
                  Insertar fila
                </a>
              }

              {
                !isReadOnly &&
                <a
                  className="dropdown-item"
                  href="#"
                  ignore-onclickoutside={componentId.current}
                  onClick={() => {
                    setIsContextMenuShown(false);
                    onDelete();
                  }}
                >
                  Eliminar fila
                </a>
              }

            </div>
          </div>
        )
      }

      {
        showSearch && column?.structure?.collection_id &&
        <Modal
          width="950"
          height="500"
          onClose={() => setShowSearch(false)}
          isCollectionDetail={isCollectionDetail}
          column={column}
          value={value}
          setShowSearch={setShowSearch}
          setIsCollectionDetail={setIsCollectionDetail}
          onChange={onChange}
          onPreviewFile={onPreviewFile}
          component={SearchCollection}
        />
      }

    </td>
  );
}

export default Cell;