import React from "react";
import { useLocation } from "react-router-dom";
import { usePost } from "seed/api";
import View from "components/tmisc/use_cases/UseCase.view";
import { Analytics } from "components/util/analytics";

function UseCase({ match }) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const templateName = match?.params?.template_name;

  const [callStartCase, reqStartCase] = usePost('/flows/start_case', {
    onCompleted: (data) => {

      const hiddenUserId = localStorage.getItem("hiddenUserId");
      const segment = localStorage.getItem("userProfile")
			
			Analytics.Identify(hiddenUserId || data.user, {
				'hidden_user_id': hiddenUserId,
				'user_id': data.user,
				'segment': segment
			});

      const isDev = localStorage.getItem("userProfile") == "dev";
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("id", data.user);
      localStorage.setItem("token", data.token);
      localStorage.setItem("id", data.user);
      sessionStorage.setItem("developer_mode", isDev);
      localStorage.setItem("developer_mode", isDev);
      window.location.replace(`/app/studio/${data.flow_id}`);

    },
    onError: (error) => {
      alert("Ha ocurrido un error, prueba de nuevo")
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/use_cases");
    },
    includeAuth: false
  });

  const onChangeCaptcha = (captcha) => {
    callStartCase({
      template_name: templateName,
      title: searchParams.get("title", ""),
      captcha: captcha,
      user_id: sessionStorage.getItem("id"),
      user_profile: localStorage.getItem("userProfile"),
      lead_token: (document.cookie.match(/t=([^;]*)/)??[])[1]
    })
  }

  return <View onChangeCaptcha={onChangeCaptcha} />
}

export default UseCase;