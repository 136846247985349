import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory, useLocation } from 'react-router-dom';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/studio/board/node_form/NodeForm.view";


function CreateNodeForm({ match, flowId, activePageId, ignoreSamplePicker = false, onSubmit }) {

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const actionId = match?.params?.actionId;
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDetailsShown, setIsDetailsShown] = useState(searchParams.get("flip") == "true");
  if (!actionId) window.location.href = "/app";

  const reqAction = useDetail(`{
    action {
      name 
      inputStructure
      outputStructure
      uiSettings
      app {
        uiSettings
      }
    }
  }`, actionId, {
    onCompleted: (data) => {
      const action = data.action;
      const newValues = {};
      const inputs = action?.uiSettings.form?.fields ?? [];
      for (const input of inputs) {
        let defaultValue = input.default
        if (defaultValue != null) {
          if (defaultValue == "date_now") defaultValue = moment().format("YYYY-MM-DD")
          if (defaultValue == "datetime_now") defaultValue = moment().format("YYYY-MM-DD HH:mm")
          newValues[input.name] = !input.multiple ? defaultValue : [defaultValue]
        }
      }

      if (searchParams.get("default_values") != null) {
        const defaultValues = JSON.parse(searchParams.get("default_values"))
        for (const key in defaultValues) {
          newValues[key] = defaultValues[key];
        }
      }
      setInitialData(newValues);
      setLoading(false)
    },
    onError: () => history.replace(`/flow`)
  });

  useEffect(() => {
    reqAction.refetch();
  }, [actionId])

  if (loading) return <PlaceholderView />;
  if (reqAction.error) return "Error";

  const { action = {} } = reqAction.data;

  const onOpenDetail = () => setIsDetailsShown(true);
  const onCloseDetail = () => setIsDetailsShown(false);

  const onSubmitForm = (values) => {

    const extraAttributes = {}

    if (searchParams.get("parent_id") != null)
      extraAttributes.parent_id = parseInt(searchParams.get("parent_id"));

    if (searchParams.get("position") != null)
      extraAttributes.position = searchParams.get("position");

    if (searchParams.get("mode") != null)
      extraAttributes.mode = searchParams.get("mode");

    if (searchParams.get("x") != null && searchParams.get("y") != null) {
      const x = parseInt(searchParams.get("x"))
      const y = parseInt(searchParams.get("y"))
      onSubmit(values, action.id, { x: x, y: y }, extraAttributes);
    } else {
      onSubmit(values, action.id, null, extraAttributes);
    }
  }

  return (
    <View
      action={action}
      flowId={flowId}
      activePageId={activePageId}
      initialData={initialData}
      ignoreSamplePicker={ignoreSamplePicker}
      isDetailsShown={isDetailsShown}
      onOpenDetail={onOpenDetail}
      onCloseDetail={onCloseDetail}
      onSubmit={onSubmitForm}
    />
  );

}

const PlaceholderView = () =>
  <div className="card pb-10">
    <div className="card-header">
      &nbsp;
    </div>
    <div className="card-body py-10">
      <Loading />
    </div>
  </div>


export default CreateNodeForm;