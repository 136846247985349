import React from "react";
import { Formik, Field, Form } from "formik";
import PermissionGridField from "components/util/helpers/PermissionField";

const TreeSettingsView = ({ 
    flowId, 
    nodeId, 
    initialValues, 
    showStudioButton,
    onSubmit 
  }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-header-title">
        Editar proceso
      </h3>
    </div>

    <div className="card-body">
      <div>
        <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) =>
          <Form>

            <div className="form-group mb-4">
              <label className="input-label w-100" for="rootName">
                Nombre del proceso
                <span className="text-danger ml-1">*</span>
              </label>
              <Field
                type="text"
                name="rootName"
                className="form-control"
                style={{ 
                  paddingLeft: "1.2rem",
                  paddingRight: "1.2rem",
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label className="input-label w-100" for="rootDescription">
                Descripción <span className="text-muted">(opcional)</span>
              </label>
              <Field
                as="textarea"
                id="rootDescription"
                name="rootDescription" 
                rows="3" 
                class="form-control"
              />
            </div>
            
            <label className="input-label w-100" for="rootDescription">
              Permisos
            </label>
            <PermissionGridField
              fields={[{name: "rootSettings.execute", label: "Ejecutar proceso"}]}
              values={values}
              setFieldValue={setFieldValue}
            />

            {showStudioButton ?
              <a className="btn btn-outline-primary mb-2" href={`/app/studio/${flowId}`}>
                <i class="fas fa-layer-group mr-2" aria-hidden="true"></i> Ver en Workix studio</a> : null}

            <button type="submit" class="btn btn-primary w-100 mt-3">Guardar</button>

          </Form>}
        </Formik>
      </div>
    </div>
  </div>
);

export default TreeSettingsView;