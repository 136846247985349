import React, { useEffect, useState } from "react";
import { useGetCall, usePost } from "seed/api";
import { APP_URL, MICROSOFT_CLIENT_ID, GOOGLE_CLIENT_ID } from "settings";
import View from "components/tmisc/auth/Login.view";


function Login(props) {
  const params = new URLSearchParams(props.location.search);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);

  const [callAuth] = useGetCall("/auth/user", "", {
    onCompleted: data => {

      if (!data.token_verified || data.is_anonymous){
        setError(data.is_anonymous ? 
          "Usuario o contraseña incorrectos" : "Su cuenta no ha sido verficada, por favor revise su correo para verificarla.")
        sessionStorage.clear();
        return
      }

      if (rememberMe) localStorage.setItem("developer_mode", data.developer_mode);
      sessionStorage.setItem("developer_mode", data.developer_mode);

      const next = localStorage.getItem("next")
      localStorage.removeItem("next")
      window.location.replace(params.get("next") ?? next ?? "/app")
    }
  });

  const onLogin = data => {
    const isInsideExtension = localStorage.getItem("isInsideExtension")
    if (rememberMe || isInsideExtension == "true") {
      localStorage.setItem("token", data.key);
      localStorage.setItem("id", data.user);
    }
    sessionStorage.setItem("token", data.key);
    sessionStorage.setItem("id", data.user);
    callAuth({ user_id: data.user });
  }

  const [callSSO, reqSSO] = usePost("/users/login_sso", {
    onCompleted: data => onLogin(data),
    onError: () => setError("Cuenta o usuario no registrada"),
    includeAuth: false
  })

  const [callLogin, reqLogin] = usePost("/auth/login", {
    onCompleted: data => onLogin(data),
    onError: () => setError("Usuario o contraseña incorrectos"),
    includeAuth: false
  });


  useEffect(() => {

    const code = params.get("code");
    const next = params.get("next");
    if (next) localStorage.setItem("next", params.get("next"))
    if (code) return callSSO({ code: code, app: code.startsWith("M.") ? "Microsoft" : "Google" });

    if (localStorage.getItem("id") != null) {
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
    callAuth({ user_id: localStorage.getItem("id") });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
  const onSubmit = values => {
    const { email, password, rememberMe } = values;
    setRememberMe(rememberMe);
    callLogin({ email: email, password: password });
  };

  const onMicrosoftLogin = () =>
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${APP_URL}/login&response_mode=query&scope=openid%20profile%20email%20User.Read%20offline_access`;

  const onGoogleLogin = () =>
    window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${APP_URL}/login&scope=openid%20profile%20email`

  const isLoading = reqLogin.loading || reqSSO.loading;

  return (
    <View
      error={error}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onGoogleLogin={onGoogleLogin}
      onMicrosoftLogin={onMicrosoftLogin}
    />
  );
}

export default Login;
