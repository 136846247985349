import React, { useEffect } from "react";
import View from "components/studio/toolbox/guides/Guides.view";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";

function Guides() {

    const qTutorials = useQuery(`{
        tutorials {
            name
            description
            media
            settings
            thumbnailPath
            categoryId{
                name
                description
            }
            tagsIds{
                name
            }
        }
    }`);

    const qCategories = useQuery(`{
        tutorialCategories {
            name
            description
        }
    }`);

    if (qTutorials.loading) return <Loading />;
    if (qTutorials.error) return "Error";
    if (qCategories.loading) return <Loading />;
    if (qCategories.error) return "Error";

    const { tutorials } = qTutorials.data;
    const { tutorialCategories } = qCategories.data;

    return <View tutorials={tutorials} tutorialCategories={tutorialCategories} />;
}

export default Guides;