import React from "react";
import View from "components/tmisc/organizations/users/orders/Form.view";
import { usePost } from "seed/api";
import swal from "sweetalert";

const FormSave = ({ userId }) => {

    const [callSave, setCallSave] = usePost("/orders/create_order", {
        onCompleted: () => {
            swal({ text: "Orden creada exitosamente", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/users";
            });
        },
        onError: () => {
            swal({ text: "No se ha podido crear la orden.", icon: "error" });
        }
    });

    const onSubmit = (values) => {
        values.user_id = userId;
        callSave({ ...values });
    }

    return <View onSubmit={onSubmit} />;
}

export default FormSave;