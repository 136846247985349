import React, { useState, useEffect, useRef } from "react";
import { ColumnTypes, ColumnTypeIcons, ColumnTypeLabels } from "components/collections/util/datatypes";
import HelpMessage from "components/util/helpers/HelpMessage";

const HeaderCell = ({
  style = {},
  column,
  filters,
  sorting,
  isReadOnly = false,
  isActive = false,
  showFilters = true,
  showSorting = true,
  setIsFilterModalShown,
  setIsSortingModalShown,
  setFilterDefault,
  setZipFieldName,
  setIsZipModalShown,
  onChange = () => { },
  onDelete = () => { },
  onDownloadZip = () => { },
  onShowEditModal = () => { },
  setSelectedCell = () => { },
}) => {

  const cellRef = useRef(null);
  const contextMenuRef = useRef(null);
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const componentId = useRef(Math.random().toString(36).substring(7));
  const isFilterActive = column.name && JSON.stringify(filters).includes(column.name);
  const isSortingActive = column.name && JSON.stringify(sorting).includes(column.name);

  useEffect(() => {

    document.addEventListener("click", (e) => {
      if (cellRef?.current && !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current) {
        setIsEditing(false);
      }
    });

    // every right click will close the context menu
    document.addEventListener("mousedown", (e) => {
      if (cellRef?.current &&
        !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current
      ) {
        setIsContextMenuShown(false);
      }
    });

  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    setTimeout(() => {
      if (isActive && !isReadOnly) setIsEditing(true);
    }, 15);
  }, [isActive, isReadOnly]);

  const onClick = (e) => {
    if (isReadOnly) return;
    setIsEditing(true);
    e.stopPropagation();
  }

  const onFilter = () => {
    setIsFilterModalShown(true);
    setIsContextMenuShown(false);
    setFilterDefault(column.name);
  }

  const onSorting = () => {
    setIsSortingModalShown(true);
    setIsContextMenuShown(false);
  }

  const onContextMenu = (e) => {

    e.preventDefault();
    e.stopPropagation();
    setIsContextMenuShown(true);

    const x = e.clientX - cellRef.current.getBoundingClientRect().x;
    const y = e.clientY - cellRef.current.getBoundingClientRect().y;
    setCoords({ x, y });

  }

  const onChangeLabel = (e) => {
    setIsEditing(false);
    onChange({
      ...column,
      label: e.target.value,
    });
  }

  const _onShowEnumModal = (e) => {
    onShowEditModal(true);
    setIsContextMenuShown(false);
    setIsEditing(false);
    setSelectedCell(null);
    e.stopPropagation();
  }

  const _onShowCollectionModal = (e) => {
    onShowEditModal(true);
    setIsContextMenuShown(false);
    setIsEditing(false);
    e.stopPropagation();
  }

  if (column.visible === false) return null;

  return (
    <>

      <th
        ref={cellRef}
        className="table-column m-0 p-0 h5 text-dark font-weight-bold"
        onClick={onClick}
        onContextMenu={onContextMenu}
        style={style}
      >

        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
            defaultValue={column.label}
            onKeyUp={(e) => {
              if (e.key === "Enter")
                onChangeLabel(e);
            }}
            onBlur={onChangeLabel}
          />
        ) : (
          <div className="table-column-content d-flex align-items-center px-2 py-1 m-0 w-100 h-100 text-truncate">

            <div className="d-flex align-items-center w-100 h-100 text-truncate">

            
            <small className="column-type d-flex align-items-center mx-1">
              <HelpMessage
                message={ColumnTypeLabels[column.type]}
                forceShow={true}
              >
                <i className={ColumnTypeIcons[column.type]} />
              </HelpMessage>
              <span className="ml-1">
                {column.type == ColumnTypes.COLLECTION && column.structure && (" - " + column.structure.type)}
              </span>
            </small>

              <div className="column-name text-truncate">
                <span>{column?.label ?? column.name}</span>
              </div>

              { 
                isSortingActive && 
                  <i 
                    className="fas fa-sort mx-1 text-primary" 
                    style={{ fontSize: "0.8rem", cursor: "pointer" }}
                    onClick={onSorting}
                  /> 
              }

              { 
                isFilterActive && 
                  <i 
                    className="fas fa-filter mx-1 text-primary" 
                    style={{ fontSize: "0.8rem", cursor: "pointer" }}
                    onClick={onFilter}
                  /> 
              }

              {
                column.type == ColumnTypes.COLLECTION && !column.structure && <>
                  <i className="fas fa-exclamation-circle mx-1 text-danger"></i> <small> No se ha seleccionado una tabla </small>
                </>
              }

              {
                column.type == ColumnTypes.ENUM && !column.structure && <>
                  <i className="fas fa-exclamation-circle mx-1 text-danger"></i> <small> No se han definido opciones </small>
                </>
              }

            </div>

            <div>
              <i
                className="column-options-icon fas fa-chevron-down ml-1 px-2"
                ignore-onclickoutside={componentId.current}
                onClick={onContextMenu}
              />
            </div>

          </div>
        )}

        {
          isContextMenuShown && (
            <div
              ref={contextMenuRef}
              class="dropdown dropdown-column-settings show"
              style={{ top: coords.y, left: coords.x }}
            >
              <div class="dropdown-menu show" aria-labelledby="add-column-dropdown">

                {
                  showFilters &&
                    <a 
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={onFilter}
                    >
                      Filtrar
                    </a>
                }

                { 
                  showSorting &&
                    <a 
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={onSorting}
                    >
                      Ordenar
                    </a>
                }

                {
                  column.type == ColumnTypes.ENUM && !isReadOnly &&
                  <a
                    className="dropdown-item"
                    href="#"
                    ignore-onclickoutside={componentId.current}
                    onClick={_onShowEnumModal}
                  >
                    Editar opciones
                  </a>
                }

                {
                  column.type == ColumnTypes.COLLECTION && !isReadOnly &&
                  <a
                    className="dropdown-item"
                    href="#"
                    ignore-onclickoutside={componentId.current}
                    onClick={_onShowCollectionModal}
                  >
                    Seleccionar tabla
                  </a>
                }

                {
                  column.type == ColumnTypes.FILE && (
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        setZipFieldName(column.name);
                        setIsZipModalShown(true);
                      }}
                    >
                      Descargar zip
                    </a>
                  )
                }

                {
                  !isReadOnly && (
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        setIsEditing(true);
                      }}
                    >
                      Cambiar nombre
                    </a>
                  )
                }

                {
                  !isReadOnly && (
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        onDelete();
                      }}
                    >
                      Eliminar
                    </a>
                  )
                }

              </div>
            </div>
          )
        }

      </th>

    </>
  );
}

export default HeaderCell;