import React from "react";

const PresentationNotFoundView = () => (
  <div className="content container-fluid px-4 py-7 py-md-10">
    <div className="d-flex justify-content-center h-100">
      <div className="card scrollbar-narrow animate__animated animate__fadeInUp animate__faster">
        <div className="card-body py-8 px-5 px-md-10">
          <div className="w-100 h-100 text-center">
            <i className="fas fa-magnifying-glass mb-5" style={{ fontSize: "4em", color: "#506070" }}></i>
            <h2>Solución no encontrado</h2>
            <p>Revisa el tienes acceso o si la liga es correcta</p>
          </div>
        </div>
      </div>
    </div>


  </div>
)

export default PresentationNotFoundView;