import React from "react";
import { Formik, Field, Form } from "formik";
import { Modal } from "seed/helpers"
import IsometricImage from "components/util/helpers/IsometricImage";
import { bodyContentMapper } from "components/studio/board/nodes/Node.lib";
import { BlockMath, InlineMath } from 'react-katex';
import WSManager from "components/util/ws";
import Execution from "components/studio/forms/executions/Execution";
import ChatForm from "components/presentation/chat/Chat.sub.form.view";
import ChatResult from "components/presentation/chat/Chat.sub.result.view";
import ChatToast from "components/presentation/chat/Chat.sub.toast.view";
import ChatExecution from "components/presentation/chat/Chat.sub.execution.view";
import 'katex/dist/katex.min.css';
import "styles/css/presentation/Chat.css";

const decodeHtml = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

const renderLatex = (content) => {

  const decodedContent = decodeHtml(content);
  const latexRegex = /(\$\$(.+?)\$\$|\\\[.+?\\\]|\\\(.+?\\\)|\$(.+?)\$)/g;
  const segments = decodedContent.split(latexRegex).filter(Boolean);
  return segments.map((segment, index) => {
    if ((segment.startsWith('$$') && segment.endsWith('$$')) || (segment.startsWith('\\[') && segment.endsWith('\\]'))) {
      const expression = segment.slice(2, -2);
      return <BlockMath key={index} math={expression} />;
    }
    else if ((segment.startsWith('\\(') && segment.endsWith('\\)')) || (segment.startsWith('$') && segment.endsWith('$'))) {
      const expression = segment.slice(2, -2);
      return <InlineMath key={index} math={expression} />;
    }
    else {
      return <span key={index} dangerouslySetInnerHTML={{ __html: segment }} />;
    }
  });

}

const ChatView = ({
  chat,
  chatRef,
  chatId,
  currentChatId,
  executionRoomName,
  formRoomName,
  resultRoomName,
  chatRoomName,
  chatToast,
  messages,
  currentMessage,
  currentSuggestions,
  isWriting,
  isExecutionDetailsShown,
  isMoreSuggestionsShown,
  isDeveloper,
  actualExecutionId,
  onClickSuggestion,
  onClickMoreSuggestions,
  onClickCreateProcess,
  onSendMessage,
  onConfirmAction,
  onCancelAction,
  onExecutionMessage,
  onFormMessage,
  onResultMessage,
  onChatMessage,
  setIsExecutionDetailsShown,
}) => (
  <div id="chat" className="d-flex justify-content-center">

    <div className="chat-squares d-flex align-items-end align-items-lg-start">
      <IsometricImage />
    </div>

    <div ref={chatRef} id="chat-content">

      <div className="chat-messages scrollbar-narrow">

        {
          function () {

            return messages.map((message, index) => {

              const content = message.content;
              const type = message.type;
              const action = message.action;
              const settings = message.settings;

              if (settings?.is_result)
                return <ChatResult key={index} formData={message.settings.data} />;

              if (settings?.is_form)
                return <ChatForm
                  key={index}
                  chatId={chatId}
                  messageId={message.id}
                  formData={message.settings.data}
                  response={message.settings.form_response}
                />;

              if (settings?.is_execution_result)
                return <ChatExecution
                  key={index}
                  executionId={message.settings.execution_id}
                  message={message}
                  isShown={true}
                />;

              if (content) {

                const text = content.text;
                const plain = content.plain;

                return (
                  <div key={index}>
                    <div
                      className={
                        `d-flex flex-column animate__animated animate__fadeIn animate__faster mx-3 mb-1 
                          align-items-${type == "BOT_MESSAGE" ? "start" : "end"}`}>
                      <div
                        className={`message ${type == "BOT_MESSAGE" ? "" : "user"} scrollbar-narrow`}
                        style={{
                          maxWidth: plain.length > 120 ? (4 * Math.log(plain.length - 120) + 66) + "%" : "66%"
                        }}>
                        {
                          renderLatex(
                            action?.node && action?.node?.action?.type == "TRIGGER" && !action?.is_form
                              ? bodyContentMapper(action.node?.action?.ui_settings?.node, action?.node?.input_values,
                                "execution_message", action.node?.action?.ui_settings?.form)
                              : text)
                        }
                        {
                          settings?.is_confirmation && <>
                            {
                              settings?.result == "OK" &&
                              <>
                                - Seleccionado:
                                <i className="fas fa-check-circle text-success ml-2" />
                              </>
                            }
                            {
                              settings?.result == "CANCEL" &&
                              <>
                                - Seleccionado:
                                <i className="fas fa-times-circle text-danger" />
                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                    {
                      settings?.is_confirmation && settings?.active &&
                      <div className="d-flex mx-3 animate__animated animate__fadeIn animate__faster">
                        <i
                          style={{ cursor: "pointer", color: "#f09090" }}
                          onClick={() => onCancelAction(message)}
                          className="fas fa-times-circle fa-2x mr-3"
                        />
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => onConfirmAction(message)}
                          className="fas fa-check-circle fa-2x text-success"
                        />
                      </div>
                    }
                  </div>
                );
              }

              return <div key={index}></div>;

            });
          }()
        }

        {chatToast && <ChatToast toastData={chatToast} />}

        {
          messages.length == 0 && !isWriting ?
            <div id="no-messages">
              <div className="icon">
                <i className="fas fa-brain"></i>
              </div>
              <div className="content">
                <h3 className="mb-1">Bienvenido a Workix</h3>
                <label>Comienza escribiendo un mensaje o ejecutando un proceso</label>
              </div>
            </div>
            : null
        }

        {
          isWriting &&
          <div className="loading animate__animated animate__fadeIn animate__faster">
            <div className="loader"></div>
          </div>
        }
      </div>
    </div>

    <div id="chat-footer"
      className="d-flex align-items-end">
      <div className="w-100">
        { !chat?.settings?.override_prompt &&
          <div 
            className="suggestion-container"
            style={{
              position: "relative"
            }}
          >

            <div 
              className="px-3"
              style={{
                position: "absolute",
                bottom: "0px",
              }}
            >
              {
                currentChatId != chatId &&
                  <span className="badge badge-primary badge-chat">
                    <i className="fas fa-comment-dots mr-2" /> Chat anterior
                  </span>
              }
            </div>

            {
              currentSuggestions
                .slice(!isMoreSuggestionsShown ? 0 : 3, !isMoreSuggestionsShown ? 3 : 7)
                .map((messageSuggestion, index) => {
                  const messageContent = messageSuggestion.content;
                  return (
                    <div
                      key={index}
                      className={
                        `d-flex flex-column mx-3
                      align-items-end animate__animated animate__fadeIn animate__faster`
                      }
                    >
                      <div
                        style={{ cursor: "pointer", maxWidth: "70%" }}
                        className="suggestion-wrapper"
                        onClick={() => onClickSuggestion(currentMessage, messageSuggestion)}
                        dangerouslySetInnerHTML={{
                          __html: "<div class='message suggestion'>" + messageContent.text + '</div>'
                        }}
                      />
                    </div>
                  );
                })
            }

            {(isMoreSuggestionsShown || currentSuggestions.length == 0) ?
              <div
                className={
                  `d-flex flex-column mx-3
                      align-items-end animate__animated animate__fadeIn animate__faster`
                }>
                <div
                  style={{ cursor: "pointer", maxWidth: "70%" }}
                  className="suggestion-wrapper"
                  onClick={onClickCreateProcess}
                >
                  <div className="message suggestion">
                    <span className={currentSuggestions.length == 0 ? "font-italic" : ""}>Crear proceso</span>
                  </div>
                </div>
              </div> : null}

            {currentSuggestions.length > 0 ?
              <div
                className={
                  `d-flex flex-column mx-3
                      align-items-end animate__animated animate__fadeIn animate__faster`
                }>
                <div
                  style={{ cursor: "pointer", maxWidth: "70%" }}
                  className="suggestion-wrapper"
                  onClick={() => onClickMoreSuggestions()}
                >
                  <div className="message suggestion">
                    <i>{!isMoreSuggestionsShown ? 'Más opciones' : 'Regresar'}</i>
                  </div>
                </div>
              </div> : null}
          </div>
        }

        <div class="input-container">
          <Formik
            initialValues={{}}
            onSubmit={onSendMessage}>
            {({ values, setFieldValue }) =>
              <Form>
                <Field type="text" name="message" autocomplete="off" placeholder="¿En qué te puedo ayudar?" />
                <button type="submit" className="tio-send send" disabled={values.message == null || values.message == ""}></button>
              </Form>}
          </Formik>
        </div>
      </div>
    </div>

    <WSManager
      initialRoom={executionRoomName}
      autoSuscribe={true}
      onMessageReceived={onExecutionMessage}
    />

    <WSManager
      initialRoom={formRoomName}
      autoSuscribe={true}
      onMessageReceived={onFormMessage}
    />

    <WSManager
      initialRoom={resultRoomName}
      autoSuscribe={true}
      onMessageReceived={onResultMessage}
    />

    <WSManager
      initialRoom={chatRoomName}
      autoSuscribe={true}
      onMessageReceived={onChatMessage}
    />

    {isExecutionDetailsShown && (
      <Modal
        width={800}
        height={800}
        animation={""}
        executionId={actualExecutionId}
        isShown={true}
        isButtonHidden={true}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={Execution}
      />
    )}

  </div>
);

export default ChatView;