import React from "react";
import View from "components/tmisc/organizations/orders/Form.view";
import { usePost } from "seed/api";
import swal from "sweetalert";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

const FormSave = ({ organizationId }) => {

    const [callSave, setCallSave] = usePost("/orders/create_order", {
        onCompleted: () => {
            swal({ text: "Orden creada exitosamente", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/orders";
            });
        },
        onError: () => {
            swal({ text: "No se ha podido crear la orden.", icon: "error" });
        }
    });

    const reqUsers = useDetail(`
        {
            organization {
                name
                description
                organizationParticipants {
                    type
                    user {
                        firstName
                        lastName
                        email
                        username
                        license { name }
                    }
                }
            }
        }
    `, organizationId)

    if (reqUsers.loading) return <Loading/>;
    if (reqUsers.error) return "Error";

    const { organization = [] } = reqUsers.data;

    const onSubmit = (values) => {
        values.organization_id = organizationId;
        callSave({ ...values });
    }

    return <View onSubmit={onSubmit} organization={organization}/>;
}

export default FormSave;