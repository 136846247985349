import React from "react";
import swal from "sweetalert";
import { useDetail, useSet } from "seed/gql";
import { usePost } from "seed/api";
import { SET_USER, USER } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/tmisc/settings/Account.view";


function Account() {

	const userId = sessionStorage.getItem('id');

	const [callUpdateUser, reqUpdateUser] = usePost('/users/update_personal_information', {
		onCompleted: () => {
			window.location.reload();
		},
		onError: (error) => {
			if (error.status == 409) {
				swal("Error al actualizar", "El correo/teléfono que intenta actualizar ya se encuentra en uso, por favor verifiquelo", "error");
			} else {
				swal("Error al actualizar", "Ha ocurrido un error al actualizar su información, por favor intente de nuevo mas tarde", "error");
			}
		}
	});

	const reqUser = useDetail(USER, userId);

	const onSubmit = (values) => {

		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		if (values?.password && !passwordRegex.test(values.password)) {
			swal("Error al actualizar", "La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial", "error");
			return;
		}

		callUpdateUser({
			user_id: userId,
			first_name: values.first_name,
			last_name: values.last_name,
			email: values.email,
			phone: values.phone,
			password: values.password,
		});
	}

	if (reqUser.loading) return <Loading />;
	if (reqUser.error) return "Error";

	const { user = {} } = reqUser.data;

	return <View
		user={user}
		onSubmit={onSubmit}
	/>;
}

export default Account;