import React, { useState, useEffect, useRef } from "react";
import ExcelJS from "exceljs";
import { useQuery } from "seed/gql";
import { parseDatatypes } from "components/collections/util/datatypes";
import { filterData } from "components/collections/util/filters";
import { parseExportData, parseRowByColumn } from "components/collections/util/datatypes";
import { downloadFile } from "components/util/file_util";
import View from "components/collections/List.view";

function List({
	query,
	filters,
	search,
	sorting,
	pageSize,
	pageNum,
	totalPages,
	values,
	rawValues,
	structure,
	collections = [],
	isReadOnly = false,
	trackKeybindings = true,
	useCustomSearch = false,
	useCustomPagination = false,
	useCustomFilters = false,
	useCustomImport = false,
	useCustomExport = false,
	height = "auto",
	loading = false,
	showFilters = true,
	showSorting = true,
	showOptions = true,
	showImport = true,
	showExport = true,
	showSearch = true,
	showForm = false,
	checkValidation = false,
	onAddRow = () => { },
	onDeleteRow = () => { },
	onInsertRow = () => { },
	onChangeRow = () => { },
	onImport = () => { },
	onExport = () => { },
	onDownloadZip = () => { },
	onPageChange = () => { },
	onChangeSearch = () => { },
	onChangeQuery = () => { },
	onChangeFilters = () => { },
	onChangeSorting = () => { },
	onClickCell = () => { },
	onShowEditModal = () => { },
	folioComponent
}) {

	const cacheRef = useRef({});

	const [_rows, setRows] = useState([]);
	const [_columns, setColumns] = useState([]);
	const [_query, setQuery] = useState("");
	const [_search, setSearch] = useState("");
	const [_filters, setFilters] = useState(filters || []);
	const [_sorting, setSorting] = useState(sorting || []);
	const [_pageNum, setPageNum] = useState(1);
	const [_pageSize, setPageSize] = useState(10);
	const [_totalPages, setTotalPages] = useState(1);

	const [selectedFile, setSelectedFile] = useState(null);
	const [filterDefault, setFilterDefault] = useState(null);
	const [isFilterModalShown, setIsFilterModalShown] = useState(false);
	const [isOptionModalShown, setIsOptionModalShown] = useState(false);
	const [isFilePreviewShown, setIsFilePreviewShown] = useState(false);
	const [isImportModalShown, setIsImportModalShown] = useState(false);
	const [isExportModalShown, setIsExportModalShown] = useState(false);
	const [isFormModalShown, setIsFormModalShown] = useState(false);
	const [isSearchShown, setIsSearchShown] = useState(false);
	const [isSortingModalShown, setIsSortingModalShown] = useState(false);
	const [isZipModalShown, setIsZipModalShown] = useState(false);
	const [zipFieldName, setZipFieldName] = useState(null);
	const [selectedCell, setSelectedCell] = useState(null);
	const [formValue, setFormValue] = useState(null);

	const [expanded, setExpanded] = useState(false);
	const tableRef = useRef(null);

	const qDataTypes = useQuery(`{
		appDatatypes {
			name
			structure
			app {
				name
				version
			}
		}
	}`);

	useEffect(() => {

    const onKeyUp = (e) => {

      if (selectedCell && selectedCell.row != -1 && trackKeybindings) {
  
        let row = selectedCell.row;
  
        if (e.shiftKey && e.key === "ArrowUp") row--;
        if (e.shiftKey && e.key === "ArrowDown") row++;
  
        if (row < 0) row = 0;
        if (row >= _rows.length) row = _rows.length - 1;
  
        setSelectedCell({ row });
  
      }
  
    }

		document.addEventListener("keyup", onKeyUp);

		return () => {
			document.removeEventListener("keyup", onKeyUp);
		}

	}, [selectedCell, trackKeybindings]);

	useEffect(() => {
		if (selectedFile)
			setIsFilePreviewShown(true);
	}, [selectedFile]);

	useEffect(() => {
		if (values) setRows(values);
	}, [values]);

	useEffect(() => {
		if(!rawValues) return setColumns(structure);
		let { newStructure, newRawValues } = parseDatatypes(structure, rawValues, appDatatypes);
		setColumns(newStructure);
		setRows(newRawValues);
	}, [structure, appDatatypes, rawValues]);

	useEffect(() => {
		if (totalPages) setTotalPages(totalPages);
	}, [totalPages]);

	useEffect(() => {
		if (pageNum) setPageNum(pageNum);
	}, [pageNum]);

	useEffect(() => {
		if (pageSize) setPageSize(pageSize);
	}, [pageSize]);

	useEffect(() => {
		if (query) setQuery(query);
	}, [query]);

	useEffect(() => {
		if (search) setSearch(search);
	}, [search]);

	useEffect(() => {
		if (filters) setFilters(filters);
	}, [filters]);

	useEffect(() => {
		if (sorting) setSorting(sorting);
	}, [sorting]);

	useEffect(() => {
		if (!trackKeybindings) setSelectedCell(null);
	}, [trackKeybindings]);

	useEffect(() => {
		if (_pageNum > _totalPages && _totalPages > 0)
			_onPageChange(_totalPages);
	}, [_totalPages]);

	useEffect(() => {
		if(!useCustomPagination) {
			let filteredData = filterData({ data: _rows, search: _search, query: _query, pageNum: _pageNum, 
				pageSize: _pageSize, useSearch: !useCustomSearch, useQuery: !useCustomFilters });
			setTotalPages(filteredData.length > _pageSize ? Math.ceil(filteredData.length / _pageSize) : 1);
		}
	}, [pageNum, totalPages, _rows, _query, _search]);

	const _onPageChange = (page) => {
		if(page < 1) page = 1;
		if(page > _totalPages) page = _totalPages;
		setPageNum(page);
		onPageChange(page);
	}

  const _onAddRow = (data = {}) => {

		if(isReadOnly && !showForm) return;

		let newRow = { data };
		_columns.forEach(column => {
			if(!newRow.data[column.name]) 
				newRow.data[column.name] = null;
		});

		newRow.data = parseRowByColumn(newRow.data, _columns);
		setSelectedCell({ ...selectedCell, row: _rows.length });

		let newRows = [..._rows, newRow];
		if(newRows.length > _pageSize) {
			setSelectedCell({ ...selectedCell, row: 0 });
			_onPageChange(_totalPages);
		}

		setRows(newRows);
		onAddRow([{ final: newRow, action: "create" }]);

	}

	const _onDeleteRow = (index) => {

		if(isReadOnly) return;

		if(!checkValidation) {
			const newRows = [..._rows];
			newRows.splice(index, 1);
			setRows(newRows);
		}

		onDeleteRow(index);

	}

	const _onInsertRow = (index) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		const newRow = { data: {} };
		_columns.forEach(column => newRow.data[column.name] = null);
		newRows.splice(index, 0, newRow);

		setRows(newRows);
		setSelectedCell(null);
		document.activeElement.blur();
		
		onInsertRow([{
			aux: _rows[index],
			final: newRow,
			action: "insert"
		}]);

	}

	const _onChangeRow = (index, name, value) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		console.log(newRows, parseInt(index), name, value);
		newRows[index].data = { ...(newRows[parseInt(index)]?.data??{}), [name]: value };

		onChangeRow([
			{
				initial: Object.assign({}, newRows[parseInt(index)]),
				final: newRows[parseInt(index)],
				action: "update",
			}
		]);
		setRows(newRows);

	}

	const _onImport = (importedRows, isOverwrite) => {
		if (useCustomImport) {
			onImport(importedRows, isOverwrite);
		}
		else {
			importedRows = importedRows.map(row => ({ data: row }));
			setRows(isOverwrite ? importedRows : [..._rows, ...importedRows]);
		}
	}

	const _onExport = (extension) => {
		if (useCustomExport) {
			onExport(extension);
		}
		else {

			if(extension === "csv") {

				const csv = _rows.map((row) => _columns.map((c) =>
					parseExportData(c, row.data[c.name]))
					.join(";")).join("\n");
	
				const blob = new Blob([csv], { type: "text/csv" });
				downloadFile(URL.createObjectURL(blob), null, "export.csv");
	
			}
			else {
	
				const sheetName = "Sheet1";
				const workbook = new ExcelJS.Workbook();
				const sheet = workbook.addWorksheet(sheetName);
	
				const excelColumns = [
					..._columns.map((c) => c.label),
					"ID Sistema",
					"Folio Sistema"
				];
	
				const excelRows = _rows.map((row) => [
					..._columns.map((c) => parseExportData(c, row.data[c.name])),
					row.id,
					row._folio
				]);
	
				sheet.addRow(excelColumns);
	
				const lastRow = sheet.lastRow;
				for(let i = 0; i < _columns.length + 2; i++) {
					if(i < _columns.length)
						lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
					else
						lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
				}
	
				excelRows.forEach((row) => sheet.addRow(row));
	
				workbook.xlsx.writeBuffer().then((buffer) => {
					const blob = new Blob([buffer], { type: "application/octet-stream" });
					downloadFile(URL.createObjectURL(blob), null, "export.xlsx");
				});
	
			}

		}
	
	}

	const _onChangeSearch = (e) => {
		const id = Math.random();
    cacheRef.current["search"] = id;
		setTimeout(() => {
      if(cacheRef.current["search"] == id) {
				setSearch(e.target.value);
        onChangeSearch(e.target.value);
			}
    }, 600);
	}

	const _onChangeQuery = (query) => {
		setQuery(query);
		onChangeQuery(query);
	}

	const _onChangeFilters = (filters) => {
		setFilters(filters);
		onChangeFilters(filters);
	}

	const _onChangeSorting = (sorting) => {
		setSorting(sorting);
		onChangeSorting(sorting);
	}

	const _onClickCell = (rowData, row) => {
		setSelectedCell({ row });
		if(isReadOnly) return;
		onClickCell(rowData, row);
	}

	const { appDatatypes } = qDataTypes.data || {};

	return <View
		height={height}
		columns={_columns}
		filteredData={_rows}
		collections={collections}
		filterDefault={filterDefault}
		zipFieldName={zipFieldName}
		isReadOnly={isReadOnly}
		isLoading={loading}
		isFilePreviewShown={isFilePreviewShown}
		isFilterModalShown={isFilterModalShown}
		isOptionModalShown={isOptionModalShown}
		isImportModalShown={isImportModalShown}
		isExportModalShown={isExportModalShown}
		isFormModalShown={isFormModalShown}
		isSearchShown={isSearchShown}
		isSortingModalShown={isSortingModalShown}
		isZipModalShown={isZipModalShown}
		showFilters={showFilters}
		showOptions={showOptions}
		showImport={showImport}
		showExport={showExport}
		showSearch={showSearch}
		showSorting={showSorting}
		showForm={showForm}
		tableRef={tableRef}
		pageSize={_pageSize}
		pageNum={_pageNum}
		totalPages={_totalPages}
		query={_query}
		filters={_filters}
		search={_search}
		sorting={_sorting}
		formValue={formValue}
		expanded={expanded}
		selectedCell={selectedCell}
		selectedFile={selectedFile}
		setSelectedCell={setSelectedCell}
		setSelectedFile={setSelectedFile}
		setFormValue={setFormValue}
		setIsFilePreviewShown={setIsFilePreviewShown}
		setIsFilterModalShown={setIsFilterModalShown}
		setIsOptionModalShown={setIsOptionModalShown}
		setIsImportModalShown={setIsImportModalShown}
		setIsExportModalShown={setIsExportModalShown}
		setIsFormModalShown={setIsFormModalShown}
		setIsSearchShown={setIsSearchShown}
		setFilterDefault={setFilterDefault}
		setIsSortingModalShown={setIsSortingModalShown}
		setIsZipModalShown={setIsZipModalShown}
		setZipFieldName={setZipFieldName}
		setExpanded={setExpanded}
		onAddRow={_onAddRow}
		onDeleteRow={_onDeleteRow}
		onInsertRow={_onInsertRow}
		onChangeRow={_onChangeRow}
		onImport={_onImport}
		onExport={_onExport}
		onDownloadZip={onDownloadZip}
		onPageChange={_onPageChange}
		onChangeSearch={_onChangeSearch}
		onChangeQuery={_onChangeQuery}
		onChangeFilters={_onChangeFilters}
		onChangeSorting={_onChangeSorting}
		onClickCell={_onClickCell}
		onShowEditModal={onShowEditModal}
		folioComponent={folioComponent}
	/>

}

export default List;