import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ModalRoute, StyleTag } from "seed/helpers";
import { Link } from "react-router-dom";
import HelpMessage from 'components/util/helpers/HelpMessage';
import StyleColor from "components/util/helpers/StyleColor"
import Collaboration from "components/studio/forms/collaboration/Collaboration";
import Chat from "components/presentation/chat/Chat"
import Collections from "components/collections/Collections";
import SidenavItem from "components/presentation/Presentation.sub.SidenavItem.view";
import FormSetFlow from "components/start/flows/FormSetFlow";
import Settings from "components/presentation/settings/Settings";
import History from "components/presentation/history/History";
import FlowValidator from "components/tmisc/flow_validator/FlowValidator";
import Flows from "components/presentation/flows/Flows";
import "styles/css/presentation/Presentation.css";

const PresentationView = ({
  user,
  flow,
  showSidenav,
  showFlows,
  showShare,
  customViews,
  customWidth,
  activeSection,
  userType,
  isAdmin,
  isEditor,
  chatId,
  currentChatId,
  refreshChat,
  changeChat,
  onClickTab,
  onClickCloseSection
}) => (
  <BrowserRouter basename={`/p/${flow.token}`}>
    <StyleColor user={user} />
    <div style={{ height: "100vh", width: "100vw" }}>
      <div className="d-flex justify-content-center h-100 w-100">
        <div id="presentation-mode" className="w-100 p-0 scrollbar-narrow">
          <div className="w-100 h-100">

            <div id="presentation-header"
              className={"d-flex w-100 justify-content-between align-items-center " + (!isEditor ? "read-mode" : "")}>

              <div id="presentation-title" class="d-flex align-items-center">

                <a
                  className="back-button d-flex align-items-center justify-content-center"
                  title="Regresar"
                  href="/app"
                  onClick={() => { }}>
                  <i class="fas fa-arrow-left d-block"></i>
                </a>

                <div class="w-auto ml-1">
                  <a href="/app" >
                    {user?.accountSettings?.logo ? (
                      <img src={user?.accountSettings?.logo} className="logo" alt="Logo" />
                    ) : (
                      <div className="logo-workix"></div>
                    )}
                  </a>
                </div>

                <div class="dropdown navbar-dropdown-account-wrapper rounded pr-2 my-0"
                  style={!isAdmin ? { background: "transparent" } : { cursor: "pointer" }}>
                  <div class="dropdown-toggle" data-toggle={isAdmin ? "dropdown" : ""} aria-expanded="false">
                    <span class="flow-name-container d-flex align-items-end text-hover-primary mb-0">
                      <span className="flow-name d-inline-block text-truncate">
                        <span>{flow.name}</span>
                      </span>
                      {isAdmin ?
                        <i className="flow-name-icon fas fa-chevron-down mt-1"></i> : null}
                    </span>
                  </div>

                  <div class="dropdown-menu pr-1" style={{ minWidth: "12.5rem" }} aria-labelledby="dropdownFlowSettings">

                    <Link to="/edit_flow" class="dropdown-item">
                      <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar solución
                    </Link>

                    <hr className="my-2" />

                    <a href={`/app/settings/flows`} class="dropdown-item">
                      <i class="fas fa-list dropdown-item-icon mr-1"></i> Ver soluciones
                    </a>

                    <a href={`/app/settings`} class="dropdown-item">
                      <i class="fas fa-cog dropdown-item-icon mr-1"></i> Ajustes
                    </a>

                  </div>
                </div>

              </div>

              <div id="presentation-actions" className="d-flex">

                <span className="d-flex align-items-center">
                  
                  {
                    (showShare[userType] ?? true) &&
                      <HelpMessage message="Comparte tu solución con otras personas, permitiendo a otros colaborar"
                        style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                        <Link to="/share" className="btn btn-sm btn-accent mr-2 share-button">
                          <i class="fas fa-share mr-1"></i> Compartir
                        </Link>
                      </HelpMessage>
                  }

                </span>

              </div>
            </div>

            <div id="presentation-content" className="d-flex w-100">
              {
                (showSidenav[userType] ?? true) &&
                <div id="presentation-sidenav" className="d-flex flex-column scrollbar-narrow h-100"
                  style={{ boxSizing: "border-box", overflowY: "auto" }}>

                  {
                    (showFlows[userType] ?? true) &&
                    <SidenavItem
                      name="flows"
                      label="Procesos"
                      icon="fas fa-project-diagram"
                      activeSection={activeSection}
                      onClickItem={onClickTab} />
                  }

                  <SidenavItem
                    name="data"
                    label="Datos"
                    icon="fas fa-table"
                    activeSection={activeSection}
                    onClickItem={onClickTab} />

                  <SidenavItem
                    name="history"
                    label="Historial"
                    icon="fas fa-clock-rotate-left"
                    activeSection={activeSection}
                    onClickItem={onClickTab} />

                  {
                    customViews.map((view, index) => (
                      <SidenavItem
                        key={`custom_view_nav_${index}`}
                        name={view.name}
                        label={view.name}
                        icon={view.icon}
                        activeSection={activeSection}
                        onClickItem={onClickTab} />
                    ))
                  }

                  <div style={{ height: "1px", background: "#ffffff50", margin: "0.8rem 0.8rem 0.9rem 0.8rem" }} />

                  <SidenavItem
                    name="settings"
                    label="Ajustes"
                    icon="fas fa-cog"
                    activeSection={activeSection}
                    onClickItem={onClickTab} />

                </div>
              }

              {
                customWidth &&
                  <StyleTag
                    content={`
                      #presentation-sections {
                        width: ${customWidth} !important;
                      }
                    `}
                  />
              }

              <div 
                id="presentation-sections"
                className={"scrollbar-narrow " + (activeSection != null ? activeSection : "collapsed")}
              >

                {activeSection == "data" ?
                  <Collections
                    flowId={flow.id}
                  /> : null}

                {activeSection == "flows" ?
                  <Flows
                    flow={flow}
                    userType={userType}
                    isEditor={isEditor}
                    chatId={chatId}
                  /> : null}

                {activeSection == "history" ?
                  <History
                    flow={flow}
                    chatId={chatId}
                    changeChat={changeChat}
                  /> : null}

                {activeSection == "settings" ?
                  <Settings
                    flow={flow}
                    chatId={chatId}
                    refreshChat={refreshChat}
                    isAdmin={isAdmin}
                  /> : null}

                  {
                    customViews.map((view, index) => (
                      activeSection == view.name ?
                        <div key={`custom_view_${index}`} className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <iframe
                            style={{ border: "none" }}
                            className="w-100 h-100"
                            src={decodeURIComponent(view.url)}
                            title={view.name}
                          ></iframe>
                        </div> : null
                    ))
                  }

              </div>

              <div id="presentation-chat">

                {(showSidenav[userType] ?? true) &&
                  <div 
                    id="collapse-icon"
                    onClick={onClickCloseSection}>
                    <i className={"fas " + (activeSection != null ? "fa-angle-left" : "fa-angle-right")} aria-hidden="true"></i>
                  </div>}

                <Chat
                  flow={flow}
                  chatId={chatId}
                  currentChatId={currentChatId}
                  onClickTab={onClickTab}
                />
              </div>

            </div>


          </div>
        </div>
      </div>

      {(showShare[userType] ?? true) ?
        <ModalRoute
          width={650}
          height={window.innerHeight - 150}
          animation={""}
          path="/share"
          component={() => <Collaboration flowId={flow.id} />}
        /> : null}

      {isAdmin ?
        <ModalRoute
          width="500"
          height="500"
          path="/edit_flow"
          component={() =>
            <FormSetFlow
              match={{ params: { "flowId": flow.id } }}
            />
          }
        /> : null}

      <ModalRoute
        path="/flow_validation/:flowId"
        width={window.innerWidth - 300}
        height={window.innerHeight - 100}
        flow={flow} 
        component={FlowValidator}
      />


    </div>
  </BrowserRouter>
)

export default PresentationView;