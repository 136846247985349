import React from "react";
import View from "components/tmisc/organizations/users/Users.view";
import { useDetail, useDelete } from "seed/gql";
import { Loading } from "seed/helpers";
import { DELETE_USER } from "seed/gql/queries";
import swal from "sweetalert";

const Users = ({ selectedOrganization }) => {

    const [callDelete] = useDelete(DELETE_USER, {
        onCompleted: () => {
            swal({ text: "Usuario eliminado exitosamente", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/users";
            });
        },
        onError: () => {
            swal({
                text: "No se ha podido eliminar el usuario",
                icon: "error"
            });
        }
    });

    const qUsersAssociated = useDetail(`
        {
            organization {
                name
                description
                organizationParticipants {
                    type
                    user {
                        firstName
                        lastName
                        email
                        username
                        license { name }
                    }
                }
            }
        }
    `, selectedOrganization);

    if (selectedOrganization === 0) {
        return (
            <div className="card col-md-12" style={{ minHeight: "75vh" }}>
                <div className="card-body text-center mt-5">
                    <h4>No tienes organizaciones asociadas</h4>
                </div>
            </div>
        );
    }

    if (qUsersAssociated.loading) return <Loading />;
    if (qUsersAssociated.error) return "Error";

    const { organization = [] } = qUsersAssociated.data;

    const deleteUser = (id) => {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado, no podrás recuperar este usuario",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                callDelete({ id });
            }
        });
    };

    return (
        <View
            organization={organization}
            deleteUser={deleteUser}
        />
    );
};

export default Users;