import React from "react";
import { Link } from "react-router-dom";

const ListView = ({ user, formatDate }) => (
  <div>
    <div className="card" style={{ minHeight: "50vh" }}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title mb-0">Órdenes</h3>
        <Link to={`/organizations/users/orders/${user.id}/create`} className="btn btn-sm btn-primary">
          <i className="fa fa-plus"></i> Crear orden
        </Link>
      </div>

      <div className="card-body animate__animated animate__fadeIn animate__faster p-0">
        {user.orders.length === 0 ? (
          <div className="p-3 text-center">No hay órdenes asignadas al usuario.</div>
        ) : (
          <>
            <div className="row border-bottom m-0 font-weight-bold py-2 px-2">
              <div className="col-md-2">Tipo</div>
              <div className="col-md-2">Fecha inicio</div>
              <div className="col-md-2">Fecha fin</div>
              <div className="col-md-2">Cantidad</div>
              <div className="col-md-2">Fecha de pago</div>
              <div className="col-md-2">Licencia</div>
            </div>

            <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
              {user.orders.map((order, idx) => (
                <div
                  key={idx}
                  className="row m-0 px-2 border-bottom py-2"
                  style={{ backgroundColor: idx % 2 ? "#f9f9f9" : "transparent" }}
                >
                  <div className="col-md-2">
                    {order.type === "CREDIT" ? "Créditos" : order.type}
                  </div>
                  <div className="col-md-2">
                    {order.type === "CREDIT" ? "-" : formatDate(order.startDate)}
                  </div>
                  <div className="col-md-2">
                    {order.type === "CREDIT" ? "-" : formatDate(order.endDate)}
                  </div>
                  <div className="col-md-2">{order.quantity || '-'}</div>
                  <div className="col-md-2">{formatDate(order.paymentDate)}</div>
                  <div className="col-md-2">{order.license?.name || '-'}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

export default ListView;