/* eslint-disable max-len */
import React from "react";
import Studio from "components/studio/Studio";
import { bodyContentMapper, warningMapper, errorMapper } from "components/studio/board/nodes/Node.lib";


const FlowValidatorView = ({ match, nodes, onClickCreateFlow }) =>
  <div className="card">

    <div className="card-header">
      <h3 className="card-header-title">
        Verifique el proceso
      </h3>
    </div>

    <div className="card-body">
      <Studio match={match} isValidator={true} />
    </div>

    <div className="card-footer">

      {nodes.map(node => (
        <div key={node.id}>

          {node.warnings.length > 0 || node.errors.length > 0 ? (
            <div dangerouslySetInnerHTML={{ __html: bodyContentMapper(node.action.uiSettings?.node ?? [], node.inputValues, "title", node.action.uiSettings.form) }}></div>
          ) : null}

          {node.warnings.length > 0 && (
            <div className="alert alert-soft-warning" role="alert">
              <ul class="list-group list-group-flush">
                {node.warnings.map((warning, idx) => (
                  <li class="list-group-item" key={idx} style={{ backgroundColor: "transparent" }}
                    dangerouslySetInnerHTML={{ __html: warningMapper(warning, node.action.uiSettings) }}
                  >
                  </li>
                ))}
              </ul>
            </div>
          )}

          {node.errors.length > 0 && (
            <div className="alert alert-soft-danger" role="alert">
              <ul class="list-group list-group-flush">
                {node.errors.map((error, idx) => (
                  <li class="list-group-item" key={idx} style={{ backgroundColor: "transparent" }}
                    dangerouslySetInnerHTML={{ __html: errorMapper(error, node.action.uiSettings) }}
                  >
                  </li>
                ))}
              </ul>
            </div>
          )}

        </div>
      ))}

      <button className="btn btn-block btn-primary"
        onClick={onClickCreateFlow}>Crear proceso</button>
    </div>
  </div>;

export default FlowValidatorView;