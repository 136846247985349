import React from "react";
import View from "components/tmisc/organizations/users/FormUploadMassive.view";
import { downloadFile, uploadFile } from "components/util/file_util";
import { SERVER_URL } from "settings";
import { usePost } from "seed/api";
import swal from "sweetalert";

const FormUploadMassive = ({ orgId }) => {

    const [callSave, setCallSave] = usePost("/users/upload_massive", {
        onCompleted: () => {
            swal({ text: "Usuarios cargados exitosamente", icon: "success" }).then(() => {
                window.location.href = "/app/organizations/users";
            });
        },
        onError: () => {
            swal({ text: "No se han podido cargar los usuarios", icon: "error" });
        }
    });

    const downloadTemplate = () => {
        downloadFile(SERVER_URL + "/static/UsersFormat.xlsx", null, "UsersFormat.xlsx");
    }

    const onSubmit = (values) => { 
        if (!values.users_format) {
            swal({ text: "Debe cargar el archivo con los usuarios.", icon: "error" });
            return;
        }
        
        callSave({ file_id: values.users_format.id, organization_id: orgId });
    }

    return <View onSubmit={onSubmit} downloadTemplate={downloadTemplate} />;
}

export default FormUploadMassive;