import React, { useState } from 'react';
import { useWS } from 'seed/ws';
import { Modal } from "seed/helpers";
import Table from "components/collections/Table";
import { usePost } from 'seed/api';

const Result = ({flow}) => {
  const userId = sessionStorage.getItem("id");
  const [callCloseResult] = usePost("/apps/forms/close_result");
  const [data, setData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [_] = useWS("SHOW_RESULT_" + userId,
    {
      onMessageReceived: (message) => {
        const pageId = sessionStorage.getItem("page_" + flow.id);
        if(message.data?.session_id && message.data?.session_id != sessionStorage.getItem("execution_session_id")) return;
        if(message.data?.page_id == pageId || !pageId){
          setData(message.data);
          setShowModal(true);
        }
      },
    });
  if (!showModal) return null;

  const title = data.title;
  const message = data.message;
  const result = data.result;
  const schema = data.schema ?? [];
  const extraData = data.extra_data ?? {}
  const nodeId = data.node_id;
  const executionId = data.execution_id

  const onCloseResult = (data) => {
    if (nodeId && executionId){
      callCloseResult({
        node_id: nodeId,
        execution_id: executionId,
        ...extraData
      });
    }
    setShowModal(false);
  }

  const SchemaComponent = () =>
    <div class="card">
      <div class="card-header">
        <h3 class="mb-0">{title != "" ? title : "Mostrar mensaje"}</h3>
      </div>
      <div class="card-body">
        {
          message ?
            <div className='mx-3 mb-3 px-3 py-3 rounded' style={{ border: "1px solid #e8e8e8", background: "#fcfcfc" }}>{message}</div> : null
        }
        {
          schema && result ?
            schema.length > 0 ?
              <Table 
                rawValues={result ?? []}
                structure={schema ?? []}
                isReadOnly={true}
                showImport={false}
                showSearch={false} /> :
              <div className="mb-2" style={{ whiteSpace: "pre-wrap", overflow: "hidden" }}>{result}</div> : null
        }

        <div className="px-3 mt-1 mb-1">
          <button type="button" class="btn btn-block btn-primary" onClick={() => onCloseResult(false)}>
            Continuar
          </button>
        </div>

      </div>
    </div>

  return <Modal
    id="form-modal"
    animation="zoomIn animate__fast"
    width={schema && result ? 700 : 500}
    height={450}
    component={SchemaComponent}
    onClose={() => onCloseResult(false)}
  />
}

export default Result;