/* eslint-disable max-len */
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { ScriptTag } from "seed/helpers";


const PanelView = ({
  user,
  developerMode
}) => (
  <>

    <div id="start-settings" class="card">
      <div class="card-body p-0">
        <aside class="navbar navbar-vertical-aside navbar-vertical navbar-bordered navbar-vertical-aside-initialized">
          <div class="navbar-vertical-container">
            <div class="navbar-vertical-footer-offset">

              <div id="start-settings-header" class="d-flex align-items-center pl-2 py-1">

                <Link to="/" className="d-flex align-items-center justify-content-center px-3">
                  <i class="fas fa-arrow-left d-block"></i>
                </Link>

                <div class="rounded w-auto">
                  <Link to="/" className="back-button-flow mt-n1"></Link>
                </div>

                <div class="ml-1">

                  <a class="navbar-dropdown-account-wrapper rounded pl-1 pr-2"
                    style={{ background: "transparent" }}>
                    <div class="ml-2">
                      <span class="d-block h5 mb-0">
                        {user?.firstName ?? ""} {user?.lastName ?? ""}
                      </span>
                      <span class="d-block font-size-sm text-body">
                        Plan {(user?.license?.name ?? "básico").toLowerCase()}
                      </span>
                    </div>
                  </a>

                </div>
              </div>

              <div id="start-settings-menu" class="navbar-vertical-content mt-3 mb-5 overflow-hidden">
                <ul class="navbar-nav navbar-nav-lg nav-tabs">

                  <li class="nav-item">
                    <NavLink to="/settings/account" className={`js-nav-tooltip-link nav-link`} >
                      <i class="fas fa-user ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Cuenta</span>
                    </NavLink>
                  </li>

                  {developerMode ?
                    <li class="nav-item">
                      <NavLink to="/settings/billing" className={`js-nav-tooltip-link nav-link`}>
                        <i class="fa-solid fa-money-bill ml-1 mr-3"></i>
                        <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Plan</span>
                      </NavLink>
                    </li> : null}


                  <li class="nav-item">
                    <NavLink to="/settings/flows" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fas fa-microchip ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Soluciones</span>
                    </NavLink>
                  </li>


                  <li class="nav-item">
                    <NavLink to="/settings/customization" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fas fa-palette ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Personalización</span>
                    </NavLink>
                  </li>


                  <li class="nav-item">
                    <NavLink to="/settings/external_apps" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fas fa-window-restore ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Aplicaciones externas</span>
                    </NavLink>
                  </li>


                  {developerMode ?
                    <li class="nav-item">
                      <NavLink to="/settings/dev" className={`js-nav-tooltip-link nav-link`}>
                        <i class="fas fa-code ml-1 mr-3"></i>
                        <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Desarrollador</span>
                      </NavLink>
                    </li> : null}

                </ul>
              </div>

            </div>
          </div>
        </aside>
      </div>
    </div>

    <ScriptTag content={`
      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });
    `} />

  </>
);

export default PanelView;