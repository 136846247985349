import React from "react";
import FlowList from "components/presentation/flows/flows/FlowList"
import Templates from "components/presentation/flows/templates/Templates";


const FlowsView = ({
  flow,
  processesTab,
  userType,
  chatId,
  isEditor,
  onClickTab,
}) => (
  <div>
    <div>
      <div id="processes-tabs" className="tabs d-flex mt-2 mb-2">
        <button
          type="button"
          className={"dropdown-header " + (processesTab == "flows" ? "active" : "")}
          onClick={() => onClickTab("flows")}>
          Procesos
        </button>
        <button
          type="button"
          className={"dropdown-header " + (processesTab == "templates" ? "active" : "")}
          onClick={() => onClickTab("templates")}>
          Plantillas
        </button>
      </div>

      {processesTab == "flows" &&
        <FlowList
          flowId={flow.id}
          userType={userType}
          isEditor={isEditor}
          chatId={chatId}
          onClickTab={onClickTab} />}

      {processesTab == "templates" &&
        <div className="py-3 px-4">
          <Templates
            flow={flow}
            isEditor={isEditor} />
        </div>}
    </div>
  </div>
);


export default FlowsView;