import React from "react";
import TemplatesV from "components/presentation/flows/templates/Templates";



const TemplatesView = ({
  flow
}) => (
  <div className="h-100">
    <div id="toolbox-templates" className="w-100 scrollbar-narrow d-flex flex-column">

      <div className="w-100 section-title pr-1 mt-0">
        <h2 className="w-100 text-left">
          Plantillas
        </h2>
      </div>

      <TemplatesV flow={flow} />

    </div>

  </div>
)

export default TemplatesView;