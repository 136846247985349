import React from "react";
import { Formik, Field, Form } from "formik";
import { ScriptTag } from "seed/helpers";
import StyleColor from "components/util/helpers/StyleColor"


const RecoverPasswordView = ({ status, message, onSubmit = () => { } }) => (

  <main id="content" role="main" class="main main pl-0 h-100 w-100" style={{ overflowY: "auto" }}>
    <StyleColor />

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster"
            style={{ border: "1px solid #d2dae0" }}>
            <div class="card-body">
              <Formik
                initialValues={{}}
                onSubmit={onSubmit}>
                {() => (
                  <Form>
                    <div class="text-center">
                      <div class="mb-5">
                        <h1 class="display-4">Recupera tu cuenta</h1>
                      </div>
                    </div>

                    {/* Email */}
                    <div class="form-group">
                      <label class="input-label" for="formEmail">
                        Ingresa tu correo electrónico
                      </label>
                      <Field type="email" name="email" id="formEmail"
                        class="form-control form-control-lg" tabindex="1"
                        placeholder="email@address.com" maxlength="512"
                        required autofocus="1"
                      />
                    </div>

                    <button type="submit" class="btn btn-lg btn-block btn-accent">
                      Buscar cuenta
                    </button>

                    {status ? (
                      <div
                        class={`alert alert-soft-${status == "ERROR" ? "danger" : "success"} text-center mt-2`}
                        role="alert"
                      >
                        {message}
                      </div>
                    ) : null}

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
)


export default RecoverPasswordView;