import React, { useState } from "react";
import { Link } from "react-router-dom";

const GuidesView = ({ tutorials, tutorialCategories }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const categoriesSet = new Set(tutorialCategories.map((cat) => cat.name));
  const hasNoCategory = tutorials.some((t) => !t.categoryId);
  if (hasNoCategory) {
    categoriesSet.add("Sin Categoría");
  }
  const allCategories = Array.from(categoriesSet);

  const filteredTutorials = tutorials.filter((tutorial) => {
    const categoryName = tutorial.categoryId?.name || "Sin Categoría";
    if (!selectedCategory) return true;
    return categoryName === selectedCategory;
  });

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="h-100">
      <div
        id="toolbox-guides"
        className="w-100 d-flex flex-column"
        style={{ overflowY: "auto" }}
      >
        <div
          className="w-100 d-flex align-items-center justify-content-between"
          style={{
            marginBottom: "1.5rem",
            color: "#444",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
        >
          <h2 className="mb-0 mr-2">Guías</h2>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <select
              id="categorySelect"
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{
                minWidth: "180px",
                padding: "0.5rem",
                borderRadius: "4px",
                border: "1px solid #ccc",
                fontSize: "0.95rem",
                backgroundColor: "#fff",
                boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                cursor: "pointer",
              }}
            >
              <option value="">Todas</option>
              {allCategories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          className="tutorials-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gap: "1rem",
          }}
        >
          {filteredTutorials.map((tutorial) => (
            <div
              key={tutorial.id}
              className="tutorial-card"
              style={{
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "1.15rem 1rem 1.25rem 1rem",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.2s",
              }}
            >
              <div
                className="mb-1"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5 style={{ color: "#333" }}>
                  {tutorial.name}
                </h5>
              </div>

              <div
                className="video-preview"
                style={{
                  position: "relative",
                  width: "100%",
                  height: "130px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onMouseEnter={() => setHoveredCard(tutorial.id)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <img
                  src={tutorial.thumbnailPath}
                  alt={tutorial.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transition: "opacity 0.3s",
                    opacity: hoveredCard === tutorial.id ? 0.8 : 1,
                  }}
                />
                {hoveredCard === tutorial.id && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <Link
                      to={`/guides/${tutorial.id}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        border: "2px solid #fff",
                        color: "#fff",
                        padding: "0.5rem 1rem",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      <i
                        className="fas fa-play"
                        style={{ marginRight: "0.5rem" }}
                      ></i>
                      Ver
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuidesView;
