import React from "react";
import { useDetail, useQuery } from "seed/gql";
import { useGet, usePost } from "seed/api";
import swal from "sweetalert";
import View from "components/presentation/settings/Settings.view";

function ChatSettings({ flow, isAdmin, refreshChat }) {

  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";
  
  const reqFlow = useDetail(`{
    flow {
      chatSettings
    }
  }`, flow.id);
  
  const qCollections = useQuery(`{
		collections {
			id
			name
			initials
		}
	}`, "flow.id=" + flow.id);

  const qModels = useGet("/chats/get_llm_models");

  const [callSet] = usePost("/chats/set_settings", {
    onCompleted: (data) => {
      if(data.token != flow.token) window.location.href = "/p/" + data.token;
      swal("Guardado", "Los cambios se han guardado correctamente", "success");
      refreshChat();
    },
    onError: (error) => {
      if(error.status == 409) 
        swal("Error", "El token ingresado ya está en uso", "error");
      else 
        swal("Error", "Ocurrió un error inesperado", "error");
    },
  });

  const { collections = [] } = qCollections.data || {};
  const { chatSettings = {} } = reqFlow.data?.flow || {};  
  const { models = [] } = qModels.data || {};

  const onSubmit = (values) =>
    callSet({ flow_id: flow.id, settings: values });

  if (reqFlow.loading) return <></>;

  return <View
    flow={flow}
    models={models}
    collections={collections}
    chatSettings={chatSettings}
    isDeveloper={isDeveloper}
    isAdmin={isAdmin}
    onSubmit={onSubmit}
  />;

}

export default ChatSettings;