import React from "react";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import FormSave from "components/tmisc/organizations/company/FormSave";
import { TwitterPicker } from 'react-color';
import { FileField, StyleTag } from "seed/helpers";

const CompanyView = ({ organization = {}, onSubmit, onDelete, isDeveloper }) => (
  <>
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h4 className="card-title">
          {organization?.id ? "Detalles de organización" : "No tienes organizaciones registradas"}
        </h4>
        {isDeveloper &&
          <Link to={`/organizations/details/create`} className="btn btn-sm btn-primary">
            <i className="fa fa-plus"></i> Nueva organización
          </Link>
        }
      </div>
      {organization?.id && (
        <div className="card-body p-4 animate__animated animate__fadeIn animate__faster">
          <Formik
            initialValues={{
              name: organization?.name || "",
              description: organization?.description || "",
              primaryColor: organization?.orgSettings?.theme?.primaryColor || "#2065a5",
              accentColor: organization?.orgSettings?.theme?.accentColor || "#f0b000",
              logo: organization?.orgSettings?.logo || "",
            }}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {/* Sección de datos básicos */}
                <div className="mb-4">
                  <h5 className="mb-3">Información básica</h5>
                  <div className="form-group">
                    <label htmlFor="name" className="mb-1">
                      Nombre <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      maxLength="512"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description" className="mb-1">
                      Descripción
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      maxLength="512"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Sección de personalización */}
                <div className="mb-4">
                  <h5 className="mb-3">Personalización</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <ColorPicker
                        label="Color principal"
                        name="primaryColor"
                        values={values}
                        defaultColor={"#2065a5"}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="col-md-6">
                      <ColorPicker
                        label="Color de acento"
                        name="accentColor"
                        values={values}
                        defaultColor={"#f0b000"}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  {/* Logo debajo de los colores */}
                  <div className="mt-4">
                    <div className="d-flex align-items-center">
                      {values.logo && (
                        <div className="mr-3" style={{ maxWidth: "150px" }}>
                          <div>Logo actual</div>
                          <img src={values.logo} className="img-fluid" alt="Logo actual" />
                        </div>
                      )}
                      <div className="form-group flex-grow-1">
                        <label className="mb-2">Logo personalizado</label>
                        <FileField
                          name="logo"
                          id="logo"
                          required={true}
                          accept=".jpg,.jpeg,.svg,.png,.gif,.bmp,.webp"
                          class="form-control form-control-lg"
                          setFieldValue={() => {}}
                          onCompleted={(data) => setFieldValue("logo", data?.url)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Botones de acciones */}
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => onDelete(organization.id)}
                  >
                    Eliminar organización
                  </button>
                  <button type="submit" className="btn btn-success">
                    Guardar cambios
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>

    <ModalRoute
      path="/organizations/details/create"
      height="350"
      width="700"
      component={FormSave}
    />
  </>
);

const ColorPicker = ({ label, name, values, defaultColor, setFieldValue, onChange = () => {} }) => {
  const colors = [
    "#FF5252", "#FF4081", "#7B1FA2", "#512DA8", "#536DFE", "#2065a5", "#3075BA",
    "#1976D2", "#03A9F4", "#0097A7", "#00796B", "#4CAF50", "#AFB42B", "#F57C00",
    "#FFA000", "#FF5722", "#5D4037", "#9E9E9E", "#455A64", "#212121", "default"
  ];
  return (
    <div className="d-flex align-items-center mb-3">
      <StyleTag content={`
        .twitter-picker input[id^=rc-editable-input] {
          display: none;
        }
        .twitter-picker div[style*="rgb(240, 240, 240)"] {
          display: none !important;
        }
      `} />
      <div className="mr-3" style={{ width: "120px" }}>
        {label}
      </div>
      <div style={{ position: "relative" }}>
        <div
          className="color-picker-preview"
          onClick={() => setFieldValue(`show_${name}`, !values[`show_${name}`])}
        >
          <div
            className="color"
            style={{
              backgroundColor: values[name] || defaultColor,
              width: "36px",
              height: "36px",
              border: "1px solid #ccc"
            }}
          ></div>
        </div>
        {values[`show_${name}`] && (
          <div style={{ position: "absolute", left: "110%", top: "0", zIndex: 1000 }}>
            <TwitterPicker
              color={values[name] || defaultColor}
              width="240px"
              triangle="hide"
              onChange={(color) => {
                setFieldValue(name, color.hex);
                setFieldValue(`show_${name}`, false);
                onChange(color.hex);
              }}
              colors={colors}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyView;
