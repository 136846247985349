import React, { useState } from 'react';
import View from 'components/studio/toolbox/apps/Apps.view';

const Apps = ({ reqApps, flowId }) => {

    const [search, setSearch] = useState("");
    const [activeTab, setActiveTab] = useState("installed");

    const onSearchApps = (value) => {
        setSearch(value);
    };

    const onUpdateAppsQuery = () => {
        reqApps.refetch();
    }

    return <View
        search={search}
        flowId={flowId}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onSearchApps={onSearchApps}
        onUpdateAppsQuery={onUpdateAppsQuery}
    />;
}

export default Apps;