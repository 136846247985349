import React from "react";
import ModelIcon from "components/util/helpers/ModelIcon";
import { Link } from "react-router-dom";


const FlowListView = ({
  user,
  flows,
  folder,
  onClickDelete,
  onClickClone,
}) => (

  <ul class="list-group" id="flow-list">

    {flows.map((flow, index) => (
      <li class="flow list-group-item mb-1" key={flow.id}>

        <div class="row align-items-center gx-2">
          <div class="col-auto">
            <ModelIcon style={{ width: "2.8rem" }} letter={flow.name[0]} />
          </div>

          <div class="col">
            <h5 class="mb-0">
              <a href={`/p/${flow.token}`} className="text-dark">
                {flow.name}
              </a>
              {function () {
                const participants = flow?.participants ?? [];
                const participant = participants.find((participant) => participant?.user?.id == user.id);
                const isParticipant = participant != null && participant != undefined;

                if (isParticipant) {
                  return (<span class="badge bg-soft-primary text-primary p-2 ml-2">Compartido</span>);
                }
              }()}
              {flow.isTemplate
                ? flow.templateSettings?.template_type == "PARTIAL" ?
                  <span class="badge badge-soft-primary ml-2">Plantilla (Parcial)</span> :
                  <span class="badge badge-soft-primary ml-2">Plantilla</span>
                : null}
            </h5>
            <ul class="list-inline list-separator small">
              <li class="list-inline-item">
                <i class="far fa-folder mr-1" style={{ display: flow.folder ? "inline-block" : "none" }}></i>
                <span>{flow.folder?.name ?? 'General'}</span>
              </li>
            </ul>
          </div>

          <div class="col-auto">

            <a href={`/p/${flow.token}`} className="btn btn-primary btn-sm mr-2">
              Ir a solución
            </a>

            <div class="hs-unfold">
              <a
                className="btn btn-lighter btn-sm"
                type="button"
                id={`dropdownMenuButton-${index}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <span class="d-none d-sm-inline-block mr-1">Más</span>
                <i class="tio-chevron-down"></i>
              </a>

              <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${index}`} >

                <Link to={`/settings/flows/${flow.id}/edit_flow`} className="dropdown-item">
                  <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar solución
                </Link>
                <a className="dropdown-item" href="" onClick={(event) => {
                  event.preventDefault();
                  onClickClone(flow.id);
                }}>
                  <i class="tio-copy dropdown-item-icon mr-1"></i> Crear copia
                </a>
                <a
                  className="dropdown-item"
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    onClickDelete(flow.id);
                  }}>
                  <i class="tio-delete dropdown-item-icon mr-1"></i> Eliminar
                </a>
              </div>

            </div>
          </div>
        </div>

      </li>
    ))}

    {flows.length == 0 && user.license?.name != "Test-ext"
      ? <div id="no-flows" className="text-center my-3 h-100">
        <i className="fas fa-solid fa-inbox mb-2"></i>
        <br />No tienes soluciones registradas<br />
        <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-primary btn-sm mt-3">
          Crea un nueva solución
        </Link>
      </div>
      : null}

  </ul>

)

export default FlowListView;