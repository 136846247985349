import React from "react";
import { useHistory } from 'react-router-dom';
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { useSet } from "seed/gql";
import { SET_FLOW_NODE } from "seed/gql/queries";
import View from "components/presentation/flows/flows/FlowList.view";

function FlowList({ flowId, userType, isEditor, chatId, onClickTab }) {

  const history = useHistory();
  const reqFlows = useQuery(`{
    flowNodes {
      rootName
      rootDescription
      rootSettings
      action {
        uiSettings
      }
      flow {
        name
      }
    }
  }`, `(flow.id=${flowId} AND is_root=true)`, { orderBy: "-id" })

  const [callSetFlowNode, reqSetFLowNode] = useSet(SET_FLOW_NODE);

  const [saveIndexes] = usePost("/flows/save_root_indexes", {
    onCompleted: () => reqFlows.refetch()
  });

  const [callExecute] = usePost("/flows/execute_node");
  const [callMessage] = usePost("/chats/create_execution_message", {
    onCompleted: (data) => {
      if(!data.action) return;
      if (!data.settings.is_form)
        callExecute({
          root_node_id: data.action.node.id,
          message_id: data.id,
          session_id: sessionStorage.getItem("execution_session_id"),
          user_id: sessionStorage.getItem("id"),
        });
    }
  });


  const onClickExecute = (nodeId) =>
    callMessage({
      chat_id: chatId,
      node_id: nodeId
    });

  const onClickMoveUp = (nodeId) => {

    const nodesIndexes = reqFlows.data.flowNodes.map((node, index) => {
      return {
        id: node.id,
        index: node.rootSettings.index ?? index
      }
    });

    const nodeIndex = nodesIndexes.find(node => node.id === nodeId);
    if (!nodeIndex || nodeIndex.index === 0) return;

    const nodeIndexUp = nodesIndexes.find(node => node.index === nodeIndex.index - 1);
    if (!nodeIndexUp) return;

    const newIndex = nodeIndex.index - 1;
    const newIndexUp = nodeIndexUp.index + 1;

    const newNodesIndexes = nodesIndexes.map(node => {
      if (node.id === nodeId)
        return { ...node, index: newIndex };
      else if (node.id === nodeIndexUp.id)
        return { ...node, index: newIndexUp };
      else return node;
    });

    saveIndexes({ indexes: newNodesIndexes });

  }

  const onClickMoveDown = (nodeId) => {

    const nodesIndexes = reqFlows.data.flowNodes.map((node, index) => {
      return {
        id: node.id,
        index: node.rootSettings.index ?? index
      }
    });

    const nodeIndex = nodesIndexes.find(node => node.id === nodeId);
    if (!nodeIndex || nodeIndex.index === nodesIndexes.length - 1) return;

    const nodeIndexDown = nodesIndexes.find(node => node.index === nodeIndex.index + 1);
    if (!nodeIndexDown) return;

    const newIndex = nodeIndex.index + 1;
    const newIndexDown = nodeIndexDown.index - 1;

    const newNodesIndexes = nodesIndexes.map(node => {
      if (node.id === nodeId)
        return { ...node, index: newIndex };
      else if (node.id === nodeIndexDown.id)
        return { ...node, index: newIndexDown };
      else return node;
    });

    saveIndexes({ indexes: newNodesIndexes });
  }

  const onSubmitTreeModal = (data) => {
      callSetFlowNode(data);
      history.replace("/")
      reqFlows.refetch();
  }    

  if (reqFlows.loading) return <Loading />;
  if (reqFlows.error) return "Error";

  let nodes = Object.assign([], reqFlows.data.flowNodes);
  nodes = nodes.sort((a, b) => {
    if(a.rootSettings.index != null && b.rootSettings.index != null) {
      return a.rootSettings.index - b.rootSettings.index;
    }
    else 
      return b.id - a.id;
  });

  return (
    <View
      flowId={flowId}
      userType={userType}
      isEditor={isEditor}
      nodes={nodes}
      onClickExecute={onClickExecute}
      onClickTab={onClickTab}
      onClickMoveUp={onClickMoveUp}
      onClickMoveDown={onClickMoveDown}
      onSubmitTreeModal={onSubmitTreeModal}
    />
  );
}

export default FlowList;