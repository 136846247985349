import React, { useEffect, useState } from "react";
import { useGetCall } from "seed/api";
import View from "components/Home.view";
import { Analytics } from "components/util/analytics";
import { useDetail } from "seed/gql";

function Home() {
	
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get("token");
	const userId = urlParams.get("user_id");
	
	const [isAuth, setIsAuth] = useState(false);
	const [user, setUser] = useState(null)

	const reqUser = useDetail(`
		{
			user {
				license {
				}
			}
		}
	`, user?.id);

	const [callAuth] = useGetCall("/auth/user", "",  {
		onCompleted: (data) => {

			sessionStorage.setItem("execution_session_id", Math.random().toString(36).substring(2, 15) + 
				Math.random().toString(36).substring(2, 15));

			setIsAuth(true)
			setUser(data)
      const segment = localStorage.getItem("userProfile")
      const hiddenUserId = localStorage.getItem("hiddenUserId");
			
			Analytics.Identify(hiddenUserId || data.user, {
				'hidden_user_id': hiddenUserId || data.user,
				'user_id': data.user,
				'segment': segment
			});

		}, 
		onError: () => {
			sessionStorage.clear();
			localStorage.clear();
			window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
		}
	})

	const { user: userQL = {} } = reqUser.data;

	useEffect(() => {
		if(!user?.id) return;
		if(!userQL?.id) return;
		if(userQL.license) return;
		window.location.replace("/license_invalid");
	}, [userQL, user]);

	useEffect(() => {    
    if(!user) return console.log("No user data");
    sessionStorage.setItem("show_helps", user?.account_settings?.showHelps);
  }, [user]);

	useEffect(() => {
		if (token) { //Preload data from URL
			sessionStorage.setItem("token", token);
			sessionStorage.setItem("id", userId);
			localStorage.setItem("token", token);
			localStorage.setItem("id", userId);
		} 
		if (localStorage.getItem("id") != null) { //Preload data from localStorage
			sessionStorage.setItem("token", localStorage.getItem("token"));
			sessionStorage.setItem("id", localStorage.getItem("id"));
		}
		callAuth({ user_id: sessionStorage.getItem("id") });
	}, [token, userId]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!isAuth) return null;
	return <View user={user} />;
}

export default Home;