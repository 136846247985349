import React from "react";
import { Formik, Field, Form } from "formik"
import { APP_URL } from "settings";
import { HelpComponent } from "components/util/schema_form/util/help_util";
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";


const FormFlowView = ({
  initialValues = {},
  flow = {},
  folders,
  isEditing = false,
  history,
  onSubmit,
  onClickDelete = () => { },
}) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        {isEditing ? "Editar solución" : "Crear solución"}
      </h3>
    </div>

    <div className="card-body px-4">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) =>
          <Form>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label" for="name">Nombre la solución <span className='text-danger'>*</span></label>
              <Field type="text" name="name" id="name" autocomplete="off"
                class="form-control form-control" maxlength="512" 
                placeholder="Ej. Prospección de cliente"
                required autoFocus={true} />
            </div>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label" for="name">Descripción (Opcional)</label>
              <textarea
                name="description"
                id="description"
                class="form-control form-control"
                value={values.description}
                maxlength="1024"
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            </div>

            <div class="form-group mb-4">
              <label class="input-label" for="folder">Carpeta</label>
              <Field as="select" name="folder" id="folder"
                class="form-control form-control" required
                onChange={(e) => {
                  const val = e.target.value
                  if (val == "create") history.push("/create_folder")
                  else setFieldValue("folder", val)
                }}>
                <option value="0">Sin carpeta</option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>{folder.name}</option>
                ))}
                <option value="create">+ Crear carpeta</option>
              </Field>
            </div>

            {/* <div class="form-group mb-4">
              <span
                class="text-primary"
                data-toggle="collapse"
                href="#flow-advance-options"
                role="button"
                aria-expanded="false"
                aria-controls="flow-advance-options"
              >
                Opciones avanzadas
              </span>
            </div> */}

            <div class="collapse" id="flow-advance-options">

              {/* <div class="form-group mb-4">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="show_animations" id="show_animations"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="show_animations">
                    Mostrar animaciones
                  </label>
                </div>
              </div>

              <div class="form-group mb-5">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="show_pagination" id="show_pagination"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="show_pagination">
                    Mostrar paginación
                  </label>
                </div>
              </div> */}


            </div>

            <div className="row">
              <div className={`col-md-${(isEditing) ? "9 pr-md-0" : "12"} mb-2 mb-md-0`}>
                <button type="submit" class="btn btn-block btn-primary">
                  {isEditing ? "Editar solución" : "Crear solución"}
                </button>
              </div>

              {(isEditing) && (
                <div className="col-md-3">
                  <button type="button" class="btn btn-block btn-outline-danger" onClick={onClickDelete}>
                    Eliminar
                  </button>
                </div>
              )}
            </div>

          </Form>}
      </Formik>
    </div>
  </div>
);

export default FormFlowView;