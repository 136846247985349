import React from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useDetail, useSet } from "seed/gql";
import { usePost } from "seed/api";
import { SET_FOLDER } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/start/folders/FormFolder.view";

function FormSetFolder({ match, onEdit=(flowId) => {}, onDelete=(flowId) => {} }) {

  const history = useHistory();

  const userId = sessionStorage.getItem("id");
  const { folderId = null } = match.params;
  if (!folderId) window.location.replace("/menu");

  const [callUpdate, reqUpdate] = useSet(SET_FOLDER, {
    onCompleted: () => {
      history.goBack();
      onEdit(folderId);
    }
  });

  const [callDelete, reqDelete] = usePost("/folders/delete_folder", {
    onCompleted: () => {
      history.goBack();
      onDelete(folderId);
    },
    onError: () => {
      swal("He ocurrido un error al eliminar la carpeta", "Por favor intente de nuevo mas tarde", "error")
    }
  });

  const reqFolder = useDetail(`{
    folder {
      name
    }
  }`, folderId);

  const onSubmit = (values) => {
    callUpdate({
      id: folderId,
      name: values.name,
    })
  }

  const onDeleteFolder = () => {
    swal({
      title: "Eliminar carpeta",
      icon: "warning",
      text: "¿Estás seguro que desea eliminar la carpeta?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({
          user_id: userId,
          folder_id: folderId
        })
      }
    })
  }

  if (reqFolder.loading) return <Loading />;
  if (reqFolder.error) return "Error";
  const { folder = {} } = reqFolder.data;

  return (
    <View
      folder={folder}
      isEditing={true}
      onSubmit={onSubmit}
      onDeleteFolder={onDeleteFolder}
    />
  );
}

export default FormSetFolder;