import React from "react";
import { HelpComponent } from "components/util/schema_form/util/help_util";
import { bodyContentMapper } from "components/studio/board/nodes/Node.lib";


const CategoryHeader = ({ value }) => {
  if (value != "collection" && value != "variable")
    return <div></div>

  return <span className="d-flex justify-content-between dropdown-header pt-2 px-3">
    <span style={{ color: "#aaa", fontSize: "0.8em" }}>
      {value == "collection" ? "Tablas" : ""}
      {value == "variable" ? "Referencias" : ""}
    </span>
  </span>
}

const CollectionItem = ({ collection, disabled, isOnSelector = false }) =>
  <div className="d-flex align-items-center pl-1 pr-4 py-2"
    style={{
      pointerEvents: "none",
      color: "#777", fontSize: "1rem",
      ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff" } : {}
    }}>
    <i className="fas fa-table mr-3" style={{ color: "#888" }}></i>
    <span style={{ color: "#606a70", fontSize: "0.9em" }}>{collection?.name.substring(1)}</span>
  </div>


const AddCollectionItem = ({ onClickAddCollection }) =>
  <div className="d-flex align-items-center px-1 py-2 w-100"
    onClick={onClickAddCollection}
    style={{ color: "#777", fontSize: "1rem" }}>
    <i className="fas fa-plus mr-3" style={{ color: "#888" }}></i>
    <span style={{ color: "#777", fontSize: "0.9em" }}><i>Crear tabla</i></span>
  </div>


const VariableItem = ({ node, disabled, isOnSelector = false }) => {

  const title = node.action.uiSettings?.node?.title ?? node.action.uiSettings?.general?.title;
  const icon = node.action.app.uiSettings?.icon ?? "fas fa-project-diagram"
  const subtitle = bodyContentMapper(node.action.uiSettings?.node ?? [], node.input, "subtitle", [])
  const numOutput = node.output_count > 1

  return (
    <div className="d-flex align-items-center pl-1 pr-1"
      style={{
        pointerEvents: "none",
        color: "#777", fontSize: "1rem",
        ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff" } : {}
      }}>
      <i className={icon + " mr-4"} style={{ color: "#888" }}></i>
      <span
        style={{
          color: "#a0a0a0", fontSize: "0.7em", background: "#fff", border: "1px solid #e0e0e0", padding: "0 0.25rem",
          marginLeft: "-1.45rem", marginTop: "1rem", position: "relative"
        }}><small style={{ fontSize: "11px", color: "#9095a0" }}>#</small>{node.idx}</span>
      <div className="mt-2 ml-1">
        <span className="text-truncate d-inline-block"
          style={{ color: "#606a70", fontSize: "0.9em", maxWidth: subtitle != "" ? "10rem" : "100rem" }}>
          {title}
        </span>
        {subtitle != "" ?
          <span className="ml-2 text-truncate d-inline-block"
            style={{ color: "#aaa", fontSize: "0.8em", maxWidth: "10rem" }}>({subtitle})</span> : null
        }
        {numOutput > 1 ?
          <span className="ml-1"
            style={{ color: "#b0b0b0", fontSize: "0.75em" }}>({node.label ?? node.name})</span> : null
        }
      </div>

    </div>
  )
}

const AttributeItem = ({ label, disabled, isOnSelector = false }) =>
  <div className="d-flex align-items-center pl-1 pr-4 py-2"
    style={{
      pointerEvents: "none",
      color: "#777", fontSize: "1rem",
      ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff" } : {}
    }}>
    {/*<i className="fas fa-diagram-project mr-3" style={{color: "#888"}}></i> */}
    <span style={{ color: "#777", fontSize: "0.9em" }}>
      {label}
    </span>
  </div>

const OutputDescription = ({ node }) => {
  const structure = node?.structure ?? [];
  const outputDescription = "Campos disponibles" + "\n" +
    (structure ?? []).map((attr, idx) => "  - " + (attr.label ?? attr.name)).join("\n")
  return structure.length > 0 ? <HelpComponent helpMessage={outputDescription} /> : null
}

export { CategoryHeader, CollectionItem, AddCollectionItem, VariableItem, AttributeItem, OutputDescription };