import React from "react";
import { HelpComponent } from "components/util/schema_form/util/help_util";


const getPlanName = (plan) => {
  if (plan == "BETA") return "Beta";
  if (plan == "BASIC") return "Básico";
  if (plan == "PRO") return "PRO";
  if (plan == "ENTERPRISE") return "Empresarial";
  return "";
}

const BillingView = ({ user, isDetailsShown, setIsDetailsShown, onRechargePoints }) => (
  <div id="billing" className="card">
    <div className="card-body animate__animated animate__fadeIn animate__faster">
      <div id="billing-container" className="card">

        <div className="card-body">
          <div className="row">

            <div id="plan-details" className="col-md mb-3 mb-md-0">

              <div className="mb-4">
                <p>
                  {user?.license?.description}
                </p>
              </div>

              <span className="card-subtitle mb-0">Créditos disponibles</span>
              <h2 className="h1 text-primary">{user?.creditsAvailable}</h2>

            </div>
          </div>
        </div>

        <hr className="my-3" />

        <div className="card-body">

          <LinearChart
            label={"Créditos usados"}
            used={user?.creditsUsed}
            total={user?.creditsTotal}
          />

          <LinearChart
            label={"Tiempo de ejecución"}
            used={parseFloat(user?.executionTimeUsed).toFixed(2)}
            total={user?.license?.executionTime}
            usedLabel="s"
            totalLabel="s"
          />

          <LinearChart
            label={"Pasos de ejecución"}
            used={user?.executionStepsUsed}
            total={user?.license?.executionSteps}
          />

          <LinearChart
            label={"Almacenamiento"}
            used={parseFloat(user?.storageUsed / 1024 / 1024).toFixed(2)}
            total={parseFloat(user?.license?.fileStorage / 1024 / 1024).toFixed(2)}
            usedLabel="MB"
            totalLabel="MB"
          />

          <div
            className="pl-5"
            style={{ display: "grid", gap: "1rem" }}
          >
            {
              user?.flows?.map((flow) => (
                <LinearChart
                  key={`flow-${flow.id}`}
                  label={flow.name}
                  used={parseFloat(flow.storageUsage / 1024 / 1024).toFixed(2)}
                  usedLabel="MB"
                />
              ))
            }
          </div>

          <div className="d-flex justify-content-between">
            <a className="btn btn-primary py-1 px-2" onClick={onRechargePoints}>
              Recargar
            </a>
            <a className="btn btn-secondary py-1 px-2" data-toggle="collapse" href="#creditDetails"
              role="button" aria-expanded="false" aria-controls="creditDetails"
              onClick={() => setIsDetailsShown((prev) => !prev)}
            >
              {isDetailsShown ? "Ocultar" : "Mostrar"} desglose
            </a>
          </div>

        </div>

        <div className="collapse" id="creditDetails">
          <hr className="my-3" />
          <div className="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <i class="fas fa-brain list-group-icon"></i> Análisis sentimental
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias exercitationem quod porro illum temporibus qui voluptas!
                  Placeat, corporis doloremque. Corrupti error minus quo a?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "20%" }}
                    aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="list-group-item">
                <i class="fas fa-copy list-group-icon"></i> Clasificación de documentos
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias exercitationem quod porro illum temporibus qui voluptas!
                  Placeat, corporis doloremque. Corrupti error minus quo a?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "20%" }}
                    ria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
              <li class="list-group-item">
                <i class="fas fa-copy list-group-icon"></i> Extracción de datos de documentos
                <HelpComponent helpMessage={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, aliquam illo.
                  Inventore quam sequi molestias?`} />
                <div class="progress rounded-pill my-3">
                  <div class="progress-bar" role="progressbar" style={{ width: "10%" }}
                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
);

const LinearChart = ({ label, used, total, usedLabel="", totalLabel="" }) => <div>
  <div class="row align-items-center flex-grow-1 mb-2">
    <div class="col">
      <h4 class="card-header-title">{label}</h4>
    </div>
    <div class="col-auto">
      <span class="font-weight-bold text-dark">{used}{usedLabel}</span> {total ? "usados de ": ""}{total}{totalLabel}
    </div>
  </div>
  <div class="progress rounded-pill mb-3">
    <div 
      style={{
        width: `${(used / total) * 100}%`
      }}
      class="progress-bar" 
      role="progressbar" 
      aria-valuenow={used}
      aria-valuemin="0" 
      aria-valuemax={total}
    />
  </div>
</div>

export default BillingView;