import React, { useState } from "react";
import { Formik, Form, Field } from "formik";

const Zip = ({ 
  zipFieldName,
  onDownloadZip = () => {} 
}) => {
  return <div className="card">
    <div class="card-header">
      <h3 class="mb-0">{"Descargar zip"}</h3>
    </div>
    <div class="card-body py-4">
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          onDownloadZip(zipFieldName, values.limit);
        }}
      >
        {({ values, setFieldValue }) =>
          <Form>

            <div class="form-group mb-4">
              <span class="d-flex justify-content-between dropdown-header pt-0 pb-2 px-0">
                <span style={{ color: "#777" }}>Archivos</span>
              </span>
              <div class="input-group">
                <Field
                  as="select"
                  name="limit"
                  id="limit"
                  class="form-control"
                  required={true}
                >
                  <option value="">Seleccione un tipo</option>
                  <option value="15">Últimos 15</option>
                  <option value="30">Últimos 30</option>
                  <option value="50">Últimos 50</option>
                </Field>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button
                type="submit"
                class="btn btn-block btn-primary mt-3"
              >
                Descargar
              </button>

            </div>

          </Form>}
      </Formik>
    </div>
  </div>
}

export default Zip;