import React from "react";
import { Formik, Field, Form } from "formik";

const custom_viewsView = ({
  customViews,
  onSubmit,
}) => {
  return <div
    className="card"
  >
    <div
      className="card-header"
    >
      <h3 className="mb-0">Vistas personalizadas</h3>
    </div>
    <div
      className="card-body px-4"
    >
      <Formik
        initialValues={{ custom_views: customViews || [] }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) =>
          <Form>

            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Ícono</th>
                  <th scope="col">URL</th>
                  <th scope="col">Ancho</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  values.custom_views.map((view, index) => (
                    <tr
                      key={`customView-${index}`}
                      className="align-middle"
                    >
                      <td>
                        <Field
                          type="text"
                          name={`custom_views[${index}].name`}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <Field
                          type="text"
                          name={`custom_views[${index}].icon`}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={decodeURIComponent(view.url)}
                          onChange={(e) => {
                            const newViews = [...values.custom_views];
                            newViews[index].url = encodeURIComponent(e.target.value);
                            setFieldValue("custom_views", newViews);
                          }}
                        />
                      </td>
                      <td>
                        <Field
                          type="text"
                          name={`custom_views[${index}].width`}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => {
                            const newViews = [...values.custom_views];
                            newViews.splice(index, 1);
                            setFieldValue("custom_views", newViews);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-2"
                          onClick={() => {
                            const newViews = [...values.custom_views];
                            const viewToMove = newViews.splice(index, 1)[0];
                            newViews.splice(index - 1, 0, viewToMove);
                            setFieldValue("custom_views", newViews);
                          }}
                        >
                          <i className="fas fa-arrow-up"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-2"
                          onClick={() => {
                            const newViews = [...values.custom_views];
                            const viewToMove = newViews.splice(index, 1)[0];
                            newViews.splice(index + 1, 0, viewToMove);
                            setFieldValue("custom_views", newViews);
                          }}
                        >
                          <i className="fas fa-arrow-down"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" className="text-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100"
                      onClick={() => {
                        setFieldValue("custom_views", [...values.custom_views, { name: "" }]);
                      }}
                    >
                      Añadir vista personalizada
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>

            <button
              type="submit"
              className="btn btn-primary w-100 mt-4"
            >
              Guardar
            </button>

          </Form>
        }
      </Formik>
    </div>
  </div>;
}

export default custom_viewsView;