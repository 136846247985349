import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/tmisc/auth/Invitation.view";

function Invitation() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invitation = searchParams.get("invitation")
  const [participant, setParticipant] = useState({});
  if (invitation == null) window.location.replace("/logout");

  const [callParticipant, reqParticipant] = usePost("/users/get_participant_invitation", {
    onCompleted: (data) => {
      setParticipant(data);
    },
    onError: () => {
      window.location.replace("/logout");
    },
    includeAuth: false
  })

  const [callAccept, reqAccept] = usePost("/flows/accept_invitation", {
    onCompleted: (data) => {
      sessionStorage.setItem("developer_mode", data.developer_mode);
      sessionStorage.setItem("token", data.key);
      sessionStorage.setItem("id", data.user);
      window.location.replace(`/app/studio/${participant.flow.id}/flow`)
    }
  })

  useEffect(() => {
    callParticipant({ token: invitation })
  }, [])

  const onSubmit = () => {
    callAccept({ participant: participant.id });
  }

  if (reqParticipant.loading) return <Loading />;

  return <View participant={participant} onSubmit={onSubmit} />;
}

export default Invitation;