import React from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/studio/forms/tree_settings/TreeSettings.view";


function TreeSettings({
  match,
  flowId,
  showStudioButton=false,
  onSubmit
}) {

  const nodeId = match?.params?.nodeId;
  const reqNode = useDetail(`{
    flowNode {
      rootName
      rootDescription
      rootSettings
    }
  }`, nodeId);

  if (reqNode.loading) return <Loading />;
  if (reqNode.error) return "Error";

  const { flowNode = {} } = reqNode.data;

  const onSubmitForm = (values) =>
    onSubmit(values)

  return (
    <View
      flowId={flowId}
      nodeId={nodeId}
      initialValues={flowNode}
      showStudioButton={showStudioButton}
      onSubmit={onSubmitForm}
    />
  );
}


export default TreeSettings;