import { API_URL } from "settings";

const uploadDragFiles = async ({
  files = [],
  onUpload = () => {},
  onUploadError = () => {}
}) => {

  const it = uploadFiles(files);
  const res = [];
  let running = true;

  while(running) {
        
      let it_value = it.next()
      if(it_value.done) break;
      
      let response = await it_value.value;

      if(!response.ok) {
          onUploadError(files[response.index]);
          break;
      }

      res.push(response.body);

  }

  onUpload(res);
  return res;

}

const requestFiles = ({
  multiple = false,
  accept = "*",
  onUpload = () => {},
  onUploadError = () => {}
}) => {

  let input = document.createElement("input");
  input.type = "file";
  input.accept = accept;
  input.multiple = multiple;
  input.style.display = "none";
  document.body.appendChild(input);

  input.addEventListener("change", async () => {

    const files = Array.from(input.files);
    const it = uploadFiles(files);
    const res = [];
    let running = true;

    while(running) {
        
        let it_value = it.next()
        if(it_value.done) break;
        
        let response = await it_value.value;

        if(!response.ok) {
            onUploadError(files[response.index]);
            break;
        }

        res.push(response.body);

    }

    onUpload(res);

  });

  input.click();

}

function* uploadFiles(files) {
  for(let i = 0; i < files.length; i++)
      yield uploadFile(files[i], i);
}

async function uploadFile(file, i) {

  let url = `${API_URL}/files/`;
  let formData = new FormData();
  formData.append("file", file);

  let res = await fetch(url, {
      cache: "no-cache",
      method: "POST",
      headers: {
        "Authorization": `Token ${sessionStorage.getItem("token")}`
      },
      body: formData
  });

  let json = await res.json();

  return {
      body: json,
      index: i,
      ok: res.status == 200 || res.status == 201
  };

}

export { requestFiles, uploadDragFiles };