import React from "react";
import NodeContent from "components/studio/board/nodes/helpers/Content";
import TargetPoints from "components/studio/board/nodes/helpers/TargetPoints";
import DropdownErrors from "components/studio/board/nodes/helpers/DropdownErrors";
import InsertChildren from "components/studio/board/nodes/helpers/InsertChildren";
import HelpPlaceholder from "components/studio/board/nodes/helpers/HelpPlaceholder";


const ControlNode = ({
  app,
  action,
  nodeId,
  nodeInput,
  nodeOutput,
  errors,
  warnings,
  isSelected,
  isDeveloper,
  onEdit,
  onSelectNode
}) => (

  <div className="control-node">
    <div className="text-center drag-handle-container" onClick={onEdit}>

      <div className="d-flex align-items-center control-node-container">
        <div className="node-content">
          <NodeContent
            app={app}
            action={action}
            nodeInput={nodeInput}
            nodeOutput={nodeOutput}
            isControl={true}
            isSelected={isSelected}
            onSelectNode={onSelectNode}
          />
        </div>
      </div>

      <TargetPoints nodeId={nodeId} action={action} />
      <HelpPlaceholder nodeId={nodeId} action={action} />
    </div>

    {/* Add child node buttons */}
    {!isDeveloper && (
      <InsertChildren
        nodeId={nodeId}
        isConditional={true}
      />
    )}

    {(warnings.length > 0 || errors.length > 0) && (
      <div className="dropright warnings-errors-control-node">
        <div className="text-center dropdown-toggle dropdown-icons" data-toggle="dropdown" aria-haspopup="true" data-offset="-50,-330">
          {errors.length > 0 ? (
            <div className="errors">
              <i class="fa-solid fa-circle-xmark text-danger"></i>
            </div>
          ) : (
            <div className="warnings">
              <i class="fa-solid fa-triangle-exclamation text-warning"></i>
            </div>
          )}
        </div>

        <DropdownErrors
          isControl={true}
          warnings={warnings}
          errors={errors}
          actionUiSettings={action.uiSettings}
        />
      </div>
    )}

  </div>
);

export default ControlNode;
