import React from "react";

const GuidesVisualizerView = ({ tutorial }) => {
  return (
    <div
      className="h-100 d-flex flex-column align-items-center justify-content-center bg-light"
      style={{ padding: "2rem" }}
    >
      <div
        className="video-container"
        style={{
          width: "90vw",
          height: "90vh",
          backgroundColor: "#000",
          borderRadius: "12px",
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <iframe
          src={tutorial.media}
          title={tutorial.name}
          frameBorder="0"
          allowFullScreen
          style={{
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </div>

      {/* Descripción debajo del video */}
      <div style={{ marginTop: "1rem", textAlign: "center", color: "#444" }}>
        {tutorial.description}
      </div>
    </div>
  );
};

export default GuidesVisualizerView;
