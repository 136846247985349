import React from "react";
import moment from "moment";
import PaginationFooter from "components/util/helpers/PaginationFooter";

const ChatHistoryView = ({
  chats,
  chatId,
  pageNum,
  totalPages,
  setPageNum,
  changeChat,
}) => (
  <div className="px-2 scrollbar-narrow">
    <div className="w-100 px-4 h-100">
      <div className="row h-100">
        <div className="col-lg-12" >
          <h2 className="mb-4 mt-2">Chats</h2>

          <div className="row">
            <div className="col-12">

              {
                chats.map(chat =>
                  <div
                    key={`chat-${chat.id}`}
                    class="d-flex justify-content-between align-items-center pr-2 pl-1 py-2 w-100 mb-2 rounded"
                    style={{ border: "1px solid #E7EAF3" }}
                  >
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="d-flex align-items-center mx-3 w-100 text-truncate">
                        <i className="fa fa-user-circle fa-2x mr-3" />
                        <div>
                          <div className="h5 text-truncate mt-1 mb-1">
                            {chat.user.firstName} {chat.user.lastName}
                          </div>
                          <label className="h6 font-weight-light">{moment(chat.createdAt).format("DD.MMM / HH:mm")}</label>
                        </div>
                      </div>
                      {
                        chatId != chat.id &&
                          <button
                            className="btn btn-sm btn-outline-primary mb-1 mr-2"
                            onClick={() => changeChat(chat.id)}
                            style={{ width: "10rem" }}
                          >
                            Ver chat
                          </button>
                      }
                      {
                        chatId == chat.id &&
                          <div className="btn btn-sm btn-primary mb-1 mr-2" style={{ width: "10rem" }}>
                            Chat abierto
                          </div>
                      }
                    </div>
                  </div>
                )
              }

            </div>
          </div>

          <PaginationFooter
            pageNum={pageNum}
            totalPages={totalPages}
            onClickPage={setPageNum}
          />

        </div>

        <button
          className="btn btn-sm btn-lighter mt-2 w-100"
          onClick={() => changeChat(null)}
        >
          Nuevo chat
        </button>

      </div>
    </div>
  </div>
);

export default ChatHistoryView;