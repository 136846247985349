import React from "react";
import View from "components/tmisc/executions/charts/helpers/BarChart.view";

const defaultOptions = {
  chart: {
    type: 'bar'
  },
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  fill: {
    opacity: 1
  },
}

function BarChart({ data, options = defaultOptions, labels = null, height = 350 }) {

  if (labels) {
    options.xaxis = {
      categories: labels
    }
  }

  return (
    <View
      options={options}
      series={data}
      height={height}
    />
  );
}

export default BarChart;