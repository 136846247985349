import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "seed/helpers"
import { NavLink, Link } from "react-router-dom";
import { ScriptTag } from "seed/helpers";
import Users from "components/tmisc/organizations/users/Users";
import Company from "components/tmisc/organizations/company/Company";
import Orders from "components/tmisc/organizations/orders/Orders";
import "styles/css/tmisc/Settings.css";


const OrganizationsView = ({
	user,
	organizations,
	selectedOrganization,
	setSelectedOrganization
}) => (

	<div id="settings" className="position-relative">
		<div className="container px-2 px-md-7 py-4 py-md-7 py-xl-8">
			<div class="row m-0 w-100">

				<div className="col-md-4">
					<div id="start-settings" class="card">
						<div class="card-body p-0">
							<aside class="navbar navbar-vertical-aside navbar-vertical navbar-bordered navbar-vertical-aside-initialized">
								<div class="navbar-vertical-container">
									<div class="navbar-vertical-footer-offset">

										<div id="start-settings-header" class="d-flex align-items-center pl-2 py-1">

											<Link to="/start" className="d-flex align-items-center justify-content-center px-3">
												<i class="fas fa-arrow-left d-block"></i>
											</Link>

											<div class="rounded w-auto">
												<Link to="/" className="back-button-flow mt-n1"></Link>
											</div>

											<div className="ml-1 d-flex align-items-center justify-content-between flex-grow-1">
												<a class="navbar-dropdown-account-wrapper rounded pl-1 pr-2" style={{ background: "transparent" }}>
													<div className="ml-2">
														<span className="d-block h5 mb-0">
															{user?.firstName} {user?.lastName}
														</span>
														<span className="d-block font-size-sm text-body">
															Plan {(user?.license?.name ?? "básico").toLowerCase()}
														</span>
													</div>
												</a>

												{organizations.length > 0 &&
													<div className="ml-auto mr-3">
														<select
															className="custom-select custom-select-sm"
															value={selectedOrganization}
															onChange={(e) => {
																setSelectedOrganization(e.target.value);
																sessionStorage.setItem("selectedOrganization", e.target.value);
																window.dispatchEvent(new Event("organizationChanged"));
															}}
															style={{
																width: "auto",
																...organizations.length == 1 ?
																	{ background: "none", pointerEvents: "none", paddingRight: "0.85rem" } : {}
															}}
														>
															{organizations.map((org) => (
																<option key={org.id} value={org.id}>
																	{org.name}
																</option>
															))}
														</select>

													</div>
												}
											</div>
										</div>

										<div id="start-settings-menu" class="navbar-vertical-content mt-3 mb-5 overflow-hidden">
											<ul class="navbar-nav navbar-nav-lg nav-tabs">

												<li class="nav-item">
													<NavLink to="/organizations/details" className={`js-nav-tooltip-link nav-link`}>
														<i class="fas fa-building ml-1 mr-3"></i>
														<span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Ajustes generales</span>
													</NavLink>
												</li>

												<li class="nav-item">
													<NavLink to="/organizations/users" className={`js-nav-tooltip-link nav-link`} >
														<i class="fas fa-users ml-1 mr-3"></i>
														<span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Usuarios</span>
													</NavLink>
												</li>

												<li class="nav-item">
													<NavLink to="/organizations/orders" className={`js-nav-tooltip-link nav-link`} >
														<i class="fas fa-clipboard ml-1 mr-3"></i>
														<span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Ordenes</span>
													</NavLink>
												</li>

											</ul>
										</div>

									</div>
								</div>
							</aside>
						</div>
					</div>
				</div>

				<div className="col-md-8 mt-3 mt-md-0">
					<Switch>
						<Route
							path="/organizations/users"
							render={(props) => <Users
								key={selectedOrganization}
								{...props}
								selectedOrganization={selectedOrganization} />}
						/>
						<Route
							path="/organizations/orders"
							render={(props) => <Orders
								key={selectedOrganization}
								{...props}
								selectedOrganization={selectedOrganization}
							/>}
						/>
						<Route
							path="/organizations/details"
							render={(props) => <Company
								key={selectedOrganization}
								{...props}
								selectedOrganization={selectedOrganization}
							/>}
						/>
						<Redirect to="/organizations/users" />
					</Switch>
				</div>

			</div>
		</div>

	</div>
);

export default OrganizationsView;