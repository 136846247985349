import React from "react";
import View from "components/studio/board/executions/Executions.view";


function Executions({
  flow,
  isExecutionsCollapsed,
  onCloseExecutions
}) {

  const onClickCloseExecutions = () => {
    onCloseExecutions();
  }

  return (
    <View
      flow={flow}
      isExecutionsCollapsed={isExecutionsCollapsed}
      onClickCloseExecutions={onClickCloseExecutions}
    />
  );
}


export default Executions;