import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";
import { HelpComponent } from "components/util/schema_form/util/help_util";
import { APP_URL } from "settings";
import { StyleTag } from "seed/helpers";


const getLabelName = (options, value) => {
  return options.find((option) => option.value == value)?.label ?? value;
}

const CollaborationView = ({
  flow,
  organizations,
  shareOrganizations,
  shareNumbers,
  shareEmails,
  shareUsers,
  shareRole,
  organizationInputValue,
  phoneInputValue,
  emailInputValue,
  userInputValue,
  participants,
  roleOptions,
  isDeveloper,
  isPhoneValid,
  isEmailValid,
  isUserValid,
  onChangeShareNumbers,
  onChangeShareEmails,
  onChangeShareUsers,
  onChangeShareRole,
  onClickShareUsers,
  onClickSharePhones,
  onClickShareEmails,
  onChangeParticipantRole,
  onRemoveParticipant,
  onPhoneInputChange,
  onEmailInputChange,
  onUserInputChange,
  onClickShareOrganizations,
  onChangeShareOrganizations,
  onOrganizationInputChange
}) => (
  <div className="card pb-4" style={{ overflowX: "hidden" }}>

    <div className="card-header">
      <h3>Compartir solución</h3>
    </div>

    <div className="card-body px-5 pt-3 pb-4">

      <span class="d-flex justify-content-between dropdown-header pt-2 pb-2 px-0">
        <span style={{ color: '#777' }}>Liga de acceso</span>
      </span>

      <span>
        <a href={`${APP_URL}/p/${flow.token}`}>
          <b>{`${APP_URL}/p/${flow.token}`}</b>
        </a>
        <CopyClipboardButton link={decodeURIComponent(`${APP_URL}/p/${flow.token}`)} />
      </span>

      <CollaborationInput
        placeholder="Agregar número de teléfono"
        label="Números de teléfono"
        helperText="Número de teléfono inválido"
        isRoleSelector={false}
        isValid={isPhoneValid}
        values={shareNumbers}
        inputValue={phoneInputValue}
        participants={participants.filter((participant) => participant.channel == "WA")}
        onChange={onChangeShareNumbers}
        onInputChange={onPhoneInputChange}
        onShare={onClickSharePhones}
        onRemoveParticipant={onRemoveParticipant}
      />

      <CollaborationInput
        placeholder="Agregar dirección de correo"
        label="Correos electrónicos"
        helperText="Dirección de correo inválida"
        isRoleSelector={false}
        isValid={isEmailValid}
        values={shareEmails}
        inputValue={emailInputValue}
        participants={participants.filter((participant) => participant.channel == "EMAIL")}
        onChange={onChangeShareEmails}
        onInputChange={onEmailInputChange}
        onShare={onClickShareEmails}
        onRemoveParticipant={onRemoveParticipant}
      />

      <CollaborationInput
        placeholder="Agregar dirección de correo"
        label="Accesos"
        helperText="Dirección de correo inválida"
        isRoleSelector={true}
        isValid={isUserValid}
        role={shareRole}
        values={shareUsers}
        inputValue={userInputValue}
        roleOptions={roleOptions}
        participants={participants.filter((participant) => participant.channel == "WEB")}
        onChange={onChangeShareUsers}
        onInputChange={onUserInputChange}
        onChangeRole={onChangeShareRole}
        onShare={onClickShareUsers}
        onChangeParticipantRole={onChangeParticipantRole}
        onRemoveParticipant={onRemoveParticipant}
      />

      <CollaborationInput
        placeholder="Selecciona organizaciones"
        label="Organizaciones"
        helperText="Selecciona una organización válida"
        isRoleSelector={false}
        isValid={true}
        values={shareOrganizations}
        inputValue={organizationInputValue}
        participants={participants.filter((participant) => participant.type === "ORGANIZATION")}
        options={organizations.map(org => ({ label: org.name, value: org.id }))}
        onChange={onChangeShareOrganizations}
        onInputChange={onOrganizationInputChange}
        onShare={onClickShareOrganizations}
        onRemoveParticipant={onRemoveParticipant}
      />

    </div>

  </div>
);

const CollaborationInput = ({
  placeholder,
  label,
  helperText,
  isRoleSelector = false,
  isValid,
  role,
  values,
  inputValue,
  roleOptions,
  participants = [],
  onChange,
  onInputChange,
  onChangeRole,
  onShare,
  onChangeParticipantRole,
  onRemoveParticipant
}) => {
  return (<>

    <span class="d-flex justify-content-between dropdown-header pt-2 pb-2 px-0 mt-4">
      <span style={{ color: '#777' }}>{label}</span>
    </span>

    <div className="row justify-content-between align-items-end mb-2">

      <div className={values.length > 0 ? "col-md-8" : "col-md-12"}>
        <Autocomplete
          id="phoneSelector"
          multiple
          freeSolo
          autoFocus={true}
          options={[]}
          inputValue={inputValue}
          value={values}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) =>
            <TextField
              {...params}
              label=""
              autoFocus={true}
              variant="outlined"
              placeholder={placeholder}
              helperText={!isValid ? helperText : ''}
            />
          }
          onChange={onChange}
          onInputChange={onInputChange}
          style={{ width: "100%" }}
        />
        <StyleTag content={`
          #phoneSelector-helper-text {
            color: #d9534f;
          }`
        } />
      </div>

      {
        isRoleSelector && values.length > 0 && (
          <div className="col-md-4 d-flex flex-column align-items-center">

            <div className="px-0" style={{ width: "100%" }}>
              <Autocomplete
                id="roleSelector"
                required={true}
                value={role}
                options={roleOptions.map((role) => role.value)}
                getOptionLabel={(option) => getLabelName(roleOptions, option)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    required={true}
                    placeholder="Acceso"
                  />
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <span className="w-100 py-2" style={{ fontSize: "0.9em" }}>{getLabelName(roleOptions, option)}</span>
                  </React.Fragment>
                )}
                onChange={(event, newValue) => onChangeRole(newValue)}
                style={{ width: "100%" }}
              />
            </div>

            <button className="btn btn-primary btn-block mt-3" onClick={onShare}>Compartir</button>

          </div>
        )
      }

      {
        !isRoleSelector && values.length > 0 && (
          <div className="col-md-4 d-flex flex-column align-items-center">
            <button className="btn btn-primary btn-block mt-3" onClick={onShare}>
              Compartir
            </button>
          </div>
        )
      }

    </div>

    {
      participants.map((participant) => (
        <div 
          className="row justify-content-between mx-1 py-3" 
          key={`participant-${participant.id}`}
          style={{ borderBottom: "1px solid #e0e5ea" }}
        >

          <div className="col-md-7 px-2">
            <span className="d-block h5 text-hover-primary mb-0">
              {
                function(){
                  let displayUser = `${participant.user?.firstName??''} ${participant.user?.lastName??''}`;
                  if(displayUser == " ") displayUser = "Sin cuenta";
                  return displayUser;
                }()
              }
            </span>
            <span className="d-block font-size-sm text-body">
              {
                function(){
                  let displayUser = "";
                  if (participant.channel == "EMAIL") displayUser = participant.user?.email ?? participant.email;
                  else if (participant.channel == "WA") displayUser = participant.user?.phone ?? participant.phone;
                  else displayUser = participant.user?.email ?? participant.email;
                  return displayUser;
                }()
              }
            </span>
          </div>

          <div className="col-md-4">
            {
              isRoleSelector && (
                <Autocomplete
                  id={`roleSelectorUser${participant?.user?.id}`}
                  forcePopupIcon
                  value={participant.type}
                  options={roleOptions.map((role) => role.value)}
                  getOptionLabel={(option) => getLabelName(roleOptions, option)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      placeholder="Buscar rol"
                    />
                  }
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <span className="w-100 py-2" style={{ fontSize: "0.9em" }}>{getLabelName(roleOptions, option)}</span>
                    </React.Fragment>
                  )}
                  onChange={(event, newRole) => onChangeParticipantRole(participant?.user?.id, newRole)}
                  style={{ width: "100%" }}
                />
              )
            }
          </div>

          <div className="col-md-1">
            <a className="btn btn-sm mr-1" onClick={() => onRemoveParticipant(participant?.id)}>
              <i class="fa-solid fa-square-minus" style={{ fontSize: "1.7rem", color: "#f07070" }}></i>
            </a>
          </div>

        </div>
      ))}

  </>);
}

export default CollaborationView;