import React, { useState } from "react";
import View from "components/presentation/flows/Flows.view";


function Flows({ flow, userType, isEditor, chatId }) {

  const [processesTab, setProcessesTab] = useState("flows");

  const onClickTab = tabName =>
    setProcessesTab(tabName)

  return (
    <View
      flow={flow}
      processesTab={processesTab}
      userType={userType}
      chatId={chatId}
      isEditor={isEditor}
      onClickTab={onClickTab}
    />
  );
}

Flows.propTypes = {};

export default Flows;