import React from "react";
import View from "components/tmisc/executions/charts/helpers/PieChart.view";


const defaultOptions = {
  chart: {
    type: 'pie',
  },
  labels: [],
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom'
      }
    }
  }]
}

function PieChart({ data, options = defaultOptions, labels = null, height = 350 }) {

  if (labels) {
    options.labels = labels
  }

  return (
    <View
      options={options}
      series={data}
      height={height}
    />
  );
}

export default PieChart;