import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import { useQuery, useSet } from "seed/gql";
import { SET_SUGGESTION } from "seed/gql/queries";
import View from "components/studio/toolbox/ideas/IdeasHistory.view";

function IdeasHistory({ 
  flowId,
  pageId,
  onClose = () => { },
  reloadFlow = () => { }
}) {

  const [selectedSuggestion, setSelectedSuggestion] = useState({});

  const reqFavoriteSuggestions = useQuery(`{
    suggestions {
      id
      type
      description
      response
      isFavorite
      isShown
      extraData
    }
  }`, `(flow.id=${flowId} AND is_favorite=true AND type=SUGGESTION)`);

  const reqHistorySuggestions = useQuery(`{
    suggestions {
      id
      type
      description
      response
      isFavorite
      isShown
      extraData
    }
  }`, `(flow.id=${flowId} AND is_favorite=false AND is_shown=true AND type=SUGGESTION)`);

  const [callSetSuggestions, reqSetSuggestions] = useSet(SET_SUGGESTION, {
    onCompleted: (data) => {
      reqFavoriteSuggestions.refetch();
      reqHistorySuggestions.refetch();
    }
  }); 

  const [calGenerateGuide, reqGenerateGuide] = usePost('/flows/generate_suggestion_guide', {
    onCompleted: () => {
      reloadFlow();
      onClose();
    }
  });

  const onChangeSuggestion = (values) => 
    callSetSuggestions(values);

  const onSelectSuggestion = (suggestion) =>
    setSelectedSuggestion(suggestion);

  const onCreateGuide = (suggestion) =>
    calGenerateGuide({ flow_id: flowId, page_id: pageId, suggestion });

  useEffect(() => {
    reqFavoriteSuggestions.refetch();
    reqHistorySuggestions.refetch();
  }, [flowId]);

  const { suggestions: _favoriteSuggestions = [] } = reqFavoriteSuggestions.data || {};
  const { suggestions: _historySuggestions = [] } = reqHistorySuggestions.data || {};

  let favoriteSuggestions = _favoriteSuggestions.map(suggestion => Object.assign({}, suggestion));
  let historySuggestions = _historySuggestions.map(suggestion => Object.assign({}, suggestion));

  return (
    <View
      savedSuggestions={favoriteSuggestions}
      historySuggestions={historySuggestions}
      selectedSuggestion={selectedSuggestion}
      onChangeSuggestion={onChangeSuggestion}
      onSelectSuggestion={onSelectSuggestion}
      onCreateGuide={onCreateGuide}
      onClose={onClose}
    />
  );

}

export default IdeasHistory;