import React from "react";
import { Formik, Form, Field } from "formik";

const FormView = ({ onSubmit }) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Crear organización</h3>
    </div>

    <div className="card-body animate__animated animate__fadeIn animate__faster">
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            {/* Nombre */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="name" className="col-md-3 mb-0">
                  Nombre <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  maxLength="512"
                  className="form-control flex-grow-1"
                  required
                />
              </div>
            </div>

            {/* Apellido */}
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label htmlFor="description" className="col-md-3 mb-0">
                  Descripción <span className="text-danger">*</span>
                </label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  rows="4"
                  className="form-control flex-grow-1"
                  required
                />
              </div>
            </div>

            {/* Botón de guardar */}
            <div className="text-right">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default FormView;
