import React from "react";
import View from "components/tmisc/organizations/users/orders/List.view";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";

const List = ({userId}) => {

    const formatDate = (date) => {
        if (!date) return '-';
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth()+1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    };

    const qUser = useDetail(`
        {
            user{
                orders{
                    type
                    startDate
                    endDate
                    quantity
                    paymentDate
                    license{
                        name
                    }
                }
            }
        }
    `, userId);

    if (qUser.loading) return <Loading />;
    if (qUser.error) return "Error";

    const { user } = qUser.data;

    return <View user={user} formatDate={formatDate}/>;
}

export default List;