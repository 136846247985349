import React from "react";
import ProcessIcon from "components/util/helpers/ProcessIcon";
import { getFlowName } from "components/util/flow";


const TemplateItem = ({ idx, flow, selectedProfile, isActive, onCloneTemplate }) => {

	return (
		<div key={flow.id} className="card card-hover-shadow my-1 pr-1 pl-0 py-0 mr-2 animate__animated animate__fadeInUp animate__faster"
			onClick={() => onCloneTemplate(flow.id)}
			style={{
				border: "1px solid #dae0e5",
				cursor: "pointer"
			}}>
			<div className="card-body pt-3 pb-3 mr-2">
				<div className="d-flex align-items-center">
					<div class="mr-3 pb-1">
						<ProcessIcon style={{ width: "2rem" }} />
					</div>
					<div>
						<h5 className="mb-0 text-truncate d-inline-block"
							style={{ maxWidth: "11.7rem", lineHeight: "1" }}>
							{getFlowName(selectedProfile, flow)}
						</h5>
						<div>
							{function () {
								const templateCategories = flow?.templateCategories ?? [];
								if (templateCategories.length > 0) {
									const templateCategory = templateCategories[0];
									return (
										<span class="badge bg-soft-primary text-primary" key={templateCategory.id} style={{ fontSize: "0.7rem" }}>
											{templateCategory?.uiSettings?.display?.title}
										</span>
									);
								}
								return <></>;
							}()}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateItem;