import React, { useEffect, useState } from "react";
import View from "components/presentation/history/ChatHistory.view";
import { usePagination } from "seed/gql";

function ChatHistory({ 
  flow, 
  chatId,
  refreshKey,
  changeChat 
}) {

  const userId = sessionStorage.getItem("id");
  const pageSize = 15;
  const [pageNum, setPageNum] = useState(1);

  const reqChats = usePagination(`{
    chatPagination {
      totalPages
      chats {
        id
        createdAt
        firstUserMessage {
          id
          content
        }
        user {
          firstName
          lastName
          email
        }
      }
    }
  }`, pageNum, pageSize, `(flow.id=${flow.id} AND channel=WEB)`, { orderBy: "-created_at" });

  const { chats = [], totalPages = 0 } = reqChats.data?.chatPagination || {};

  useEffect(() => {
    reqChats.refetch();
  }, [refreshKey]);

  return (
    <View
      chats={chats}
      chatId={chatId}
      pageNum={pageNum}
      totalPages={totalPages}
      setPageNum={setPageNum}
      changeChat={changeChat}
    />
  );

}

export default ChatHistory;