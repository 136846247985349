import React from "react";
import { StyleTag } from "seed/helpers";


function StyleColor({ user }) {

	let theme = user?.accountSettings?.theme ?? user?.account_settings?.theme ?? {};

	let primaryColor = theme.primaryColor ?? "#2080e5";
	let accentColor = theme.accentColor ?? "#f0b000";
	let darkColor = theme.darkColor ?? getDarkerColor(primaryColor, -30);
	let lightColor = theme.lightColor ?? getDarkerColor(primaryColor, 30);
	let accentDarkColor = theme.accentDarkColor ?? getDarkerColor(accentColor, -30)

	return <div>
		{theme.primaryColor || theme.accentColor ?
			<StyleTag content={`

		  /*
			** General components
			*/

			/* Background */

			#background-hero .path-a {
				fill: ${darkColor} !important;
			}
			#background-hero .path-b {
				fill: ${primaryColor} !important;
			}
			#background-hero .path-c {
				fill: ${accentColor} !important;
			}

			/* Buttons & Text */

			.btn.btn-primary {
				background: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}
			.btn.btn-primary:hover {
				background: ${darkColor} !important;
			}			
			.btn.btn-outline-primary {
				border-color: ${primaryColor} !important;
				color: ${primaryColor} !important;
			}
			.btn.btn-outline-primary:hover{
				background: ${primaryColor} !important;
				color: white !important;
			}
			.btn.btn-outline-primary-white {
				border-color: ${primaryColor} !important;
				color: ${primaryColor} !important;
			}
			.btn.btn-outline-primary-white:hover {
				background: ${primaryColor} !important;
				color: white !important;
			}
			.btn.btn-accent {
				background: ${accentColor} !important;
				border-color: ${accentColor} !important;
			}
			.btn.btn-accent:hover {
				background: ${accentDarkColor} !important;
			}
			.btn.btn-outline-accent {
				border-color: ${accentColor} !important;
				color: ${accentColor} !important;
			}
			.btn.btn-outline-accent:hover{
				background: ${accentColor} !important;
				color: white !important;
			}
			.icon-primary {
				border-color: ${primaryColor} !important;
				background: ${primaryColor} !important;
			}
			.text-primary {
				color: ${primaryColor} !important;
			}
			.navbar-nav.nav-tabs .nav-link.active {
				border-left-color: ${primaryColor} !important;
			}
			.navbar .nav-link.active {
				color: ${primaryColor} !important;
			}			

			.badge-primary {
				background: ${primaryColor} !important;
			}
			.badge-accent {
				background: ${accentColor} !important;
			}

			/* Icons */
			.process-icon-1 { fill:${primaryColor} !important;  }

			.model-icon-1{ stroke:${primaryColor} !important;}
			.model-icon-3{ fill:${primaryColor} !important;}
			
			/* Pagination */

			.page-item.active .page-link {
				background: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}

			.page-item .page-link:hover {
				background: ${darkColor} !important;
				color: white !important;
			}

			/*
			** Specific views
			*/

			/* Start */

			#start-templates {
				background: #ffffff !important;
			}

			#start .navbar-vertical-content .nav-link.active i, #start .navbar-vertical-content .nav-link.active span, 
			#start .navbar-vertical-content .nav-link:hover i, #start .navbar-vertical-content .nav-link:hover span{
				color: ${primaryColor} !important
			}

			#background-hero-template .path-a {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-b {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-c {
				fill: ${darkColor}20 !important;
			}

			/* Settings */

			#settings .navbar-vertical-content .nav-link.active i, #settings .navbar-vertical-content .nav-link.active span, 
			#settings .navbar-vertical-content .nav-link:hover i, #settings .navbar-vertical-content .nav-link:hover span{
				color: ${primaryColor} !important
			}


			/* Flow */
			
			#flow-tabs a.active, #flow-tabs a:hover{
				color: ${primaryColor} !important;
				border-bottom-color: ${primaryColor} !important;
			}
			
			#workspace-title .logo-sub label {
				background: ${primaryColor} !important;
			}

			#toolbox-sidenav .btn-app:hover, #toolbox-sidenav .btn-app.active {
				color: ${primaryColor} !important;
			}

			#toolbox-container #toolbox-main #toolbox-actions div.apps a.btn-category[aria-expanded=true] {
				background: ${primaryColor} !important;
			}

			#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-filters.active {
				color: ${primaryColor} !important;
			}
			#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button.active {
				background: ${primaryColor} !important;
			}

			#toolbox-container #toolbox-main #toolbox-templates .tab-button.active {
				background: ${primaryColor} !important;
			}

			#template-categories-container .tab-button.active {
				background: ${primaryColor} !important;
			}
			

			/* Collection */			

			#collection-tabs .collection-tab.active {
				background: ${primaryColor} !important;
			}

			#collection-tabs .collection-tab:hover {
				background: ${darkColor} !important;
			}

			#collection-tabs .collection-tab.active span,
			#collection-tabs .collection-tab:hover span {
				color: white !important;
			}
			#table-title .edit-btn:hover{
				color: ${primaryColor} !important;
			}


			/* Presentation */

			#presentation-sections .tabs button.active,
			#presentation-sections .tabs button:hover{
				color: ${primaryColor} !important;
				border-bottom-color: ${primaryColor} !important;
			}

			/* Presentation chat */

			#presentation-sidenav {
				background: ${primaryColor} !important;
			}

			#chat .message.user{
				background: ${primaryColor} !important;
			}

			#chat .message.suggestion{
				color: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}

			#chat .loading .loader:before,
			#chat .loading .loader:after {
				--c: no-repeat linear-gradient(${primaryColor} 0 0); 
			}

			#chat .message.inner-toast .loading-icon {
				color: ${primaryColor} !important;
			}

			#chat .input-container input:focus {
				border: 1px solid ${primaryColor}a0 !important;
        box-shadow: 0 0 0.25rem ${primaryColor}b0 !important;
			}

			#chat .input-container input:hover {
				border: 1px solid ${primaryColor}a0 !important;
			}

			#chat .input-container .send,
			#chat .input-container .send:hover {
				color: ${primaryColor} !important;
			}
			

		` } />
			: null}

		<StyleTag content={` 
			#background-hero { display: block !important; }
		` } />

	</div>

}

function getDarkerColor(color, v) {

	const normalize = (x) =>
		x > 255 ? x - (x - 255) : x;

	const getHex = (x) => {
		if (x < 0 || isNaN(x)) x = 0;
		if (x > 255) x = 255;
		x = x.toString(16);
		return x.length === 1 ? "0" + x : x;
	}

	if (color.length > 6)
		color = color.substring(1, color.length)

	var rgb = parseInt(color, 16);

	var r = Math.abs(((rgb >> 16) & 0xFF) + v);
	r = normalize(r);
	r = getHex(r);

	var g = Math.abs(((rgb >> 8) & 0xFF) + v);
	g = normalize(g);
	g = getHex(g);

	var b = Math.abs((rgb & 0xFF) + v);
	b = normalize(b);
	b = getHex(b);

	return "#" + r + g + b;

}

export default StyleColor;