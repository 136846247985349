import React from "react";
import { Modal } from "seed/helpers";
import Filters from "components/collections/helpers/Filters";
import ImportFile from "components/collections/helpers/Import";
import ExportFile from "components/collections/helpers/Export";
import Form from "components/collections/helpers/Form";
import Sorting from "components/collections/helpers/Sorting";
import Zip from "components/collections/helpers/Zip";
import { getDate } from "components/collections/util/format";
import TableControls from "components/collections/Table.sub.controls.view";
import PaginationFooter from "components/util/helpers/PaginationFooter";

const ListView = ({
  height = "auto",
  columns = [],
  rows = [],
  filteredData = [],
  filterDefault,
  zipFieldName,
  isReadOnly = false,
  isFilterModalShown = false,
  isImportModalShown = false,
  isExportModalShown = false,
  isFormModalShown = false,
  isSearchShown = false,
  isSortingModalShown = false,
  isZipModalShown = false,
  showFilters = true,
  showImport = true,
  showExport = true,
  showSearch = true,
  showSorting = true,
  showForm = false,
  pageSize,
  pageNum,
  totalPages,
  query,
  filters,
  sorting,
  search,
  formValue,
  expanded,
  selectedCell,
  setSelectedCell,
  setFormValue,
  setIsFilterModalShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSearchShown,
  setIsSortingModalShown,
  setIsZipModalShown,
  setExpanded,
  onAddRow = () => { },
  onDeleteRow = () => { },
  onChangeRow = () => { },
  onImport = () => { },
  onExport = () => { },
  onDownloadZip = () => { },
  onPageChange = () => { },
  onChangeSearch = () => { },
  onChangeQuery = () => { },
  onChangeFilters = () => { },
  onChangeSorting = () => { },
  onClickCell = () => { },
  onSubmitForm = () => { },
  folioComponent
}) => {
  return (
    <div className="h-100" id="tables-board" style={{position: "relative"}}>

      <TableControls
        search={search}
        filters={filters}
        sorting={sorting}
        showSearch={showSearch}
        showImport={showImport}
        showExport={showExport}
        showForm={showForm}
        showSorting={showSorting}
        showFilters={showFilters}
        isReadOnly={isReadOnly}
        isSearchShown={isSearchShown}
        setIsImportModalShown={setIsImportModalShown}
        setIsExportModalShown={setIsExportModalShown}
        setIsFormModalShown={setIsFormModalShown}
        setIsSortingModalShown={setIsSortingModalShown}
        setIsSearchShown={setIsSearchShown}
        setIsFilterModalShown={setIsFilterModalShown}
        setSelectedCell={setSelectedCell}
        onChangeSearch={onChangeSearch}
      />

      <div 
        className="pt-1 mt-2 mb-1 scrollbar-narrow" 
        style={{ 
          overflowX: "auto" 
        }}
      >
        <table className="table-hover table-sm m-0 p-0 w-100">

          <thead 
            style={{
              borderBottom: "1px solid #d5dae0"
            }}
          >
            <tr>
              <th scope="col"></th>
              <th scope="col" className="m-0 p-0 h5 text-dark font-weight-bold">Elemento</th>
              <th scope="col" className="m-0 p-0 h5 text-dark font-weight-bold">Creación</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody 
            style={{
              borderBottom: "1px solid #d5dae0"
            }}
          >
            {
              filteredData.map((row, i) => (
                <tr key={i}>

                  <td>
                    <div className="d-flex justify-content-center align-items-center mt-2 px-2 flex-">
                      <span>
                        {
                          folioComponent
                            ? folioComponent(row)
                            : <> {pageSize * (pageNum - 1) + i + 1}</>
                        }
                      </span>
                    </div>
                  </td>

                  <td 
                    className="m-0 p-0"
                    style={{ 
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (isReadOnly) return;
                      setSelectedCell({ row: i });
                      onClickCell(row, i);
                    }}
                  >
                    <span>
                      {
                        selectedCell && selectedCell.row === i
                          ? <div className="d-flex justify-content-between align-items-center w-100 h-100">
                              {
                                expanded
                                  ? <textarea
                                      autoFocus
                                      className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                                      rows={row.data["list"]?.split("\n").length??1}
                                      defaultValue={row.data["list"]}
                                      disabled={isReadOnly}
                                      onChange={(e) => onChangeRow(i, "list", e.target.value)}
                                      onBlur={(e) => onChangeRow(i, "list", e.target.value)}
                                    />
                                  : <input
                                      type="text"
                                      className="form-control d-block w-100 h-100"
                                      defaultValue={row.data["list"]}
                                      disabled={isReadOnly}
                                      onChange={(e) => onChangeRow(i, "list", e.target.value)}
                                      onBlur={(e) => onChangeRow(i, "list", e.target.value)}
                                      onKeyUp={(e) => {
                                        if (e.key === "Enter")
                                          onChangeRow(i, "list", e.target.value + "\n");
                                      }}
                                    />
                                }
                              <span
                                className="d-flex align-items-center ml-2"
        
                              >
                                {
                                  expanded
                                    ? <i className="fas fa-compress-alt text-primary" style={{ cursor: "pointer" }} 
                                        onClick={() => setExpanded(false)} />
                                    : <i className="fas fa-expand-alt text-primary" style={{ cursor: "pointer" }} 
                                        onClick={() => setExpanded(true)} />
                                }
                              </span>
                            </div>
                          : <div 
                              style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                              }}
                            >
                              {
                                function(){
                                  const value = row.data["list"] || "";
                                  const compress = value?.length > 30;
                                  if(compress) return value?.toString().substring(0, 30) + "...";
                                  return value?.toString();
                                }()
                              }
                            </div>
                      }
                    </span>
                  </td>

                  <td 
                    className="m-0 py-0"
                    style={{
                      width: "5rem",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center my-1">
                        {getDate(row.data._internal_created_at, "YYYY-MM-DD")}
                    </div>
                  </td>
                  
                  {
                    !isReadOnly && 
                      <td
                        className="delete-row-button"
                        style={{ width: "3rem", cursor: "pointer" }}
                        onClick={() => onDeleteRow(i)}
                      >
                        <div className="h5 d-flex justify-content-center align-items-center my-1 py-0 px-3">
                          <span>-</span>
                        </div>
                      </td>
                  }

                </tr>
              ))
            }
            {
              filteredData.length == 0 &&
              <tr>
                <td
                  colSpan={columns.length + 2}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    No hay datos en la lista
                  </div>
                </td>
              </tr>
            }
          </tbody>

          {
            !isReadOnly && !filters.length &&
            <tfoot className=""
              style={{ borderTop: filteredData.length == 0 ? "1.5rem solid #F8FAFE" : "none" }}>
              <tr>
                <td
                  colSpan={columns.length + 2}
                  style={{ cursor: "pointer" }}
                  onClick={onAddRow}
                >
                  <div
                    className="h5 d-flex justify-content-center align-items-center my-0 py-2"
                    style={{
                      fontSize: "0.97em",
                      borderTop: filteredData.length == 0 ? "1px solid #d5dae0" : "none"
                    }}
                  >
                    <span className="mr-1">+</span> Agregar nuevo dato
                  </div>
                </td>
              </tr>
            </tfoot>
          }

          </table>
      </div>

      <div className="w-100 px-3 m-0" id="collection-pagination">
        <nav class="mt-3 scrollbar-narrow">
          <PaginationFooter
            pageNum={pageNum}
            totalPages={totalPages}
            onClickPage={onPageChange}
          />
        </nav>
      </div>

      {
        isFilterModalShown && <Modal
          width="800"
          height={window.innerHeight - 150}
          columns={columns}
          query={query}
          onClose={() => setIsFilterModalShown(false)}
          component={() =>
            <div className="card card-body w-100">
              <Filters
                columns={columns}
                query={query}
                filters={filters}
                filterDefault={filterDefault}
                onChangeQuery={onChangeQuery}
                onChangeFilters={onChangeFilters}
                onClose={() => setIsFilterModalShown(false)}
              />
            </div>
          }
        />
      }

      {
        isExportModalShown && <Modal
          width="325"
          height="400"
          rows={rows}
          columns={columns}
          onExport={onExport}
          onClose={() => setIsExportModalShown(false)}
          component={ExportFile}
        />
      }

      {
        isImportModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          rows={rows}
          columns={columns}
          onImport={onImport}
          onClose={() => setIsImportModalShown(false)}
          component={ImportFile}
        />
      }

      {
        isFormModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          title={formValue ? "Editar dato" : "Agregar dato"}
          structure={columns}
          initialRow={formValue}
          onSubmit={onSubmitForm}
          onClose={() => {
            setIsFormModalShown(false);
            setFormValue(null);
          }}
          component={Form}
        />
      }

      {
        isSortingModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          columns={columns}
          sorting={sorting}
          onChangeSorting={onChangeSorting}
          onClose={() => setIsSortingModalShown(false)}
          component={Sorting}
        />
      }

      {
        isZipModalShown && <Modal
          width="325"
          height="400"
          zipFieldName={zipFieldName}
          onDownloadZip={onDownloadZip}
          onClose={() => setIsZipModalShown(false)}
          component={Zip}
        />
      }

    </div>
  )
}

export default ListView;