/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import { TabButton } from "components/studio/toolbox/actions/Actions.sub.ActionElement.view"
import NavApps from "components/studio/toolbox/actions/Actions.sub.NavApps.view";
import ScrollArrow from "components/util/helpers/ScrollArrow";
import "styles/css/studio/Toolbox.css";


const ActionsView = ({
  apps,
  allApps,
  flow,
  filtersData,
  actionSearch,
  actionFilters,
  isDeveloper,
  isValidator,
  isFilterSectionShown,
  onEnableApp,
  onSearchActions,
  onClickSearchRemove,
  onClickSearchFilter,
  onDragStart,
  onClickAction,
  onClickFilterTab,
  onClickFilterRemove
}) =>
  <div className="h-100">
    <div id="toolbox-actions"
      className={"w-100 scrollbar-narrow d-flex " + (actionSearch != "" || actionFilters.length > 0 ? "flex-column" : "flex-wrap") + (isValidator ? " validator" : "")}
    >

      <div id="toolbox-search" className="w-100">
        <input type="text" placeholder="Buscar" class="rounded w-100 search-input"
          value={actionSearch} onChange={(event) => onSearchActions(event.target.value)}
        />
        <i className="fas fa-search d-block search-icon"></i>
        <div className="d-flex flex-row-reverse search-cta">

          <i class={"fas fa-tags search-filters py-0 px-2 " +
            (isFilterSectionShown ? "active" : "")}
            onClick={onClickSearchFilter}
          ></i>

          {actionSearch != "" ?
            <i class="fas fa-times search-close py-0 px-2"
              onClick={onClickSearchRemove}
            ></i> : null}
        </div>

        <div id="toolbox-tabs"
          className="animate__animated animate__fadeIn animate__faster"
          style={{ display: isFilterSectionShown ? "block" : "none" }}>
          <ScrollArrow
            scrollAmount={150}
            backgroundFadeColor={{ red: 235, green: 241, blue: 246 }}
            leftButtonStyle={{
              paddingLeft: "2px",
              paddingRight: "2px"
            }}
            rightButtonStyle={{
              paddingLeft: "2px",
              paddingRight: "2px"
            }}
            arrowsWidth="auto"
          >
            {filtersData.map(filter =>
              <TabButton
                key={filter.name}
                name={filter.name}
                label={filter.label}
                helpMessage={filter?.helpMessage ?? ""}
                actionFilters={actionFilters}
                onClickFilterTab={onClickFilterTab}
                onClickFilterRemove={onClickFilterRemove}
              />
            )}
          </ScrollArrow>
        </div>

      </div>

      {actionSearch != "" || actionFilters.length > 0 ?
        <span className={"d-flex justify-content-between dropdown-header pb-1 px-1 search-title " +
          (actionSearch != "" ? "pt-2" : "pt-0")}>
          {actionSearch != "" ? "Búsqueda" : filtersData.find(tab => tab.name == actionFilters[0])?.label}
        </span> : null}

      <NavApps
        apps={actionSearch != "" ? allApps : apps}
        flowId={flow.id}
        actionSearch={actionSearch}
        actionFilters={actionFilters}
        isDeveloper={isDeveloper}
        onClickAction={onClickAction}
        onDragStart={onDragStart}
        onEnableApp={onEnableApp}
      />

      {
        actionSearch != "" && !isFilterSectionShown ?
          <div className="text-center mx-1 no-results">No se encontraron resultados</div> : null
      }
    </div>

    {!isValidator && (
      <div className="pb-3 more-actions-wrapper">
        <div className="more-actions-divider" />
        <Link to="/apps"
          class="btn btn-sm btn-primary py-1 w-100" data-toggle="collapse">
          Personalizar acciones
        </Link>
      </div>
    )}

  </div>;

export default ActionsView;
