import React from "react";
import { Formik, Form } from "formik";
import { FileField } from "seed/helpers";

const FormUploadMassiveView = ({
  downloadTemplate,
  reqCall = { loading: false, error: false },
  onSubmit
}) => (
  <div className="card col-md-12" style={{ minHeight: "33vh" }}>
    {/* Sección de instrucciones */}
    <div className="card p-3">
      <div className="text-muted mb-3">INSTRUCCIONES</div>
      <div>
        <span className="h5 text-primary mr-1">Paso 1.</span>
        <span className="h5 font-weight-light">Descargar la plantilla</span>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-sm btn-default"
          onClick={downloadTemplate}
        >
          Descargar
        </button>
      </div>
      <div className="mt-3">
        <span className="h5 text-primary mr-1">Paso 2.</span>
        <span className="h5 font-weight-light">Modifica el archivo</span>
      </div>
      <div className="mt-2">
        <span className="h5 text-primary mr-1">Paso 3.</span>
        <span className="h5 font-weight-light">Sube el archivo modificado</span>
      </div>
    </div>

    <Formik
      initialValues={{ users_format: null }}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <FileField
                name="users_format"
                accept=".xlsx"
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-sm btn-primary text-center col-md-12 mt-4">
            {reqCall.loading
              ? "Cargando..."
              : reqCall.error
              ? "Ha ocurrido un error, prueba de nuevo"
              : "Cargar usuarios"}
          </button>
        </Form>
      )}
    </Formik>
  </div>
);

export default FormUploadMassiveView;
