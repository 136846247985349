import React, { useEffect } from "react";
import { usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { useHistory } from "react-router-dom";
import ExecutionDetails from "components/tmisc/executions/ExecutionDetails";
import { LimitExceptions } from "components/util/flow";

function ChatExecution({ 
  executionId, 
  message,
  isShown,
  isButtonHidden
}) {

  const history = useHistory();
  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";

  const reqExecutions = useQuery(`{
    executions {
      id
      status
      startDate
      endDate
      isRead
      exception
      settings
      flow {
        id
        name
      }
    }
  }`, `id=${executionId}`);

  const [callResume] = usePost("/executions/resume_execution", {
    onCompleted: () => {
      reqExecutions.refetch();
    }
  });

  const [callCancel] = usePost("/executions/cancel_execution", {
    onCompleted: () => {
      reqExecutions.refetch();
    }
  });

  useEffect(() => {
    reqExecutions.refetch();
  }, [executionId])

  if(reqExecutions.loading) return <div style={{ padding: "0.72rem 0rem" }} />
  const { executions = [] } = reqExecutions.data || {};

  let execution = null;
  if(executions.length > 0) execution = executions[0];

  const onResumeExecution = () => {
    history.replace(`/flow/${execution?.flow?.id}/flow?resume=${execution?.id}`);
    callResume({ execution_id: execution?.id });
  }

  const onCancelExecution = () =>
    callCancel({ execution_id: execution?.id });

  return (
    <div className={`d-flex flex-column animate__animated animate__fadeIn animate__faster mx-3 mb-1 align-items-start`}>
      <div className={`message scrollbar-narrow`}>
        <div className="">
          <div dangerouslySetInnerHTML={{ __html: message.content?.text ?? "" }} />
          <small className="text-danger">
            {LimitExceptions[message.settings?.exception?.type] || (isDeveloperMode && message.settings?.exception?.details)}
          </small>
        </div>
        {/* <div>
          <div className="card-body px-0">
            <div className="w-100">
              {execution ? (
                <ExecutionDetails
                  idx={0}
                  execution={execution}
                  isUnique={true}
                  isShown={isShown}
                  isButtonHidden={isButtonHidden}
                  onResumeExecution={onResumeExecution}
                  onCancelExecution={onCancelExecution}
                />
              ) : (
                <div className="h-100">
                  <div className="d-flex justify-content-start align-items-center h-100 ml-1">
                    <span style={{ color: "#b0b2b2", fontSize: "0.95em" }}>Error al cargar la información</span>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div> */}
      </div>
    </div>
  );

}

export default ChatExecution;