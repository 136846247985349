import React, { useState, useEffect, useRef } from "react";
import { useQuery, useDetail } from "seed/gql";
import { useGetCall, usePost, useGet } from "seed/api";
import { Loading } from "seed/helpers";
import NotFoundView from "components/presentation/Presentation.sub.NotFound.view"
import View from "components/presentation/Presentation.view";

function Presentation({ match }) {

  const token = match.params?.token;
  const userId = sessionStorage.getItem("id");
  const execModeRef = useRef(null);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const reqFlow = useQuery(`{
    flows {
      name
      description
      token
      presentationSettings
      settings
      chatSettings
      user {}
    }
  }`, `token=${token}`);

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      accountSettings
    }
  }`, userId);

  const reqPermissions = useGet("/users/get_perms", { user_id: userId, token });

  const [callAuth] = useGetCall("/auth/user", "", {
    onCompleted: () => {
      sessionStorage.setItem("execution_session_id", Math.random().toString(36).substring(2, 15) + 
				Math.random().toString(36).substring(2, 15));
      setIsAuth(true)
      reqFlow.refetch();
      reqUser.refetch();
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      postMessage({ type: "logout" });
      window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    }
  })

  const [callInitChat] = usePost("/chats/init_web_chat", {
    onCompleted: (data) => {
      setChatId(data.id);
      setCurrentChatId(data.id);
    },
  });

  const { user = null } = reqUser.data || {};
  const { flows = [] } = reqFlow.data;
  const flow = flows[0];

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
    callAuth({ user_id: sessionStorage.getItem("id") });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!user) return console.log("No user data");
    sessionStorage.setItem("show_helps", user?.account_settings?.showHelps);

    const currentTheme = sessionStorage.getItem("theme") ?? "{}";
    const theme = JSON.stringify(user?.account_settings?.theme ?? {});
    if (currentTheme != theme) {
      sessionStorage.setItem("theme", theme);
      window.location.reload();
    }
  }, [flow]);

  useEffect(() => {
    if (!flow || chatId) return;
    callInitChat({ flow_id: flow.id });
  }, [flow, flows, chatId]);

  if (!isAuth) return null;
  if (reqFlow.loading || reqUser.loading) return <Loading />;
  if (reqFlow.error || reqUser.error) return "Error";
  if (flows.length == 0) window.location.replace("/app");

  const onClickTab = section =>
    setActiveSection(section != activeSection ? section : null)

  const onClickCloseSection = () => {
    const defaultTab = ((flow.chatSettings?.show_flows??{})[userType] ?? true) ? "flows" : "data";
    setActiveSection(activeSection == null ? defaultTab : null)
  }

  const changeChat = (chatId) => {
    setChatId(chatId)
    setActiveSection(null)
  }

  const refreshChat = () =>
    reqFlow.refetch();

  const permissions = reqPermissions.data || {};
  const isAdmin = permissions?.is_admin || false;
  const isEditor = permissions?.is_editor || false;
  const isViewer = permissions?.is_viewer || false;
  const userType = permissions?.type || null;

  if(!userType) return <NotFoundView />

  const chatSettings = flow.chatSettings || {};
  const showSidenav = chatSettings.show_sidenav || {};
  const showFlows = chatSettings.show_flows || {};
  const showShare = chatSettings.show_share || {};
  const customViews = chatSettings.custom_views || [];
  const customWidth = customViews.find(view => view.name == activeSection)?.width;

  return (
    <View
      user={user}
      flow={flow}
      showSidenav={showSidenav}
      showFlows={showFlows}
      showShare={showShare}
      customViews={customViews}
      customWidth={customWidth}
      activeSection={activeSection}
      execModeRef={execModeRef}
      userType={userType}
      isAdmin={isAdmin}
      isEditor={isEditor}
      chatId={chatId}
      currentChatId={currentChatId}
      refreshChat={refreshChat}
      changeChat={changeChat}
      onClickTab={onClickTab}
      onClickCloseSection={onClickCloseSection}
    />);
}

export default Presentation;