import React from "react";
import swal from "sweetalert";
import { usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/tmisc/flow_validator/FlowValidator.view";


function FlowValidator({ match, location, flow }) {

  const tempFlowId = match?.params?.flowId;

  const [callCreateFlow, reqCreateFlow] = usePost("/flows/insert_temp_flow", {
    onCompleted: (data) => {
      window.location.replace(`/p/${flow.token}`);
    },
    onError: (error) => {
      swal("Error al crear el proceso", "El proceso aun cuenta con errores/advertencias, para crearlo por favor solucione estos errores", "error");
    }
  });

  const reqNodes = useQuery(`{
    flowNodes {
      createdAt
      inputValues
      outputValues
      uiSettings
      isEnabled
      isVerified
      isIterable
      iterableField
      rootName
      errors
      warnings
      flow { name }
      action { 
        name
        type
        uiSettings
      }
    }  
  }`, `flow.id=${tempFlowId}`);

  const onClickCreateFlow = () => {
    callCreateFlow({
      flow_id: flow.id,
      temp_flow_id: tempFlowId
    })
  }

  if (reqNodes.loading) return <Loading />;
  if (reqNodes.error) return "Error";

  const { flowNodes = [] } = reqNodes.data;

  console.log("nodes", flowNodes);

  return (
    <View
      match={match}
      nodes={flowNodes}
      onClickCreateFlow={onClickCreateFlow}
    />
  );
}

export default FlowValidator;