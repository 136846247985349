import React, { useState } from "react";
import { useQuery, useDelete, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { DELETE_PARTICIPANT } from "seed/gql/queries";
import View from "components/studio/forms/collaboration/Collaboration.view";


function Collaboration({ flowId }) {

  const userId = sessionStorage.getItem("id");
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

  const [users, setUsers] = useState([]);

  const [shareNumbers, setShareNumbers] = useState([]);
  const [shareEmails, setShareEmails] = useState([]);
  const [shareUsers, setShareUsers] = useState([]);
  const [shareOrganizations, setShareOrganizations] = useState([]);

  const [shareRole, setShareRole] = useState("EDITOR");
  const [organizationInputValue, setOrganizationInputValue] = useState('');

  const [phoneInputValue, setPhoneInputValue] = useState('');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [userInputValue, setUserInputValue] = useState('');

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isUserValid, setIsUserValid] = useState(true);
  
  const roleOptions = [
    { "label": "Editor", "value": "EDITOR" },
    { "label": "Lector", "value": "VIEWER" }
  ];

  const reqUsers = useQuery(`{
    users {
      email
      firstName
      lastName
      username
      participants {
        flow { name }
      }
    }
  }`, `id <> ${userId} AND isAnonymous=False`, {
    onCompleted: (data) => {
      const auxUsers = data?.users ?? [];
      const filteredUsers = auxUsers.filter((user) => {
        const participations = user?.participants ?? [];
        if (participations.find((participation) => participation.flow.id == flowId)) {
          return false;
        }
        return true;
      })
      setUsers(filteredUsers);
    }
  })

  const reqParticipants = useQuery(`{
    participants {
      type
      channel
      email
      phone
      user {
        email
        phone
        firstName
        lastName
        username
      }
    }
  }`, `flow.id = ${flowId}`)

  const reqFlow = useDetail(`{
     flow {
       name
       token
       settings
     } 
    }`, flowId)

  const reqOrganizations = useQuery(`{
    organizations {
      name
      owner { 
        username 
      }
    }
  }`)

  const [callDelete, reqDelete] = useDelete(DELETE_PARTICIPANT, {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err);
    }
  })

  const [callSharePhones] = usePost("/flows/share_phones", {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const [callShareEmails] = usePost("/flows/share_emails", {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const [callShareOrganizations] = usePost("/flows/share_organizations", {
    onCompleted: () => reqParticipants.refetch(),
    onError: err => console.log(err)
  });

  const [callShare, reqShare] = usePost("/flows/share", {
    onCompleted: () => {
      reqUsers.refetch();
      reqParticipants.refetch();
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10,15}$/;
    return phoneRegex.test(phone);
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const onPhoneInputChange = (_, value) => {
    setPhoneInputValue(value);
    const isValid = validatePhone(value)
    if (value == "") setIsPhoneValid(true);
    else setIsPhoneValid(isValid);
  }
  
  const onEmailInputChange = (_, value) => {
    setEmailInputValue(value);
    const isValid = validateEmail(value)
    if (value == "") setIsEmailValid(true);
    else setIsEmailValid(isValid);
  }

  const onUserInputChange = (_, value) => {
    setUserInputValue(value);
    const isValid = validateEmail(value)
    if (value == "") setIsUserValid(true);
    else setIsUserValid(isValid);
  }

  const onChangeShareNumbers = (_, newNumbers) => {
    if (isPhoneValid) setShareNumbers(newNumbers);
  }

  const onChangeShareEmails = (_, newEmails) => {
    if (isEmailValid) setShareEmails(newEmails);
  }

  const onChangeShareUsers = (_, newUsers) => {
    if (isUserValid) setShareUsers(newUsers);
  }

  const onChangeShareRole = (newRole) => {
    setShareRole(newRole);
  }

  const onClickSharePhones = () => {
    if (shareNumbers.length > 0) {
      setShareNumbers([]);
      callSharePhones({
        phones: shareNumbers,
        flow_id: flowId
      });
    }
  }

  const onClickShareEmails = () => {
    if (shareEmails.length > 0) {
      setShareEmails([]);
      callShareEmails({
        emails: shareEmails,
        flow_id: flowId
      });
    }
  }

  const onClickShareUsers = () => {
    if (shareUsers.length > 0 && ["EDITOR", "VIEWER"].includes(shareRole)) {
      setShareUsers([])
      callShare({
        role: shareRole,
        emails: shareUsers,
        flow_id: flowId
      })
    }
  }

  const onClickShareOrganizations = () => {
    if (shareOrganizations.length > 0) {
      callShareOrganizations({
        organizations: shareOrganizations.map(org => org.value),
        flow_id: flowId
      });
      setShareOrganizations([]);
    }
  };

  const onChangeParticipantRole = (userId, newRole) => {
    if (["EDITOR", "VIEWER"].includes(newRole)) {
      callShare({
        role: newRole,
        users: [userId],
        flow_id: flowId
      })
    }
  }

  const onRemoveParticipant = (participantId) => {
    callDelete({ id: participantId });
  }


  if (reqUsers.loading || reqParticipants.loading || reqFlow.loading || reqOrganizations.loading) return <Loading />;
  if (reqUsers.error || reqParticipants.error || reqFlow.error || reqOrganizations.error) return <div />;

  const { participants = [] } = reqParticipants.data;
  const { flow = {} } = reqFlow.data
  const organizations = (reqOrganizations.data?.organizations ?? []).filter(org =>
    org.participants?.some(ownerItem => ownerItem.id == userId)
  );

  return (
    <View
      flow={flow}
      users={users}
      organizations={organizations}
      shareOrganizations={shareOrganizations}
      shareNumbers={shareNumbers}
      shareEmails={shareEmails}
      shareUsers={shareUsers}
      shareRole={shareRole}
      organizationInputValue={organizationInputValue}
      phoneInputValue={phoneInputValue}
      emailInputValue={emailInputValue}
      userInputValue={userInputValue}
      participants={participants}
      roleOptions={roleOptions}
      isDeveloper={isDeveloper}
      isPhoneValid={isPhoneValid}
      isEmailValid={isEmailValid}
      isUserValid={isUserValid}
      onChangeShareNumbers={onChangeShareNumbers}
      onChangeShareEmails={onChangeShareEmails}
      onChangeShareUsers={onChangeShareUsers}
      onChangeShareRole={onChangeShareRole}
      onClickShareUsers={onClickShareUsers}
      onClickSharePhones={onClickSharePhones}
      onClickShareEmails={onClickShareEmails}
      onChangeParticipantRole={onChangeParticipantRole}
      onRemoveParticipant={onRemoveParticipant}
      onPhoneInputChange={onPhoneInputChange}
      onEmailInputChange={onEmailInputChange}
      onUserInputChange={onUserInputChange}
      onClickShareOrganizations={onClickShareOrganizations}
      onOrganizationInputChange={(_, val) => setOrganizationInputValue(val)}
      onChangeShareOrganizations={(_, orgs) => setShareOrganizations(orgs)}
    />
  );
}

export default Collaboration;