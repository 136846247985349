import React from "react";
import { Formik, Form, Field } from 'formik';
import { TwitterPicker } from 'react-color';
import PreviewFileField from "components/util/helpers/PreviewFileField";
import { StyleTag } from "seed/helpers";



const CustomizationView = ({ 
  user, 
  onSubmit 
}) => (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Personalización</h3>
      </div>
      <div className="card-body p-5 animate__animated animate__fadeIn animate__faster">

        <Formik
          initialValues={{
            showHelps: user.accountSettings.showHelps,
            primaryColor: user.accountSettings.theme?.primaryColor,
            accentColor: user.accountSettings.theme?.accentColor,
            logo: user.accountSettings.logo,
            showAnimations: user.accountSettings.showAnimations,
            showPagination: user.accountSettings.showPagination,
          }}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>

              <ColorPicker
                label="Color principal"
                name="primaryColor"
                values={values}
                defaultColor={"#2065a5"}
                setFieldValue={setFieldValue}
              />

              <ColorPicker
                label="Color de acento"
                name="accentColor"
                values={values}
                defaultColor={"#f0b000"}
                setFieldValue={setFieldValue}
              />

              <div className="d-flex align-items-center mt-3">
                <label class="avatar avatar-xl avatar-uploader mr-5" for="logo-uploader" style={{ width: "auto" }}>
                  <img
                    id="logo-image"
                    class="avatar-img"
                    src={values?.logo ?? "/theme/img/160x160/img1.jpg"}
                    alt="Custom logo"
                  />
                  <PreviewFileField
                    name="logo"
                    id="logo-uploader"
                    required={true}
                    className="js-file-attach avatar-uploader-input"
                    accept=".jpg,.jpeg,.svg,.png,.gif,.bmp,.webp"
                    hsFileAttachOptions={`{
                    "textTarget": "#logo-image",
                    "mode": "image",
                    "targetAttr": "src",
                    "resetTarget": ".js-file-attach-reset-img",
                    "resetImg": "/theme/img/160x160/img1.jpg"
                  }`}
                    setFieldValue={() => { }}
                    onCompleted={(data) => setFieldValue("logo", data?.url)}
                  />
                  <span class="avatar-uploader-trigger">
                    <i class="tio-edit avatar-uploader-icon shadow-soft"></i>
                  </span>
                </label>
                <button type="button" class="js-file-attach-reset-img btn btn-white" onClick={() => setFieldValue("logo", null)}>
                  <i className="fas fa-trash mr-2"></i>Eliminar
                </button>
              </div>

              <div class="form-group mt-2 mb-1">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="showHelps" id="showHelps"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="showHelps">
                    Mostrar mensajes de ayuda
                  </label>
                </div>
              </div>

              <div class="form-group mt-2 mb-1">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="showAnimations" id="showAnimations"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="showAnimations">
                    Mostrar animaciones
                  </label>
                </div>
              </div>

              <div class="form-group mt-2 mb-1">
                <div class="custom-control custom-checkbox">
                  <Field type="checkbox" name="showPagination" id="showPagination"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="showPagination">
                    Mostrar paginación
                  </label>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-sm btn-lighter-white mt-3"
                onClick={() => {
                  setFieldValue("showHelps", false);
                  setFieldValue("primaryColor", null);
                  setFieldValue("accentColor", null);
                  setFieldValue("logo", "");
                  handleSubmit()
                }}
              >
                Reestablecer configuración
              </button>

              <div className="text-right">
                <button type="submit" className="btn btn-primary">
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
);

const ColorPicker = ({
  label,
  name,
  values,
  defaultColor,
  setFieldValue,
  onChange = () => { }
}) => {
  const colors = [
    "#FF5252", "#FF4081", "#7B1FA2", "#512DA8", "#536DFE", "#2065a5", "#3075BA", "#1976D2", "#03A9F4", "#0097A7", "#00796B", "#4CAF50", "#AFB42B",
    "#F57C00", "#FFA000", "#FF5722", "#5D4037", "#9E9E9E", "#455A64", "#212121", "default"
  ];
  return <div className="d-flex align-items-center w-100 mb-3">
    <StyleTag content={`
      .twitter-picker input[id^=rc-editable-input] {
        display: none;
      }
      .twitter-picker div[style*="rgb(240, 240, 240)"] {
        display: none !important;
      }
    `} />
    <div className="mb-1 mr-2" style={{ width: "100px" }}>
      {label}
    </div>
    <div style={{ position: "relative" }}>
      <div className="color-picker-preview" onClick={() => setFieldValue(`show_${name}`, !values[`show_${name}`])}>
        <div className="color" style={{ backgroundColor: values[name] ?? defaultColor }}></div>
      </div>
      <div style={{ position: "absolute", left: "110%", top: "-13px", zIndex: 1000 }}>
        {
          values[`show_${name}`] &&
          <TwitterPicker
            color={values[name] ?? defaultColor}
            width="240px"
            triangle="hide"
            onChange={(color) => {
              setFieldValue(name, color.hex);
              setFieldValue(`show_${name}`, false);
              onChange(color.hex);
            }}
            colors={colors}
          />
        }
      </div>
    </div>
  </div>
}


export default CustomizationView;