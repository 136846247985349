import React, { useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { usePost } from "seed/api";
import { SAVE_FLOW, SAVE_FLOW_PAGE } from "seed/gql/queries";
import { useSave, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/start/flows/FormFlow.view";

function FormSaveFlow({ match, 
  onSave = (flow) => window.location.replace(`/p/${flow.token}`) }) {

  const userId = sessionStorage.getItem("id");
  const history = useHistory();

  const { folderId = null } = match?.params ?? {};
  const initialValues = {
    folder: folderId,
    show_animations: true,
    show_pagination: false
  }

  const [callSave] = usePost("/flows/create_flow", {
    onCompleted: (createdFlow) => {
      onSave(createdFlow)
    }, onError: () => {
      swal("Error al crear la solución", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  });

  const reqFolders = useQuery(`{
    folders {
      name
    }
  }`, `user.id = ${userId}`, { orderBy: "name" });

  const onSubmit = (values) => {

    const newValues = {
      name: values.name ?? "",
      description: values.description ?? "",
      settings: { },
      user_id: parseInt(userId),
    }

    if(values.folder != "0")
      newValues.folder_id = parseInt(values.folder);
    callSave(newValues);
  }

  if (reqFolders.loading) return <Loading />;
  if (reqFolders.error) return "Error";

  const { folders = [] } = reqFolders.data;

  return (
    <View
      folders={folders}
      initialValues={initialValues}
      history={history}
      onSubmit={onSubmit}
    />
  );
}

export default FormSaveFlow;