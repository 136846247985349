/* eslint-disable max-len */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal, StyleTag } from "seed/helpers";
import WSManager from "components/util/ws";
import FormSave from "components/collections/forms/FormSave";
import FormSet from "components/collections/forms/FormSet";
import FormImport from "components/collections/forms/FormImport";
import HelpMessage from 'components/util/helpers/HelpMessage';
import CollapseMenu from "components/util/helpers/CollapseMenu";
import Table from "components/collections/Table";
import Documents from "components/collections/Documents";
import List from "components/collections/List";
import "styles/css/collections/Collections.css";

const createTableMessage = "Crea tablas personalizadas para guardar información del proceso";
const createFolderMessage = "Crea carpetas para guardar documentos y archivos del proceso";
const importExcelDataMessage = "Importa datos desde un archivo Excel para integrarlos en tu proceso";

const CollectionsView = ({
  styles,
  flowId,
  showTabs,
  columns,
  rows,
  pageNum,
  pageSize,
  totalPages,
  collections,
  activeCollectionId,
  query,
  filters,
  sorting,
  search,
  height,
  loading,
  currentTutorialStep,
  embeddingStatus,
  collectionRoomName,
  collectionType,
  isTutorialShown,
  isModalOpen,
  isEditModalOpen,
  isImportModalOpen,
  isImportModalShown,
  isExportModalShown,
  isFilterModalShown,
  isOptionModalShown,
  isReadOnly,
  onClickTab,
  onClickDelete,
  setIsModalOpen,
  setIsEditModalOpen,
  setIsImportModalOpen,
  setCollectionType,
  onAddColumn,
  onClickEdit,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn,
  onAddRow,
  onDeleteRow,
  onInsertRow,
  onChangeRow,
  onImport,
  onExport,
  onDownloadZip,
  onChangeSearch,
  onPageChange,
  onChangeQuery,
  onChangeFilters,
  onChangeSorting,
  onClickCell,
  onSaveCollection,
  onEditCollection,
  onCollectionMessage,
}) => (
  <div id="tables" class="px-2 py-1" style={styles}>

    <BrowserRouter basename="/app/data">

      {showTabs && (
        <div className="w-100" id="tables-header">
          <div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pt-3 px-3 pb-2">
            {(collections ?? []).map((collection, index) => (
              <a
                key={collection.id}
                className={"btn collection-tab d-flex align-items-center mr-2 px-3 py-0 " +
                  (activeCollectionId == collection.id ? "active" : "")}
              >
                <span className="text-truncate py-2" onClick={() => onClickTab(collection.id)}>{collection.name}</span>
              </a>
            ))}

            {!isReadOnly && (
              <div id="add-collection-tab">

                <div class="dropdown">

                  <button
                    id="create-table-dropdown"
                    class="btn collection-tab d-flex align-items-center dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="fas fa-plus mr-2 tab-icon"></i>
                      <span>Crear colección</span>
                    </span>
                  </button>

                  <div id="create-table-dropdown-content">
                    <div>
                      <div class="dropdown-menu" aria-labelledby="create-table-dropdown">

                        <StyleTag
                          content={`
                            #create-table-dropdown-sub:hover .dropdown-menu {
                              display: block;
                            }
                            #create-table-dropdown-submenu {
                              display: none;
                              position: absolute;
                              left: 100%;
                              top: 0;
                              z-index: 9999;
                            }
                          `}
                        />

                        <HelpMessage message={createTableMessage}>
                          <span
                            id="create-table-dropdown-sub"
                            class="dropdown-item"
                            style={{ cursor: "pointer", position: "relative" }}
                          >

                            <div>
                              <i class="tio-table dropdown-item-icon"></i>
                              <span>Crear tabla</span>
                            </div>

                            <div 
                              id="create-table-dropdown-submenu"
                              class="dropdown-menu"
                              style={{
                                position: "absolute",
                                left: "100%",
                                top: "-1rem",
                              }}
                            >
                              <HelpMessage message={importExcelDataMessage}>
                                <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                                  setIsImportModalOpen(true);
                                  setCollectionType("TABLE");
                                }}>
                                  <i class="tio-file-add dropdown-item-icon"></i>
                                  <span>Importar desde excel</span>
                                </span>
                              </HelpMessage>
                              <HelpMessage message={createTableMessage}>
                                <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                                  setIsModalOpen(true);
                                  setCollectionType("TABLE");
                                }}>
                                  <div>
                                    <i class="tio-table dropdown-item-icon"></i>
                                    <span>Crear tabla</span>
                                  </div>
                                </span>
                              </HelpMessage>
                            </div>

                          </span>
                        </HelpMessage>

                        <HelpMessage message={createFolderMessage}>
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                            setIsModalOpen(true);
                            setCollectionType("DOCUMENT");
                          }}>
                            <div>
                              <i class="tio-folder dropdown-item-icon"></i>
                              <span>Crear carpeta</span>
                            </div>
                          </span>
                        </HelpMessage>

                        <HelpMessage message={createFolderMessage}>
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {
                            setIsModalOpen(true);
                            setCollectionType("LIST");
                          }}>
                            <div>
                              <i class="fas fa-list dropdown-item-icon"></i>
                              <span>Crear lista</span>
                            </div>
                          </span>
                        </HelpMessage>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            )}

          </div>
        </div>
      )}

      <div className="h-100" id="tables-body">

        {!activeCollectionId && !loading && (
          <div className="w-100 h-100" id="no-tables-content">
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-75 pb-7">
              <h3 className="text-muted text-center" style={{ width: "27rem" }}>
                {
                  collections && collections.length > 0 && !isReadOnly
                    ? <span>No se ha seleccionado ninguna colección</span>
                    : <span>
                      <span className="mb-1">Aún no tienes colecciones creadas<br /></span>
                      <small>Utiliza las colecciones para entrenar a tu solución o utilizarlo en tu proceso.</small>
                    </span>
                }
              </h3>
              {
                collections && collections.length == 0 && !loading && !isReadOnly &&
                <div id="no-table-ctas" className="d-flex justify-content-center">
                  <div>
                    <HelpMessage message={createTableMessage}>
                      <button
                        type="button"
                        className="btn btn-lighter-white mt-2"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Crear colección
                      </button>
                    </HelpMessage>
                  </div>
                  <div>
                    <HelpMessage message={importExcelDataMessage}>
                      <button
                        type="button"
                        className="btn btn-lighter-white mt-2 ml-2"
                        onClick={() => setIsImportModalOpen(true)}
                      >
                        <i className="fas fa-file-import mr-2"></i>
                        Importar excel
                      </button>
                    </HelpMessage>
                  </div>
                </div>
              }
            </div>
          </div>
        )}


        {
          function () {

            const activeCollection = collections.find(collection => collection.id == activeCollectionId);
            if (isReadOnly) return;
            if (!activeCollection) return;

            return <div id="table-title" className="d-flex align-items-center w-50">
              <h2 className='ml-3 my-auto mr-2'>
                {activeCollection.name}
              </h2>
              <CollapseMenu
                component={
                  <div className="edit-btn">
                    <i class="fas fa-edit"></i>
                  </div>
                }
                options={[
                  {
                    label: <> <i class="tio-edit dropdown-item-icon"></i> Editar</>,
                    onClick: () => onClickEdit(activeCollection.id)
                  },
                  {
                    label: <> <i class="tio-delete dropdown-item-icon"></i> Eliminar</>,
                    onClick: () => onClickDelete(activeCollection.id)
                  }
                ]}
              />
              {
                embeddingStatus != null && <div
                  className="ml-2"
                >
                  <span className="badge badge-pill badge-primary d-flex align-items-center">
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                    Actualizando... {embeddingStatus != 100 && embeddingStatus != 0 && !isNaN(embeddingStatus) ? `${embeddingStatus?.toFixed(2)}%` : ""}
                  </span>
                </div>
              }
            </div>

          }()
        }


        {activeCollectionId && (
          <div className="px-3 mt-4">
            {
              function () {

                const activeCollection = collections.find(collection => collection.id == activeCollectionId);
                if (!activeCollection) return;

                if (activeCollection.type == "TABLE") return (
                  <Table
                    trackKeybindings={(
                      !isModalOpen && !isEditModalOpen && !isImportModalShown &&
                      !isExportModalShown && !isFilterModalShown && !isOptionModalShown
                    )}
                    isReadOnly={isReadOnly || !showTabs}
                    useCustomPagination={true}
                    useCustomSearch={true}
                    useCustomFilters={true}
                    useCustomImport={true}
                    useCustomExport={true}
                    useCustomSorting={true}
                    showExport={!isReadOnly}
                    showImport={!isReadOnly}
                    showOptions={!isReadOnly}
                    showSearch={!isReadOnly}
                    showFilters={!isReadOnly}
                    showSorting={!isReadOnly}
                    checkValidation={true}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    values={rows}
                    structure={columns}
                    collections={collections}
                    query={query}
                    filters={filters}
                    sorting={sorting}
                    search={search}
                    height={height}
                    loading={loading}
                    onAddColumn={onAddColumn}
                    onChangeColumn={onChangeColumn}
                    onChangeColumnOptions={onChangeColumnOptions}
                    onDeleteColumn={onDeleteColumn}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                    onInsertRow={onInsertRow}
                    onChangeRow={onChangeRow}
                    onImport={onImport}
                    onExport={onExport}
                    onDownloadZip={onDownloadZip}
                    onPageChange={onPageChange}
                    onChangeSearch={onChangeSearch}
                    onChangeQuery={onChangeQuery}
                    onChangeFilters={onChangeFilters}
                    onChangeSorting={onChangeSorting}
                    onClickCell={onClickCell}
                    onShowEditModal={() => setIsEditModalOpen(true)}
                    folioComponent={(data) => {
                      return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data._folio}</label>;
                    }}
                  />
                );

                if (activeCollection.type == "DOCUMENT") return (
                  <Documents
                    query={query}
                    filters={filters}
                    sorting={sorting}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    values={rows}
                    structure={columns}
                    isReadOnly={isReadOnly}
                    useCustomSearch={true}
                    useCustomPagination={true}
                    useCustomFilters={true}
                    useCustomSorting={true}
                    height={height}
                    loading={loading}
                    showFilters={true}
                    showSorting={true}
                    showSearch={true}
                    checkValidation={true}
                    onAddRow={onAddRow}
                    onChangeRow={onChangeRow}
                    onDeleteRow={onDeleteRow}
                    onDownloadZip={onDownloadZip}
                    onPageChange={onPageChange}
                    onChangeSearch={onChangeSearch}
                    onChangeQuery={onChangeQuery}
                    onChangeFilters={onChangeFilters}
                    onChangeSorting={onChangeSorting}
                    folioComponent={(data) => {
                      return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data._folio}</label>;
                    }}
                  />
                );

                if (activeCollection.type == "LIST") return (
                  <List
                    trackKeybindings={(
                      !isModalOpen && !isEditModalOpen && !isImportModalShown &&
                      !isExportModalShown && !isFilterModalShown && !isOptionModalShown
                    )}
                    isReadOnly={isReadOnly || !showTabs}
                    useCustomPagination={true}
                    useCustomSearch={true}
                    useCustomFilters={true}
                    useCustomImport={true}
                    useCustomExport={true}
                    useCustomSorting={true}
                    showExport={!isReadOnly}
                    showImport={!isReadOnly}
                    showOptions={!isReadOnly}
                    showSearch={!isReadOnly}
                    showFilters={!isReadOnly}
                    showSorting={!isReadOnly}
                    checkValidation={true}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    values={rows}
                    structure={columns}
                    collections={collections}
                    query={query}
                    filters={filters}
                    sorting={sorting}
                    search={search}
                    height={height}
                    loading={loading}
                    onAddColumn={onAddColumn}
                    onChangeColumn={onChangeColumn}
                    onChangeColumnOptions={onChangeColumnOptions}
                    onDeleteColumn={onDeleteColumn}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                    onInsertRow={onInsertRow}
                    onChangeRow={onChangeRow}
                    onImport={onImport}
                    onExport={onExport}
                    onDownloadZip={onDownloadZip}
                    onPageChange={onPageChange}
                    onChangeSearch={onChangeSearch}
                    onChangeQuery={onChangeQuery}
                    onChangeFilters={onChangeFilters}
                    onChangeSorting={onChangeSorting}
                    onClickCell={onClickCell}
                    onShowEditModal={() => setIsEditModalOpen(true)}
                    folioComponent={(data) => {
                      return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data._folio}</label>;
                    }}
                  />
                )

              }()
            }

          </div>
        )}

      </div>

      {
        isModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          collectionType={collectionType}
          onSave={onSaveCollection}
          onClose={() => setIsModalOpen(false)}
          component={FormSave}
        />
      }

      {
        isEditModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsEditModalOpen(false)}
          component={FormSet}
        />
      }

      {
        isImportModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsImportModalOpen(false)}
          component={FormImport}
        />
      }

      <WSManager
        initialRoom={collectionRoomName}
        autoSuscribe={true}
        onMessageReceived={onCollectionMessage}
      />

    </BrowserRouter>
  </div>
);

export default CollectionsView;